class CommonJsService {
  isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isAndroidWebView =
      /Android/.test(userAgent) && /wv|Version\/\d+\.\d+/.test(userAgent);

    const isIOSWebView =
      /iPad|iPhone|iPod/.test(userAgent) &&
      !/CriOS|FxiOS|EdgiOS|OPiOS|Mercury|MQQBrowser|Safari/.test(userAgent) &&
      /AppleWebKit/.test(userAgent);

    const isIPadOS13OrLater = (() => {
      return (
        navigator.platform === "MacIntel" &&
        navigator.maxTouchPoints > 1 &&
        !window.MSStream
      );
    })();

    const isWebView = isAndroidWebView || isIOSWebView || isIPadOS13OrLater;

    return isWebView;
  };
}

export default new CommonJsService();
