import { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL, ROUTES, STAFF_POSITION_OPTION } from "../../constants";
import { DataInputForm } from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import { ListingPageHeader } from "./components/ListingPageHeader";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  dayOfMonth: {
    color: "#ffffff",
    padding: "5px 5px 5px 5px",
    border: "0.5px solid #e0e0e0",
  },
  boxes: {
    padding: "5px 5px 5px 5px",
    border: "0.5px solid #e0e0e0",
  },
  greenBoxes: {
    padding: "5px 5px 5px 5px",
    border: "0.5px solid #e0e0e0",
    backgroundColor: "#bee2c0",
  },
  redBoxes: {
    padding: "5px 5px 5px 5px",
    border: "0.5px solid #e0e0e0",
    backgroundColor: "#ff9494",
  },
  tableHead: {
    backgroundColor: "#014598",
  },
  tableHeadRow: {
    color: "#ffffff",
    minwidth: "100px",
    border: "0.5px solid #e0e0e0",
  },
  tableRow: {
    padding: "1px 5px 1px 5px",
    border: "0.5px solid #e0e0e0",
  },
  tableRowTitle: {
    borderTop: "0.5px solid #e0e0e0",
    borderLeft: "0.5px solid #e0e0e0",
    borderBottom: "0.5px solid #e0e0e0",
    padding: "5px 5px 5px 5px",
  },
  tableRowBlank: {
    padding: "5px 5px 5px 5px",
    borderTop: "0.5px solid #e0e0e0",
    borderRight: "0.5px solid #e0e0e0",
    borderBottom: "0.5px solid #e0e0e0",
  },
  tableRowNone: {
    padding: "5px 5px 5px 5px",
    border: "0.5px solid #e0e0e0",
  },
}));

export default function RosterRecurringPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [entity, setEntity] = useState(null);
  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("yyyy-MM")
  );
  const [homeData, setHomeData] = useState([]);
  const [stat, setStat] = useState([]);
  const classes = useStyles();

  const coreCreateFilteringParams = (values) => {
    let params = {};

    params.home = home?.home?.id;

    let dayofmonth = moment(selectedMonth).daysInMonth();
    params.startDate = selectedMonth + "-01";
    params.endDate = selectedMonth + "-" + dayofmonth;

    return params;
  };

  const normalizeReceivedValues = (values) => {};

  const handleFilterChange = (values) => {
    let params = {};

    setSelectedMonth(moment(new Date(values.rosterMonth)).format("yyyy-MM"));
    setFilteringParams(params);
  };

  const getEntities = () => {
    setLoading(true);

    const getFilteringParams = coreCreateFilteringParams;

    let params = getFilteringParams(filteringParams, coreCreateFilteringParams);
    delete params.timestamp; // remove timestamp when getting records

    axios
      .get("/api/homeStaffs", { params: params })
      .then(function (response) {
        let data = response.data;

        setEntity(data.content);
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
      });

    axios
      .get("/api/homeStaffs" + "/dailyStat", { params: params })
      .then(function (response) {
        setStat(response.data);
      })
      .catch(function (response) {});
  };

  const onCreate = (values, actions) => {
    // date need to be the current date for successful submission
    let newMonth = new Date(values.month);
    newMonth.setDate(new Date().getDate());
    values.month = newMonth.toISOString();
    // end of date need to be the current date for successful submission
    axios
      .post(API_URL.STAFF_ROSTER_RECURRING, {
        homeStaff: values.roster.id,
        startDate: values.month,
        endDate: values.month,
        startTime: values.startTime,
        endTime: values.endTime,
        tag: values.tag,
        recurringOrders: values.recurringOrders,
        stillApprove: values.stillApprove,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        getEntities();
        actions.setSubmitting(false);
        setCreating(false);
      })
      .catch((error) => {
        if (error.response.data?.errors[0].includes("schedule")) {
          dispatch(
            NotificationActions.error(
              null,
              `此員工於以下日期及時間被編更，是否仍要通過？
                ${error.response.data.errors
                  .map((e) => {
                    return e.substring(10);
                  })
                  .join("\n")}`
            )
          );
        } else {
          dispatch(NotificationActions.error(null, "錯誤"));
        }
        actions.setSubmitting(false);
      });
  };

  const DayOfMonth = () => {
    let dayOfMonth = [];
    for (let day = 1; day <= moment(selectedMonth).daysInMonth(); day++) {
      dayOfMonth.push(
        <TableCell key={day} align="center" className={classes.dayOfMonth}>
          {day}
        </TableCell>
      );
    }
    return dayOfMonth;
  };

  const WeekDayOfMonth = () => {
    let weekOfMonth = [];
    for (let day = 1; day <= moment(selectedMonth).daysInMonth(); day++) {
      weekOfMonth.push(
        <TableCell key={day} align="center" className={classes.dayOfMonth}>
          {/* <FormattedMessage
            id={moment(
              selectedMonth + "-" + ("0" + day).slice(-2)
            ).isoWeekday()}
          /> */}
          <FormattedMessage
            id={moment(
              selectedMonth + "-" + ("0" + day).slice(-2)
            ).isoWeekday()}
          />
        </TableCell>
      );
    }
    return weekOfMonth;
  };

  const SelectedDay = (row) => {
    let tableRow = [],
      selectedDay = [];
    row.row.rosterRecurring?.map((tag) => {
      tag.rosterSessions?.map((days) => {
        selectedDay.push({ day: days.day, tag: days.tag });
      });
    });

    for (let day = 1; day <= moment(selectedMonth).daysInMonth(); day++) {
      let i = 0;
      tableRow.push(
        <TableCell key={day} align="center" className={classes.boxes}>
          {selectedDay.map((item) => {
            if (
              item.day.includes(selectedMonth + "-" + ("0" + day).slice(-2))
            ) {
              return item.tag;
            } else {
              i++;
              return i === selectedDay.length ? "O" : "";
            }
          })}
        </TableCell>
      );
    }
    return tableRow;
  };

  const WorkingPeople = (props) => {
    const { data } = props;
    const [stat, setStat] = useState([]);

    useEffect(() => {
      let rows = [];

      data?.forEach((datum) => {
        rows[datum.date] = datum.count;
      });

      setStat(rows);
    }, [data]);

    let daysOfMonth = [];
    for (let day = 1; day <= moment(selectedMonth).daysInMonth(); day++) {
      let date = selectedMonth + "-" + ("0" + day).slice(-2);

      daysOfMonth.push(
        <TableCell key={day} align="center" className={classes.boxes}>
          {stat[date] || 0}
        </TableCell>
      );
    }
    return daysOfMonth;
  };

  const OwedPeople = (props) => {
    const { required, data } = props;
    const [stat, setStat] = useState([]);

    useEffect(() => {
      let rows = [];

      data?.forEach((datum) => {
        rows[datum.date] = datum.count;
      });

      setStat(rows);
    }, [data]);

    let daysOfMonth = [];
    for (let day = 1; day <= moment(selectedMonth).daysInMonth(); day++) {
      let date = selectedMonth + "-" + ("0" + day).slice(-2);
      let owedNo = Math.max(required - (stat[date] || 0), 0);

      daysOfMonth.push(
        <TableCell
          key={day}
          align="center"
          className={clsx({
            [classes.greenBoxes]: owedNo <= 0,
            [classes.redBoxes]: owedNo > 0,
          })}
        >
          {owedNo}
        </TableCell>
      );
    }
    return daysOfMonth;
  };

  function sortPosition(a, b) {
    var sortingOrder = ["HW", "RNEN", "HW", "CW", "AW"];
    return sortingOrder.indexOf(a.position) - sortingOrder.indexOf(b.position);
  }

  useEffect(() => {
    setLoading(true);
    axios.get("/api/homes/" + home?.home?.id).then((response) => {
      let data = response.data;
      setHomeData(data);
      setLoading(false);
    });
    setApiUrl({
      getAll: API_URL.HOME_STAFF,
      getOne: API_URL.HOME_STAFF,
      create: API_URL.STAFF_ROSTER_RECURRING,
      edit: API_URL.STAFF_ROSTER_RECURRING,
      delete: API_URL.STAFF_ROSTER_RECURRING,
      print: "/api/homes/doc/staffSchedule/print",
    });
    getEntities();
  }, [home, filteringParams]);

  return (
    <div>
      <ListingPageHeader
        homeData={homeData}
        handleFilterChange={handleFilterChange}
        filteringParams={filteringParams}
        setCreating={setCreating}
        apiUrl={apiUrl}
        selectedMonth={selectedMonth}
      />
      <Paper elevation={0}>
        <TableContainer>
          <Table size="small">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell
                  align="center"
                  rowSpan={2}
                  className={classes.tableHeadRow}
                >
                  姓名
                </TableCell>
                <TableCell
                  align="center"
                  rowSpan={2}
                  className={classes.tableHeadRow}
                >
                  職級
                </TableCell>
                <TableCell
                  align="center"
                  rowSpan={2}
                  className={classes.tableHeadRow}
                >
                  上班時間
                </TableCell>
                <TableCell
                  align="center"
                  rowSpan={2}
                  className={classes.tableHeadRow}
                >
                  工作時數
                </TableCell>
                <DayOfMonth />
              </TableRow>
              <TableRow>
                <WeekDayOfMonth />
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody style={{ height: 450 }}>
                <TableRow rowSpan={20}>
                  <TableCell
                    align="center"
                    colSpan={36}
                    className={classes.tableRowBlank}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.tableRowTitle}>
                    日更
                  </TableCell>
                  <TableCell colSpan={36} className={classes.tableRowBlank}>
                    &nbsp;
                  </TableCell>
                </TableRow>
                {entity && entity.filter((row) => row.roster == "DAY") != "" ? (
                  entity
                    .filter((row) => row.roster == "DAY")
                    .sort(sortPosition)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.staffUser.nameTc}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {
                            STAFF_POSITION_OPTION.find(
                              (elem) => elem.value == row.position
                            )?.label
                          }
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingStartTime.substring(0, 5) +
                            " - " +
                            row.workingEndTime.substring(0, 5)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingHours + " 小時"}
                        </TableCell>
                        <SelectedDay row={row} />
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={36}
                      align="center"
                      className={classes.tableRowNone}
                    >
                      未有任何日更員工
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="left"
                    className={classes.tableRowTitle}
                  >
                    日更替假
                  </TableCell>
                  <TableCell colSpan={36} className={classes.tableRowBlank}>
                    &nbsp;
                  </TableCell>
                </TableRow>
                {entity &&
                entity.filter((row) => row.roster == "DAY_SUBSTITUTE") != "" ? (
                  entity
                    .filter((row) => row.roster == "DAY_SUBSTITUTE")
                    .sort(sortPosition)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.staffUser.nameTc}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {
                            STAFF_POSITION_OPTION.find(
                              (elem) => elem.value == row.position
                            )?.label
                          }
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingStartTime.substring(0, 5) +
                            " - " +
                            row.workingEndTime.substring(0, 5)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingHours + " 小時"}
                        </TableCell>
                        <SelectedDay row={row} />
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={36}
                      align="center"
                      className={classes.tableRowNone}
                    >
                      未有任何日更替假員工
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.tableRowTitle}>
                    夜更
                  </TableCell>
                  <TableCell colSpan={36} className={classes.tableRowBlank}>
                    &nbsp;
                  </TableCell>
                </TableRow>
                {entity &&
                entity.filter((row) => row.roster == "NIGHT") != "" ? (
                  entity
                    .filter((row) => row.roster == "NIGHT")
                    .sort(sortPosition)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.staffUser.nameTc}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {
                            STAFF_POSITION_OPTION.find(
                              (elem) => elem.value == row.position
                            )?.label
                          }
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingStartTime.substring(0, 5) +
                            " - " +
                            row.workingEndTime.substring(0, 5)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingHours + " 小時"}
                        </TableCell>
                        <SelectedDay row={row} />
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={36}
                      align="center"
                      className={classes.tableRowNone}
                    >
                      未有任何夜更員工
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="left"
                    className={classes.tableRowTitle}
                  >
                    夜更替假
                  </TableCell>
                  <TableCell colSpan={36} className={classes.tableRowBlank}>
                    &nbsp;
                  </TableCell>
                </TableRow>
                {entity &&
                entity.filter((row) => row.roster == "NIGHT_SUBSTITUTE") !=
                  "" ? (
                  entity
                    .filter((row) => row.roster == "NIGHT_SUBSTITUTE")
                    .sort(sortPosition)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.staffUser.nameTc}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {
                            STAFF_POSITION_OPTION.find(
                              (elem) => elem.value == row.position
                            )?.label
                          }
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingStartTime.substring(0, 5) +
                            " - " +
                            row.workingEndTime.substring(0, 5)}
                        </TableCell>
                        <TableCell align="center" className={classes.tableRow}>
                          {row.workingHours + " 小時"}
                        </TableCell>
                        <SelectedDay row={row} />
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={36}
                      align="center"
                      className={classes.tableRowNone}
                    >
                      未有任何夜更替假員工
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    日更HM
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.dayHmCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.dayNumHm}
                    data={stat?.dayHmCount}
                  />
                </TableRow>

                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    日更RN/EN
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.dayRnEnCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.dayNumRnEn}
                    data={stat?.dayRnEnCount}
                  />
                </TableRow>

                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    日更HW
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.dayHwCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.dayNumHw}
                    data={stat?.dayHwCount}
                  />
                </TableRow>

                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    日更CW
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.dayCwCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.dayNumCw}
                    data={stat?.dayCwCount}
                  />
                </TableRow>

                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    日更AW
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.dayAwCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.dayNumAw}
                    data={stat?.dayAwCount}
                  />
                </TableRow>

                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    夜更HW
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.nightHwCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.nightNumHw}
                    data={stat?.nightHwCount}
                  />
                </TableRow>

                <TableRow>
                  <TableCell
                    rowSpan={2}
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    夜更CW
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    工作人數
                  </TableCell>
                  <WorkingPeople data={stat?.nightCwCount} />
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    className={classes.tableRow}
                  >
                    尚欠人數
                  </TableCell>
                  <OwedPeople
                    required={homeData.nightNumCw}
                    data={stat?.nightCwCount}
                  />
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    //rowsPerPage={rowsPerPage}
                    page={page}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
      </Paper>
      <DataInputForm
        open={creating}
        onClose={() => {
          setCreating(false);
          dispatch(NotificationActions.clear());
        }}
        onSubmit={onCreate}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </div>
  );
}
