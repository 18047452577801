import * as Yup from 'yup';
import { useState, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from '../../../components/dialogs';
import FormikTextField from "../../../components/inputs/FormikTextField";

export const initialValues = {
    prefix: '',
    from: '',
    to: '',
}

export const validationSchema = Yup.object().shape({
    prefix: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    from: Yup.string()
        .min(1, "手帶編號最少為1個位")
        .max(4, "手帶編號最多為4個位")
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    to: Yup.string()
        .min(1, "手帶編號最少為1個位")
        .max(4, "手帶編號最多為4個位")
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export function BatchCreateDialog(props) {
    const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } = props;
    const [entityValues, setEntityValues] = useState();
    const [loaded, setLoaded] = useState(false);

    return (
        <FormikDialog
            open={open}
            dialogTitle={"批量新增手帶"}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onClose={onClose}
            maxWidth="xs"
        >
            <BatchCreateForm
                entity={entityValues}
            />
        </FormikDialog>
    );
}

const BatchCreateForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", } = props;
    const [beds, setBeds] = useState([]);
    const [detailEntity, setDetailEntity] = useState({});
    const [loaded, setLoaded] = useState(false);
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();


    useLayoutEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: { ...initialValues, ...entity } });
        } else {
            resetForm({ values: initialValues });
        }
    }, [entity]);

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                    <Card variant="outlined" style={{ marginBottom: 15 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <FormikTextField
                                        required
                                        label="手帶編號前綴"
                                        name="prefix"
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormikTextField
                                        required
                                        label="手帶編號由"
                                        name="from"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <FormikTextField
                                        required
                                        label="手帶編號至"
                                        name="to"
                                        type="number"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
}