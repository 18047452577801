import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikHKIDField from "../../../components/inputs/FormikHKIDField";
import {
  API_URL,
  DOWN_PAYMENT_METHOD_OPTION,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import InputAdornment from "@material-ui/core/InputAdornment";

export const initialValues = {
  lastnameTc: "",
  lastname: "",
  firstnameTc: "",
  firstname: "",
  gender: null,
  dateOfBirth: "",
  hkid: "",
  contactName: "",
  contactNo: "",
  contactRelationship: "",
  roomRemarks: "",
  estimatedCheckInDate: moment(new Date()).toISOString(),
  bed: "",
  residentBedType: "",
  ldsNo: "",
  evaluationResult: "NOT_EVALUATED",
  evaluationDate: "",
};

export const validationSchema = Yup.object().shape({
  lastnameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  lastname: Yup.string()
    // .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  firstnameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  firstname: Yup.string()
    // .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  gender: Yup.string()
    // .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dateOfBirth: Yup.date()
    // .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  hkid: Yup.string()
    //.required(<FormattedMessage id="validation.message.required" />).nullable(),
    .matches(
      /^([A-Z]{1,2})([0-9]{6})([(]{1})([A0-9])([)]{1})$/,
      "請輸入正確的身份證號碼"
    )
    .min(8, "身份證號碼長度必須為8個位或以上"),
  contactName: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  contactNo: Yup.string()
    .min(8, "電話長度必須為8個位")
    .max(8, "電話長度必須為8個位")
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // contactRelationship: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // roomRemarks: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  estimatedCheckInDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  bed: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  residentBedType: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // ldsNo: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationResult: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationDate: Yup.date()
  //     .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable()
  downPayment: Yup.boolean(),
  downPrice: Yup.string().when("downPayment", {
    is: true,
    then: Yup.string().required(
      <FormattedMessage id="validation.message.required" />
    ),
  }),
  downPriceConfirmation: Yup.string().when("downPrice", (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return schema
      .oneOf([Yup.ref("downPrice")], "確認金額與金額不符")
      .required(<FormattedMessage id="validation.message.required" />);
  }),
  downIssueTime: Yup.string().when("downPayment", {
    is: true,
    then: Yup.string().required(
      <FormattedMessage id="validation.message.required" />
    ),
  }),
  downPaymentMethod: Yup.string().when("downPayment", {
    is: true,
    then: Yup.string().required(
      <FormattedMessage id="validation.message.required" />
    ),
  }),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [payNow, setPayNow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  const setLunarDateOfBirthAndAge = (value) => {
    setFieldValue(
      "lunarDateOfBirth",
      value ? moment(value).lunar().format("YYYY-MM-DD") : ""
    );
    setFieldValue("age", moment().diff(value, "years", false) || "0");
  };

  const handleDateOfBirthChange = (value) => {
    setFieldValue("dateOfBirth", moment(value).format("YYYY-MM-DD"));
    setLunarDateOfBirthAndAge(value);
  };

  const handlePayNowChange = (event) => {
    if (event.target.checked) {
      setPayNow(true);
      setFieldValue("downIssueTime", moment(new Date()).toISOString());
    } else {
      setPayNow(false);
      setFieldValue("downIssueTime", "");
    }
  };

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
      setLunarDateOfBirthAndAge(entity.dateOfBirth);
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  個人資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="中文姓氏"
                      name="lastnameTc"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="中文名字"
                      name="firstnameTc"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      //required
                      label="英文姓氏"
                      name="lastname"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      //required
                      label="英文名字"
                      name="firstname"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikSelectField
                      //required
                      label="性別"
                      name="gender"
                      options={GENDER_OPTIONS}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikHKIDField label="身份證號碼" name="hkid" />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikTextField
                      label="其他證明文件號碼"
                      name="homeReturnPermitId"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikDateField
                      //required
                      startTime
                      label="出生日期"
                      name="dateOfBirth"
                      onChange={handleDateOfBirthChange}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikDateField
                      //required
                      disabled
                      label="農曆"
                      name="lunarDateOfBirth"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikTextField
                      //required
                      disabled
                      label="年齡"
                      name="age"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  床位資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormikReferenceField
                      edit={entity ? true : false}
                      required
                      label="床號"
                      name="bed"
                      apiUrl={API_URL.BED + "/references"}
                      apiParams={{
                        homeId: home?.home.id,
                        status: "AVAILABLE",
                      }}
                      getOptionLabel={(option) => option?.bedNo}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormikReferenceField
                      edit={entity ? true : false}
                      required
                      label="床位類別"
                      name="residentBedType"
                      apiUrl={"/api/residentBedTypes"}
                      getOptionLabel={(option) => option?.type}
                      apiParams={{
                        unpaged: true,
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormikDateField
                      startTime
                      required
                      label="預計入住日期"
                      name="estimatedCheckInDate"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  聯絡人資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormikTextField
                      required
                      label="聯絡人"
                      name="contactName"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="聯絡人電話"
                      name="contactNo"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField label="關係" name="contactRelationship" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  備註
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormikTextField label="房間備註" name="roomRemarks" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  評核資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormikTextField label="LDS編號" name="ldsNo" />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormikSelectField
                      label="評核結果"
                      name="evaluationResult"
                      options={EVALUATION_RESULT_OPTION}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormikDateField
                      startTime
                      label="評核日期"
                      name="evaluationDate"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              訂金資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={2} xs={12}>
                <FormikCheckboxField
                  label="立即遞交訂金"
                  name="downPayment"
                  onChange={handlePayNowChange}
                />
              </Grid>
              <Grid item sm={10} xs={12}>
                {payNow && (
                  <Grid container spacing={2}>
                    <Grid item sm={3} xs={12}>
                      <FormikTextField
                        required
                        label="訂金金額"
                        name="downPrice"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <FormikTextField
                        required
                        label="確認訂金金額"
                        name="downPriceConfirmation"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <FormikDateTimeField
                        required
                        label="訂金日期及時間"
                        name="downIssueTime"
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <FormikSelectField
                        required
                        label="付款方法"
                        name="downPaymentMethod"
                        options={DOWN_PAYMENT_METHOD_OPTION}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
