import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, DEATH_REASON_OPTIONS, GENDER_OPTIONS } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

export default function DeathRecordPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;
    params.resident = params.resident.id || params.resident;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;
    params.resident = values.resident;

    if (params.dateFrom != null) {
      params.dateFrom = moment(values.dateFrom).format("YYYY-MM-DD");
    }

    if (params.dateTo != null) {
      params.dateTo = moment(values.dateTo).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.residentName =
      values.resident.lastnameTc + values.resident.firstnameTc;
    values.resident = values.resident.id;
    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "hkid",
        headerName: "身份證號碼",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].hkid;
        },
      },
      {
        field: "gender",
        headerName: "性別",
        width: 70,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return GENDER_OPTIONS.find(
            (elem) => elem.value == params.row["resident"].gender
          )?.label;
        },
      },
      // {
      //     field: 'age', headerName: '年齡', width: 50, sortable: false, filterable: false, renderCell: function (params) {
      //         return params.row['resident'].age;
      //     }
      // },
      {
        field: "checkInDate",
        headerName: "入住院舍日期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].checkInDate?.substring(0, 10);
        },
      },
      {
        field: "hospitalLocation",
        headerName: "留醫地點",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "hospitalDate",
        headerName: "留醫日期",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "deathLocation",
        headerName: "死亡地點",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "deathDate",
        headerName: "死亡日期",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "reason",
        headerName: "死亡原因",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return DEATH_REASON_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "source",
        headerName: "資料來源",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.DEATH,
      getOne: API_URL.DEATH,
      create: API_URL.DEATH,
      edit: API_URL.DEATH,
      delete: API_URL.DEATH,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="死亡記錄"
      editableFields={[
        "resident",
        "hospitalLocation",
        "hospitalDate",
        "deathLocation",
        "deathDate",
        "reason",
        "source",
        "remarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    />
  );
}
