import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  ROUTES,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
  CHECK_IN_STATUS_OPTIONS,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import { initialFilteringValues } from "./components/FilteringForm";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Image from "../../components/Image";

export default function ResidentManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    /*if (params.licenseExpiryDate) {
            if (typeof params.licenseExpiryDate === "string") {
                params.licenseExpiryDate = params.licenseExpiryDate.substring(0, 10);
            } else {
                params.licenseExpiryDate = format(params.licenseExpiryDate, "yyyy-MM-dd");
            }
        }*/

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (values.hkid === "_______(_)") {
      values.hkid = null;
    }

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.bed && (values.bed = values.bed?.id);

    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {} = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <Link to={ROUTES.RESIDENT.replace(":id", params.row.id)}>
              <Tooltip title="修改">
                <IconButton color="default" size="small">
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Link>
          );
        },
      },
      {
        field: "photoUrl",
        headerName: "相片",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? (
            <Image
              url={params.row[params.field]}
              style={{ maxWidth: 48, maxHeight: 32 }}
            />
          ) : null;
        },
      },
      {
        field: "nameTc",
        headerName: "姓名 (中文)",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["lastnameTc"] + params.row["firstnameTc"];
        },
      },
      {
        field: "name",
        headerName: "姓名 (英文)",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["lastname"] && params.row["firstname"]
            ? params.row["lastname"] + " " + params.row["firstname"]
            : "--";
        },
      },
      {
        field: "bed",
        headerName: "床位",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["bed"] ? params.row["bed"]["bedNo"] : null;
        },
      },
      {
        field: "gender",
        headerName: "性別",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return GENDER_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "dateOfBirth",
        headerName: "出生日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "evaluationResult",
        headerName: "評核結果",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return EVALUATION_RESULT_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "hkid",
        headerName: "身份證號碼",
        width: 150,
        sortable: false,
        filterable: false,
      },
      {
        field: "checkInStatus",
        headerName: "院友狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return CHECK_IN_STATUS_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT + "/search",
      getOne: API_URL.RESERVATION,
      create: API_URL.RESERVATION,
      edit: API_URL.RESERVATION,
      delete: API_URL.RESERVATION,
      export: API_URL.RESIDENT + "/export",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="院友"
      //editableFields={['name', 'nameTc', 'address', 'addressTc', 'tel', 'fax', 'website', 'email', 'license', 'licenseExpiryDate', 'brNo', 'typeOfService', 'servicePurposeAndGoals', 'logo']}
      disableCreate
      disableEdit
      disableDelete
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    ></EntityManagement>
  );
}
