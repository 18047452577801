import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";

export default function MedicalRecordManagementPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.resident = params.resident.id || params.resident;

    params.medicalItems = (params.medicalItems || []).map((i) => {
      return {
        id: i.id,
        medicalItem: i.medicalItem,
        startDate: moment(i.startDate).format("YYYY-MM-DD"),
        endDate: moment(i.endDate).format("YYYY-MM-DD"),
      };
    });

    params.medicineAllergys = (params.medicineAllergys || []).map((i) => {
      // return { medicine: i.nameTc };
      return { name: i.name };
    });
    params.medicalAdverseReactions = (params.medicalAdverseReactions || []).map((i) => {
      // return { medicine: i.nameTc };
      return { name: i.name };
    });
    // params.icd9 = (params.icd9 || []).map((i) => {
    //     return {id: i.id};
    // });

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.medicalAdverseReactions = values.medicalAdverseReactions.map(
      (item) => { return { name:
        item.name ||
        ((item.medicine?.code && item.medicine?.namePackageDose) ? `${item.medicine?.code} - ${item.medicine?.namePackageDose}` : null) ||
        item.medicine?.namePackageDose ||
        item.medicine?.name ||
        item.medicine?.id
      } }
    );
    values.medicineAllergys = values.medicineAllergys.map(
      (item) => { return { name:
        item.name ||
        ((item.medicine?.code && item.medicine?.namePackageDose) ? `${item.medicine?.code} - ${item.medicine?.namePackageDose}` : null) ||
        item.medicine?.namePackageDose ||
        item.medicine?.name ||
        item.medicine?.id
      } }
    );
    values.medicalItems = values.medicalItems.map((item) => ({
      id: item.id,
      medicalItem: item.medicalItem.id,
      startDate: item.startDate,
      endDate: item.endDate,
    }));
    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 50,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row["resident"].lastnameTc +
            params.row["resident"].firstnameTc
          );
        },
      },
      {
        field: "bed",
        headerName: "床號",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.bed?.bedNo;
        },
      },
      {
        field: "medicalItems",
        headerName: "病歷摘要",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {params.row[params.field]
                    ? params.row[params.field].map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{item.medicalItem.nameTc}</TableCell>
                          </TableRow>
                        );
                      })
                    : "--"}
                </TableBody>
              </Table>
            </div>
          );
        },
      },
      {
        field: "diagnosis",
        headerName: "診斷",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "medicalAdverseReactions",
        headerName: "不良反應藥物",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {params.row[params.field]
                    ? params.row[params.field].map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {
                              (item.medicine)
                              ? item.medicine.code +
                              " - " +
                              item.medicine.namePackageDose
                              : item.name
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : "--"}
                </TableBody>
              </Table>
            </div>
          );
        },
      },
      {
        field: "medicineAllergys",
        headerName: "藥物過敏",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {params.row[params.field]
                    ? params.row[params.field].map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {
                              (item.medicine)
                                ? item.medicine.code +
                                " - " +
                                item.medicine.namePackageDose
                                : item.name
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : "--"}
                </TableBody>
              </Table>
            </div>
          );
        },
      },
      {
        field: "foodAllergys",
        headerName: "食物過敏",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {params.row[params.field]
                    ? params.row[params.field].map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{item.nameTc}</TableCell>
                          </TableRow>
                        );
                      })
                    : "--"}
                </TableBody>
              </Table>
            </div>
          );
        },
      },
      {
        field: "othersAllergys",
        headerName: "其他過敏",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {params.row[params.field]
                    ? params.row[params.field].map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{item.nameTc}</TableCell>
                          </TableRow>
                        );
                      })
                    : "--"}
                </TableBody>
              </Table>
            </div>
          );
        },
      },
      {
        field: "allergyRemarks",
        headerName: "過敏備註",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_MEDICAL_INFO,
      getOne: API_URL.RESIDENT_MEDICAL_INFO,
      create: API_URL.RESIDENT_MEDICAL_INFO,
      edit: API_URL.RESIDENT_MEDICAL_INFO,
      delete: API_URL.RESIDENT_MEDICAL_INFO,
      export: API_URL.RESIDENT_MEDICAL_INFO + "/export",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="病歷管理"
      editableFields={[
        "resident",
        "medicalItems",
        "diagnosis",
        "medicalAdverseReactions",
        "medicineAllergys",
        "foodAllergys",
        "othersAllergys",
        "allergyRemarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      rowHeight={"250"}
      maxWidth="lg"
    ></EntityManagement>
  );
}
