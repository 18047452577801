import * as Yup from "yup";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useFormikContext, FieldArray } from "formik";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import FormikTextAreaField from "../../../../components/inputs/FormikTextAreaField";
import axios from "axios";
import {
  API_URL,
  GENDER_OPTIONS,
  CHECK_IN_STATUS_OPTIONS,
} from "../../../../constants";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../../components/inputs/FormikDateField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import { useParams } from "react-router-dom";
import Image from "../../../../components/Image";

export const initialValues = {
  resident: "",
  medicalItems: [],
  //icd9: [],
  diagnosis: "",
  medicalAdverseReactions: [],
  medicineAllergys: [],
  foodAllergys: [],
  othersAllergys: [],
  startDate: "",
  endDate: "",
  allergyRemarks: "",
};

export const validationSchema = Yup.object().shape({
  medicalItems: Yup.array()
    .of(
      Yup.object().shape({
        medicalItem: Yup.string().required(
          <FormattedMessage id="validation.message.required" />
        ),
      })
    )
    .required(<FormattedMessage id="validation.message.required" />),
    // FIXME:
    medicineAllergys: Yup.array().of(
      Yup.mixed().required(<FormattedMessage id="validation.message.required" />)
    ),
  // medicineAllergys: Yup.array()
  //   .of(
  //     // Yup.mixed().oneOf([
  //     //   Yup.number().required(),
  //     //   // Yup.string().required(),
  //     //   Yup.object().shape({
  //     //     nameTc: Yup.string().required(
  //     //       <FormattedMessage id="validation.message.required" />
  //     //     )
  //     //   })
  //     // ])
  //     // Yup.string().required(
  //     //   <FormattedMessage id="validation.message.required" />
  //     // )
  //     Yup.object().shape({
  //       medicine: Yup.string().required(
  //         <FormattedMessage id="validation.message.required" />
  //       )
  //     })
  //   )
  //   .required(<FormattedMessage id="validation.message.required" />),
    // FIXME:
    medicalAdverseReactions: Yup.array().of(
      Yup.mixed().required(<FormattedMessage id="validation.message.required" />)
    ),
  // medicalAdverseReactions: Yup.array()
  //   .of(
  //     // Yup.object().shape({
  //     //   nameTc: Yup.string().required(
  //     //     <FormattedMessage id="validation.message.required" />
  //     //   ),
  //     // })
  //     Yup.string().required(
  //       <FormattedMessage id="validation.message.required" />
  //     )
  //   )
  //   .required(<FormattedMessage id="validation.message.required" />),
  foodAllergys: Yup.array()
    .of(
      Yup.object().shape({
        nameTc: Yup.string().required(
          <FormattedMessage id="validation.message.required" />
        ),
      })
    )
    .required(<FormattedMessage id="validation.message.required" />),
  othersAllergys: Yup.array()
    .of(
      Yup.object().shape({
        nameTc: Yup.string().required(
          <FormattedMessage id="validation.message.required" />
        ),
      })
    )
    .required(<FormattedMessage id="validation.message.required" />),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  let { id } = useParams();
  const [infoEntity, setInfoEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
    handleChange,
  } = useFormikContext();
  const [guarantor, setGuarantor] = useState(null);

  const handleMedicalItemChange = (event, value, index) => {
    setFieldValue(`medicalItems.${index}.name`, value?.name);
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    axios
      .get(API_URL.RESIDENT + "/" + id)
      .then((response) => {
        setInfoEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
    if (entity) {
      if (entity.contactList) {
        setGuarantor(entity.contactList.find((i) => i.guarantor));
      } else {
        setGuarantor(null);
      }
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={9} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              院友病歷資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <fieldset>
                  <FormLabel component="legend">病歷摘要</FormLabel>
                  <FieldArray
                    name="medicalItems"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  英文名稱
                                </TableCell>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  開始日期
                                </TableCell>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  結束日期
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.medicalItems &&
                                values.medicalItems.map((item, index) => (
                                  <TableRow key={index} disablegutters>
                                    <TableCell
                                      width={200}
                                      style={{
                                        paddingRight: 1,
                                        paddingTop: 4,
                                      }}
                                    >
                                      <FormikReferenceField
                                        edit={entity ? true : false}
                                        label="項目"
                                        apiUrl={API_URL.MEDICAL_ITEM}
                                        apiParams={{
                                          unpaged: true,
                                        }}
                                        getOptionLabel={(option) =>
                                          option.nameTc
                                        }
                                        getOptionValue={(option) => option?.id}
                                        name={`medicalItems.${index}.medicalItem`}
                                        onChange={(event, value) =>
                                          handleMedicalItemChange(
                                            event,
                                            value,
                                            index
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell style={{ paddingRight: 1 }}>
                                      <FormikTextField
                                        label="英文名稱"
                                        disabled
                                        name={`medicalItems.${index}.name`}
                                      />
                                    </TableCell>
                                    <TableCell style={{ paddingRight: 1 }}>
                                      <FormikDateField
                                        label="開始日期"
                                        name={`medicalItems.${index}.startDate`}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <FormikDateField
                                        label="結束日期"
                                        name={`medicalItems.${index}.endDate`}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <DeleteOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          style={{ marginRight: 15 }}
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                        <span style={{ color: "red" }}>
                          {errors.medicalItems &&
                            touched.medicalItems &&
                            "請填寫欄位"}
                        </span>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              {/* <Grid item sm={6} xs={12}>
                                <fieldset>
                                    <FormLabel component="legend">ICD-9</FormLabel>
                                    <FieldArray
                                        name="icd9"
                                        render={arrayHelpers => (
                                            <div>
                                                <TableContainer style={{ maxHeight: 400 }}>
                                                    <Table stickyHeader padding="none" style={{ marginBottom: 15 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell omponent="th" style={{ padding: 10 }}>
                                                                    編號
                                                            </TableCell>
                                                                <TableCell omponent="th" style={{ padding: 10 }}>
                                                                    中文名稱
                                                            </TableCell>
                                                                <TableCell align="right" style={{ width: 50 }}>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                values.icd9 && values.icd9.map((item, index) => (
                                                                    <TableRow key={index} disablegutters>
                                                                        <TableCell style={{ paddingRight: 1 }}>
                                                                            <FormikReferenceField
                    edit={entity ? true : false}
                                                                                label="編號"
                                                                                apiUrl={API_URL.ICD9}
                                                                                getOptionLabel={(option) => (option.code)}
                                                                                getOptionValue={(option) => (option?.id)}
                                                                                name={`icd9.${index}.id`}
                                                                                onChange={(event, value) => handleICD9Change(event, value, index)}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <FormikTextField
                                                                                label="中文名稱"
                                                                                disabled
                                                                                name={`icd9.${index}.nameTc`}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <IconButton onClick={() => arrayHelpers.remove(index)}>
                                                                                <DeleteOutlinedIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Button color="default" variant="contained" type="button" size="small" onClick={() => arrayHelpers.push('')}>
                                                    新增
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </fieldset>
                            </Grid> */}
              <Grid item sm={12} xs={12}>
                <FormikTextAreaField label="診斷" name="diagnosis" rows="2" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              院友過敏資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <fieldset>
                  <FormLabel component="legend">過敏藥物</FormLabel>
                  <FieldArray
                    name="medicineAllergys"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.medicineAllergys &&
                                values.medicineAllergys.map((item, index) => (
                                  <TableRow key={index} disablegutters>
                                    <TableCell style={{ paddingRight: 1 }}>
                                      {/* <FormikReferenceField
                                        edit={entity ? true : false}
                                        required
                                        label="過敏藥物"
                                        apiUrl={"/api/medicines"}
                                        getOptionLabel={(option) =>
                                          option?.code +
                                          " - " +
                                          option?.namePackageDose
                                        }
                                        name={`medicineAllergys.${index}.nameTc`}
                                      /> */}
                                      <FormikTextField
                                        edit={entity ? true : false}
                                        required
                                        label="過敏藥物"
                                        name={`medicineAllergys.${index}.name`}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <DeleteOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          style={{ marginRight: 15 }}
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                        <span style={{ color: "red" }}>
                          {errors.medicineAllergys &&
                            touched.medicineAllergys &&
                            "請填寫欄位"}
                        </span>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              <Grid item sm={6} xs={12}>
                <fieldset>
                  <FormLabel component="legend">不良反應藥物</FormLabel>
                  <FieldArray
                    name="medicalAdverseReactions"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.medicalAdverseReactions &&
                                values.medicalAdverseReactions.map(
                                  (item, index) => (
                                    <TableRow key={index} disablegutters>
                                      <TableCell style={{ paddingRight: 1 }}>
                                        {/* <FormikReferenceField
                                          edit={entity ? true : false}
                                          required
                                          label="不良反應藥物"
                                          apiUrl={"/api/medicines"}
                                          getOptionLabel={(option) =>
                                            option?.code +
                                            " - " +
                                            option?.namePackageDose
                                          }
                                          name={`medicalAdverseReactions.${index}.nameTc`}
                                        /> */}
                                        <FormikTextField
                                          edit={entity ? true : false}
                                          required
                                          label="過敏藥物"
                                          name={`medicalAdverseReactions.${index}.name`}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <DeleteOutlinedIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          style={{ marginRight: 15 }}
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                        <span style={{ color: "red" }}>
                          {errors.medicalAdverseReactions &&
                            touched.medicalAdverseReactions &&
                            "請填寫欄位"}
                        </span>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              <Grid item sm={6} xs={12}>
                <fieldset>
                  <FormLabel component="legend">食物過敏</FormLabel>
                  <FieldArray
                    name="foodAllergys"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.foodAllergys &&
                                values.foodAllergys.map((item, index) => (
                                  <TableRow key={index} disablegutters>
                                    <TableCell style={{ paddingRight: 1 }}>
                                      <FormikTextField
                                        label="食物過敏"
                                        name={`foodAllergys.${index}.nameTc`}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <DeleteOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          style={{ marginRight: 15 }}
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                        <span style={{ color: "red" }}>
                          {errors.foodAllergys &&
                            touched.foodAllergys &&
                            "請填寫欄位"}
                        </span>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              <Grid item sm={6} xs={12}>
                <fieldset>
                  <FormLabel component="legend">其他過敏</FormLabel>
                  <FieldArray
                    name="othersAllergys"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.othersAllergys &&
                                values.othersAllergys.map((item, index) => (
                                  <TableRow key={index} disablegutters>
                                    <TableCell style={{ paddingRight: 1 }}>
                                      <FormikTextField
                                        label="其他過敏"
                                        name={`othersAllergys.${index}.nameTc`}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <DeleteOutlinedIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          style={{ marginRight: 15 }}
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                        <span style={{ color: "red" }}>
                          {errors.othersAllergys &&
                            touched.othersAllergys &&
                            "請填寫欄位"}
                        </span>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextAreaField
                  label="過敏備註"
                  name="allergyRemarks"
                  rows="2"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={3} xs={12}>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Card variant="outlined" style={{ marginBottom: 15 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    院友基本資料
                  </Typography>
                  <Grid item sm={12} xs={12}>
                    <table>
                      <tbody>
                        <tr>
                          <td>姓名</td>
                          <td>
                            {infoEntity &&
                              infoEntity.lastnameTc + infoEntity.firstnameTc}
                          </td>
                        </tr>
                        <tr>
                          <td>狀態</td>
                          <td>
                            {infoEntity &&
                              CHECK_IN_STATUS_OPTIONS.find(
                                (elem) => elem.value == infoEntity.checkInStatus
                              )?.label}
                          </td>
                        </tr>
                        <tr>
                          <td>床位</td>
                          <td>{infoEntity && infoEntity.bed?.bedNo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <fieldset style={{ textAlign: "center", padding: "15px" }}>
                      {infoEntity.photoUrl && (
                        <Image
                          url={infoEntity.photoUrl}
                          style={{ width: "100%" }}
                        />
                      )}
                    </fieldset>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <table>
                      <tbody>
                        <tr>
                          <td>姓名 (中文)</td>
                          <td>
                            {infoEntity &&
                              infoEntity.lastnameTc + infoEntity.firstnameTc}
                          </td>
                        </tr>
                        <tr>
                          <td>姓名 (英文)</td>
                          <td>
                            {infoEntity &&
                            infoEntity?.lastname &&
                            infoEntity?.firstname
                              ? infoEntity?.lastname +
                                " " +
                                infoEntity?.firstname
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <td>性別</td>
                          <td>
                            {infoEntity && infoEntity.gender
                              ? GENDER_OPTIONS.find(
                                  (elem) => elem.value == infoEntity.gender
                                )?.label
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <td>身份證號碼</td>
                          <td>
                            {infoEntity && infoEntity.hkid
                              ? infoEntity.hkid
                              : "--"}
                          </td>
                        </tr>
                        <tr>
                          <td>出生日期</td>
                          <td>{infoEntity && infoEntity?.dateOfBirth}</td>
                        </tr>
                        <tr>
                          <td>入住日期</td>
                          <td>
                            {infoEntity &&
                              infoEntity?.checkInDate?.substr(0, 10)}
                          </td>
                        </tr>
                        <tr>
                          <td>保證人姓名</td>
                          <td>{guarantor ? guarantor.contactName : "--"}</td>
                        </tr>
                        <tr>
                          <td>保證人關係</td>
                          <td>{guarantor ? guarantor.relationship : "--"}</td>
                        </tr>
                        <tr>
                          <td>保證人電話</td>
                          <td>{guarantor ? guarantor.contactNo : "--"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
