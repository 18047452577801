import { API_URL, ROUTES } from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  BloodSugarForm,
  initialValues,
  validationSchema,
} from "./forms/BloodSugarForm";
import { MergeInputForm } from "./forms/MergeInputForm";
import {
  MergePrintForm,
  mergePrintInitialValues,
  mergePrintValidationSchema,
} from "./forms/MergePrintForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "../components/FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { BLOOD_SUGAR_SAMPLE_TIMES } from "../../../constants/others.constant";

const BloodSugarPageHeader = (props) => {
  const {
    setCreating,
    setMergeCreating,
    setMergePrinting,
    onExport,
    clearNotification,
  } = props;

  return (
    <PageHeader>
      <Button
        style={{ marginRight: 15, marginBottom: 15 }}
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setCreating(true);
        }}
      >
        新增血糖
      </Button>
      <Button
        style={{ marginRight: 15, marginBottom: 15 }}
        variant="contained"
        color="primary"
        startIcon={<OpenInBrowserOutlinedIcon />}
        onClick={onExport}
      >
        匯出
      </Button>
      <Button
        style={{ marginRight: 15, marginBottom: 15 }}
        variant="contained"
        color="primary"
        startIcon={<LibraryAddIcon />}
        onClick={() => {
          clearNotification();
          setMergeCreating(true);
        }}
      >
        合拼輸入
      </Button>
      <Button
        style={{ marginRight: 15, marginBottom: 15 }}
        variant="contained"
        color="primary"
        startIcon={<PrintIcon />}
        onClick={() => {
          clearNotification();
          setMergePrinting(true);
        }}
      >
        合拼列印
      </Button>
    </PageHeader>
  );
};

export default function BloodSugar(props) {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const { filteringParams, createFilteringParams } = props;

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.resident = params.resident.id || params.resident;

    // multiple blodd sugar inputs to array
    params.bloodSugarRecords = [];

    BLOOD_SUGAR_SAMPLE_TIMES.forEach(time => {
      if (!values[time]) return; // if that time field is false then return
      params.bloodSugarRecords.push({
        time: time,
        bloodSugar: values[`${time}BloodSugar`],
      });
    });
    // end of multiple blood sugar inputs to array

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    BLOOD_SUGAR_SAMPLE_TIMES.forEach(sampleTime => {
      if (!values[sampleTime]) return;
      values[`${sampleTime}BloodSugar`] = values.bloodSugar;
    })
    return values;
  };

  const getTabColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.lastnameTc +
            params.row[params.field]?.firstnameTc
          );
        },
      },
      {
        field: "bed",
        headerName: "床位",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.bed?.bedNo;
        },
      },
      {
        field: "recordDate",
        headerName: "記錄日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      // {
      //   field: "recordTime",
      //   headerName: "記錄時間",
      //   width: 150,
      //   sortable: false,
      //   filterable: false,
      //   renderCell: function (params) {
      //     return params.row["recordDate"]?.substring(11, 16);
      //   },
      // },
      {
        field: "bloodSugar",
        headerName: "血糖(mmol/l)",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "samplingTime",
        headerName: "血糖取樣時段",
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: getSamplingTime,
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  function getSamplingTime(params) {
    const timeMapToOutputStr = {
      mealBefore: '餐前',
      mealAfter: '餐後',
      fasting: '空腹',
      morning: '早上',
      noon: '中午',
      nigth: '夜晚',
    }
    let outputStr;
    for (let time in timeMapToOutputStr) {
      if (params.row[time]) {
      // if that time field is true, then set the output string corresponds to that time field
        outputStr = timeMapToOutputStr[time]
        break;
      }
    }
    return outputStr || '--';
  }

  useEffect(() => {
    setApiUrl({
      getAll:
        API_URL.RESIDENT_VITAL_SIGN +
        "?module=bloodSugar" +
        "&home=" +
        home?.home?.id,
      getOne: API_URL.RESIDENT_VITAL_SIGN,
      create: API_URL.RESIDENT_VITAL_SIGN,
      edit: API_URL.RESIDENT_VITAL_SIGN,
      delete: API_URL.RESIDENT_VITAL_SIGN,
      copy: API_URL.RESIDENT_VITAL_SIGN,
      export:
        API_URL.RESIDENT_VITAL_SIGN +
        "/export?module=bloodSugar" +
        "&home=" +
        home?.home?.id,
      mergePrint: "/api/residents/doc/vitalSign/print",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="血糖"
      editableFields={[
        "resident",
        "recordDate",
        "bloodPressurePluse",
        "systolicBloodPressure",
        "diastolicBloodPressure",
        "pluse",
        "bloodOxygen",
        "bloodSugar",
        "breathingRate",
        "temperature",
        "height",
        "weight",
        "remarks",
        "mealBefore",
        "mealAfter",
        "fasting",
        "morning",
        "noon",
        "night",
      ]}
      getColumnSettings={getTabColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={BloodSugarForm}
      FilteringForm={FilteringForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mergePrintInitialValues={mergePrintInitialValues}
      mergePrintValidationSchema={mergePrintValidationSchema}
      mergeInputForm={MergeInputForm}
      mergePrintForm={MergePrintForm}
      maxWidth="sm"
      pageHeader={BloodSugarPageHeader}
    />
  );
}
