export default function flattenDocumentFolders(folders, parentId=null, level=0) {
  const flattened = [...folders];

  folders.forEach(f => {
    // the corresponding index of the file in flattened array
    const index = flattened.findIndex(newF => newF.id == f.id);
    // if have subfolders
    if (f.subfolders && typeof f.subfolders === "object" && f.subfolders.length > 0) {
      // get flattened subfolders by recursion
      const subfolders = flattenDocumentFolders(f.subfolders, f.id, level+1);
      // insert subfolders as folders in flattened array
      flattened.splice(index + 1, 0, ...subfolders);
    }
    flattened[index].name = "─".repeat(level) + " " + f.name;
    delete flattened[index].subfolders;
    parentId !== null && (flattened[index].parentId = parentId);
  });

  return flattened;
}
