import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikDateField from "../../../components/inputs/FormikDateField";
import { NotificationActions } from "../../../actions";
import fileDownload from "js-file-download";
import axios from "axios";
import { API_URL } from "../../../constants";
import { apiService } from "../../../services";

const initialValues = {
  startDate: "",
  endDate: "",
}

const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  endDate: Yup.date().when("startDate", (value, schema) => {
    if (!value) {
      return schema
        .required(<FormattedMessage id="validation.message.required" />)
        .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
        .nullable();
    }
    return schema
      .required(<FormattedMessage id="validation.message.required" />)
      .min(value, '此日期不可設定為"日期由"之前')
      .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
      .nullable();
  }),
})

export default function DailyReportDialog(props) {
  const { open, setOpen, entity, onClose, normalizeReceivedValues, home } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (values, formikActions) => {
    const params = {...values};

    params.home = home?.home?.id || home?.id || home;
    params.startDate = moment(params.startDate).format("YYYY-MM-DD");
    params.endDate = moment(params.endDate).format("YYYY-MM-DD");

    apiService
      .get(API_URL.EXPENSE_TRANSACTION + "/print/", {
        params: params,
        responseType: "blob",
      })
      .then((response) => {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") +
            "_院舍支出報表_export.docx"
        );
        dispatch(NotificationActions.success(null, "已匯出"));
        formikActions.setSubmitting(false);
        setOpen(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
      });
  }

  return (
    <FormikDialog
      open={open}
      dialogTitle={"匯出報表"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
    >
      <ReportForm entity={entityValues} />
    </FormikDialog>
  );
}

const ReportForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const { values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormikDateField
            required
            label="日期由"
            name="startDate"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormikDateField
            required
            label="日期至"
            name="endDate"
          />
        </Grid>
      </Grid>
    </form>
  );
};
