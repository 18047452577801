import * as Yup from 'yup';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikDateField from "../../../../../components/inputs/FormikDateField";
import { useParams } from "react-router-dom";

export const mergePrintInitialValues = {
    resident: '',
    startDate: '',
    endDate: moment(new Date()).toISOString(),
}

export const mergePrintValidationSchema = Yup.object().shape({
    startDate: Yup.date()
        .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable(),
    endDate: Yup.date()
        .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable(),
});

export const MergePrintForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const { mergeInitialValues, values, touched, errors, handleChange, setFieldValue, resetForm } = useFormikContext();
    let { id } = useParams();
    values.resident = id;

    useEffect(() => {
        resetForm({ values: mergeInitialValues });
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({ values: mergeInitialValues });
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <FormikDateField required label="開始日期" name="startDate" />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormikDateField required label="結束日期" name="endDate" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}