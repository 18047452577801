import { useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikUploadField from "../../../components/inputs/FormikUploadField";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  API_URL,
  CHECK_IN_STATUS_OPTIONS,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";
import { NotificationActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";

export const PhotoFormContent = (props) => {
  const { entity, onChange } = props;
  const {
    values,
    setFieldValue,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
    initialValues,
  } = useFormikContext();
  const [guarantor, setGuarantor] = useState(null);

  useEffect(() => {
    resetForm({ values: entity });
    if (entity.contactList) {
      setGuarantor(entity.contactList.find((i) => i.guarantor));
    } else {
      setGuarantor(null);
    }
  }, [entity]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <fieldset>
            <FormLabel component="legend">院友基本資料</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikUploadField
                  required
                  label="相片"
                  name="photoUrl"
                  policy="RESIDENT_PHOTO"
                  onChange={onChange}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ minWidth: 100 }}>姓名 (中文)</td>
                      <td>{entity.lastnameTc + entity.firstnameTc}</td>
                    </tr>
                    <tr>
                      <td>姓名 (英文)</td>
                      <td>
                        {entity.lastname && entity.firstname
                          ? entity.lastname + " " + entity.firstname
                          : "--"}
                      </td>
                    </tr>
                    <tr>
                      <td>狀態</td>
                      <td>
                        {
                          CHECK_IN_STATUS_OPTIONS.find(
                            (elem) => elem.value == entity?.checkInStatus
                          )?.label
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>床位</td>
                      <td>{entity?.bed?.bedNo}</td>
                    </tr>
                    <tr>
                      <td>性別</td>
                      <td>
                        {entity.gender
                          ? GENDER_OPTIONS.find(
                              (elem) => elem.value == entity.gender
                            )?.label
                          : "--"}
                      </td>
                    </tr>
                    <tr>
                      <td>身份證號碼</td>
                      <td>{entity.hkid ? entity.hkid : "--"}</td>
                    </tr>
                    <tr>
                      <td>出生日期</td>
                      <td>{entity.dateOfBirth}</td>
                    </tr>
                    <tr>
                      <td>入住日期</td>
                      <td>{entity?.checkInDate?.substr(0, 10)}</td>
                    </tr>
                    <tr>
                      <td>評核</td>
                      <td>
                        {
                          EVALUATION_RESULT_OPTION.find(
                            (elem) => elem.value == entity?.evaluationResult
                          )?.label
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>保證人姓名</td>
                      <td>{guarantor ? guarantor.contactName : "--"}</td>
                    </tr>
                    <tr>
                      <td>保證人關係</td>
                      <td>{guarantor ? guarantor.relationship : "--"}</td>
                    </tr>
                    <tr>
                      <td>保證人電話</td>
                      <td>{guarantor ? guarantor.contactNo : "--"}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </form>
  );
};

export const PhotoForm = (props) => {
  const { initialValues = {}, validationSchema, ...rest } = props;
  const [entity, setEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  let { id } = useParams();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  const createParams = (values) => {
    let params = {};

    params.photoUrl = values.photoUrl;

    return params;
  };

  const handleSubmit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT + "/" + id + "/photo", createParams(values))
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    axios
      .get(API_URL.RESIDENT + "/" + id)
      .then((response) => {
        setEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
  }, [id, notification]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) =>
        loaded && <PhotoFormContent entity={entity} onChange={handleSubmit} />
      }
    </Formik>
  );
};
