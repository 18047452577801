import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import axios from "axios";
import FormikTextField from "../../../components/inputs/FormikTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";

export const initialValues = {
  position: "",
  salary: 0,
  mpf: 0,
  total: 0,
};

export const validationSchema = Yup.object().shape({
  salary: Yup.string()
    // .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
    .required(<FormattedMessage id="validation.message.required" />)
    .test(
      "salary",
      "薪金不應少於0",
      (salaryValue) => {
        return parseFloat(salaryValue) >= 0;
      }
    )
    .nullable(),
  mpf: Yup.string().when(["salary", "total"], (salaryValue, totalValue, schema) => {
    if (salaryValue && totalValue) {
      return schema
        .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
        .required(<FormattedMessage id="validation.message.required" />)
        .test(
          "mpf",
          "強積金金額不應少於0",
          (mpfValue) => {
            return parseFloat(mpfValue) >= 0;
          }
        )
        .test(
          "mpf",
          "強積金金額不應多過薪金",
          (mpfValue) => {
            return parseFloat(mpfValue) <= parseFloat(salaryValue);
          }
        )
        .test(
          "mpf",
          "強積金金額應是薪金與實際收入之差",
          (mpfValue) => {
            return parseFloat(mpfValue) == parseFloat(salaryValue) - parseFloat(totalValue);
          }
        )
        .nullable();
    }
    if (salaryValue) {
      return schema
        .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
        .required(<FormattedMessage id="validation.message.required" />)
        .test(
          "mpf",
          "強積金金額不應少於0",
          (mpfValue) => {
            return parseFloat(mpfValue) >= 0;
          }
        )
        .test(
          "mpf",
          "強積金金額不應多過薪金",
          (mpfValue) => {
            return parseFloat(mpfValue) <= parseFloat(salaryValue);
          }
        )
        .nullable();
    }
    return schema
      .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
      .required(<FormattedMessage id="validation.message.required" />)
      .test(
        "mpf",
        "強積金金額不應少於0",
        (mpfValue) => {
          return parseFloat(mpfValue) >= 0;
        }
      )
      .nullable();
  }),
  total: Yup.string().when("salary", (salaryValue, schema) => {
    if (salaryValue) {
      return schema
        .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
        .required(<FormattedMessage id="validation.message.required" />)
        .test(
          "total",
          "實際收入不應少於0",
          (totalValue) => {
            return parseFloat(totalValue) >= 0;
          }
        )
        .test(
          "total",
          "實際收入不應多過薪金",
          (totalValue) => {
            return parseFloat(totalValue) <= parseFloat(salaryValue);
          }
        )
        .nullable();
    }
    return schema
      .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
      .required(<FormattedMessage id="validation.message.required" />)
      .test(
        "total",
        "實際收入不應少於0",
        (totalValue) => {
          return parseFloat(totalValue) >= 0;
        }
      )
      .nullable();
  }),
});

export function EditDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"修改員工薪金"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
      validationSchema={validationSchema}
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

export const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  // useEffect(() => {
  //   resetForm({ values: initialValues });
  //   if (entity) {
  //     resetForm({ values: entity });
  //   } else {
  //     resetForm({ values: initialValues });
  //   }
  // }, [entity]);

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    disabled
                    label="員工姓名"
                    name="nameTc"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    label="職位"
                    name="position"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    required
                    label="薪金"
                    name="salary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    required
                    label="實際收入"
                    name="total"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    required
                    label="強積金金額"
                    name="mpf"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
