import * as Yup from 'yup';
import { useState, useEffect, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../../components/inputs/FormikTextField";

export const initialValues = {
    name: '',
    nameTc: '',
}

export const validationSchema = Yup.object().shape({
    nameTc: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const InvoiceItemsForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();

    useEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({values: initialValues});
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="收費項目名稱(中文)" name="nameTc" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField label="收費項目名稱(英文)" name="name" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}