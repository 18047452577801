import { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const home = useSelector((state) => state.home);

  const { setCreating, handleFilterChange, clearNotification } = props;

  return (
    <PageHeader title="藥物設定">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增藥物
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
