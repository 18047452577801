import { API_URL } from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  MedicalTreatmentItemsForm,
  initialValues,
  validationSchema,
} from "./form/MedicalTreatmentItemsForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import StopIcon from "@material-ui/icons/Stop";
import Tooltip from "@material-ui/core/Tooltip";

const MedicalTreatmentItemsPageHeader = (props) => {
  const { setCreating, clearNotification } = props;

  return (
    <PageHeader title="醫療項目">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setCreating(true);
        }}
      >
        新增醫療項目
      </Button>
    </PageHeader>
  );
};

export default function MedicalTreatmentItems() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);
    params.resident = params.pickColor;

    return params;
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "nameTc",
        headerName: "醫療項目",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "calendarColor",
        headerName: "日曆顏色編碼",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <StopIcon
                style={{
                  color: params.row[params.field],
                  verticalAlign: "middle",
                }}
                fontSize="large"
              />
              {params.row[params.field]}
            </div>
          );
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.MEDICAL_TREATMENT_ITEM,
      getOne: API_URL.MEDICAL_TREATMENT_ITEM,
      create: API_URL.MEDICAL_TREATMENT_ITEM,
      edit: API_URL.MEDICAL_TREATMENT_ITEM,
      delete: API_URL.MEDICAL_TREATMENT_ITEM,
      copy: API_URL.MEDICAL_TREATMENT_ITEM,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="醫療項目"
      editableFields={["nameTc", "name", "calendarColor", "calendarFontColor"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      apiUrl={apiUrl}
      dataInputForm={MedicalTreatmentItemsForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth="sm"
      pageHeader={MedicalTreatmentItemsPageHeader}
    ></EntityManagement>
  );
}
