import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { STAFF_TAG_OPTION, STAFF_ROSTER_OPTION } from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import FormikMonthToggleButtonGroup from "../../../components/inputs/FormikMonthToggleButtonGroup";
import axios from "axios";

export const initialValues = {
  staff: "",
  roster: "",
  startTime: "",
  endTime: "",
  recurringOrders: [],
  tag: "",
  month: moment(new Date()).toISOString(),
  stillApprove: false,
};

export const validationSchema = Yup.object().shape({
  staff: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  roster: Yup.object()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  tag: Yup.string()
    .max(3, "更表標籤最多為3個字元")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  month: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  recurringOrders: Yup.array().min(1, "請選擇最少1天上班日子"),
});

export function DataInputForm(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"編制更表"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
    >
      <PaymentForm entity={entityValues} />
    </FormikDialog>
  );
}

const PaymentForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [rosterOptions, setRosterOptions] = useState([]);
  const [selectedRoster, setSelectedRoster] = useState(null);

  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  const handleStaffChange = (event, value) => {
    setRosterOptions(value?.workHomes);
    setFieldValue("roster", null);
    setFieldValue("startTime", null);
    setFieldValue("endTime", null);
  };

  const handleRosterChange = (event, value) => {
    setFieldValue("roster", event?.target?.value);
    setFieldValue(
      "startTime",
      event?.target?.value?.workingStartTime?.substring(0, 5)
    );
    setFieldValue(
      "endTime",
      event?.target?.value?.workingEndTime?.substring(0, 5)
    );
  };

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                工作資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="員工姓名"
                    name="staff"
                    apiUrl={"/api/staffUsers?home=" + home?.home?.id}
                    getOptionLabel={(option) => option?.nameTc}
                    getOptionValue={(option) => option?.id}
                    onChange={handleStaffChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormikSelectField
                    disabled={values.staff == "" ? true : false}
                    required
                    label="更期"
                    name="roster"
                    options={
                      rosterOptions
                        ? rosterOptions
                            .filter((r) => r.home.id == home.home.id)
                            ?.map((item) => ({
                              value: item,
                              label:
                                STAFF_ROSTER_OPTION.find(
                                  (elem) => elem.value == item.roster
                                )?.label +
                                " - " +
                                item.position +
                                " - " +
                                item.workingStartTime.substring(0, 5) +
                                "至" +
                                item.workingEndTime.substring(0, 5),
                            }))
                        : null
                    }
                    onChange={handleRosterChange}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormikTextField
                    disabled
                    required
                    name="startTime"
                    label="開始時間"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormikTextField
                    disabled
                    required
                    name="endTime"
                    label="結束時間"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                選擇月份及日子
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={6}>
                    <FormikDateField
                      required
                      views={["month"]}
                      label="選擇月份"
                      name="month"
                      format={"yyyy 年 M 月"}
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <FormikSelectField
                      required
                      label="更表標籤"
                      name="tag"
                      options={STAFF_TAG_OPTION}
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <FormikMonthToggleButtonGroup
                      name="recurringOrders"
                      month={moment(values.month).daysInMonth()}
                    />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <FormikCheckboxField name="stillApprove" label="強制通過" />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
