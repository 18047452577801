import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL } from "../../constants";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import {
  AssignDialog,
  initialValues,
  validationSchema,
} from "./components/AssignDialog";
import { AssignInRowDialog } from "./components/AssignInRowDialog";
import { RevokeDialog } from "./components/RevokeDialog";
import { NotificationActions } from "../../actions";

export default function WristbandManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [assignInRow, setAssignInRow] = useState(null);
  const [assign, setAssign] = useState(false);
  const [revoke, setRevoke] = useState(null);
  const [assignInRowOpen, setAssignInRowOpen] = useState(false);
  const [revokeOpen, setRevokeOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.code = values.code;
    params.resident = params.resident.id || params.resident;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="分配手帶">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setAssignInRow(params.row);
                    setAssignInRowOpen(true);
                  }}
                  disabled={params.row["resident"] || loading}
                >
                  <PersonAddOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="收回手帶">
                <span>
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      setRevoke(params.row);
                      setRevokeOpen(true);
                    }}
                    disabled={!params.row["resident"] || loading}
                  >
                    <PersonAddDisabledOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "code",
        headerName: "手帶編號",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]
            ? params.row["resident"].lastnameTc +
                params.row["resident"].firstnameTc
            : "--";
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"] &&
            params.row["resident"].lastname &&
            params.row["resident"].firstname
            ? params.row["resident"].lastname +
                " " +
                params.row["resident"].firstname
            : "--";
        },
      },
    ];
  };

  const onAssign = (values, actions) => {
    axios
      .put(API_URL.WRISTBAND + "/resident/" + values.code, {
        home: home.home.id,
        code: values.code,
        resident: values.resident,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已分配"));
        actions.setSubmitting(false);
        setAssign(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onAssignInRow = (values, actions) => {
    axios
      .put(API_URL.WRISTBAND + "/resident/" + values.id, {
        home: home.home.id,
        code: values.code,
        resident: values.resident.id || values.resident,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已分配"));
        actions.setSubmitting(false);
        setAssignInRowOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onRevoke = (values, actions) => {
    axios
      .put(API_URL.WRISTBAND + "/resident/" + values.id, {
        home: home.home.id,
        code: values.code,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已收回"));
        actions.setSubmitting(false);
        setRevokeOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.WRISTBAND,
      getOne: API_URL.WRISTBAND,
      edit: API_URL.WRISTBAND + "/resident/",
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="手帶"
      editableFields={["resident", "wristbandId"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      initialValues={initialValues}
      validationSchema={validationSchema}
      apiUrl={apiUrl}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setAssign: setAssign,
      }}
      maxWidth="xs"
    >
      <AssignDialog
        open={assign}
        onClose={() => {
          setAssign(false);
        }}
        onSubmit={onAssign}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <AssignInRowDialog
        open={assignInRowOpen}
        entity={assignInRow}
        onClose={() => {
          setAssignInRow(null);
          setAssignInRowOpen(false);
        }}
        onSubmit={onAssignInRow}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <RevokeDialog
        open={revokeOpen}
        entity={revoke}
        onClose={() => {
          setRevoke(null);
          setRevokeOpen(false);
        }}
        onSubmit={onRevoke}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
