import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const { setCreating, setCreateCheckInAgain, handleFilterChange, clearNotification } = props;

  return (
    <PageHeader title="訂位總覽">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
              style={{ marginRight: "15px" }}
            >
              新增訂位
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RotateLeftIcon />}
              onClick={() => {
                clearNotification();
                setCreateCheckInAgain(true);
              }}
            >
              重新入住
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
