import { Formik } from "formik";
import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { Grid, Typography, Link } from "@material-ui/core";
import { PageHeader } from "../../../components/PageHeader";

export const ListingPageHeader = (props) => {
  const { handleFilterChange, setCurrentFolderId, folderNav, setFolderNav } = props;

  return (
    <PageHeader title="文件總覽">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Grid container style={{ marginTop: "45px" }}>
              <Link
                onClick={() => {
                  setCurrentFolderId("")
                  setFolderNav([]);
                }}
                style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 700 }}
              >
                主目錄
              </Link>
              {folderNav.map((folder, index) => (
                <>
                  <Typography
                    key={index}
                    style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 700 }}
                  >
                    &nbsp;/&nbsp;
                  </Typography>
                  <Link
                    key={index}
                    onClick={() => {
                      setCurrentFolderId(folder.id)
                      setFolderNav([...folderNav].splice(0, index + 1));
                    }}
                    style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 700 }}
                  >
                    {folder.name}
                  </Link>
                </>
              ))}
            </Grid>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
