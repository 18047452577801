import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import {
  API_URL,
  RESIDENT_EMERGENCY_STATUS_OPTION,
  RESIDENT_EMERGENCY_REASON_OPTION,
  RESIDENT_EMERGENCY_DISCHARGED_REASON_OPTION,
} from "../../../constants";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";

export const initialValues = {
  resident: "",
  status: "",
  reason: "",
  issueDateTime: moment(new Date()).toISOString(),
  institution: "",
  wardBedNo: "",
  remarks: "",
  dischargedDateTime: "",
  dischargedReason: "",
  dischargedRemarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  reason: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  institution: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // wardBedNo: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              院友資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  disabled={entity ? true : false}
                  required
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home.home.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="急症狀態"
                  name="status"
                  options={RESIDENT_EMERGENCY_STATUS_OPTION}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              急症記錄
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikSelectField
                  required
                  label="急症原因"
                  name="reason"
                  options={RESIDENT_EMERGENCY_REASON_OPTION}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikDateTimeField
                  required
                  label="急症日期及時間"
                  name="issueDateTime"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="急症醫院 / 診所"
                  name="institution"
                  apiUrl={"/api/institutions"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) =>
                    option.code
                      ? option.code + " - " + option.nameTc
                      : option.nameTc
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="留醫病房 / 床號" name="wardBedNo" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="急症備註" name="remarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              出院紀錄
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikDateTimeField
                  label="出院日期及時間"
                  name="dischargedDateTime"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  label="出院原因"
                  name="dischargedReason"
                  options={RESIDENT_EMERGENCY_DISCHARGED_REASON_OPTION}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
