import axios from 'axios';
import { ROUTES } from '../constants';
import AuthService from './auth.service';

class ApiService {
    async get(url, config) {
        let returnData = [];

        await axios.get(url, config).then((response) => {
            returnData = response;
        }).catch((err) => {
            this.handleErrorResponse(err);
            returnData = Promise.reject(err);
        });

        return returnData;
    }

    async post(url, data, config) {
        let returnData = [];

        await axios.post(url, data, config).then((response) => {
            returnData = response;
        }).catch((err) => {
            this.handleErrorResponse(err);
            returnData = Promise.reject(err);
        });

        return returnData;
    }

    async put(url, data, config) {
        let returnData = [];

        await axios.put(url, data, config).then((response) => {
            returnData = response;
        }).catch((err) => {
            this.handleErrorResponse(err);
            returnData = Promise.reject(err);
        });

        return returnData;
    }

    async delete(url, config) {
        let returnData = [];

        await axios.delete(url, config).then((response) => {
            returnData = response;
        }).catch((err) => {
            this.handleErrorResponse(err);
            returnData = Promise.reject(err);
        });

        return returnData;
    }

    handleErrorResponse(error) {
        const status = error?.response?.status;

        if (status === 401) {
            AuthService.logout();
            window.location.href = ROUTES.LOGIN;
        }
    }
}

export default new ApiService();