export const API_HOSTNAME = "/api"; //window.location.protocol + '//' + window.location.hostname + (window.location.hostname.indexOf('localhost') != -1 ? ':3000' : '') + '/api';

export const API_URL = {
  // Others
  LOGIN: API_HOSTNAME + "/oauth",
  HOME: API_HOSTNAME + "/homes",

  // 院舍管理
  BED: API_HOSTNAME + "/beds",
  CALENDAR: API_HOSTNAME + "/calendar",
  ROLE_MANAGEMENT: API_HOSTNAME + "/roles",

  // 院友管理
  RESIDENT: API_HOSTNAME + "/residents",
  DIET: API_HOSTNAME + "/diets",
  DIAPER: API_HOSTNAME + "/diapers",
  DINING: API_HOSTNAME + "/dinings",
  MEMO: API_HOSTNAME + "/memos",
  ALLOWANCE: API_HOSTNAME + "/allowances",
  BED_TYPE: API_HOSTNAME + "/residentBedTypes",
  RESERVATION: API_HOSTNAME + "/reservations",
  DISCHARGE: API_HOSTNAME + "/residentDischarge",
  DEATH: API_HOSTNAME + "/residentDeath",
  LEAVE: API_HOSTNAME + "/residentLeave",
  DIET: API_HOSTNAME + "/diets",
  DIAPER: API_HOSTNAME + "/diapers",
  MEMO: API_HOSTNAME + "/memos",
  RESERVATION_DISCHARGED: API_HOSTNAME + "/reservations/getDischarged",
  RESIDENT_DISCHARGED_REFERENCE: API_HOSTNAME + "/residentDischarge/residentRefernce",
  RESIDENT_DISCHARGED_TO_CHECKIN: API_HOSTNAME + "/residents/dischargedToCheckin",

  // 會計管理
  RESIDENT_INVOICE: API_HOSTNAME + "/residentInvoice",
  INVOICE_ITEM: API_HOSTNAME + "/invoiceItems",
  EXPENSE: API_HOSTNAME + "/expense",
  EXPENSE_TRANSACTION: API_HOSTNAME + "/expenseTransaction",
  EXPENSE_ITEM: API_HOSTNAME + "/expenseItems",
  EXPENSE_SUPPLIERS: API_HOSTNAME + "/expenseSuppliers",
  DEFAULT_EXPENSE: API_HOSTNAME + "/defaultExpense",
  DEFAULT_EXPENSE_ITEMS: API_HOSTNAME + "/defaultExpense/defaultItem",
  PETTY_CASH: API_HOSTNAME + "/pettyCash",
  PETTY_CASH_TOTAL: API_HOSTNAME + "/pettyCash/total",
  PETTY_CASH_EXPENSE: API_HOSTNAME + "/pettyCash/expense",
  STAFF_SALARY: API_HOSTNAME + "/staffSalary",

  // 藥物管理
  RESIDENT_DRUG_MEDICATION: API_HOSTNAME + "/residentDrugMedication",
  MEDICINE: API_HOSTNAME + "/medicines",
  INSTITUTIONS: API_HOSTNAME + "/institutions",
  SPECIALISTS: API_HOSTNAME + "/specialists",
  MEDICATION_SCHEDULES: API_HOSTNAME + "/medicationSchedules",
  MEDICINE_TAKING_REMARKS: API_HOSTNAME + "/medicationTakingRemarks",

  // 醫療記錄
  RESIDENT_MEDICAL_INFO: API_HOSTNAME + "/residentMedicalInfo",
  //ICD9: API_HOSTNAME + '/icd9',
  RESIDENT_FOLLOW_UP: API_HOSTNAME + "/residentFollowUp",
  RESIDENT_EMERGENCY: API_HOSTNAME + "/residentEmergency",
  RESIDENT_TREATMENT_ITEM: API_HOSTNAME + "/residentTreatmentItem",
  MEDICAL_FOLLOW_UP_ITEM: API_HOSTNAME + "/medicalFollowUpItem",
  MEDICAL_TRANSPORT: API_HOSTNAME + "/transport",
  MEDICAL_ESCORTER_RELATIONSHIP: API_HOSTNAME + "/escorterRelationship",
  MEDICAL_TREATMENT_ITEM: API_HOSTNAME + "/treatmentItem",
  MEDICAL_ITEM: API_HOSTNAME + "/medicalItem",

  // 護理記錄
  RESIDENT_VITAL_SIGN: API_HOSTNAME + "/residentVitalSign",
  RESIDENT_WOUND: API_HOSTNAME + "/residentWound",
  RESIDENT_RESTRAINT_ITEM: API_HOSTNAME + "/residentRestraint",
  RESIDENT_ICP: API_HOSTNAME + "/residentIcp",
  NURSING_WOUND_DRESSINGS: API_HOSTNAME + "/woundDressings",

  // 員工管理
  STAFF_USERS: API_HOSTNAME + "/staffUsers",
  HOME_STAFF: API_HOSTNAME + "/homeStaffs",
  STAFF_ROSTER_RECURRING: API_HOSTNAME + "/rosterRecurring",
  STAFF_ROSTER_SESSION: API_HOSTNAME + "/rosterSession",

  // 手帶管理
  WRISTBAND: API_HOSTNAME + "/wristBands",

  // 防遊走管理
  IBEACON: API_HOSTNAME + "/iBeacon",
  IBEACON_RESIDENT_MATCH: API_HOSTNAME + "/iBeacon/addResident",
  IBEACON_WANDERING_INFO: API_HOSTNAME + "/iBeacon/getIbeaconInfo",

  // 文件管理
  DOCUMENT: API_HOSTNAME + "/upload/document",
  DOCUMENT_UPLOAD: API_HOSTNAME + "/upload",
  DOCUMENT_DELETE: API_HOSTNAME + "/upload",
  DOCUMENT_FOLDER: API_HOSTNAME + "/upload/folder",

};
