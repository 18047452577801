import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { API_URL, EXPENSE_TRANSACTION_METHOD_OPTION } from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";

export const initialValues = {
  amount: "",
  amountConfirmation: "",
  paymentMethod: "",
  paymentDate: moment(new Date()).toISOString(),
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  paymentMethod: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  paymentDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  amount: Yup.string()
    .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  amountConfirmation: Yup.string().when("amount", (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return schema
      .oneOf([Yup.ref("amount")], "確認付款金額與付款金額不符")
      .required(<FormattedMessage id="validation.message.required" />);
  }),
});

export function PaymentDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"按金付款 (" + entityValues?.invoiceId + ")"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="sm"
    >
      <PaymentForm entity={entityValues} />
    </FormikDialog>
  );
}

const PaymentForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {}, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                發票資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    disabled
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    required
                    label="按金編號"
                    name="invoiceId"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateTimeField
                    disabled
                    required
                    label="按金日期及時間"
                    name="issueDateTime"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    required
                    label="按金金額"
                    name="total"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                付款資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    type="number"
                    label="付款金額"
                    name="amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    type="number"
                    label="確認付款金額"
                    name="amountConfirmation"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="付款方法"
                    name="paymentMethod"
                    options={EXPENSE_TRANSACTION_METHOD_OPTION}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateTimeField
                    required
                    label="入帳日期及時間"
                    name="paymentDate"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField label="付款備註" name="remarks" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
