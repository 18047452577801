import { USER_KEY, AUTHENTICATION_STATUS } from "../constants";

let user = JSON.parse(localStorage.getItem(USER_KEY));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_STATUS.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case AUTHENTICATION_STATUS.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case AUTHENTICATION_STATUS.LOGIN_FAILURE:
      return {};
    case AUTHENTICATION_STATUS.LOGOUT:
      return {};
    default:
      return state
  }
}