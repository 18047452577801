import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, RESIDENT_DEPOSIT_STATUS_OPTION } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
//import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
//import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { PaymentDialog } from "./components/PaymentDialog";
import { DetailsDialog } from "./components/DetailsDialog";
//import { PrintDialog } from './components/PrintDialog';
import { ReturnDepositDialog } from "./components/ReturnDepositDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import fileDownload from "js-file-download";

export default function DownPaymentRecordPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editing, setEditing] = useState(false);
  const [paying, setPaying] = useState(false);
  const [details, setDetails] = useState(false);
  //const [printing, setPrinting] = useState(false);
  const [returning, setReturning] = useState(false);
  const [editEntity, setEditEntity] = useState(null);
  const [paymentEntity, setPaymentEntity] = useState(null);
  const [detailsEntity, setDetailsEntity] = useState(null);
  //const [printEntity, setPrintEntity] = useState(null);
  const [returnEntity, setReturnEntity] = useState(null);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [reload, setReload] = useState(false);
  //const [depositId, setDepositId] = useState("");

  const createParams = (values, coreCreateParams) => {
    let params = {};

    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.resident = params.resident.id || params.resident;
    params.status = "NOT_YET_PAID";
    params.residentInvoiceItems = [
      {
        invoiceItem: values.depositId,
        description: values.description,
        price: values.price,
        qty: values.qty,
      },
    ];

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改訂金狀態">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditing(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <IconButton color="default" size="small" onClick={() => { clearNotification(); setDeletingEntity(params.row); }} disabled={params.row['status'] != 'PAID' || loading}>
                                <DeleteOutlinedIcon />
                            </IconButton> */}
              <Tooltip title="訂金付款">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setPaymentEntity(params);
                    setPaying(true);
                  }}
                  disabled={loading}
                >
                  <LocalAtmOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="退還訂金">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setReturnEntity(params);
                    setReturning(true);
                  }}
                  disabled={loading}
                >
                  <ReplayOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="列印訂金退還確認書">
                <span>
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      onPrintReturn(params.row.id);
                    }}
                    disabled={
                      params.row["status"] !== "RETURNED_DOWN" || loading
                    }
                  >
                    <PrintOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetails(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "issueDateTime",
        headerName: "訂金日期及時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.substring(0, 10) +
            " / " +
            params.row[params.field]?.substring(11, 16)
          );
        },
      },
      {
        field: "invoiceId",
        headerName: "訂金編號",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["reservationListViewList"]?.map((item) => {
            return item.lastnameTc + item.firstnameTc;
          });
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["reservationListViewList"]?.map((item) => {
            return item.lastname && item.firstname
              ? item.lastname + " " + item.firstname
              : "--";
          });
        },
      },
      {
        field: "total",
        headerName: "訂金金額",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return "HK$ " + params.row[params.field].toLocaleString();
        },
      },
      {
        field: "status",
        headerName: "付款狀態",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_DEPOSIT_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT_INVOICE + "/" + values.id, {
        status: values.status,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditing(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onPay = (values, actions) => {
    const paymentList = [
      {
        paymentMethod: values.paymentMethod,
        paymentDate: values.paymentDate,
        remarks: values.remarks,
        amount: values.amount,
      },
    ];
    axios
      .post(API_URL.RESIDENT_INVOICE + "/" + values.id + "/pay", {
        paymentList,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已付款"));
        actions.setSubmitting(false);
        setPaying(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onReturn = (values, actions) => {
    axios
      .put("/api/residentInvoice/" + values.id + "/returnDown", {
        status: "RETURNED_DOWN",
        amount: values.amount,
        description: values.description,
        method: values.method,
        issueDateTime: values.issueDateTime,
        remarks: values.remarks,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已退款"));
        actions.setSubmitting(false);
        setReturning(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onPrintReturn = (values, formikActions) => {
    axios
      .get(
        apiUrl.print ? apiUrl.print + "/print/returnDown/" + values : apiUrl,
        { responseType: "blob" }
      )
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_訂金退還確認書_print.docx"
        );
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_INVOICE + "?type=DOWN",
      getOne: API_URL.RESIDENT_INVOICE,
      create: API_URL.RESIDENT_INVOICE,
      edit: API_URL.RESIDENT_INVOICE,
      delete: API_URL.RESIDENT_INVOICE,
      print: API_URL.RESIDENT_INVOICE,
      export: API_URL.RESIDENT_INVOICE + "/export?type=DOWN",
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="訂金"
      editableFields={[
        "resident",
        "residentInvoiceItems",
        "issueDateTime",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      maxWidth="sm"
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    >
      <EditDialog
        open={editing}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditing(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <PaymentDialog
        open={paying}
        entity={paymentEntity}
        onClose={() => {
          setPaymentEntity(null);
          setPaying(false);
        }}
        onSubmit={onPay}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DetailsDialog
        open={details}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetails(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      {/* <PrintDialog
                open={printing}
                entity={printEntity}
                onClose={() => { setPrintEntity(null); setPrinting(false); }}
                normalizeReceivedValues={normalizeReceivedValues}
                apiUrl={apiUrl}
            /> */}
      <ReturnDepositDialog
        open={returning}
        entity={returnEntity}
        onClose={() => {
          setReturnEntity(null);
          setReturning(false);
        }}
        onSubmit={onReturn}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
