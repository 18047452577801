import React, { useState } from "react";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import axios from "axios";
import {
  FormikTextField,
  FormikPasswordField,
} from "../../../components/inputs";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { API_URL } from "../../../constants";
import { Link, useLocation } from "react-router-dom";
import { NotificationActions, authActions } from "../../../actions";

export const validationSchema = Yup.object().shape({
  username: Yup.string().required(
    <FormattedMessage id="validation.message.required" />
  ),
  password: Yup.string().required(
    <FormattedMessage id="validation.message.required" />
  ),
});

export let initialValues = {
  username: "",
  password: "",
};

export const createParams = (values) => {
  const params = new URLSearchParams();

  values.username && params.append("username", values.username);
  values.password && params.append("password", values.password);

  return params;
};

export const normalizeReceivedValues = (receivedValues) => {
  receivedValues.username || (receivedValues.username = "");
  receivedValues.password || (receivedValues.password = "");
};

function FormikLoginForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const notification = useSelector((state) => state.notification);

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);

        const { from } = location.state || { from: { pathname: "/" } };
        dispatch(authActions.login(values.username, values.password, from));
        /*axios.post(API_URL.LOGIN, {
                    username: values.username,
                    password: values.password
                }).then(response => {
                    let data = response.data;
                    if (typeof data === 'object' && 'token' in data) {
                        localStorage.setItem('user', JSON.stringify(data));
                        dispatch(NotificationActions.success(null, <FormattedMessage id={"api.message.LOGIN_SUCCESS"} />));
                    }
                }).catch(error => {
                    let response = error.response;
                    if (response) {
                        dispatch(NotificationActions.error(null, <FormattedMessage id={"api.message." + error.response.data.messageCode} />));
                    }
                });*/
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          {(notification.title || notification.message) && (
            <Alert
              severity={notification.type}
              onClose={() => {
                dispatch(NotificationActions.clear());
              }}
            >
              {notification.title && (
                <AlertTitle>{notification.title}</AlertTitle>
              )}
              {notification.message}
            </Alert>
          )}
          <FormikTextField
            name="username"
            label={<FormattedMessage id="login.form.username" />}
          />
          <FormikPasswordField
            name="password"
            label={<FormattedMessage id="login.form.password" />}
          />
          <Grid container spacing={3}>
            <Grid item xs>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                <FormattedMessage id="login.form.button.login" />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default connect(null, {})(FormikLoginForm);
