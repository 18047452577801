import { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { API_URL, ROUTES } from "../../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import _uniqueId from "lodash/uniqueId";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Draggable from "react-draggable";
import Typography from "@material-ui/core/Typography";
import { Notification } from "../../../../components/Notification";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const initialValues = {
  newBed: "",
};

export const validationSchema = Yup.object().shape({
  newBed: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

function PaperComponent(props) {
  const { dialogId, ...rest } = props;

  return (
    <Draggable
      bounds="parent"
      handle={"#" + dialogId}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...rest} />
    </Draggable>
  );
}

export function ChangeBedDialog(props) {
  const {
    initialValues = {},
    validationSchema,
    onSubmit,
    onClose,
    resident,
    open,
    dialogTitle,
    entity,
    button,
    maxWidth,
    mode,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        isSubmitting,
      }) => (
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          scroll="body"
          open={open}
          PaperComponent={PaperComponent}
          PaperProps={{ dialogId: dialogId }}
          aria-labelledby={dialogId}
        >
          <DialogTitle
            style={{ cursor: "move" }}
            id={dialogId}
            disableTypography
          >
            <Typography variant="h4" component="h2">
              轉換床位
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="div">
              <Notification />
              <ChangeBedForm />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={onClose} disabled={isSubmitting}>
              取消
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => {
                setConfirmOpen(true);
              }}
            >
              {button ? button : "儲存"}
            </Button>
          </DialogActions>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            scroll="body"
            open={confirmOpen}
            PaperComponent={PaperComponent}
            PaperProps={{ dialogId: dialogId }}
            aria-labelledby={dialogId}
          >
            <form onSubmit={handleSubmit} noValidate>
              <DialogTitle
                style={{ cursor: "move" }}
                id={dialogId}
                disableTypography
              >
                <Typography variant="h4" component="h2">
                  轉換床位
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText component="div">
                  <Notification />
                  確定要將 <strong>{resident}</strong> 轉換床位 ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  onClick={() => setConfirmOpen(false)}
                  disabled={isSubmitting}
                >
                  取消
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => setConfirmOpen(false)}
                >
                  確定
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Dialog>
      )}
    </Formik>
  );
}

const ChangeBedForm = (props) => {
  const home = useSelector((state) => state.home);
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
  }, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    required
                    label="轉換床位號碼"
                    name="newBed"
                    apiUrl={API_URL.BED + "/references"}
                    apiParams={{
                      homeId: home?.home.id,
                      status: "AVAILABLE",
                    }}
                    getOptionLabel={(option) => option?.bedNo}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
