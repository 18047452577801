import { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import PrintIcon from "@material-ui/icons/Print";
import { NotificationActions } from "../../../actions";

const useStyles = makeStyles((theme) => ({
  right: {
    minWidth: 240,
    display: "flex",
    justifyContent: "flex-end",
  },
  statsData: {
    minWidth: 10,
  },
}));

export const ListingPageHeader = (props) => {
  const home = useSelector((state) => state.home);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { setCreating, handleFilterChange, homeData, apiUrl, selectedMonth } =
    props;

  const onPrint = (values, formikActions) => {
    let dayofmonth = moment(selectedMonth).daysInMonth();
    axios
      .get(apiUrl.print ? apiUrl.print : apiUrl, {
        params: {
          home: home.home.id,
          startDate: selectedMonth + "-01",
          endDate: selectedMonth + "-" + dayofmonth,
        },
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        setLoading(false);
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") +
            "_" +
            selectedMonth +
            "月份更表_print.docx"
        );
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  return (
    <div>
      <PageHeader title="編制更表">
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Formik
              initialValues={initialFilteringValues}
              validationSchema={filteringValidationSchema}
            >
              {() => (
                <Fragment>
                  <FilteringForm handleFilterChange={handleFilterChange} />
                  <Button
                    style={{ marginRight: 15, marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircleOutlinedIcon />}
                    onClick={() => {
                      dispatch(NotificationActions.clear());
                      setCreating(true);
                    }}
                  >
                    新增上班員工
                  </Button>
                  <Button
                    style={{ marginTop: 10 }}
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    onClick={onPrint}
                  >
                    列印員工每月輪值表
                  </Button>
                </Fragment>
              )}
            </Formik>
          </Grid>
          <Grid item sm={12} md={6}>
            <Table size="small" className={classes.right}>
              <TableBody>
                <TableRow>
                  <TableCell>日更HM人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.dayNumHm}
                  </TableCell>
                  <TableCell>日更RN/EN人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.dayNumRnEn}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>日更HW人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.dayNumHw}
                  </TableCell>
                  <TableCell>日更CW人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.dayNumCw}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>日更AW人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.dayNumAw}
                  </TableCell>
                  <TableCell>夜更HW人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.nightNumHw}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>夜更CW人數</TableCell>
                  <TableCell className={classes.statsData} align="right">
                    {homeData.nightNumCw}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell
                    className={classes.statsData}
                    align="right"
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PageHeader>
    </div>
  );
};
