import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import { API_URL, INSTITUTION_TYPE_OPTIONS } from '../../../../constants';

export const initialValues = {
    code: '',
    type: '',
    name: '',
    nameTc: '',
}

export const validationSchema = Yup.object().shape({
    // code: Yup.string()
    //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
    name: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    nameTc: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    type: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const MedicineInstitutionForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();


    useEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({values: initialValues});
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField label="機構代號" name="code" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikSelectField
                                    required
                                    label="機構類別"
                                    name="type"
                                    options={INSTITUTION_TYPE_OPTIONS}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="機構中文名稱" name="nameTc" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="機構英文名稱" name="name" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
