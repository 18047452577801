import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../components/PageHeader';
import { Formik } from "formik";
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import Button from '@material-ui/core/Button';
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { API_URL } from '../../../constants';

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);
    const [totalPettyCash, setTotalPettyCash] = useState(0);
    const {
        handleFilterChange,
        clearNotification,
        reload,
        reloadPageHeader
    } = props;

    useEffect(() => {
      console.debug(reloadPageHeader);
      axios
        .get(API_URL.PETTY_CASH_TOTAL + `?home=${home?.home?.id || home?.id || home}`)
        .then(response => {
          setTotalPettyCash(response.data.total);
        })
        .catch(error => console.error(error));
    }, [home, reload, reloadPageHeader]);

    return (
        <PageHeader title="零用現金支出">
            <Typography variant="h3" component="h3" style={{marginBottom: "10px"}}>{`零用現金總額 : $ ${totalPettyCash?.toLocaleString() || 0}`}</Typography>
            <Formik
                initialValues={initialFilteringValues}
                validationSchema={filteringValidationSchema}
            >
                {() => (
                    <Fragment>
                        <FilteringForm
                            handleFilterChange={handleFilterChange}
                        />
                    </Fragment>
                )}
            </Formik>
        </PageHeader >
    );
}
