import { useState, useEffect, useLayoutEffect } from 'react';
import { API_URL, ROUTES, HOME_EXPENSE_STATUS_OPTION, EXPENSE_TRANSACTION_METHOD_OPTION } from '../../../constants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import axios from 'axios';

export const initialValues = {

}

export function DetailsDialog(props) {
    const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } = props;
    const [entityValues, setEntityValues] = useState();
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        if (entity) {
          // use getAll because getOne is not allowed in backend
            axios.get((apiUrl.getAll ? apiUrl.getAll : apiUrl) + '/').then((response) => {
                let data = response?.data?.content;
                let itemData = data?.find(item => item.id === entity.id);
                if (!itemData) return;
                let entityValues = { ...initialValues, ...itemData };
                normalizeReceivedValues(entityValues);
                setEntityValues(entityValues);
            }).catch((error) => {
            }).finally(() => {
                setLoaded(true);
            });
        } else {
            setEntityValues(initialValues);
        }
    }, [entity]);

    return (

      <Dialog
          open={open}
          keepMounted
          maxWidth="lg"
          onClose={onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">{"支出詳細 (" + entityValues?.expenseId + ")"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  <Table>
                      <TableBody>
                          <TableRow>
                              <TableCell style={{ width:200 }}>支出項目</TableCell>
                              <TableCell style={{ width:500 }}>{entityValues?.expenseItem?.nameTc}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>供應商</TableCell>
                              <TableCell>{entityValues?.supplier?.nameTc}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>支出編號</TableCell>
                              <TableCell>{entityValues?.expenseId}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>支出日期及時間</TableCell>
                              <TableCell>{entityValues?.issueDateTime?.substring(0, 10) + ' / ' + entityValues?.issueDateTime?.substring(11, 16)}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>付款狀態</TableCell>
                              <TableCell>{HOME_EXPENSE_STATUS_OPTION.find(elem => elem.value == entityValues?.status)?.label}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>支出金額</TableCell>
                              <TableCell>$ {" "} {entityValues?.amount}</TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>付款記錄</TableCell>
                              <TableCell>{(entityValues &&
                                  entityValues.expenseExpenseTransaction)?(
                                  entityValues.expenseExpenseTransaction.length > 0 && (
                                      <Table size="small">
                                          <TableHead>
                                              <TableRow>
                                                  <TableCell>付款方式</TableCell>
                                                  <TableCell>付款日期</TableCell>
                                                  <TableCell>總數</TableCell>
                                                  <TableCell>備註</TableCell>
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {entityValues.expenseExpenseTransaction.map(item => (
                                                  <TableRow hover key={item.id}>
                                                      <TableCell>{EXPENSE_TRANSACTION_METHOD_OPTION.find(elem => elem.value == item.expenseTransaction?.method)?.label}</TableCell>
                                                      <TableCell>{item.expenseTransaction?.issueDateTime?.substring(0,10) + ' / ' + item.expenseTransaction?.issueDateTime?.substring(11,16)}</TableCell>
                                                      <TableCell>$ {item.amount} </TableCell>
                                                      <TableCell>{item.remarks} </TableCell>
                                                  </TableRow>
                                              ))}
                                          </TableBody>
                                      </Table>
                                  )) : "未有任何付款記錄"}
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button color="primary" variant="contained" onClick={onClose}>
                  關閉
              </Button>
          </DialogActions>
      </Dialog>
    );
}

