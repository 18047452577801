import { combineReducers } from 'redux';

import { authentication } from './auth.reducer';
import { notification } from './notification.reducer';
import { home } from './home.reducer';

const rootReducer = combineReducers({
  authentication,
  notification,
  home
});

export default rootReducer;