import { API_URL } from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  MedicineForm,
  initialValues,
  validationSchema,
} from "./form/MedicineForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./medicineSetting/FilteringForm";
import { ListingPageHeader } from "./medicineSetting/ListingPageHeader";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";

export default function MedicineSettingPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.homeId = home?.home?.id;

    params.contact = [
      {
        contactName: params.contactName,
        contactNo: params.contactTel,
        relationship: params.contactRelationship,
      },
    ];

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.homeId = home?.home?.id;

    return params;
  };

  const normalizeReceivedValues = (values) => {};

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;
    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "code",
        headerName: "藥物編號",
        width: 100,
        sortable: true,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "namePackageDose",
        headerName: "藥物英文名稱 - 包裝 - 劑量",
        width: 350,
        sortable: true,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "藥物中文名稱",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "productName",
        headerName: "商品名稱",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "takingRemarks",
        headerName: "服用須知",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "effect",
        headerName: "藥物功效",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "sideEffect",
        headerName: "副作用",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "ingredients",
        headerName: "成份",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.MEDICINE + "/searchNameDetails",
      getOne: API_URL.MEDICINE,
      create: API_URL.MEDICINE,
      edit: API_URL.MEDICINE,
      delete: API_URL.MEDICINE,
      copy: API_URL.MEDICINE,
    });
  }, [home]);

  return (
    <div>
      <EntityManagement
        entityModel="藥物"
        editableFields={[
          "code",
          "namePackageDose",
          "name",
          "nameTc",
          "productName",
          "unit",
          "takingInstruction",
          "prescriptionType",
          "takingRemarks",
          "effect",
          "sideEffect",
          "ingredients",
          "remarks",
          "isArMedicine",
          "isEffective",
          //"injectionPosition",
        ]}
        getColumnSettings={getColumnSettings}
        createParams={createParams}
        createFilteringParams={createFilteringParams}
        normalizeReceivedValues={normalizeReceivedValues}
        filteringParams={filteringParams}
        apiUrl={apiUrl}
        dataInputForm={MedicineForm}
        initialValues={initialValues}
        validationSchema={validationSchema}
        maxWidth="md"
        pageHeader={ListingPageHeader}
        pageHeaderProps={{ handleFilterChange: handleFilterChange }}
        defaultSorting={"code,asc"}
      ></EntityManagement>
    </div>
  );
}
