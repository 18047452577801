import { useEffect, useState } from "react";
import { apiService } from "../services";

const Image = ({ url, style }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!url) return;
    apiService
      .get(url, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        setData(Buffer.from(response.data, "binary").toString("base64"));
      })
      .catch((reason) => {
        console.debug(reason);
      });
  }, [url]);

  return (
    <img src={`data:image/png;base64,${data}`} style={style} /> || (
      <img src={`data:image/jpeg;base64,${data}`} style={style} />
    )
  );
};

export default Image;
