import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API_URL, ROUTES } from '../../constants';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './components/FilteringForm';
import { ListingPageHeader } from './components/ListingPageHeader';
import moment from 'moment';


export default function ResidentVitalSignsPage() {
    const home = useSelector(state => state.home);
    const [apiUrl, setApiUrl] = useState({});
    const [filteringParams, setFilteringParams] = useState(initialFilteringValues);
    const [hide, setHide] = useState(false);

    const createParams = (values, coreCreateParams) => {
        let params = {};
        params = coreCreateParams(values);

        return params;
    }

    const createFilteringParams = (values, coreCreateFilteringParams) => {
        let params = {};
        params = coreCreateFilteringParams(values);

        params.resident = params.resident;

        if (params.startDate != null) {
            params.startDate = moment(values.startDate).format("YYYY-MM-DD");
        }

        if (params.endDate != null) {
            params.endDate = moment(values.endDate).format("YYYY-MM-DD");
        }

        return params;
    }


    const handleFilterChange = (values) => {
        setFilteringParams(values);
    }

    useEffect(() => {
        setApiUrl({
            getAll: API_URL.RESIDENT_VITAL_SIGN,
            getOne: API_URL.RESIDENT_VITAL_SIGN,
            create: API_URL.RESIDENT_VITAL_SIGN,
            edit: API_URL.RESIDENT_VITAL_SIGN,
            delete: API_URL.RESIDENT_VITAL_SIGN,

        });
    }, [home]);




    return (
        <ListingPageHeader
            handleFilterChange={handleFilterChange}
            filteringParams={filteringParams}
            createFilteringParams={createFilteringParams}
        />
    );
}
