import React from "react";
import { useFormikContext } from "formik";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import zhLocale from "date-fns/locale/zh-TW";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      paddingRight: 0,
    },
  },
}));

const FormikDateField = (props) => {
  const {
    name,
    value,
    format,
    startTime,
    endTime,
    component: Component,
    onChange,
    ...rest
  } = props;
  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting,
  } = useFormikContext();

  const Field = Component || KeyboardDatePicker;

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={zhLocale}
      className={classes.root}
    >
      <Field
        okLabel="確定"
        clearLabel="重設"
        cancelLabel="取消"
        todayLabel="今日"
        invalidDateMessage="日期格式錯誤"
        id={rest.id || name}
        margin={rest.margin || "dense"}
        format={format || "yyyy-MM-dd"}
        fullWidth
        value={value || _.get(values, name) || null}
        inputVariant={rest.inputVariant || "outlined"}
        onChange={
          onChange ||
          ((date) => {
            if (startTime) {
              setFieldValue(
                name,
                date ? moment(date).format("YYYY-MM-DDT00:00:00+08:00") : null
              );
            } else if (endTime) {
              setFieldValue(
                name,
                date ? moment(date).format("YYYY-MM-DDT23:59:59+08:00") : null
              );
            } else {
              setFieldValue(name, date ? date : null);
            }
          })
        }
        onClose={() => {
          setFieldTouched(name, true, false);
        }}
        onBlur={handleBlur}
        showTodayButton
        clearable={true}
        disabled={isSubmitting}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
        helperText={_.get(touched, name) && _.get(errors, name)}
        /*onError={error => {
          if (error && _.get(errors, name) !== error) {
            setFieldError(name, error);
          }
        }}*/
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default FormikDateField;
