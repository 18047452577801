import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikSwitchField from "../../../components/inputs/FormikSwitchField";
import FormikDateField from "../../../components/inputs/FormikDateField"
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

export const initialValues = {
  resident: "",
  defaultExpenseItemEntityList: [],
  startDate: "",
  endDate: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  defaultExpenseItemEntityList: Yup.array().of(
    Yup.object().shape({
      invoiceItem: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      price: Yup.string()
        .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
        .required(<FormattedMessage id="validation.message.required" />),
      qty: Yup.string()
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />),
    })
  ),
  startDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />),
  endDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();
  const total = [];

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home.home.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  views={['month', 'year']}
                  format={"yyyy 年 M 月"}
                  name="startDate"
                  label="月份由"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  views={['month', 'year']}
                  format={"yyyy 年 M 月"}
                  name="endDate"
                  label="月份至"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} xs={12}>
        <fieldset>
          <FormLabel component="legend">收費項目資料</FormLabel>
          <FieldArray
            name="defaultExpenseItemEntityList"
            render={(arrayHelpers) => (
              <div>
                <Table stickyHeader padding="none" style={{ marginBottom: 15 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 225 }}
                      >
                        收費項目
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 100 }}
                      >
                        是否冷氣費
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 150 }}
                      >
                        描述
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 25 }}
                      >
                        數量
                      </TableCell>
                      <TableCell
                        omponent="th"
                        style={{ padding: 10, minWidth: 75 }}
                      >
                        單價
                      </TableCell>
                      <TableCell
                        align="center"
                        omponent="th"
                        style={{ padding: 10, minWidth: 100 }}
                      >
                        總數
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ width: 50, minWidth: 25 }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.defaultExpenseItemEntityList &&
                      values.defaultExpenseItemEntityList.map((item, index) => (
                        <TableRow key={index} disablegutters>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikReferenceField
                              edit={entity ? true : false}
                              required
                              styles={{
                                marginTop: "-4px",
                              }}
                              label="收費項目"
                              apiUrl={"/api/invoiceItems?type=INVOICE"}
                              getOptionLabel={(option) => option?.nameTc}
                              getOptionValue={(option) => option?.id}
                              name={`defaultExpenseItemEntityList.${index}.invoiceItem`}
                              apiParams={{
                                unpaged: true,
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikSwitchField
                              name={`defaultExpenseItemEntityList.${index}.airConditioningFee`}
                              center
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikTextField
                              label="描述"
                              name={`defaultExpenseItemEntityList.${index}.description`}
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 1 }}>
                            <FormikTextField
                              required
                              type="number"
                              label="數量"
                              name={`defaultExpenseItemEntityList.${index}.qty`}
                              defaultValue="0"
                            />
                          </TableCell>
                          <TableCell style={{ paddingRight: 10 }}>
                            <FormikTextField
                              required
                              type="number"
                              label="單價"
                              name={`defaultExpenseItemEntityList.${index}.price`}
                              defaultValue="0"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {values.defaultExpenseItemEntityList[index].qty &&
                            values.defaultExpenseItemEntityList[index].price
                              ? "$ " +
                                Number(
                                  (
                                    values.defaultExpenseItemEntityList[index].qty *
                                    values.defaultExpenseItemEntityList[index].price
                                  ).toFixed(2)
                                )
                              : "$ " + 0}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Button
                  color="default"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => arrayHelpers.push("")}
                >
                  新增
                </Button>
                <Typography variant="h4" component="h2" align="right">
                  {"賬單總數: $ " +
                    Number(
                      (
                        values.defaultExpenseItemEntityList &&
                        values.defaultExpenseItemEntityList.map(item =>
                          item.qty &&
                          item.price
                            ? item.qty *
                              item.price
                            : 0
                        )
                      )
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                    )}
                </Typography>
              </div>
            )}
          />
        </fieldset>
      </Grid>
    </Grid>
  );
};
