import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../components/PageHeader';
import { Formik } from "formik";
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import Button from '@material-ui/core/Button';
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { API_URL } from '../../../constants';

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);
    const [totalPettyCash, setTotalPettyCash] = useState(0);
    const {
        setCreating,
        handleFilterChange,
        clearNotification,
        reload,
        reloadPageHeader,
        setExportOpen,
    } = props;

    useEffect(() => {
      console.debug("fetchin petty cash");
      axios
        .get(API_URL.PETTY_CASH_TOTAL + `?home=${home?.home?.id || home?.id || home}`)
        .then(response => {
          setTotalPettyCash(response.data.total);
        })
        .catch(error => console.error(error));
    }, [home, reload, reloadPageHeader]);

    return (
        <PageHeader title="零用現金管理">
            <Typography variant="h3" component="h3" style={{marginBottom: "10px"}}>{`總額 : $ ${totalPettyCash?.toLocaleString() || 0}`}</Typography>
            <Formik
                initialValues={initialFilteringValues}
                validationSchema={filteringValidationSchema}
            >
                {() => (
                    <Fragment>
                        <FilteringForm
                            handleFilterChange={handleFilterChange}
                        />
                        <Button
                          style={{ marginRight: 15 }}
                          variant="contained"
                          color="primary"
                          startIcon={<AddCircleOutlinedIcon />}
                          onClick={() => {
                            clearNotification();
                            setCreating(true);
                          }}
                        >
                          新增總額
                        </Button>
                        <Button
                          style={{ marginRight: 15 }}
                          variant="contained"
                          color="primary"
                          startIcon={<OpenInBrowserOutlinedIcon />}
                          onClick={() => setExportOpen(true)}
                        >
                          匯出
                        </Button>
                    </Fragment>
                )}
            </Formik>
        </PageHeader >
    );
}
