import * as Yup from 'yup';
import { useState, useLayoutEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { FormikDialog } from '../../../components/dialogs';

export const initialValues = {
    bandCode: '',
    resident: '',
}

export const validationSchema = Yup.object().shape({
    bandCode: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export function RevokeDialog(props) {
    const { entity, onClose, onSubmit, open, apiUrl, normalizeReceivedValues } = props;

    return (
        <FormikDialog
            open={open}
            dialogTitle={"收回智能手帶 (" + entity?.bandCode + ")"}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onClose={onClose}
            maxWidth="xs"
            mode="delete"
        >
            <RevokeForm
                entity={entity}
                normalizeReceivedValues={normalizeReceivedValues}
            />
        </FormikDialog>
    );
}

const RevokeForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", } = props;
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();

    useLayoutEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: { ...initialValues, ...entity } });
        } else {
            resetForm({ values: initialValues });
        }
    }, [entity]);

    return (
        <form>
            注意！ 智能手帶編號 <strong>{values.bandCode}</strong> 將會與院友的配對刪除
        </form>
    );
}
