import { useState, useEffect } from 'react';
import { API_URL } from '../../../constants';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import QrReader from 'react-qr-reader'
import { Link } from "react-router-dom";

export const initialValues = {

}

export function QrCodeDialog(props) {
    const { open, onClose, onSubmit, apiUrl, normalizeReceivedValues } = props;
    const [code, setCode] = useState("");
    const [resident, setResident] = useState("");
    const [residentId, setResidentId] = useState("");
    const home = useSelector(state => state.home);

    const handleScan = data => {
        if (data) {
            setCode(data)
        }
    }
    const handleError = err => {
        console.error(err)
    }

    const handleClear = () => {
        setCode("");
        setResident("");
        setResidentId("");
    }

    const getEntities = () => {
        if (code !== "") {
            axios.get(API_URL.WRISTBAND + '/QRcode', {
                params: {
                    home: home.home.id,
                    code: code,
                }
            }).then((response) => {
                if (response.data.code !== "more than one result" && response.data.code !== "error") {
                    let data = response.data;
                    setResident(data?.resident?.lastnameTc + data?.resident?.firstnameTc);
                    setResidentId(data?.resident?.id);
                }
            });
        }
    }

    useEffect(() => {
        getEntities();
    }, [code]);

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="lg"
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"掃描QR Code"}</DialogTitle>
            <DialogContent>
                <Grid>
                    {open && (
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: 320, height: 320 }}
                            facingMode="environment"
                        />
                    )}
                    <Typography variant="h6" style={{ marginTop: 15 }}>手帶編號: {code !== "" ? code : "--"}</Typography>
                    <Typography variant="h6" style={{ marginTop: 5 }}>院友姓名: {resident ? resident : "--"}</Typography>
                </Grid>

            </DialogContent>
            <DialogActions>
                {residentId != "" ? (
                    <Link to={'/residents/' + residentId}>
                        <Button color="primary" variant="outlined" onClick={() => { onClose(); handleClear() }} >
                            查看個人資料
                        </Button>
                    </Link>
                ) : ""}
                <Button color="primary" variant="contained" onClick={() => { onClose(); handleClear() }} >
                    關閉
                </Button>
            </DialogActions>
        </Dialog>
    );
}


