
import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(30),
        minHeight: '100vh',
        background: '#ffffff'
    },
    content: {
        [theme.breakpoints.up('md')]: {
            maxWidth: 'calc(100vw - ' + (drawerWidth + parseInt(theme.spacing(3)) * 2) + 'px)',
        },
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            maxWidth: 'calc(100vw - ' + 50 + 'px)',
        },
    }
}));

export function MainContainer(props) {
    const classes = useStyles();

    return (
        <main className={classes.mainContainer}>
            <div className={classes.content} >
                {props.children}
            </div>
        </main>
    );
}