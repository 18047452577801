import { API_URL } from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import { PageHeader } from "../../../components/PageHeader";
import { Formik } from "formik";
import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "../components/WanderingRecordFilteringForm";

const WanderingRecordPageHeader = (props) => {
  const { handleFilterChange } = props;

  return (
    <PageHeader>
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm
              handleFilterChange={handleFilterChange}
            />
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};

export default function WanderingRecord(props) {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const { handleFilterChange, filteringParams, createFilteringParams } = props;

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.resident = params.resident.id || params.resident;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    return values;
  };

  const getTabColumnSettings = (props) => {
    return [
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.lastnameTc +
            params.row[params.field]?.firstnameTc
          );
        },
      },
      {
        field: "bandCode",
        headerName: "手帶編號",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]
          );
        },
      },
      {
        field: "passStartDate",
        headerName: "遊走開始日期及時間",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          const dateTime = params.row[params.field]?.split("T");
          return (
            `${dateTime[0]} / ${dateTime[1]}`
          );
        },
      },
      {
        field: "passEndDate",
        headerName: "遊走結束日期及時間",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          const dateTime = params.row[params.field]?.split("T");
          return (
            `${dateTime[0]} / ${dateTime[1]}`
          );
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.IBEACON_WANDERING_INFO,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="遊走記錄"
      editableFields={[]}
      getColumnSettings={getTabColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      FilteringForm={FilteringForm}
      maxWidth="sm"
      pageHeader={WanderingRecordPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    ></EntityManagement>
  );
}
