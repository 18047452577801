import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { NotificationActions } from '../../actions';
import WoundDressings from './components/WoundDressings';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Card>
                <CardContent>
                    {value === index && children}
                </CardContent>
            </Card >
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}




const tabs = [
    { title: '傷口敷料管理', component: <WoundDressings /> },
];

export default function NursingSettingPage() {
    const [tabActiveValue, setTabActiveValue] = useState(0);
    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        setTabActiveValue(newValue);
        dispatch(NotificationActions.clear());
    };

    return (
        <div>
            <Typography variant="h2" component="h2" style={{ marginBottom: 15 }}>護理設定</Typography>
            <AppBar position="static" color="default" style={{ marginBottom: 15 }}>
                <Tabs
                    value={tabActiveValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        tabs && tabs.map((tab, index) => {
                            return <Tab key={index} label={tab.title} disabled={tab.disabled} {...a11yProps(index)} />
                        })
                    }
                </Tabs>
            </AppBar>

            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    {
                        tabs && tabs.map((tab, index) => {
                            return (
                                <TabPanel key={index} value={tabActiveValue} index={index}>
                                    {tab.component || <h3>{tab.title}</h3>}
                                </TabPanel>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </div>
    );
}