import * as Yup from "yup";
import moment from "moment";
import "moment-lunar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikUploadField from "../../../components/inputs/FormikUploadField";
import axios from "axios";
import {
  API_URL,
  BED_TYPE_OPTIONS,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import { it } from "date-fns/locale";
import InputAdornment from "@material-ui/core/InputAdornment";

export const initialValues = {
  resident: "",
  issueDateTime: moment(new Date()).toISOString(),
  status: "NOT_YET_PAID",
  invoiceItem: "",
  description: "",
  price: "",
  priceConfirmation: "",
  residentInvoiceItems: [],
  depositId: "",
  qty: 1,
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  price: Yup.string()
    .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  priceConfirmation: Yup.string().when("price", (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return schema
      .oneOf([Yup.ref("price")], "確認金額與金額不符")
      .required(<FormattedMessage id="validation.message.required" />);
  }),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();
  const total = [];

  useEffect(() => {
    axios.get("/api/invoiceItems?type=DOWN").then((response) => {
      setFieldValue("depositId", response.data.content[0].id);
    });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" +
                    home?.home?.id +
                    "/reservationResidents/references"
                  }
                  getOptionLabel={(option) =>
                    option.reservations &&
                    option?.reservations[0].lastnameTc +
                      option?.reservations[0].firstnameTc
                  }
                  getOptionValue={(option) => option?.id}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateTimeField
                  required
                  label="訂金日期及時間"
                  name="issueDateTime"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="金額"
                  name="price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="確認金額"
                  name="priceConfirmation"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="描述" name="description" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
