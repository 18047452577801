import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../../components/inputs/FormikTextField";


export const initialValues = {
    code: '',
    name: '',
    nameTc: '',
}

export const validationSchema = Yup.object().shape({
    code: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    nameTc: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    // type: Yup.string()
    //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
    // medicationScheduleSessionList: Yup.array().of(Yup.object().shape({
    //     time: Yup.string().required(<FormattedMessage id="validation.message.required" />).nullable(),
    //     sort: Yup.string().matches(/^[0-9]*$/, "輸入必須為正整數").required(<FormattedMessage id="validation.message.required" />).nullable(),
    // })),
});

export const MedicationTimeForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();

    useEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({ values: initialValues });
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="時間代號" name="code" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="時間名稱 (中文)" name="nameTc" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="時間名稱 (英文)" name="name" />
                            </Grid>
                            {/* <Grid item sm={12} xs={12}>
                                <fieldset>
                                    <FormLabel component="legend">用藥時間</FormLabel>
                                    <FieldArray
                                        name="medicationScheduleSessionList"
                                        render={arrayHelpers => (
                                            <div>
                                                <TableContainer style={{ maxHeight: 400 }}>
                                                    <Table stickyHeader padding="none" style={{ marginBottom: 15 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell omponent="th" style={{ padding: 10 }}>
                                                                    時間
                                                                </TableCell>
                                                                <TableCell omponent="th" style={{ padding: 10 }}>
                                                                    格數次序（藥紙及藥板）
                                                                </TableCell>
                                                                <TableCell align="right" style={{ width: 50 }}>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                values.medicationScheduleSessionList && values.medicationScheduleSessionList.map((schedule, index) => (
                                                                    <TableRow key={index} disablegutters>
                                                                        <TableCell style={{ paddingRight: 1 }}>
                                                                            <FormikTimeField label="用藥區間" name={`medicationScheduleSessionList.${index}.time`} />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <FormikTextField label="格數次序" name={`medicationScheduleSessionList.${index}.sort`} type="number" />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <IconButton onClick={() => arrayHelpers.remove(index)}>
                                                                                <DeleteOutlinedIcon />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Button color="default" variant="contained" type="button" size="small" onClick={() => arrayHelpers.push('')}>
                                                    新增
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikSelectField
                                    required
                                    label="時間類別"
                                    name="type"
                                    options={SCHEDULE_SESSION_TYPE}
                                />
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}