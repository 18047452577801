import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../components/PageHeader';
import { Formik } from "formik";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import BloodPressurePluse from '../tabs/BloodPressurePluse'
import BloodOxygen from '../tabs/BloodOxygen'
import BloodSugar from '../tabs/BloodSugar'
import Breathing from '../tabs/Breathing'
import Temperature from '../tabs/Temperature'
import BMI from '../tabs/BMI'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { NotificationActions } from '../../../actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Card>
                <CardContent>
                    {value === index && children}
                </CardContent>
            </Card >
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}




const tabs = [
    { title: '血壓 / 脈搏', component: BloodPressurePluse },
    { title: '血氧', component: BloodOxygen },
    { title: '血糖', component: BloodSugar },
    { title: '呼吸速率', component: Breathing },
    { title: '體溫', component: Temperature },
    { title: '身高體重指數', component: BMI },

];

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);
    const [tabActiveValue, setTabActiveValue] = useState(0);
    const dispatch = useDispatch();

    const handleTabChange = (event, newValue) => {
        setTabActiveValue(newValue);
        dispatch(NotificationActions.clear());
    };

    const {
        filteringParams,
        handleFilterChange,
        createFilteringParams
    } = props;

    return (
        <div>
            <PageHeader title="生命表徵" hideNotification>
                <Formik
                    initialValues={initialFilteringValues}
                    validationSchema={filteringValidationSchema}
                >
                    {() => (
                        <Fragment>
                            <FilteringForm
                                handleFilterChange={handleFilterChange}
                            />
                        </Fragment>
                    )}
                </Formik>
            </PageHeader >
            <AppBar position="static" color="default" style={{ marginBottom: 15 }}>
                <Tabs
                    value={tabActiveValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        tabs && tabs.map((tab, index) => {
                            return <Tab key={index} label={tab.title} disabled={tab.disabled} {...a11yProps(index)} />
                        })
                    }
                </Tabs>
            </AppBar>

            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    {
                        tabs && tabs.map((tab, index) => {
                            const Component = tab.component;

                            return (
                                <TabPanel key={index} value={tabActiveValue} index={index}>
                                    {<Component filteringParams={filteringParams} createFilteringParams={createFilteringParams} /> || <h3>{tab.title}</h3>}
                                </TabPanel>
                            );
                        })
                    }
                </Grid>
            </Grid>
        </div>
    );
}