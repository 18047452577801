import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import axios from "axios";
import FormikTextField from "../../../components/inputs/FormikTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export const initialValues = {
  issueDateTime: "",
  amount: 0,
  amountConfirmation: 0,
};

export const validationSchema = Yup.object().shape({
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  amount: Yup.string()
    .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  amountConfirmation: Yup.string()
    .oneOf([Yup.ref("amount")], "確認金額與金額不符")
    .required(<FormattedMessage id="validation.message.required" />),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  // useEffect(() => {
  //   resetForm({ values: initialValues });
  //   if (entity) {
  //     resetForm({ values: entity });
  //   } else {
  //     resetForm({ values: initialValues });
  //   }
  // }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="金額"
                  name="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="確認金額"
                  name="amountConfirmation"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikDateTimeField
                  required
                  label="日期及時間"
                  name="issueDateTime"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
