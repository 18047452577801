import * as Yup from 'yup';
import { useState, useEffect, React } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import { API_URL, CALENDAR_FONT_COLOR_OPTION } from '../../../../constants';
import ColorPicker from 'material-ui-color-picker'
import InputAdornment from '@material-ui/core/InputAdornment';
import StopIcon from '@material-ui/icons/Stop';
import FormHelperText from '@material-ui/core/FormHelperText';

export const initialValues = {
    name: '',
    nameTc: '',
    calendarColor: '',
    calendarFontColor: '#000000',
}

export const validationSchema = Yup.object().shape({
    nameTc: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    calendarColor: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    calendarFontColor: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const MedicalTreatmentItemsForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const [pickColor, setPickColor] = useState("");
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();


    const handleColorChange = (color) => {
        setPickColor(color);
        setFieldValue("calendarColor", color);
    }

    useEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({ values: initialValues });
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined" style={{ height: 450 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="醫療項目 (中文)" name="nameTc" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField label="醫療項目 (英文)" name="name" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <ColorPicker
                                    fullWidth
                                    required
                                    label="日曆顏色編碼"
                                    variant="outlined"
                                    name="calendarColor"
                                    margin="dense"
                                    onChange={handleColorChange}
                                    value={values?.calendarColor}
                                    defaultValue={pickColor}
                                    InputProps={{
                                        value: values?.calendarColor,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <StopIcon style={{ color: values?.calendarColor }} fontSize="large" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikSelectField
                                    required
                                    label="日曆字體顏色"
                                    name="calendarFontColor"
                                    options={CALENDAR_FONT_COLOR_OPTION}
                                />
                                <FormHelperText id="standard-weight-helper-text">如日曆顏色使用深色, 請使用白色字體</FormHelperText>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}