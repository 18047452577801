import React, { useState, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { IntlProvider } from "react-intl";



const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%",
    fontSize : "16px"
  },
  locale: {
    margin: theme.spacing(6, 0, 1)
  }
}));

export default function TermsAndConditionsZhPage() {
  const classes = useStyles();

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .no-background {
        background-image: none !important;
      }
    `;
    document.head.appendChild(style);
    document.body.classList.add('no-background');
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <IntlProvider>
      <Grid
        component="main"
        justify="space-evenly"
        className={classes.root}
      >
        <Grid item xs={12} sm={12} md={12} >
          <div className={classes.panel}>

          <h2><b>條款和條件</b></h2>
            <pre>
{`
1.接受條款與條件
在親人成為我們老人院的居民之前，必須接受所有條款與條件，包含居民行為準則、訪客政策、付款方式等。

2.入住條件
所有希望入住的居民必須通過健康評估，以確保我們能提供所需護理水平。

3.付款條款
費用必須在每個月的第一個工作日支付，接受銀行轉帳或支票支付。遲延超過一週，我們有權拒絕服務。

4.退房政策
若居民或其家人希望退房，需提前一個月通知。退房後的剩餘費用將退還。

5.訪客政策
所有訪客必須在訪問前提前通知我們。我們有權在必要時限制訪客數量和訪問時間。

6.個人物品
居民可帶部分個人物品，但大小和數量可能會受限，以確保安全和清潔。我們不負責任何遺失或損壞的物品。

7.醫療照護
我們提供基本醫療照護，若居民需要特殊或進階照護，可能需付額外費用，或轉移到更適合的醫療設施。

8.行為規範
所有居民必須遵守行為規範，如尊重他人、保持環境清潔。嚴重或持續的行為問題可能導致終止合約。

9.變更條款與條件
我們有權隨時修改條款與條件，將提前通知所有居民和家人重大變更。

10.居民權利與責任
所有居民有權受到尊重、公正對待，並有責任尊重他人。我們鼓勵居民參與活動，提供反饋以改善服務。

11.服務變更
我們可能會因業務需求或法規變更調整服務，將在變更生效前提前通知。

12.緊急情況
在緊急情況下，我們可能需暫時變更服務或條款和條件，將盡力提前通知。

13.個人資料保護
我們將遵守所有數據保護法規，尊重居民隱私，並未經許可不會提供居民資訊給第三方。

14.投訴程序
我們鼓勵居民或他們的家人若對我們的服務有不滿，可通過我們的投訴程序提出。我們將認真對待並盡快解決。

15.終止合約
我們有權在特定情況下終止居民的合約，如長期未支付費用、嚴重或持續的行為問題，或我們無法提供所需護理水平。在此情況下，我們將提供最大程度的通知並協助尋找其他護理機構。

以上條款旨在保護每一位居民的權益，並確保我們能提供高質量的護理服務。我們期待每一位居民和他們的家人的理解和合作。
`}
            </pre>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
