import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL } from "../../constants";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { ExportDialog } from "./components/ExportDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import { NotificationActions } from "../../actions";

export default function HomeExpensePaymentRecordPage() {
  const home = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [exportEntity, setExportEntity] = useState(null);
  const [exportOpen, setExportOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [reload, setReload] = useState(false);

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const onEdit = (values, formikActions) => {
    const params = _.pick(values, ['salary', 'mpf', 'total', 'position']);
    params.home = home?.home?.id;

    axios
      .put(apiUrl.edit + "/" + values.id, params)
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  }

  const onExport = (values, formikActions) => {
    console.log("onExport");
    const params = {...values};
    params.home = home?.home?.id;

    // TODO: finish the codes when backend api finish
    dispatch(NotificationActions.error(null, "Error"));
    formikActions.setSubmitting(false);
    setExportOpen(false);
    setReload(!reload);
    // axios
    //   .get(apiUrl.edit + "/" + values.id, params)
    //   .then((response) => {
    //     dispatch(NotificationActions.success(null, "已匯出"));
    //     formikActions.setSubmitting(false);
    //     setEditOpen(false);
    //     setReload(!reload);
    //   })
    //   .catch((error) => {
    //     dispatch(NotificationActions.error(null, "錯誤"));
    //     formikActions.setSubmitting(false);
    //   });
  }

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "nameTc",
        headerName: "員工姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "name",
        headerName: "員工姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "position",
        headerName: "職位",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] || '沒有記錄';
        },
      },
      {
        field: "salary",
        headerName: "薪金",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return `$ ${params.row[params.field]?.toLocaleString() || "0"}`;
        },
      },
      {
        field: "mpf",
        headerName: "強積金金額",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return `$ ${params.row[params.field]?.toLocaleString() || "0"}`;
        },
      },
      {
        field: "total",
        headerName: "實際收入",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return `$ ${params.row[params.field]?.toLocaleString() || "0"}`;
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.STAFF_SALARY,
      getOne: API_URL.STAFF_SALARY,
      edit: API_URL.STAFF_SALARY,
      delete: API_URL.STAFF_SALARY,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="員工薪金"
      editableFields={[
        "salary",
        "mpf",
        "total",
        "position",
      ]}
      getColumnSettings={getColumnSettings}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      pageHeader={ListingPageHeader}
      maxWidth="xs"
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        reload: reload,
        setExportOpen: setExportOpen,
      }}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <ExportDialog
        open={exportOpen}
        entity={exportEntity}
        onClose={() => {
          setExportEntity(null);
          setExportOpen(false);
        }}
        onSubmit={onExport}
      />
    </EntityManagement>
  );
}
