export default {
  '@global': {
    html: {
      minHeight: '100%'
    },
    body: {
		  backgroundColor: '#ffffff',
		  backgroundSize: 'cover',
      backgroundPosition: 'center center'
	}
  }
};