import * as Yup from "yup";
import moment from "moment";
import "moment-lunar";
import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { API_URL, GENDER_OPTIONS, EVALUATION_RESULT_OPTION, DOWN_PAYMENT_METHOD_OPTION } from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikHKIDField from "../../../components/inputs/FormikHKIDField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import { HKID_REGEX } from "../../../constants/others.constant";

export const initialValues = {
  resident: "",
  lastnameTc: "",
  firstnameTc: "",
  contactName: "",
  contactNo: "",
  contactRelationship: "",
  estimatedCheckInDate: moment(new Date()).toISOString(),
  checkInDate: moment(new Date()).toISOString(),
  bed: "",
  residentBedType: "",
  ldsNo: "",
  evaluationResult: "NOT_EVALUATED",
  evaluationDate: "",
};

var today = new Date();

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),

  // TODO: uncomment if need this fields in the future
  // lastnameTc: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // lastname: Yup.string()
  //   // .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // firstnameTc: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // firstname: Yup.string()
  //   // .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // gender: Yup.string()
  //   // .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // dateOfBirth: Yup.date()
  //   // .required(<FormattedMessage id="validation.message.required" />)
  //   .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
  //   .nullable(),
  // hkid: Yup.string()
  //   //.required(<FormattedMessage id="validation.message.required" />).nullable(),
  //   .matches(
  //     /^([A-Z]{1,2})([0-9]{6})([(]{1})([A0-9])([)]{1})$/,
  //     "請輸入正確的身份證號碼"
  //   )
  //   .min(8, "身份證號碼長度必須為8個位或以上"),
  // contactName: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // contactNo: Yup.string()
  //   .min(8, "電話長度必須為8個位")
  //   .max(8, "電話長度必須為8個位")
  //   .matches(/^[0-9]*$/, "輸入必須為正整數")
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),


  // contactRelationship: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // roomRemarks: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),

  // TODO: uncomment if need these fields in the future
  // checkInDate: Yup.date()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
  //   .nullable()
  //   .max(today, "日期必須為今日或以前"),
  // estimatedCheckInDate: Yup.date()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
  //   .nullable(),
  // bed: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  // residentBedType: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),


  // ldsNo: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationResult: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationDate: Yup.date()
  //     .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable()

  // TODO: uncomment if need this field in the future
  // downPayment: Yup.boolean(),
  // downPrice: Yup.string().when("downPayment", {
  //   is: true,
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
  // downPriceConfirmation: Yup.string().when("downPrice", (value, schema) => {
  //   if (!value) {
  //     return schema.notRequired();
  //   }
  //   return schema
  //     .oneOf([Yup.ref("downPrice")], "確認金額與金額不符")
  //     .required(<FormattedMessage id="validation.message.required" />);
  // }),
  // downIssueTime: Yup.string().when("downPayment", {
  //   is: true,
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
  // downPaymentMethod: Yup.string().when("downPayment", {
  //   is: true,
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
});

export function CheckInAgainDialog(props) {
  const { entity, open, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  return (
    <FormikDialog
      open={open}
      entity={entity}
      dialogTitle={"重新入住"}
      onSubmit={onSubmit}
      onClose={onClose}
      button={"入住登記"}
      validationSchema={validationSchema}
      maxWidth="sm"
    >
      <CheckInAgainForm entity={entityValues} />
    </FormikDialog>
  );
}

const CheckInAgainForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();
  const [currentResidentId, setCurrentResidentId] = useState("");
  const [payNow, setPayNow] = useState(false);
  const handlePayNowChange = (event) => {
    if (event.target.checked) {
      setPayNow(true);
      setFieldValue("downIssueTime", moment(new Date()).toISOString());
    } else {
      setPayNow(false);
      setFieldValue("downIssueTime", "");
    }
  };

  const setLunarDateOfBirthAndAge = (value) => {
    setFieldValue(
      "lunarDateOfBirth",
      value ? moment(value).lunar().format("YYYY-MM-DD") : ""
    );
    setFieldValue("age", moment().diff(value, "years", false) || "0");
  };

  const handleDateOfBirthChange = (value) => {
    setFieldValue("dateOfBirth", moment(value).format("YYYY-MM-DD"));
    setLunarDateOfBirthAndAge(value);
  };

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
      setLunarDateOfBirthAndAge(entity.dateOfBirth);
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  // TODO: uncomment if require fields other than resident in the future
  // useEffect(() => {
  //   if (values.resident != currentResidentId) {
  //     setCurrentResidentId(values.resident);
  //     if (!values.resident) return;

  //     axios
  //       .get(API_URL.RESERVATION_DISCHARGED)
  //       .then((response) => {
  //         let data = response?.data;

  //         let dischargeOfResident = data.find((discharge) => discharge.resident?.id === values.resident);

  //         const newValues = {
  //           ...values,
  //           ...{
  //             lastnameTc: "",
  //             firstnameTc: "",
  //             lastname: "",
  //             firstname: "",
  //             gender: "",
  //             hkid: "",
  //             homeReturnPermitId: "",
  //             dateOfBirth: "",
  //             lunarDateOfBirth: "",
  //             age: "",
  //           },
  //           ...(dischargeOfResident?.resident || [])
  //         };

  //         if (newValues.hkid === "_______(_)" || !HKID_REGEX.test(newValues.hkid)) {
  //           delete newValues.hkid;
  //         }

  //         resetForm({ values: newValues });
  //       })
  //       .catch((error) => {});
  //   }
  // }, [values]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <FormikReferenceField
            edit={entity ? true : false}
            required
            label="已退院院友"
            name="resident"
            apiUrl={API_URL.RESERVATION_DISCHARGED}
            getOptionLabel={(option) => option?.lastnameTc + option?.firstnameTc}
            variation="dischargedResident"
          />
        </Grid>
        {/* <Grid item sm={8} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                已退院院友
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="院友"
                    name="resident"
                    apiUrl={API_URL.RESERVATION_DISCHARGED}
                    getOptionLabel={(option) => option?.lastnameTc + option?.firstnameTc}
                    variation="dischargedResident"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                個人資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    label="中文姓氏"
                    name="lastnameTc"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    label="中文名字"
                    name="firstnameTc"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    label="英文姓氏"
                    name="lastname"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    label="英文名字"
                    name="firstname"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikSelectField
                    label="性別"
                    name="gender"
                    options={GENDER_OPTIONS}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikHKIDField label="身份證號碼" name="hkid" />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikTextField
                    label="其他證明文件號碼"
                    name="homeReturnPermitId"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikDateField
                    label="出生日期"
                    name="dateOfBirth"
                    onChange={handleDateOfBirthChange}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikDateField
                    label="農曆"
                    name="lunarDateOfBirth"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikTextField label="年齡" name="age" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                床位資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="床號"
                    name="bed"
                    apiUrl={API_URL.BED + "/references"}
                    apiParams={{
                      homeId: home?.home.id,
                      status: "AVAILABLE",
                    }}
                    getOptionLabel={(option) => option?.bedNo}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="床位類別"
                    name="residentBedType"
                    apiUrl={"/api/residentBedTypes"}
                    getOptionLabel={(option) => option?.type}
                    apiParams={{
                      unpaged: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <FormikDateField
                    startTime
                    required
                    label="預計入住日期"
                    name="estimatedCheckInDate"
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <FormikDateField
                    startTime
                    required
                    label="實際入住日期"
                    name="checkInDate"
                    helperText="日期必須為今日或以前"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    聯絡人資料
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        required
                        label="聯絡人"
                        name="contactName"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        required
                        label="聯絡人電話"
                        name="contactNo"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField label="關係" name="contactRelationship" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    備註
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField label="房間備註" name="roomRemarks" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    評核資料
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField label="LDS編號" name="ldsNo" />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikSelectField
                        label="評核結果"
                        name="evaluationResult"
                        options={EVALUATION_RESULT_OPTION}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikDateField
                        startTime
                        label="評核日期"
                        name="evaluationDate"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                訂金資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={2} xs={12}>
                  <FormikCheckboxField
                    label="立即遞交訂金"
                    name="downPayment"
                    onChange={handlePayNowChange}
                  />
                </Grid>
                <Grid item sm={10} xs={12}>
                  {payNow && (
                    <Grid container spacing={2}>
                      <Grid item sm={3} xs={12}>
                        <FormikTextField
                          required
                          label="訂金金額"
                          name="downPrice"
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormikTextField
                          required
                          label="確認訂金金額"
                          name="downPriceConfirmation"
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormikDateTimeField
                          required
                          label="訂金日期及時間"
                          name="downIssueTime"
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FormikSelectField
                          required
                          label="付款方法"
                          name="downPaymentMethod"
                          options={DOWN_PAYMENT_METHOD_OPTION}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </form>
  );
};
