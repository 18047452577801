import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useFormikContext } from "formik";
import { makeStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles";
import _ from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  group: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    marginLeft: -theme.spacing(0.5),
    marginRight: -theme.spacing(0.5),
  },
  endOfMonth: {
    borderRadius: "20px !important",
    "&:not(:first-child)": {
      width: theme.spacing(0.5) * 4 + 120,
    },
    "&:first-child": {
      width: theme.spacing(0.5) * 4 + 120,
    },
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    width: 40,
    height: 40,
    "&:not(:first-child)": {
      borderRadius: "50%",
      border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5),
    },
    "&:first-child": {
      borderRadius: "50%",
      border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.5),
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
    "&$selected": {
      color: theme.palette.primary.main,
      backgroundColor: fade(theme.palette.primary.main, 0.12),
      "&:hover": {
        backgroundColor: fade(theme.palette.primary.main, 0.15),
      },
      "& + &": {
        borderRadius: "50%",
        border: `1px solid ${fade(theme.palette.action.active, 0.12)}`,
        margin: theme.spacing(0.5),
        padding: theme.spacing(0.5),
      },
    },
  },
  selected: {},
}))(ToggleButton);

const FormikWeekToggleButtonGroup = (props) => {
  const { name, month, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();

  let dayOfMonth = [];
  for (let day = 1; day <= month; day++) {
    dayOfMonth.push({ day: day });
  }

  return (
    <div>
      <ToggleButtonGroup
        className={classes.group}
        size="small"
        {...rest}
        value={values[name]}
        onChange={(event, value) => {
          setFieldTouched(name, true, false);
          setFieldValue(name, value);
        }}
      >
        {dayOfMonth.map((item) => {
          return (
            <StyledToggleButton value={item.day} disabled={isSubmitting}>
              <Typography variant="button">{item.day}</Typography>
            </StyledToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {Boolean(_.get(touched, name) && _.get(errors, name)) && (
        <FormHelperText error={true}>
          {_.get(touched, name) && _.get(errors, name)}
        </FormHelperText>
      )}
    </div>
  );
};

export default FormikWeekToggleButtonGroup;
