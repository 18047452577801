import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import RemoveIcon from "@material-ui/icons/Remove";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import InsertLinkOutlinedIcon from "@material-ui/icons/InsertLinkOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import WatchOutlinedIcon from "@material-ui/icons/WatchOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import { STAFF_ROLE_SECTION_OPTIONS } from "../../../constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  nested: {
    paddingLeft: 35,
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export const initialValues = {
  code: "",
  name: "",
  nameTc: "",
};

export const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  name: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  nameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

const CollapseList = (props) => {
  const classes = useStyles();
  const { label, value, name, icon, children, tick, ...rest } = props;
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleToggle = (event) => {
    let checked = event.target.checked;
    let value = event.target.value;
    let selected = values[name] || [];

    if (checked) {
      if (!selected.includes(value)) {
        selected.push(value);
      }
    } else {
      selected = selected.filter((i) => i !== event.target.value);
    }

    setFieldValue(name, selected);
  };

  const handleToggleAll = (event) => {
    let checked = event.target.checked;
    let selected = values[name] || [];

    if (checked) {
      children.forEach((ss) => {
        if (!selected.includes(ss.value)) {
          selected.push(ss.value);
        }
      });
    } else {
      children.forEach((ss) => {
        selected = selected.filter((i) => i !== ss.value);
      });
    }
    setFieldValue(name, selected);
  };

  const handleEntityToggleAll = (event) => {
    let checked = event.target.checked;
    let value = event.target.value;
    let selected = values.allPermissions || [];

    if (checked) {
      if (!selected.includes(value)) {
        selected.push(value);
      }
    } else {
      selected = selected.filter((i) => i !== event.target.value);
    }

    setFieldValue(name, selected);
  };

  const getIconFromString = (iconName) => {
    switch (iconName) {
      case "HomeWorkOutlinedIcon":
        return <HomeWorkOutlinedIcon />;
      case "PeopleOutlineOutlinedIcon":
        return <PeopleOutlineOutlinedIcon />;
      case "AccountBalanceWalletOutlinedIcon":
        return <AccountBalanceWalletOutlinedIcon />;
      case "InsertLinkOutlinedIcon":
        return <InsertLinkOutlinedIcon />;
      case "LocalHospitalOutlinedIcon":
        return <LocalHospitalOutlinedIcon />;
      case "FavoriteBorderOutlinedIcon":
        return <FavoriteBorderOutlinedIcon />;
      case "PermContactCalendarOutlinedIcon":
        return <PermContactCalendarOutlinedIcon />;
      case "WatchOutlinedIcon":
        return <WatchOutlinedIcon />;
      case "FolderOpenOutlinedIcon":
        return <FolderOpenOutlinedIcon />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <ListItem component="div">
        <ListItemIcon>{getIconFromString(icon)}</ListItemIcon>
        <FormikCheckboxField
          label={label}
          name={tick}
          checked={children.every((p) => values.permissions?.includes(p.value))}
          value={value}
          onChange={
            values.permissions?.includes(tick)
              ? handleEntityToggleAll
              : handleToggleAll
          }
        />
        <ListItemSecondaryAction
          component="div"
          button="true"
          onClick={handleOpen}
        >
          <IconButton edge="end" aria-label="comments">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children?.map((ss, index) => {
            return (
              <ListItem key={index} button="true" className={classes.nested}>
                <ListItemIcon>
                  <RemoveIcon />
                </ListItemIcon>
                <FormikCheckboxField
                  icon={ss.icon}
                  label={ss.label}
                  name={ss.value}
                  value={ss.value}
                  checked={values[name]?.includes(ss.value)}
                  onChange={handleToggle}
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </Fragment>
  );
};

export const DataInputForm = (props) => {
  const { entity, apiUrl = "", ...rest } = props;
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="代號" name="code" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="群組名稱(英文)" name="name" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField
                  required
                  label="群組名稱(中文)"
                  name="nameTc"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      請選擇用戶權限
                    </ListSubheader>
                  }
                  className={classes.root}
                >
                  <Divider />
                  {STAFF_ROLE_SECTION_OPTIONS.map((s, index) => {
                    return (
                      <CollapseList
                        key={index}
                        icon={s.icon}
                        tick={s.value}
                        label={s.label}
                        name="permissions"
                        children={s?.children}
                      />
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
