import { useState, useEffect, Fragment } from "react";
import PropTypes from 'prop-types';
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import { Formik } from "formik";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import WanderingRecord from "../tabs/WanderingRecord";
import AntiWanderingManagement from "../tabs/AntiWanderingManagement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Card>
        <CardContent>{value === index && children}</CardContent>
      </Card>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const tabs = [
  { title: "遊走紀錄", component: WanderingRecord },
  { title: "防遊走管理", component: AntiWanderingManagement },
];

export const ListingPageHeader = (props) => {
  const [tabActiveValue, setTabActiveValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabActiveValue(newValue);
  };
  const {
    handleFilterChange,
    filteringParams,
    createFilteringParams,
  } = props;

  // clear filter params when switch tab
  useEffect(() => {
    handleFilterChange({});
  }, [tabActiveValue]);

  return (
    <div>
      <PageHeader title="防遊走總覽">
        {/* <Formik
          initialValues={initialFilteringValues}
          validationSchema={filteringValidationSchema}
        >
          {() => (
            <Fragment>
              <FilteringForm
                handleFilterChange={handleFilterChange}
              />
            </Fragment>
          )}
        </Formik> */}
      </PageHeader>
      <AppBar position="static" color="default" style={{ marginBottom: 15 }}>
        <Tabs
          value={tabActiveValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs &&
            tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={tab.title}
                  disabled={tab.disabled}
                  {...a11yProps(index)}
                />
              );
            })}
        </Tabs>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          {tabs &&
            tabs.map((tab, index) => {
              const Component = tab.component;

              return (
                <TabPanel key={index} value={tabActiveValue} index={index}>
                  {(
                    <Component
                      filteringParams={filteringParams}
                      createFilteringParams={createFilteringParams}
                      handleFilterChange={handleFilterChange}
                    />
                  ) || <h3>{tab.title}</h3>}
                </TabPanel>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};
