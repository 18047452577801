import * as Yup from "yup";
import moment from "moment";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikTimeField from "../../../components/inputs/FormikTimeField";
import axios from "axios";
import {
  API_URL,
  TAKING_STATUS_OPTIONS,
  TAKING_INSTRUCTION_OPTIONS,
  PRESCRIPTION_TYPE_OPTIONS,
  TAKING_PERIOD_OPTIONS,
  UNIT_OPTIONS,
  CHECK_IN_STATUS_OPTIONS,
} from "../../../constants";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import Image from "../../../components/Image";

export const initialValues = {
  resident: "",
  medicineCode: "",
  medicineNamePackageDose: "",
  takingMethod: "",
  takingPeriod: "",
  nValue: "",
  medicineCount: "",
  medicineUnit: "",
  isPrn: false,
  isGp: false,
  institution: "",
  specialist: "",
  prescriptionType: "",
  startDate: moment(new Date()).toISOString(),
  endDate: "",
  residentDrugMedicationRemarks: [],
  remarks: "",
  status: "",
  medicationSchedule: "",
  residentMedicationScheduleSession: [],
  //injectionPosition: "",
};

export const validationSchema = Yup.object().shape({
  medicineCode: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  medicineNamePackageDose: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  takingMethod: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  takingPeriod: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  nValue: Yup.string().when("takingPeriod", (value, schema) => {
    if (
      value == "EVERY_N_DAY" ||
      value == "EVERY_N_WEEK" ||
      value == "EVERY_N_DAY_OF_WEEK" ||
      value == "EVERY_N_MONTH"
    ) {
      return schema.required(
        <FormattedMessage id="validation.message.required" />
      );
    }
  }),
  medicineCount: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  medicineUnit: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  prescriptionType: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  medicationSchedule: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  institution: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  specialist: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  residentMedicationScheduleSession: Yup.array().of(
    Yup.object().shape({
      time: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
    })
  ),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [residentName, setresidentName] = useState("");
  const [checkInStatus, setCheckInStatus] = useState("");
  const [bed, setBed] = useState("");
  const [photo, setPhoto] = useState("");
  const [residentInfoEntity, setResidentInfoEntity] = useState("");
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  const handleMedicineCodeChange = (event, value) => {
    setFieldValue("medicineNamePackageDose", value?.namePackageDose);
    setFieldValue("takingMethod", value?.takingInstruction);
    setFieldValue("medicineUnit", value?.unit);
    setFieldValue("prescriptionType", value?.prescriptionType);
    setFieldValue("injectionPosition", value?.injectionPosition);
  };

  const handleResidentChange = (event, value) => {
    setresidentName(value?.lastnameTc + value?.firstnameTc);
    setCheckInStatus(value?.checkInStatus);
    setBed(value?.bed?.bedNo);
    setPhoto(value?.photoUrl);
  };

  const handlePrescriptionTypeChange = (event) => {
    setFieldValue("prescriptionType", event.target.value);
    if (event.target.value !== "INJECTION") {
      setFieldValue("injectionPosition", "");
    }
  };

  const getPersonalInfo = () => {
    axios
      .get("/api/residents/" + entity.resident)
      .then((response) => {
        setResidentInfoEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      getPersonalInfo();
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={3}>
      <Grid item sm={9} xs={12}>
        <fieldset>
          <FormLabel component="legend">院友藥物資料</FormLabel>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    disabled={entity ? true : false}
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                    onChange={handleResidentChange}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="藥物代號"
                    apiUrl={"/api/medicines"}
                    getOptionLabel={(option) => option?.code}
                    getOptionValue={(option) => option?.id}
                    name="medicineCode"
                    onChange={handleMedicineCodeChange}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    required
                    label="藥物英文名稱 - 包裝 - 劑量"
                    name="medicineNamePackageDose"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikSelectField
                    required
                    label="服用方法"
                    name="takingMethod"
                    options={TAKING_INSTRUCTION_OPTIONS}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="服用週期"
                    name="takingPeriod"
                    options={TAKING_PERIOD_OPTIONS}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField label="#N 值" name="nValue" />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <fieldset>
                    <FormLabel component="legend">服用次數及時間</FormLabel>
                    <Grid item sm={12} xs={12}>
                      <FormikReferenceField
                        edit={entity ? true : false}
                        required
                        label="次數"
                        apiUrl={"/api/medicationSchedules"}
                        getOptionLabel={(option) =>
                          option.code + " - " + option.nameTc
                        }
                        name="medicationSchedule"
                        apiParams={{
                          unpaged: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FieldArray
                        name="residentMedicationScheduleSession"
                        render={(arrayHelpers) => (
                          <div>
                            <TableContainer style={{ maxHeight: 400 }}>
                              <Table
                                stickyHeader
                                padding="none"
                                style={{ marginBottom: 15 }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      omponent="th"
                                      style={{ padding: 10 }}
                                    >
                                      #
                                    </TableCell>
                                    <TableCell
                                      omponent="th"
                                      style={{ padding: 10 }}
                                    >
                                      時間
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: 50 }}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.residentMedicationScheduleSession &&
                                    values.residentMedicationScheduleSession.map(
                                      (time, index) => (
                                        <TableRow key={index} disablegutters>
                                          <TableCell align="center">
                                            {index + 1}
                                          </TableCell>
                                          <TableCell>
                                            <FormikTimeField
                                              required
                                              label="時間"
                                              name={`residentMedicationScheduleSession.${index}.time`}
                                            />
                                          </TableCell>
                                          <TableCell align="right">
                                            <IconButton
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <DeleteOutlinedIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {values.residentMedicationScheduleSession?.length !=
                            12 ? (
                              <Button
                                color="default"
                                variant="contained"
                                type="button"
                                size="small"
                                onClick={() => arrayHelpers.push("")}
                              >
                                新增
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                  </fieldset>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    label="每次劑量"
                    name="medicineCount"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="單位"
                    name="medicineUnit"
                    options={UNIT_OPTIONS}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikCheckboxField label="需要時藥物(PRN)" name="isPrn" />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                                    <FormikCheckboxField label="GP藥物" name="isGp" />
                                </Grid> */}
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="來源"
                    apiUrl={"/api/institutions"}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) =>
                      option.code
                        ? option.code + " - " + option.nameTc
                        : option.nameTc
                    }
                    name="institution"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="專科"
                    apiUrl={"/api/specialists"}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) =>
                      option.code + " - " + option.nameTc
                    }
                    name="specialist"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikSelectField
                    required
                    label="藥紙類別"
                    name="prescriptionType"
                    options={PRESCRIPTION_TYPE_OPTIONS}
                    onChange={handlePrescriptionTypeChange}
                  />
                </Grid>
                {/* {values.prescriptionType === "INJECTION" && (
                  <Grid item sm={12} xs={12}>
                    <FormikTextField
                      label="注射位置"
                      name="injectionPosition"
                    />
                  </Grid>
                )} */}
                {/* <Grid item sm={6} xs={12}>
                                    <FormikSelectField
                                        label="藥板"
                                        name="prescriptionBoard"
                                    />
                                </Grid> */}
                <Grid item sm={6} xs={12}>
                  <FormikDateField label="用藥開始日期" name="startDate" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateField label="結束日期" name="endDate" />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <fieldset>
                    <FormLabel component="legend">服用備註</FormLabel>
                    <FieldArray
                      name="residentDrugMedicationRemarks"
                      render={(arrayHelpers) => (
                        <div>
                          <TableContainer style={{ maxHeight: 400 }}>
                            <Table
                              stickyHeader
                              padding="none"
                              style={{ marginBottom: 15 }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    omponent="th"
                                    style={{ padding: 10 }}
                                  >
                                    服用備註
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: 50 }}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.residentDrugMedicationRemarks &&
                                  values.residentDrugMedicationRemarks.map(
                                    (remarksId, index) => (
                                      <TableRow key={index} disablegutters>
                                        <TableCell>
                                          <FormikReferenceField
                                            edit={entity ? true : false}
                                            label="備註"
                                            apiUrl={
                                              "/api/medicationTakingRemarks"
                                            }
                                            apiParams={{
                                              unpaged: true,
                                            }}
                                            getOptionLabel={(option) =>
                                              option?.nameTc
                                            }
                                            getOptionValue={(option) =>
                                              option?.id
                                            }
                                            name={`residentDrugMedicationRemarks.${index}.remarksId`}
                                          />
                                        </TableCell>
                                        <TableCell align="right">
                                          <IconButton
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <DeleteOutlinedIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Button
                            color="default"
                            variant="contained"
                            type="button"
                            size="small"
                            onClick={() => arrayHelpers.push("")}
                          >
                            新增
                          </Button>
                        </div>
                      )}
                    />
                  </fieldset>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField label="用藥備註" name="remarks" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="用藥狀態"
                    name="status"
                    options={TAKING_STATUS_OPTIONS}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </Grid>
      <Grid item sm={3} xs={12}>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <fieldset>
                <FormLabel component="legend">院友基本資料</FormLabel>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <fieldset style={{ textAlign: "center", padding: "15px" }}>
                      {residentInfoEntity.photoUrl && (
                        <Image
                          url={residentInfoEntity.photoUrl}
                          style={{ width: "100%" }}
                        />
                      )}
                    </fieldset>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <table>
                      <tbody>
                        <tr>
                          <td>姓名</td>
                          <td>
                            {(residentInfoEntity &&
                              residentInfoEntity.lastnameTc +
                                residentInfoEntity.firstnameTc) ||
                              residentName}
                          </td>
                        </tr>
                        <tr>
                          <td>狀態</td>
                          <td>
                            {(residentInfoEntity &&
                              CHECK_IN_STATUS_OPTIONS.find(
                                (elem) =>
                                  elem.value == residentInfoEntity.checkInStatus
                              )?.label) ||
                              CHECK_IN_STATUS_OPTIONS.find(
                                (elem) => elem.value == checkInStatus
                              )?.label}
                          </td>
                        </tr>
                        <tr>
                          <td>床位</td>
                          <td>
                            {(residentInfoEntity &&
                              residentInfoEntity.bed?.bedNo) ||
                              bed}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              </fieldset>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
