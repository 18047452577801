import {
  API_URL,
  RESIDENT_WOUND_DRESSING_OPTION,
  ROUTES,
} from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  ExpenseSuppliersForm,
  initialValues,
  validationSchema,
} from "./form/ExpenseSuppliersForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";

const ExpenseSuppliersPageHeader = (props) => {
  const { setCreating, clearNotification } = props;

  return (
    <PageHeader title="供應商">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setCreating(true);
        }}
      >
        新增供應商
      </Button>
    </PageHeader>
  );
};

export default function ExpenseSuppliers() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    return params;
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "nameTc",
        headerName: "供應商中文名稱",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "name",
        headerName: "供應商英文名稱",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "checkPayable",
        headerName: "供應商支票抬頭名稱",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.EXPENSE_SUPPLIERS,
      getOne: API_URL.EXPENSE_SUPPLIERS,
      create: API_URL.EXPENSE_SUPPLIERS,
      edit: API_URL.EXPENSE_SUPPLIERS,
      delete: API_URL.EXPENSE_SUPPLIERS,
      copy: API_URL.EXPENSE_SUPPLIERS,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="供應商"
      editableFields={["name", "nameTc", "checkPayable"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      apiUrl={apiUrl}
      dataInputForm={ExpenseSuppliersForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth="sm"
      pageHeader={ExpenseSuppliersPageHeader}
    ></EntityManagement>
  );
}
