import * as Yup from "yup";
import { useState, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { PRESCRIPTION_TYPE_OPTIONS } from "../../../../constants";
import { FormikDialog } from "../../../../components/dialogs";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikDateField from "../../../../components/inputs/FormikDateField";
import moment from "moment";

export const initialValues = {
  resident: "",
  type: "",
  month: moment(new Date()).format("yyyy-MM"),
};

export const validationSchema = Yup.object().shape({
  month: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  type: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function PrintEmptyDialog(props) {
  const { open, entity, onClose, onSubmit } = props;
  const [entityValues, setEntityValues] = useState();

  return (
    <FormikDialog
      open={open}
      dialogTitle={"列印空白藥紙"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
    >
      <PrintEmptyForm initialValues={initialValues} />
    </FormikDialog>
  );
}

const PrintEmptyForm = (props) => {
  const home = useSelector((state) => state.home);
  const { values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
  }, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    name="type"
                    label="藥紙類別"
                    options={PRESCRIPTION_TYPE_OPTIONS}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateField
                    required
                    views={["month", "year"]}
                    format={"yyyy 年 M 月"}
                    name="month"
                    label="列印月份"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
