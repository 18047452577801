import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import FormLabel from "@material-ui/core/FormLabel";
import CardContent from "@material-ui/core/CardContent";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikUploadField from "../../../components/inputs/FormikUploadField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import {
  API_URL,
  ROUTES,
  RESIDENT_WOUND_DRESSING_OPTION,
  RESIDENT_WOUND_STATUS_OPTION,
  RESIDENT_WOUND_SOURCE_OPTION,
  RESIDENT_WASHING_WOUND_LEVEL_OPTION,
  RESIDENT_WASHING_WOUND_COLOR_OPTION,
  RESIDENT_WASHING_WOUND_EXUDATE_TYPE_OPTION,
  RESIDENT_WASHING_WOUND_SMELL_OPTION,
  RESIDENT_WASHING_WOUND_EXUDATE_AMOUNT_OPTION,
  RESIDENT_WOUND_POSITION_FRONT_OPTION,
  RESIDENT_WOUND_POSITION_BACK_OPTION,
} from "../../../constants";
import InputAdornment from "@material-ui/core/InputAdornment";
import frontBody from "./img/frontBody.png";
import backBody from "./img/backBody.png";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme) => ({
  field: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  background: {
    height: "100%",
    width: "100%",
    display: "block",
    position: "relative",
  },
  img: {
    width: "100%",
    height: "100%",
    top: 0,
    position: "relative",
  },
  RIGHT_EAR: {
    position: "absolute",
    top: "5%",
    left: "22.0%",
  },
  LEFT_EAR: {
    position: "absolute",
    top: "5%",
    right: "22%",
  },
  LEFT_SHOULDER: {
    position: "absolute",
    top: "14%",
    right: "17.0%",
  },
  RIGHT_SHOULDER: {
    position: "absolute",
    top: "14%",
    left: "17.0%",
  },
  FRONT_CHEST: {
    position: "absolute",
    top: "20%",
    left: "38%",
  },
  LEFT_FRONT_ABDOMEN: {
    position: "absolute",
    top: "38%",
    right: "25%",
  },
  RIGHT_FRONT_ABDOMEN: {
    position: "absolute",
    top: "38%",
    left: "25%",
  },
  LEFT_PLAM: {
    position: "absolute",
    top: "50%",
    right: "1%",
  },
  RIGHT_PLAM: {
    position: "absolute",
    top: "50%",
    left: "1%",
  },
  LEFT_WRIST: {
    position: "absolute",
    top: "43%",
    right: "4%",
  },
  RIGHT_WRIST: {
    position: "absolute",
    top: "43%",
    left: "4%",
  },
  LEFT_UPPER_THIGH: {
    position: "absolute",
    top: "52%",
    right: "25%",
  },
  RIGHT_UPPER_THIGH: {
    position: "absolute",
    top: "52%",
    left: "25%",
  },
  LEFT_LOWER_THIGH: {
    position: "absolute",
    top: "59%",
    right: "27%",
  },
  RIGHT_LOWER_THIGH: {
    position: "absolute",
    top: "59%",
    left: "27%",
  },
  LEFT_KNEE: {
    position: "absolute",
    top: "67%",
    right: "28%",
  },
  RIGHT_KNEE: {
    position: "absolute",
    top: "67%",
    left: "28%",
  },
  LEFT_CALF: {
    position: "absolute",
    top: "79%",
    right: "28%",
  },
  RIGHT_CALF: {
    position: "absolute",
    top: "79%",
    left: "28%",
  },
  LEFT_TOE: {
    position: "absolute",
    top: "95%",
    right: "23%",
  },
  RIGHT_TOE: {
    position: "absolute",
    top: "95%",
    left: "23%",
  },
  BACK_OF_HEAD: {
    position: "absolute",
    top: "3%",
    right: "38%",
  },
  LEFT_SCAPULA: {
    position: "absolute",
    top: "19%",
    left: "25%",
  },
  RIGHT_SCAPULA: {
    position: "absolute",
    top: "19%",
    right: "25%",
  },
  BACK: {
    position: "absolute",
    top: "25%",
    left: "38%",
  },
  WAIST: {
    position: "absolute",
    top: "33%",
    left: "38%",
  },
  COCCYX: {
    position: "absolute",
    top: "41%",
    left: "38%",
  },
  LEFT_HIP: {
    position: "absolute",
    top: "48%",
    left: "28%",
  },
  RIGHT_HIP: {
    position: "absolute",
    top: "48%",
    right: "28%",
  },
  LEFT_BACK_THIGH: {
    position: "absolute",
    top: "58%",
    left: "28%",
  },
  RIGHT_BACK_THIGH: {
    position: "absolute",
    top: "58%",
    right: "28%",
  },
  LEFT_BACK_CALF: {
    position: "absolute",
    top: "77%",
    left: "28%",
  },
  RIGHT_BACK_CALF: {
    position: "absolute",
    top: "77%",
    right: "28%",
  },
  LEFT_ELBOW: {
    position: "absolute",
    top: "33%",
    left: "10%",
  },
  RIGHT_ELBOW: {
    position: "absolute",
    top: "33%",
    right: "10%",
  },
  LEFT_BACK_HAND: {
    position: "absolute",
    top: "50%",
    left: "1%",
  },
  RIGHT_BACK_HAND: {
    position: "absolute",
    top: "50%",
    right: "1%",
  },
  LEFT_HEEL: {
    position: "absolute",
    top: "90%",
    left: "30%",
  },
  RIGHT_HEEL: {
    position: "absolute",
    top: "90%",
    right: "30%",
  },
}));

export const initialValues = {
  resident: "",
  woundStatus: "",
  woundSource: "",
  suggestWashingFrequencyDay: "",
  suggestWashingFrequencyTimes: "",
  startDate: moment(new Date()).format("yyyy-MM-DDT00:00:00+08:00"),
  recoveredDate: "",
  woundPosition: [],
  woundLength: "",
  woundWidth: "",
  woundDepth: "",
  woundLevel: "",
  woundColour: "",
  woundExudateType: "",
  woundSmell: "",
  woundExudateAmount: "",
  residentWoundDressings: [],
  imageUrl: "",
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  woundStatus: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  startDate: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  recoveredDate: Yup.string()
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  suggestWashingFrequencyDay: Yup.string()
    .max(3, "日數最多為3位數")
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .nullable(),
  suggestWashingFrequencyTimes: Yup.string()
    .max(3, "次數最多為3位數")
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .nullable(),
  woundLength: Yup.string()
    .max(5, "長度最多為3位數+1個小數位")
    .matches(/^[0-9]+(\.[0-9]{1})?$/, "輸入必須為數字")
    .nullable(),
  woundWidth: Yup.string()
    .max(5, "闊度最多為3位數+1個小數位")
    .matches(/^[0-9]+(\.[0-9]{1})?$/, "輸入必須為數字")
    .nullable(),
  woundDepth: Yup.string()
    .max(5, "深度最多為3位數+1個小數位")
    .matches(/^[0-9]+(\.[0-9]{1})?$/, "輸入必須為數字")
    .nullable(),
  woundSource: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  woundLevel: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  woundColour: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  woundExudateType: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  woundSmell: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  woundExudateAmount: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, onChange, apiUrl = "", ...rest } = props;
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, handleChange, setFieldValue, resetForm } =
    useFormikContext();
  const classes = useStyles();
  const [checkedWoundPosition, setCheckedWoundPosition] = useState([]);

  const handleWoundDressingsChange = (event, value, index) => {
    setFieldValue(`residentWoundDressings.${index}.type`, value?.type);
  };

  const handleWoundPositionChange = (event) => {
    if (event.target.checked) {
      if (checkedWoundPosition.includes(event.target.value)) {
        return checkedWoundPosition;
      } else {
        setCheckedWoundPosition([...checkedWoundPosition, event.target.value]);
        return [...checkedWoundPosition, event.target.value];
      }
    } else {
      const newValues = checkedWoundPosition.filter(
        (p) => p !== event.target.value
      );
      setCheckedWoundPosition(newValues);
      return newValues;
    }
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
      setCheckedWoundPosition([...checkedWoundPosition, entity.woundPosition]);
      setFieldValue(entity.woundPosition, true);
      // RESIDENT_WOUND_POSITION_OPTION.forEach(function(item){
      //     if(item.value == entity.woundPosition){
      //         setFieldValue(entity.woundPosition, true);
      //     }else{
      //     }
      // })
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikReferenceField
                      edit={entity ? true : false}
                      disabled={entity ? true : false}
                      required
                      label="院友姓名"
                      name="resident"
                      apiUrl={
                        "/api/homes/" + home.home.id + "/residents/references"
                      }
                      getOptionLabel={(option) =>
                        option.lastnameTc + option.firstnameTc
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口狀態"
                      name="woundStatus"
                      options={RESIDENT_WOUND_STATUS_OPTION}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <fieldset>
                      <FormLabel component="legend">傷口位置</FormLabel>
                      <Grid container spacing={2} className={classes.field}>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          className={classes.background}
                        >
                          <img src={frontBody} className={classes.img}></img>
                          {RESIDENT_WOUND_POSITION_FRONT_OPTION.map(
                            (option) => (
                              <FormikCheckboxField
                                disabled={entity ? true : false}
                                name={option.value}
                                className={classes[option.value]}
                                value={option.value}
                                size="small"
                                labelPlacement="top"
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                                onChange={(event) =>
                                  setFieldValue(
                                    "woundPosition",
                                    handleWoundPositionChange(event)
                                  )
                                }
                              />
                            )
                          )}
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          className={classes.background}
                        >
                          <img src={backBody} className={classes.img}></img>
                          {RESIDENT_WOUND_POSITION_BACK_OPTION.map((option) => (
                            <FormikCheckboxField
                              disabled={entity ? true : false}
                              name={option.value}
                              className={classes[option.value]}
                              value={option.value}
                              size="small"
                              labelPlacement="top"
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                              onChange={(event) =>
                                setFieldValue(
                                  "woundPosition",
                                  handleWoundPositionChange(event)
                                )
                              }
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </fieldset>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      required
                      label="開始日期"
                      name="startDate"
                      startTime
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      label="結束日期"
                      name="recoveredDate"
                      endTime
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口來源"
                      name="woundSource"
                      options={RESIDENT_WOUND_SOURCE_OPTION}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormikTextField
                      label="洗傷口頻率"
                      name="suggestWashingFrequencyDay"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">天</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <FormikTextField
                      label="洗傷口頻率"
                      name="suggestWashingFrequencyTimes"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">次</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      label="傷口長度"
                      name="woundLength"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      label="傷口闊度"
                      name="woundWidth"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      label="傷口深度"
                      name="woundDepth"
                      type="number"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cm</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口級別"
                      name="woundLevel"
                      options={RESIDENT_WASHING_WOUND_LEVEL_OPTION}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口顏色"
                      name="woundColour"
                      options={RESIDENT_WASHING_WOUND_COLOR_OPTION}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口滲液種類"
                      name="woundExudateType"
                      options={RESIDENT_WASHING_WOUND_EXUDATE_TYPE_OPTION}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口氣味"
                      name="woundSmell"
                      options={RESIDENT_WASHING_WOUND_SMELL_OPTION}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="傷口滲液份量"
                      name="woundExudateAmount"
                      options={RESIDENT_WASHING_WOUND_EXUDATE_AMOUNT_OPTION}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <FormikTextField label="備註" name="remarks" />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <fieldset>
                      <FormLabel component="legend">敷料</FormLabel>
                      <FieldArray
                        name="residentWoundDressings"
                        render={(arrayHelpers) => (
                          <div>
                            <TableContainer style={{ maxHeight: 400 }}>
                              <Table
                                stickyHeader
                                padding="none"
                                style={{ marginBottom: 15 }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      omponent="th"
                                      style={{ padding: 10 }}
                                    >
                                      名稱
                                    </TableCell>
                                    <TableCell
                                      omponent="th"
                                      style={{ padding: 10 }}
                                    >
                                      種類
                                    </TableCell>
                                    <TableCell
                                      omponent="th"
                                      style={{ padding: 10 }}
                                    >
                                      備註
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{ width: 50 }}
                                    ></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.residentWoundDressings &&
                                    values.residentWoundDressings.map(
                                      (woundDressing, index) => (
                                        <TableRow key={index} disablegutters>
                                          <TableCell
                                            style={{
                                              paddingRight: 1,
                                              paddingTop: 5,
                                            }}
                                          >
                                            <FormikReferenceField
                                              edit={entity ? true : false}
                                              label="名稱"
                                              apiUrl={
                                                API_URL.NURSING_WOUND_DRESSINGS
                                              }
                                              apiParams={{
                                                unpaged: true,
                                              }}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              getOptionValue={(option) =>
                                                option?.id
                                              }
                                              name={`residentWoundDressings.${index}.woundDressingId`}
                                              onChange={(event, value) =>
                                                handleWoundDressingsChange(
                                                  event,
                                                  value,
                                                  index
                                                )
                                              }
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{ paddingRight: 1 }}
                                          >
                                            <FormikSelectField
                                              disabled
                                              label="種類"
                                              name={`residentWoundDressings.${index}.type`}
                                              options={
                                                RESIDENT_WOUND_DRESSING_OPTION
                                              }
                                            />
                                          </TableCell>
                                          <TableCell>
                                            <FormikTextField
                                              label="備註"
                                              name={`residentWoundDressings.${index}.remarks`}
                                            />
                                          </TableCell>
                                          <TableCell align="right">
                                            <IconButton
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <DeleteOutlinedIcon />
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Button
                              color="default"
                              variant="contained"
                              type="button"
                              size="small"
                              onClick={() => arrayHelpers.push("")}
                            >
                              新增
                            </Button>
                          </div>
                        )}
                      />
                    </fieldset>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <fieldset>
                      <FormLabel component="legend">預設顯示圖片</FormLabel>
                      <FormikUploadField
                        required
                        label="相片"
                        name="imageUrl"
                        policy="WOUND_PHOTO"
                        onChange={onChange}
                      />
                    </fieldset>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
