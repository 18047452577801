import * as Yup from "yup";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikUploadField from "../../../components/inputs/FormikUploadField";

export const initialValues = {};

export const validationSchema = Yup.object().shape({});

export const DataInputForm = (props) => {
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikUploadField
                  required
                  label="文件"
                  policy="DOCUMENT"
                  document
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
