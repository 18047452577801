import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, ROUTES } from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import { NotificationActions } from "../../actions";
import { useParams } from "react-router-dom";

export default function RosterDetailsPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  let { id } = useParams();
  const [reload, setReload] = useState(false);

  const normalizeReceivedValues = (values) => {};

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改標籤">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="查看">
                                <IconButton color="default" size="small" onClick={() => { clearNotification(); setDetailsEntity(params); }} disabled={loading}>
                                    <RemoveRedEyeOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "day",
        headerName: "日期",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "workingTime",
        headerName: "上班時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row["startTime"].substring(0, 5) +
            " - " +
            params.row["endTime"].substring(0, 5)
          );
        },
      },
      {
        field: "tag",
        headerName: "更表標籤",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.STAFF_ROSTER_SESSION + "/" + values.id, {
        tag: values.tag,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAllDetail: API_URL.STAFF_ROSTER_RECURRING + "/" + id,
      getOne: API_URL.STAFF_ROSTER_SESSION,
      delete: API_URL.STAFF_ROSTER_SESSION,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="更表"
      editableFields={["tag"]}
      getColumnSettings={getColumnSettings}
      normalizeReceivedValues={normalizeReceivedValues}
      apiUrl={apiUrl}
      pageHeader={ListingPageHeader}
      defaultPageSize={31}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
