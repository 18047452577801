import typography from "../typography";

export default {
  selectMenu: {
    //height: '1.1876em'
  },
  outlined: {
    height: '1.1876em !important'
  }
};


