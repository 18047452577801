import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import { API_URL, ROUTES, FOLLOW_UP_STATUS_OPTION } from "../../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./followUp/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./followUp/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ListingPageHeader } from "./followUp/ListingPageHeader";
import { useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationActions } from "../../../actions";
import { EditDialog } from "./followUp/EditDialog";

export default function FollowUp() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  let { id } = useParams();
  const [editEntity, setEditEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editOpen, setEditOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);
    //params.homeId = home?.home?.id;
    params.resident = [id];
    params.institution = values.institution;
    params.specialist = values.specialist;
    params.transport = values.transport;
    params.medicalFollowUpItems = values.medicalFollowUpItems;
    params.escorterRelationship = values.escorterRelationship;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.dateFrom != null) {
      params.dateFrom = moment(values.dateFrom).format("YYYY-MM-DD");
    }

    if (params.dateTo != null) {
      params.dateTo = moment(values.dateTo).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.institution = values.institution.id;
    values.specialist = values.specialist.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="default" size="small" disabled={loading}>
                                <AssignmentIndOutlinedIcon />
                            </IconButton> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "bed",
        headerName: "床位",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.bed?.bedNo;
        },
      },
      {
        field: "date",
        headerName: "覆診日期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "time",
        headerName: "覆診時間",
        width: 80,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["date"]?.substring(11, 16);
        },
      },
      {
        field: "institution",
        headerName: "醫院 / 診所",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.nameTc;
        },
      },
      {
        field: "specialist",
        headerName: "專科",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.nameTc;
        },
      },
      {
        field: "medicalFollowUpItems",
        headerName: "醫療事項",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]
                .map((item) => {
                  return item.nameTc;
                })
                .join(", ")
            : "--";
        },
      },
      {
        field: "transport",
        headerName: "交通工具",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.nameTc
            : "--";
        },
      },
      {
        field: "escorterName",
        headerName: "陪診員",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "status",
        headerName: "覆診狀態",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return FOLLOW_UP_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "finishDate",
        headerName: "完成覆診日期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10)
            : "--";
        },
      },
    ];
  };

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_FOLLOW_UP + "/" + values.id, {
        date: values.date,
        escorterName: values.escorterName,
        escorterRelationship:
          values?.escorterRelationship?.id || values?.escorterRelationship,
        institution: values.institution,
        medicalFollowUpItems: (values.medicalFollowUpItems || []).map((i) => {
          return { id: i.id };
        }),
        remarks: values.remarks,
        resident: [id],
        specialist: values.specialist,
        status: values.status,
        transport: values?.transport?.id || values?.transport,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    if (values.status == "COMPLETED" && values.nextDate !== "") {
      axios.post(API_URL.RESIDENT_FOLLOW_UP, {
        date: values.nextDate,
        escorterName: values.escorterName,
        escorterRelationship:
          values.escorterRelationship.id || values.escorterRelationship,
        institution: values.institution,
        medicalFollowUpItems: (values.medicalFollowUpItems || []).map((i) => {
          return { id: i.id };
        }),
        remarks: values.remarks,
        resident: [id],
        specialist: values.specialist,
        status: "APPOINTMENT",
        transport: values.transport.id || values.transport,
      });
    }
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_FOLLOW_UP + "?resident=" + id,
      getOne: API_URL.RESIDENT_FOLLOW_UP,
      create: API_URL.RESIDENT_FOLLOW_UP,
      edit: API_URL.RESIDENT_FOLLOW_UP,
      delete: API_URL.RESIDENT_FOLLOW_UP,
      copy: API_URL.RESIDENT_FOLLOW_UP,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="覆診"
      editableFields={[
        "resident",
        "bed",
        "date",
        "institution",
        "specialist",
        "medicalFollowUpItems",
        "transport",
        "escorterName",
        "escorterRelationship",
        "remarks",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      maxWidth="md"
      defaultSorting={"date,asc"}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
