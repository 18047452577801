import { apiService } from "../services";
import { ACCESS_TOKEN_KEY, USER_KEY } from "../constants/auth.constant";

export function getDocumentAndFolderEntities(
  setLoading,
  createFilteringParams,
  coreCreateFilteringParams,
  apiUrl,
  setDocumentRows,
  setFolderRows,
  filteringParams,
  setListEmpty,
  currentFolderId,
) {
  setLoading(true);

  const getFilteringParams = createFilteringParams
    ? createFilteringParams
    : coreCreateFilteringParams;

  let params = getFilteringParams(filteringParams, coreCreateFilteringParams);

  delete params.timestamp; // remove timestamp when getting records

  apiService
    .get(apiUrl.getAll ? apiUrl.getAll : apiUrl, {
      params: params,
    })
    .then(function (response) {
      let data = response.data;
      let content = data?.content || data;
      setDocumentRows(content?.file || []);
      setFolderRows(((currentFolderId)
        ? content?.subfolders
        : content) // if the apiUrl is /upload/folder but not /upload/folder/{id}, the content is the subfolders already
        || []);
      setLoading(false);
      setListEmpty(false);
    })
    .catch(function (response) {
      setLoading(false);
    });
}
