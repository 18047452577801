import * as Yup from "yup";
import moment from "moment";
import { useState, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { API_URL, EXPENSE_TRANSACTION_METHOD_OPTION } from "../../../constants";
import { useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";

export const initialValues = {
  issueDateTime: moment(new Date()).toISOString(),
  method: "",
  expenseList: [],
};

export const validationSchema = Yup.object().shape({
  method: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
});

export function MergePaymentDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;

  return (
    <FormikDialog
      open={open}
      dialogTitle={"合拼付款"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="lg"
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <MergePaymentForm />
    </FormikDialog>
  );
}

const MergePaymentForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  const handleExpenseChange = (event, value, index) => {
    setFieldValue(`expenseList.${index}.amount`, value?.amount);
  };

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                付款資料
              </Typography>
              <Grid container spacing={2} style={{ marginBottom: 15 }}>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="付款方法"
                    name="method"
                    options={EXPENSE_TRANSACTION_METHOD_OPTION}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateTimeField
                    required
                    label="入帳日期及時間"
                    name="issueDateTime"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <fieldset>
          <FormLabel component="legend">付款項目</FormLabel>
          <FieldArray
            name="expenseList"
            render={(arrayHelpers) => (
              <div>
                <TableContainer style={{ maxHeight: 400 }}>
                  <Table
                    stickyHeader
                    padding="none"
                    style={{ marginBottom: 15, minWidth: 650 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          omponent="th"
                          style={{ padding: 10, width: "50%" }}
                        >
                          項目
                        </TableCell>
                        <TableCell omponent="th" style={{ padding: 10 }}>
                          付款金額
                        </TableCell>
                        <TableCell omponent="th" style={{ padding: 10 }}>
                          付款備註
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: 50 }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.expenseList &&
                        values.expenseList.map((item, index) => (
                          <TableRow key={index} disablegutters>
                            <TableCell
                              style={{ paddingRight: 1, paddingTop: 5 }}
                            >
                              <FormikReferenceField
                                edit={entity ? true : false}
                                required
                                label="項目"
                                name={`expenseList.${index}.expense`}
                                apiUrl={API_URL.EXPENSE}
                                apiParams={{
                                  home: home?.home?.id,
                                  type: "EXPENSE",
                                  status: "NOT_YET_PAID",
                                  unpaged: true,
                                }}
                                getOptionLabel={(option) =>
                                  option?.expenseId +
                                  " - " +
                                  option?.supplier?.nameTc +
                                  " - " +
                                  option.expenseItem?.nameTc
                                }
                                onChange={(event, value) =>
                                  handleExpenseChange(event, value, index)
                                }
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikTextField
                                label="付款金額"
                                name={`expenseList.${index}.amount`}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikTextField
                                label="付款備註"
                                name={`expenseList.${index}.remarks`}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  color="default"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => arrayHelpers.push("")}
                >
                  新增
                </Button>
              </div>
            )}
          />
        </fieldset>
      </Grid>
    </form>
  );
};
