import typography from "../typography";

export default {
  root: {
    ...typography.body1,
    "&:last-child": {
      paddingRight: 0
    }
  },
  sizeSmall: {
    "&:last-child": {
      paddingRight: 5
    }
  }
};
