import { colors } from '@material-ui/core';

export default {
  paper: {
    margin: '15px'
  },
  paperFullWidth: {
    width: 'calc(100% - 30px)'
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 30px)'
  }
};
