import * as Yup from "yup";
import moment from "moment";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import { PRESCRIPTION_TYPE_OPTIONS, TAKING_STATUS_OPTIONS } from "../../../constants";

export const mergePrintInitialValues = {
  resident: "",
  type: "",
  // if current date is on 21st or beyond of the month, then the inital month is set to the next month
  month: (
    (new Date()).getDate() > 20
      ? moment(new Date()).add(1, 'M')
      : moment(new Date())
    ).format("yyyy-MM"),
};

export const mergePrintValidationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  month: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  type: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const PrintForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    mergePrintInitialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: mergePrintInitialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: mergePrintInitialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikReferenceField
                      edit={entity ? true : false}
                      required
                      label="院友姓名"
                      name="resident"
                      apiUrl={
                        "/api/homes/" + home.home.id + "/residents/references"
                      }
                      getOptionLabel={(option) =>
                        option.lastnameTc + option.firstnameTc
                      }
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      name="type"
                      label="藥紙類別"
                      options={PRESCRIPTION_TYPE_OPTIONS}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      required
                      views={["month", "year"]}
                      format={"yyyy 年 M 月"}
                      name="month"
                      label="列印月份"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      name="status"
                      label="用藥狀態"
                      options={TAKING_STATUS_OPTIONS}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
