import { useState, useEffect } from 'react';
import { API_URL, ROUTES, GENDER_OPTIONS, STAFF_STATUS_OPTION, STAFF_ROSTER_OPTION } from '../../../constants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import axios from 'axios';
import Image from '../../../components/Image';

export const initialValues = {

}

export function DetailsDialog(props) {
    const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } = props;
    const [entityValues, setEntityValues] = useState();
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        if (entity) {
            axios.get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + '/' + entity.id).then((response) => {
                let data = response?.data;
                let entityValues = { ...initialValues, ...data };
                setEntityValues(entityValues);
            }).catch((error) => {
            }).finally(() => {
                setLoaded(true);
            });
        } else {
            setEntityValues(initialValues);
        }
    }, [entity]);

    return (
        <Dialog
            open={open}
            keepMounted
            maxWidth="lg"
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"員工詳細"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width:200 }}>員工姓名 (中文)</TableCell>
                                <TableCell style={{ width:500 }}>{entityValues?.nameTc}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>員工姓名 (英文)</TableCell>
                                <TableCell>{entityValues?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>用戶名稱</TableCell>
                                <TableCell>{entityValues?.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>系統權限</TableCell>
                                <TableCell>{entityValues?.role?.nameTc}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>身份證號碼</TableCell>
                                <TableCell>{entityValues?.hkid}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>性別</TableCell>
                                <TableCell>{GENDER_OPTIONS.find(elem => elem.value == entityValues?.gender)?.label}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>聯絡電話</TableCell>
                                <TableCell>{entityValues?.phone}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>入職日期</TableCell>
                                <TableCell>{entityValues?.entryDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>離職日期</TableCell>
                                <TableCell>{(entityValues?.resignationDate)? entityValues?.resignationDate : "--"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>員工狀態</TableCell>
                                <TableCell>{STAFF_STATUS_OPTION.find(elem => elem.value == entityValues?.status)?.label}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>工作資料</TableCell>
                                <TableCell>{(entityValues &&
                                    entityValues.homeStaffs)?(
                                    entityValues.homeStaffs.length > 0 && (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>工作院舍</TableCell>
                                                    <TableCell>相應更期</TableCell>
                                                    <TableCell>相應職級</TableCell>
                                                    <TableCell>相應上班時間</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {entityValues.homeStaffs.map(item => (
                                                    <TableRow hover key={item.id}>
                                                        <TableCell>{item.home}</TableCell>
                                                        <TableCell>{STAFF_ROSTER_OPTION.find(elem => elem.value == item.roster)?.label}</TableCell>
                                                        <TableCell>{item.position} </TableCell>
                                                        <TableCell>{item.workingStartTime.substring(11, 16) + " - " + item.workingEndTime.substring(11, 16)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )) : "未有任何工作資料"}
                                </TableCell>
                            </TableRow>
                            {entityValues?.vaccinationUrl && (
                            <TableRow>
                              <TableCell>針卡圖片</TableCell>
                              <TableCell>
                                  <Image
                                    style={{ maxWidth: 150, maxHeight: 150 }}
                                    url={entityValues.vaccinationUrl}
                                  />
                              </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onClose} >
                    關閉
                </Button>
            </DialogActions>
        </Dialog>
    );
}

