import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  RESIDENT_EMERGENCY_DISCHARGED_REASON_OPTION,
  RESIDENT_EMERGENCY_STATUS_OPTION,
  RESIDENT_EMERGENCY_REASON_OPTION,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationActions } from "../../actions";
import { EditDialog } from "./components/EditDialog";

export default function ResidentEmergencyRecordPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [editEntity, setEditEntity] = useState(null);
  const [loading, setLoading] = useState(false);

  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.resident = params.resident.id || params.resident;
    params.institution = params.institution.id || params.institution;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;
    values.institution = values.institution.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.lastnameTc +
            params.row[params.field]?.firstnameTc
          );
        },
      },
      {
        field: "status",
        headerName: "急症狀態",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_EMERGENCY_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "reason",
        headerName: "急症原因",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_EMERGENCY_REASON_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "issueDateTime",
        headerName: "急症日期 / 時間",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.substring(0, 10) +
            " / " +
            params.row[params.field]?.substring(11, 16)
          );
        },
      },
      {
        field: "institution",
        headerName: "急症醫院 / 診所",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.nameTc;
        },
      },
      {
        field: "wardBedNo",
        headerName: "留醫病房 / 床號",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "remarks",
        headerName: "急症備註",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "dischargedDateTime",
        headerName: "出院日期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10)
            : "--";
        },
      },
      {
        field: "dischargedReason",
        headerName: "出院原因",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? RESIDENT_EMERGENCY_DISCHARGED_REASON_OPTION.find(
                (elem) => elem.value == params.row[params.field]
              )?.label
            : "--";
        },
      },
      {
        field: "dischargedRemarks",
        headerName: "出院備註",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  const onEdit = (values, formikActions) => {
    if (
      values.dischargedDateTime !== null &&
      values.dischargedDateTime !== ""
    ) {
      axios
        .put(API_URL.RESIDENT_EMERGENCY + "/" + values.id, {
          resident: values.resident,
          status: "DISCHARGED",
          reason: values.reason,
          issueDateTime: values.issueDateTime,
          institution: values.institution,
          wardBedNo: values.wardBedNo,
          remarks: values.remarks,
          dischargedDateTime: values.dischargedDateTime,
          dischargedReason: values.dischargedReason,
        })
        .then((response) => {
          dispatch(NotificationActions.success(null, "已儲存"));
          setEditEntity(null);
          formikActions.setSubmitting(false);
          setEditOpen(false);
        })
        .catch((error) => {
          dispatch(NotificationActions.error(null, "錯誤"));
          formikActions.setSubmitting(false);
        });
    } else {
      axios
        .put(API_URL.RESIDENT_EMERGENCY + "/" + values.id, {
          resident: values.resident,
          status: values.status,
          reason: values.reason,
          issueDateTime: values.issueDateTime,
          institution: values.institution,
          wardBedNo: values.wardBedNo,
          remarks: values.remarks,
          dischargedDateTime: values.dischargedDateTime,
          dischargedReason: values.dischargedReason,
        })
        .then((response) => {
          dispatch(NotificationActions.success(null, "已儲存"));
          setEditEntity(null);
          formikActions.setSubmitting(false);
          setEditOpen(false);
        })
        .catch((error) => {
          dispatch(NotificationActions.error(null, "錯誤"));
          formikActions.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_EMERGENCY,
      getOne: API_URL.RESIDENT_EMERGENCY,
      create: API_URL.RESIDENT_EMERGENCY,
      edit: API_URL.RESIDENT_EMERGENCY,
      delete: API_URL.RESIDENT_EMERGENCY,
      copy: API_URL.RESIDENT_EMERGENCY,
    });
  }, [home, editOpen]);

  return (
    <EntityManagement
      entityModel="急症記錄"
      editableFields={[
        "resident",
        "status",
        "reason",
        "issueDateTime",
        "institution",
        "wardBedNo",
        "remarks",
        "dischargedDateTime",
        "dischargedReason",
        "dischargedRemarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
