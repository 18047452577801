import { useSelector } from 'react-redux';
import { PageHeader } from '../../../components/PageHeader';
import { Link } from "react-router-dom";

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);

    const {
        setCreating,
        handleFilterChange
    } = props;

    return (
        <PageHeader title="更期管理">
            <Link to='/rosterManagement/listing'>更表管理</Link> {"> 更期管理"}
        </PageHeader>
    );
}