import { Fragment } from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FormLabel } from "@material-ui/core";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import Button from "@material-ui/core/Button";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";

export const initialFilteringValues = {
  code: "",
  nameTc: "",
  name: "",
  namePackageDose: "",
};

export const filteringValidationSchema = Yup.object().shape({});

const useStyles = makeStyles((theme) => ({
  containerFields: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginRight: theme.spacing(3),
  },
}));

export const FilteringForm = (props) => {
  const { handleFilterChange } = props;
  const { values, isSubmitting, submitForm, resetForm } = useFormikContext();
  const classes = useStyles();

  const handleSubmit = (event) => {
    handleFilterChange({
      ...values,
      timestamp: new Date(), //Force refresh when click submit without changing values
    });
  };

  const handleReset = (event) => {
    resetForm();
    handleFilterChange(initialFilteringValues);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item className={classes.containerFields} xs={12} md={12} lg={12}>
          <fieldset>
            <FormLabel component="legend">搜尋</FormLabel>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormikTextField label="藥物編號" name="code" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormikTextField label="藥物名稱(中文)" name="nameTc" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormikTextField label="藥物名稱(英文)" name="name" />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormikTextField
                  label="藥物英文名稱 - 包裝 - 劑量"
                  name="namePackageDose"
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ alignSelf: "center" }}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  startIcon={<SearchOutlinedIcon />}
                >
                  篩選
                </Button>
                <Button
                  className={classes.button}
                  color="default"
                  variant="outlined"
                  onClick={handleReset}
                  disabled={isSubmitting}
                  startIcon={<RotateLeftOutlinedIcon />}
                >
                  重設
                </Button>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </Fragment>
  );
};
