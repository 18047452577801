import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikUploadField from "../../../components/inputs/FormikUploadField";
import axios from "axios";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import { API_URL, DEATH_REASON_OPTIONS } from "../../../constants";

export const initialValues = {
  resident: "",
  hospitalLocation: "",
  hospitalDate: "",
  deathLocation: "",
  deathDate: moment(new Date()).format("YYYY-MM-DD"),
  reason: "",
  source: "",
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  hospitalLocation: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  hospitalDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  deathLocation: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  deathDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  reason: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const { initialValues, values, handleChange, setFieldValue, resetForm } =
    useFormikContext();

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  // useEffect(() => {
  //   if (values.hospitalDate) values.hospitalDate = moment(values.hospitalDate).format("YYYY-MM-DD");
  //   if (values.deathDate) values.deathDate = moment(values.deathDate).format("YYYY-MM-DD");
  // }, [values]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  個人資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormikTextField
                      disabled={entity ? true : false}
                      required
                      label="院友姓名"
                      name="resident"
                    />
                  </Grid>
                  {/* <Grid item sm={6} xs={12}>
                                <FormikSelectField
                                    required
                                    label="性別"
                                    name="gender"
                                    options={GENDER_OPTIONS}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormikTextField required label="身份證號碼" name="hkid" />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormikTextField required label="年齡" name="age" />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormikDateField required label="入住院舍日期" name="dateOfCheckIn" />
                            </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  死亡記錄
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="留醫地點"
                      name="hospitalLocation"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      required
                      label="留醫日期"
                      name="hospitalDate"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="死亡地點"
                      name="deathLocation"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      required
                      label="死亡日期"
                      name="deathDate"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      required
                      label="死亡原因"
                      name="reason"
                      options={DEATH_REASON_OPTIONS}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField label="資料來源" name="source" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              備註
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
