import { API_URL } from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  MedicalEscorterRelationshipForm,
  initialValues,
  validationSchema,
} from "./form/MedicalEscorterRelationshipForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";

const MedicalEscorterRelationshipPageHeader = (props) => {
  const { setCreating, clearNotification } = props;

  return (
    <div style={{ marginTop: 15 }}>
      <PageHeader title="陪診員" hideNotification>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlinedIcon />}
          onClick={() => {
            clearNotification();
            setCreating(true);
          }}
        >
          新增陪診員
        </Button>
      </PageHeader>
    </div>
  );
};

export default function MedicalEscorterRelationship() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    return params;
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "nameTc",
        headerName: "項目名稱",
        width: 1000,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.MEDICAL_ESCORTER_RELATIONSHIP,
      getOne: API_URL.MEDICAL_ESCORTER_RELATIONSHIP,
      create: API_URL.MEDICAL_ESCORTER_RELATIONSHIP,
      edit: API_URL.MEDICAL_ESCORTER_RELATIONSHIP,
      delete: API_URL.MEDICAL_ESCORTER_RELATIONSHIP,
      copy: API_URL.MEDICAL_ESCORTER_RELATIONSHIP,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="陪診員"
      editableFields={["nameTc", "name"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      apiUrl={apiUrl}
      dataInputForm={MedicalEscorterRelationshipForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth="sm"
      pageHeader={MedicalEscorterRelationshipPageHeader}
      defaultPageSize={5}
    ></EntityManagement>
  );
}
