export const BLOOD_SUGAR_SAMPLE_TIMES = [
  'mealBefore',
  'mealAfter',
  'fasting',
  'morning',
  'noon',
  'night',
];

export const HKID_REGEX = /^([A-Z]{1,2})([0-9]{6})([(]{1})([A0-9])([)]{1})$/;
