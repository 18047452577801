import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikTimeField from "../../../components/inputs/FormikTimeField";
import FormikHKIDField from "../../../components/inputs/FormikHKIDField";
import FormikUploadField from "../../../components/inputs/FormikUploadField"
import {
  API_URL,
  GENDER_OPTIONS,
  STAFF_STATUS_OPTION,
  STAFF_ROSTER_OPTION,
  STAFF_POSITION_OPTION,
  NIGHT_STAFF_POSITION_OPTION,
} from "../../../constants";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";

export const initialValues = {
  username: "",
  password: "",
  passwordConfirmation: "",
  role: "",
  name: "",
  nameTc: "",
  entryDate: moment(new Date()).toISOString(),
  resignationDate: "",
  hkid: "",
  gender: "",
  phone: "",
  status: "",
  workHomes: [],
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  nameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  hkid: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  gender: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  phone: Yup.string()
    .min(8, "電話長度必須為8個位")
    .max(8, "電話長度必須為8個位")
    .matches(/^[0-9]*$/, "輸入必須為正整數")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  username: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  entryDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  password: Yup.string().nullable(),
  passwordConfirmation: Yup.string().when("password", (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return schema
      .oneOf([Yup.ref("password")], "確認密碼與密碼不符")
      .required(<FormattedMessage id="validation.message.required" />);
  }),
  role: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  workHomes: Yup.array().of(
    Yup.object().shape({
      home: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      roster: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      position: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      workingStartTime: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      workingEndTime: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
    })
  ),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={9} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  個人資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="員工姓名 (中文)"
                      name="nameTc"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      required
                      label="員工姓名 (英文)"
                      name="name"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikHKIDField required label="身份證號碼" name="hkid" />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikSelectField
                      required
                      label="性別"
                      name="gender"
                      options={GENDER_OPTIONS}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikTextField required label="聯絡電話" name="phone" />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikDateField
                      required
                      label="入職日期"
                      name="entryDate"
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikDateField label="離職日期" name="resignationDate" />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikSelectField
                      required
                      label="員工狀態"
                      name="status"
                      options={STAFF_STATUS_OPTION}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Typography variant="h6" gutterBottom>
                      針卡圖片
                    </Typography>
                    <FormikUploadField
                      label="針卡圖片"
                      name="vaccinationUrl"
                      policy="VACCINATION_PHOTO"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  系統資料
                </Typography>
                <Grid item sm={12} xs={12}>
                  <FormikTextField required label="用戶名稱" name="username" />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    label="密碼"
                    name="password"
                    type="password"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    label="確認密碼"
                    name="passwordConfirmation"
                    type="password"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="系統權限"
                    apiUrl={API_URL.ROLE_MANAGEMENT}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) => option?.nameTc}
                    name="role"
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} xs={12}>
        <fieldset>
          <FormLabel component="legend">工作資料</FormLabel>
          <FieldArray
            name="workHomes"
            render={(arrayHelpers) => (
              <div>
                <TableContainer style={{ maxHeight: 400, minWidth: 1024 }}>
                  <Table
                    stickyHeader
                    padding="none"
                    style={{ marginBottom: 15 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell omponent="th" style={{ padding: 10 }}>
                          工作院舍
                        </TableCell>
                        <TableCell omponent="th" style={{ padding: 10 }}>
                          相應更期
                        </TableCell>
                        <TableCell omponent="th" style={{ padding: 10 }}>
                          相應職級
                        </TableCell>
                        <TableCell
                          omponent="th"
                          style={{ padding: 10, width: "30%" }}
                        >
                          相應上班時間
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ width: 50 }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.workHomes &&
                        values.workHomes.map((item, index) => (
                          <TableRow key={index} disablegutters>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikReferenceField
                                edit={entity ? true : false}
                                required
                                styles={{ marginTop: "-4px" }}
                                label="院舍名稱"
                                apiUrl={"/api/homes"}
                                apiParams={{
                                  unpaged: true,
                                }}
                                getOptionLabel={(option) => option?.nameTc}
                                getOptionValue={(option) => option?.id}
                                name={`workHomes.${index}.home`}
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikSelectField
                                required
                                label="更期"
                                name={`workHomes.${index}.roster`}
                                options={STAFF_ROSTER_OPTION}
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 1 }}>
                              <FormikSelectField
                                required
                                label="職級"
                                name={`workHomes.${index}.position`}
                                options={
                                  values.workHomes[index].roster == "NIGHT" ||
                                  values.workHomes[index].roster ==
                                    "NIGHT_SUBSTITUTE"
                                    ? NIGHT_STAFF_POSITION_OPTION
                                    : STAFF_POSITION_OPTION
                                }
                              />
                            </TableCell>
                            <TableCell style={{ paddingRight: 10 }}>
                              <Grid container spacing={0}>
                                <Grid item sm={5} xs={5}>
                                  <FormikTimeField
                                    required
                                    label="開始時間"
                                    name={`workHomes.${index}.workingStartTime`}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sm={2}
                                  xs={2}
                                  style={{
                                    alignSelf: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  至
                                </Grid>
                                <Grid item sm={5} xs={5}>
                                  <FormikTimeField
                                    required
                                    label="結束時間"
                                    name={`workHomes.${index}.workingEndTime`}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  color="default"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => arrayHelpers.push("")}
                >
                  新增
                </Button>
              </div>
            )}
          />
        </fieldset>
      </Grid>
    </Grid>
  );
};
