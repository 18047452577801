import _uniqueId from "lodash/uniqueId";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Draggable from "react-draggable";
import Typography from "@material-ui/core/Typography";
import { Notification } from "../Notification";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { useEffect } from "react";

function PaperComponent(props) {
  const { dialogId, ...rest } = props;

  return (
    <Draggable
      bounds="parent"
      handle={"#" + dialogId}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...rest} />
    </Draggable>
  );
}

export const FormikDialog = (props) => {
  const {
    initialValues = {},
    validationSchema,
    onSubmit,
    onClose,
    open,
    dialogTitle,
    entity,
    button,
    maxWidth,
    mode,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;

  useEffect(() => {}, []);

  return (
    props.children && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
        }) => (
          <Dialog
            fullWidth={true}
            maxWidth={maxWidth || "md"}
            scroll="body"
            open={open}
            PaperComponent={PaperComponent}
            PaperProps={{ dialogId: dialogId }}
            aria-labelledby={dialogId}
          >
            <form onSubmit={handleSubmit} noValidate>
              <DialogTitle
                style={{ cursor: "move" }}
                id={dialogId}
                disableTypography
              >
                <Typography variant="h4" component="h2">
                  {dialogTitle}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText component="div">
                  <Notification />
                  {props.children}
                </DialogContentText>
              </DialogContent>
              {mode !== "close" ? (
                <DialogActions>
                  <Button
                    color="default"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    取消
                  </Button>
                  {mode == "delete" ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      刪除
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {button ? button : "儲存"}
                    </Button>
                  )}
                </DialogActions>
              ) : (
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    關閉
                  </Button>
                </DialogActions>
              )}
            </form>
          </Dialog>
        )}
      </Formik>
    )
  );
};
