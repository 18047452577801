import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../../../components/inputs/FormikTextField";
import FormikDateField from "../../../../../components/inputs/FormikDateField";
import InputAdornment from "@material-ui/core/InputAdornment";

export const initialValues = {
  resident: "",
  recordDate: moment(new Date()).format("yyyy-MM-DDT00:00:00+08:00"),
  systolicBloodPressure: "",
  diastolicBloodPressure: "",
  pluse: "",
  bloodOxygen: "",
  bloodSugar: "",
  breathingRate: "",
  temperature: "",
  height: "",
  weight: "",
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  recordDate: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  systolicBloodPressure: Yup.number()
    .min(10, "上壓最少為2位數")
    .max(999, "上壓最多為3位數")
    .nullable(),
  diastolicBloodPressure: Yup.number()
    .min(10, "下壓最少為2位數")
    .max(999, "下壓最多為3位數")
    .nullable(),
  pluse: Yup.number()
    .min(10, "脈搏最少為2位數")
    .max(999, "脈搏最多為3位數")
    .nullable(),
  bloodOxygen: Yup.number()
    .min(10, "脈搏最少為2位數")
    .max(999, "脈搏最多為3位數")
    .nullable(),
  bloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  breathingRate: Yup.number()
    .min(1, "呼吸速率最少為1位數")
    .max(999, "呼吸速率最多為3位數")
    .nullable(),
  temperature: Yup.number()
    .min(10, "體溫最少為2位數")
    .max(99, "體溫最多為2位數")
    .nullable(),
  height: Yup.number()
    .min(10, "高度最少為2位數")
    .max(999, "高度最多為3位數")
    .nullable(),
  weight: Yup.number()
    .min(10, "體重最少為2位數")
    .max(999, "體重最多為3位數")
    .nullable(),
});

export const TemperatureForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  required
                  label="記錄日期"
                  name="recordDate"
                  startTime
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="體溫"
                  name="temperature"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">°C</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
