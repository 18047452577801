import {
  API_URL,
  TAKING_STATUS_OPTIONS,
  TAKING_INSTRUCTION_OPTIONS,
  PRESCRIPTION_TYPE_OPTIONS,
  TAKING_PERIOD_OPTIONS,
  UNIT_OPTIONS,
} from "../../../constants";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  ResidentMedicineForm,
  initialValues,
  validationSchema,
} from "./medicine/ResidentMedicineForm";
import {
  PrintForm,
  mergePrintInitialValues,
  mergePrintValidationSchema,
} from "./medicine/PrintForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import { useParams } from "react-router-dom";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import { Formik } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import fileDownload from "js-file-download";
import { apiService } from "../../../services";
import { PrintEmptyDialog } from "./medicine/PrintEmptyForm";

const useStyles = makeStyles({
  isPrn: {
    color: "red",
  },
});

const MedicinePageHeader = (props) => {
  const home = useSelector((state) => state.home);
  const {
    setCreating,
    setMergePrinting,
    handleStatusChange,
    clearNotification,
    setPrintOpen,
    onExport,
  } = props;

  return (
    <PageHeader hideNotification>
      <Formik
        initialValues={{
          resident: "",
          status: "",
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
        }) => (
          <Fragment>
            <Grid container spacing={3} style={{ marginBottom: 15 }}>
              <Grid item xs={12} sm={3}>
                <fieldset>
                  <FormLabel component="legend">搜尋</FormLabel>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormikSelectField
                        label="用藥狀態"
                        name="status"
                        options={TAKING_STATUS_OPTIONS}
                        onChange={(event) => {
                          handleChange(event);
                          handleStatusChange(event);
                        }}
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
            </Grid>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增院友藥物
            </Button>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                clearNotification();
                setMergePrinting(true);
              }}
            >
              列印藥紙
            </Button>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                clearNotification();
                setPrintOpen(true);
              }}
            >
              列印空白藥紙
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<OpenInBrowserOutlinedIcon />}
              onClick={onExport}
            >
              匯出
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};

export default function Medicine() {
  const classes = useStyles();
  const home = useSelector((state) => state.home);
  let { id } = useParams();
  const [apiUrl, setApiUrl] = useState({});
  const [status, setStatus] = useState(""); //用藥狀態
  const [printOpen, setPrintOpen] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.resident = id;
    params.institution = params.institution.id || params.institution;
    params.specialist = params.specialist.id || params.specialist;
    params.medicineNamePackageDose = params.medicineNamePackageDose;
    params.medicine = values.medicineCode.id || values.medicineCode;
    if (values.startDate) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }
    if (values.endDate) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }
    params.medicationSchedule =
      params.medicationSchedule.id || params.medicationSchedule;
    params.residentMedicationScheduleSession = (
      params.residentMedicationScheduleSession || []
    ).map((i) => {
      return { time: moment(i.time).format("HH:mm") };
    });
    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;
    values.medicineCode = values.medicine.id;
    values.medicationSchedule = values.medicationSchedule.id;
    values.residentMedicationScheduleSession.map((item) => {
      item.time = moment().format("YYYY-MM-DD") + "T" + item.time + "+08:00";
    });
    values.institution = values.institution.id;
    values.specialist = values.specialist.id;

    return values;
  };

  const onPrintEmpty = (values, actions) => {
    let dayofmonth = moment(values.month).daysInMonth();
    apiService
      .get(apiUrl.emptyPrint + id, {
        params: {
          // startDate: moment(values.month).add(-1, "months").format("YYYY-MM-21"),
          startDate: moment(values.month).format("YYYY-MM-01"),
          // endDate: moment(values.month).format("YYYY-MM-20"),
          endDate: moment(values.month).format("YYYY-MM-") + dayofmonth,
          type: values.type,
        },
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        actions.setSubmitting(false);
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_空白藥紙_print.docx"
        );
        setPrintOpen(false);
      })
      .catch(function (response) {
        actions.setSubmitting(false);
      });
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "medicineNamePackageDose",
        headerName: "藥物英文名稱 - 包裝 - 劑量",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]}
            </div>
          );
        },
      },
      {
        field: "medicineCount",
        headerName: "用藥數量",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field] +
                " " +
                UNIT_OPTIONS.find(
                  (elem) => elem.value == params.row["medicineUnit"]
                )?.label}
            </div>
          );
        },
      },
      {
        field: "medicationSchedule",
        headerName: "服用次數",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field].code +
                " - " +
                params.row[params.field].nameTc}
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: "用藥狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {
                TAKING_STATUS_OPTIONS.find(
                  (elem) => elem.value == params.row[params.field]
                )?.label
              }
            </div>
          );
        },
      },
      {
        field: "takingPeriod",
        headerName: "服用週期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          if (params.row[params.field] == "EVERY_N_DAY") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"每" + params.row["nValue"] + "日"}/
              </div>
            );
          } else if (params.row[params.field] == "EVERY_N_WEEK") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"每" + params.row["nValue"] + "星期"}
              </div>
            );
          } else if (params.row[params.field] == "EVERY_N_DAY_OF_WEEK") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"逢星期" + params.row["nValue"]}
              </div>
            );
          } else if (params.row[params.field] == "EVERY_N_MONTH") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"每" + params.row["nValue"] + "個月"}
              </div>
            );
          } else {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {
                  TAKING_PERIOD_OPTIONS.find(
                    (elem) => elem.value == params.row[params.field]
                  )?.label
                }
              </div>
            );
          }
        },
      },
      {
        field: "takingMethod",
        headerName: "服用方法",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]
                ? TAKING_INSTRUCTION_OPTIONS.find(
                    (elem) => elem.value == params.row[params.field]
                  )?.label
                : "--"}
            </div>
          );
        },
      },
      {
        field: "prescriptionType",
        headerName: "藥紙類別",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {
                PRESCRIPTION_TYPE_OPTIONS.find(
                  (elem) => elem.value == params.row[params.field]
                )?.label
              }
            </div>
          );
        },
      },
      // hide && ({
      //     field: 'prescriptionBoard', headerName: '藥板', width: 100, sortable: false, filterable: true, renderCell: function (params) {
      //         return params.row[params.field] ? params.row[params.field] : "--";
      //     }
      // }),
      {
        field: "startDate",
        headerName: "開始使用藥物日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]}
            </div>
          );
        },
      },
      {
        field: "endDate",
        headerName: "結束日期",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field] ? params.row[params.field] : "--"}
            </div>
          );
        },
      },
      {
        field: "institution",
        headerName: "來源",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]
                ? params.row[params.field].nameTc
                : "--"}
            </div>
          );
        },
      },
      {
        field: "specialist",
        headerName: "專科",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]
                ? params.row[params.field].nameTc
                : "--"}
            </div>
          );
        },
      },
      {
        field: "isPrn",
        headerName: "需要時PRN",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field] ? "是" : "否"}
            </div>
          );
        },
      },
    ];
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (status != null) {
      setApiUrl({
        getAll:
          API_URL.RESIDENT_DRUG_MEDICATION +
          "/searchResidentMedication?residentId=" +
          id +
          "&status=" +
          status,
        getOne: API_URL.RESIDENT_DRUG_MEDICATION,
        create: API_URL.RESIDENT_DRUG_MEDICATION,
        edit: API_URL.RESIDENT_DRUG_MEDICATION,
        delete: API_URL.RESIDENT_DRUG_MEDICATION,
        copy: API_URL.RESIDENT_DRUG_MEDICATION,
        medicinePrint: "/api/residents/doc/personalPrescription/print",
        emptyPrint: "/api/residents/doc/personalPrescription/print/empty/",
        export:
          API_URL.RESIDENT_DRUG_MEDICATION + "/report/export?residentId=" +
          id +
          "&status=" +
          status,
      });
    } else {
      setApiUrl({
        getAll:
          API_URL.RESIDENT_DRUG_MEDICATION +
          "/searchResidentMedication?residentId=" +
          id,
        getOne: API_URL.RESIDENT_DRUG_MEDICATION,
        create: API_URL.RESIDENT_DRUG_MEDICATION,
        edit: API_URL.RESIDENT_DRUG_MEDICATION,
        delete: API_URL.RESIDENT_DRUG_MEDICATION,
        copy: API_URL.RESIDENT_DRUG_MEDICATION,
        medicinePrint: "/api/residents/doc/personalPrescription/print",
        emptyPrint: "/api/residents/doc/personalPrescription/print/empty/",
        export:
          API_URL.RESIDENT_DRUG_MEDICATION + "/report/export?residentId=" + id,
      });
    }
  }, [home, status]);

  return (
    <EntityManagement
      entityModel="院友藥物"
      entityPrintTitle="列印藥紙"
      editableFields={[
        "resident",
        "medicineCode",
        "medicineNamePackageDose",
        "takingMethod",
        "takingPeriod",
        "nValue",
        "medicationSchedule",
        "medicineCount",
        "medicineUnit",
        "isPrn",
        "isGp",
        "institution",
        "specialist",
        "prescriptionType",
        "prescriptionBoard",
        "startDate",
        "endDate",
        "residentDrugMedicationRemarks",
        "remarkName",
        "remarks",
        "status",
        "medicine",
        "residentMedicationScheduleSession",
        //"injectionPosition",
      ]}
      getColumnSettings={getColumnSettings}
      pageHeaderProps={{
        handleStatusChange: handleStatusChange,
        setPrintOpen: setPrintOpen,
      }}
      createParams={createParams}
      apiUrl={apiUrl}
      dataInputForm={ResidentMedicineForm}
      normalizeReceivedValues={normalizeReceivedValues}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mergePrintInitialValues={mergePrintInitialValues}
      mergePrintValidationSchema={mergePrintValidationSchema}
      mergePrintForm={PrintForm}
      maxWidth="md"
      pageHeader={MedicinePageHeader}
      defaultPageSize={10}
    >
      <PrintEmptyDialog
        open={printOpen}
        onClose={() => {
          setPrintOpen(false);
        }}
        onSubmit={onPrintEmpty}
      />
    </EntityManagement>
  );
}
