import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormattedMessage } from "react-intl";
import FormikDateField from "../../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import FormikCheckboxField from "../../../../components/inputs/FormikCheckboxField";
import {
  RESIDENT_RESTRAINT_STATUS_OPTION,
  RESIDENT_RESTRAINT_ITEM_OPTION,
  RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION,
} from "../../../../constants";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

export const initialValues = {
  resident: "",
  status: "",
  startDate: moment(new Date()).toISOString(),
  endDate: "",
  residentRestraintItemList: [],
};

export const validationSchema = Yup.object().shape({
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  startDate: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  endDate: Yup.string()
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  residentRestraintItemList: Yup.array().of(
    Yup.object().shape({
      item: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      residentRestraintItemRemarks: Yup.array().of(
        Yup.object().shape({
          remarks: Yup.string()
            .required(<FormattedMessage id="validation.message.required" />)
            .nullable(),
        })
      ),
    })
  ),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormikContext();
  const [guarantor, setGuarantor] = useState(null);
  // const [residentInfoEntity, setResidentInfoEntity] = useState("");
  const [dayTimeFieldDisabled, setDayTimeFieldDisabled] = useState(true);
  const [nightTimeFieldDisabled, setNightTimeFieldDisabled] = useState(true);

  // const handleResidentChange = (event, value) => {
  //     axios.get('/api/residents/' + value?.id).then((response) => {
  //         setResidentInfoEntity(response.data);
  //         setLoaded(true);
  //         if (residentInfoEntity.contactList) {
  //             setGuarantor(residentInfoEntity.contactList.find(i => i.guarantor));
  //         } else {
  //             setGuarantor(null);
  //         }
  //     }).catch((error) => {
  //     });
  // }

  const handleDayTimeChange = (event, value, index) => {
    if (event.target.checked) {
      setDayTimeFieldDisabled(false);
      setFieldValue(`residentRestraintItemList.${index}.dayTime`, true);
    } else {
      setDayTimeFieldDisabled(true);
      setFieldValue(`residentRestraintItemList.${index}.dayTimeStartTime`, "");
      setFieldValue(`residentRestraintItemList.${index}.dayTimeEndTime`, "");
      setFieldValue(`residentRestraintItemList.${index}.dayTime`, false);
    }
  };

  const handleNightTimeChange = (event, value, index) => {
    if (event.target.checked) {
      setNightTimeFieldDisabled(false);
      setFieldValue(`residentRestraintItemList.${index}.nightTime`, true);
    } else {
      setNightTimeFieldDisabled(true);
      setFieldValue(
        `residentRestraintItemList.${index}.nightTimeStartTime`,
        ""
      );
      setFieldValue(`residentRestraintItemList.${index}.nightTimeEndTime`, "");
      setFieldValue(`residentRestraintItemList.${index}.nightTime`, false);
    }
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      //     axios.get('/api/residents/' + entity.resident.id).then((response) => {
      //         setResidentInfoEntity(response.data);
      //         setLoaded(true);
      //     }).catch((error) => {
      //     });
      //     if (residentInfoEntity.contactList) {
      //         setGuarantor(residentInfoEntity.contactList.find(i => i.guarantor));
      //     } else {
      //         setGuarantor(null);
      //     }
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} md={4}>
                  <FormikSelectField
                    required
                    label="約束物品狀態"
                    name="status"
                    options={RESIDENT_RESTRAINT_STATUS_OPTION}
                    s
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FormikDateField
                    required
                    label="約束物品開始日期"
                    name="startDate"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FormikDateField label="約束物品結束日期" name="endDate" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item md={6} sm={12} xs={12}>
                    <form noValidate>
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <Card variant="outlined" style={{ marginBottom: 15 }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            院友基本資料
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <table>
                                                    <tr><td>姓名</td><td>{residentInfoEntity && (residentInfoEntity.lastnameTc + residentInfoEntity.firstnameTc)}</td></tr>
                                                    <tr><td>狀態</td><td>{residentInfoEntity && (CHECK_IN_STATUS_OPTIONS.find(elem => elem.value == residentInfoEntity.checkInStatus)?.label)}</td></tr>
                                                    <tr><td>床位</td><td>{residentInfoEntity && (residentInfoEntity.bed?.bedNo)}</td></tr>
                                                </table>
                                                <fieldset style={{ textAlign: 'center' }}>
                                                    <img src={residentInfoEntity && residentInfoEntity.photoUrl} />
                                                </fieldset>
                                            </Grid>
                                            <Grid item md={6} sm={12} xs={12}>
                                                <table>
                                                    <tr><td>姓名 (中文)</td><td>{residentInfoEntity && (residentInfoEntity.lastnameTc + residentInfoEntity.firstnameTc)}</td></tr>
                                                    <tr><td>姓名 (英文)</td><td>{residentInfoEntity && (residentInfoEntity.lastname + " " + residentInfoEntity.firstname)}</td></tr>
                                                    <tr><td>性別</td><td>{residentInfoEntity && (GENDER_OPTIONS.find(elem => elem.value == residentInfoEntity.gender)?.label)}</td></tr>
                                                    <tr><td>身份證號碼</td><td>{residentInfoEntity && (residentInfoEntity.hkid)}</td></tr>
                                                    <tr><td>出生日期</td><td>{residentInfoEntity && (residentInfoEntity.dateOfBirth)}</td></tr>
                                                    <tr><td>入住日期</td><td>{residentInfoEntity && ((residentInfoEntity.checkInDate)?.substr(0, 10))}</td></tr>
                                                    <tr><td>保證人姓名</td><td>{guarantor && guarantor.contactName}</td></tr>
                                                    <tr><td>保證人關係</td><td>{guarantor && RELATIONSHIP_OPTIONS.find(r => r.value == guarantor.relationship)?.label}</td></tr>
                                                    <tr><td>保證人電話</td><td>{guarantor && guarantor.contactNo}</td></tr>
                                                </table>
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </form>
                </Grid> */}
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <fieldset>
          <FormLabel component="legend">約束物品資料</FormLabel>
          <FieldArray
            name="residentRestraintItemList"
            render={(arrayHelpers) => (
              <div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>項目</TableCell>
                      <TableCell>備註</TableCell>
                      <TableCell>使用情況</TableCell>
                      <TableCell>全日</TableCell>
                      <TableCell>日間</TableCell>
                      <TableCell>晚間</TableCell>
                      <TableCell>PRN</TableCell>
                      <TableCell>動作</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.residentRestraintItemList &&
                      values.residentRestraintItemList.map((item, index) => (
                        <TableRow hover key={index}>
                          <TableCell>
                            <FormikSelectField
                              required
                              label="項目"
                              name={`residentRestraintItemList.${index}.item`}
                              options={RESIDENT_RESTRAINT_ITEM_OPTION}
                            />
                          </TableCell>
                          <TableCell>
                            <FormikTextField
                              label="備註"
                              name={`residentRestraintItemList.${index}.remarks`}
                            />
                          </TableCell>
                          <TableCell>
                            <FieldArray
                              name={`residentRestraintItemList.${index}.residentRestraintItemRemarks`}
                              render={(arrayHelpers) => (
                                <div>
                                  <Table
                                    stickyHeader
                                    padding="none"
                                    style={{ marginBottom: 15 }}
                                  >
                                    <TableBody>
                                      {values.residentRestraintItemList[index]
                                        .residentRestraintItemRemarks &&
                                        values.residentRestraintItemList[
                                          index
                                        ].residentRestraintItemRemarks.map(
                                          (remarks, remarkIndex) => (
                                            <TableRow
                                              key={remarkIndex}
                                              disablegutters
                                            >
                                              <TableCell
                                                style={{ paddingRight: 1 }}
                                              >
                                                <FormikSelectField
                                                  label="種類"
                                                  name={`residentRestraintItemList.${index}.residentRestraintItemRemarks.${remarkIndex}.remarks`}
                                                  options={
                                                    RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION
                                                  }
                                                />
                                              </TableCell>
                                              <TableCell align="right">
                                                <IconButton
                                                  onClick={() =>
                                                    arrayHelpers.remove(index)
                                                  }
                                                >
                                                  <DeleteOutlinedIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </TableBody>
                                  </Table>
                                  <Button
                                    color="default"
                                    variant="contained"
                                    type="button"
                                    size="small"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    新增
                                  </Button>
                                </div>
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <FormikCheckboxField
                              name={`residentRestraintItemList.${index}.fullDay`}
                            />
                          </TableCell>
                          <TableCell>
                            <FormikCheckboxField
                              name={`residentRestraintItemList.${index}.dayTime`}
                              onChange={(event, value) =>
                                handleDayTimeChange(event, value, index)
                              }
                            />
                            <Grid container spacing={2}>
                              <Grid item sm={6} xs={12}>
                                <FormikTextField
                                  disabled={
                                    values.residentRestraintItemList[index]
                                      .dayTime
                                      ? false
                                      : dayTimeFieldDisabled
                                  }
                                  label="開始時間"
                                  name={`residentRestraintItemList.${index}.dayTimeStartTime`}
                                  type="number"
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <FormikTextField
                                  disabled={
                                    values.residentRestraintItemList[index]
                                      .dayTime
                                      ? false
                                      : dayTimeFieldDisabled
                                  }
                                  label="結束時間"
                                  name={`residentRestraintItemList.${index}.dayTimeEndTime`}
                                  type="number"
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <FormikCheckboxField
                              name={`residentRestraintItemList.${index}.nightTime`}
                              onChange={(event, value) =>
                                handleNightTimeChange(event, value, index)
                              }
                            />
                            <Grid container spacing={2}>
                              <Grid item sm={6} xs={12}>
                                <FormikTextField
                                  disabled={
                                    values.residentRestraintItemList[index]
                                      .nightTime
                                      ? false
                                      : nightTimeFieldDisabled
                                  }
                                  label="開始時間"
                                  name={`residentRestraintItemList.${index}.nightTimeStartTime`}
                                  type="number"
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <FormikTextField
                                  disabled={
                                    values.residentRestraintItemList[index]
                                      .nightTime
                                      ? false
                                      : nightTimeFieldDisabled
                                  }
                                  label="結束時間"
                                  name={`residentRestraintItemList.${index}.nightTimeEndTime`}
                                  type="number"
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <FormikCheckboxField
                              name={`residentRestraintItemList.${index}.timePrn`}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Button
                  style={{ marginTop: 15 }}
                  color="default"
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => arrayHelpers.push("")}
                >
                  新增
                </Button>
              </div>
            )}
          />
        </fieldset>
      </Grid>
    </div>
  );
};
