import { API_URL } from "../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import {
  MedicationTimeForm,
  initialValues,
  validationSchema,
} from "./form/MedicationTimeForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";

const MedicationTimePageHeader = (props) => {
  const { setCreating, clearNotification } = props;

  return (
    <PageHeader title="用藥時間">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setCreating(true);
        }}
      >
        新增用藥時間
      </Button>
    </PageHeader>
  );
};

export default function MedicationTime() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    // params.medicationScheduleSessionList = values.medicationScheduleSessionList.map((item) => {
    //     return {id: item.id, time: moment(item.time).format("HH:mm"), sort: item.sort}
    // });

    return params;
  };

  const normalizeReceivedValues = (values) => {
    // values.medicationScheduleSessionList.map(item => {
    //     item.time = ((moment().format("YYYY-MM-DD")) + "T" + item.time + "+08:00");
    // })
    // return values;
  };

  const normalizeCopyValues = (values) => {
    for (let i in values.medicationScheduleSessionList) {
      delete values.medicationScheduleSessionList[i]["id"];
    }
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "code",
        headerName: "時間代號",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "時間名稱 (中文)",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "name",
        headerName: "時間名稱 (英文)",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      // {
      //     field: 'medicationScheduleSessionTime', headerName: '時間', width: 400, sortable: false, filterable: false, renderCell: function (params) {
      //         return (
      //             <div>
      //                 {params?.row['medicationScheduleSessionList']?.sort(
      //                     (a, b) => { return a.sort > b.sort ? 1 : -1; }
      //                 ).map((m) => { return m.time?.substr(0, 5) }).join(', ') }
      //             </div>
      //         );
      //     }
      // },
      // {
      //     field: 'medicationScheduleSessionList', headerName: '藥紙時間格數', width: 400, sortable: false, filterable: false, renderCell: function (params) {
      //         return (
      //             <div>
      //                 {params?.row[params.field]?.sort(
      //                     (a, b) => { return a.sort > b.sort ? 1 : -1; }
      //                 ).map((m) => { return m.sort + ' - ' + m.time?.substr(0, 5)}).join(', ') }
      //             </div>
      //         );
      //     }
      // },
      // {
      //     field: 'type', headerName: '時間類別', width: 150, sortable: false, filterable: false, renderCell: function (params) {
      //         return SCHEDULE_SESSION_TYPE.find(elem => elem.value == params.row[params.field])?.label;
      //     }
      // }
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.MEDICATION_SCHEDULES,
      getOne: API_URL.MEDICATION_SCHEDULES,
      create: API_URL.MEDICATION_SCHEDULES,
      edit: API_URL.MEDICATION_SCHEDULES,
      delete: API_URL.MEDICATION_SCHEDULES,
      copy: API_URL.MEDICATION_SCHEDULES,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="用藥時間"
      editableFields={["code", "nameTc", "name"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      apiUrl={apiUrl}
      dataInputForm={MedicationTimeForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      normalizeCopyValues={normalizeCopyValues}
      normalizeReceivedValues={normalizeReceivedValues}
      maxWidth="sm"
      pageHeader={MedicationTimePageHeader}
    ></EntityManagement>
  );
}
