import { DEFAULT_LOCALE, MESSAGES } from "../constants/locale.constant";

class LocaleService {
  getLocale = () => {
    let locale = window.sessionStorage.getItem("locale") || DEFAULT_LOCALE ||
      (navigator.languages && navigator.languages[0]) || navigator.language;

    locale = this.formatToHyphen(locale && this.isLocaleSupported(locale) ? locale : DEFAULT_LOCALE);

    window.sessionStorage.setItem("locale", locale);

    return locale;
  };

  setLocale = (locale) => {
    locale = this.formatToHyphen(locale && this.isLocaleSupported(locale) ? locale : DEFAULT_LOCALE);

    window.sessionStorage.setItem("locale", locale);

    return locale;
  };

  isLocaleSupported(locale) {
    return Object.keys(MESSAGES).includes(locale);
  }

  formatToHyphen = (locale) => {
    return locale.replace(/_/g, '-');
  }

  isSameLocale(locale1, locale2) {
    return this.formatToHyphen(locale1) == this.formatToHyphen(locale2);
  }

  defaultLocale = DEFAULT_LOCALE;

  messages = MESSAGES;
}

export default new LocaleService();