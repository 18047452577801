import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import { API_URL, LEAVE_STATUS_OPTIONS } from "../../../../constants";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import axios from "axios";
import { FormikDialog } from "../../../../components/dialogs";
import FormikDateField from "../../../../components/inputs/FormikDateField";

export const initialValues = {
  resident: "",
  bed: "",
  status: "",
  reason: "",
  remarks: "",
  leaveDate: "",
  estimateReturnDate: "",
  actualReturnDate: "",
  returnRemarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function LeaveDialog(props) {
  const { open, entity, onClose, onSubmit, normalizeReceivedValues } = props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get(API_URL.LEAVE + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"修改外宿記錄"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="md"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                院友資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    disabled={entity ? true : false}
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="外宿狀態"
                    name="status"
                    options={LEAVE_STATUS_OPTIONS}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {values.status == "RETURNED" && (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  外宿資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      label="實際回院日期"
                      name="actualReturnDate"
                      startTime
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField label="回院備註" name="returnRemarks" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
