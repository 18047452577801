import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { DEFAULT_COMPANY_CONFIG } from '../../config/app.config';
import Copyright from '../../components/Copyright';
import LoginForm from './components/LoginForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 0),
    padding: theme.spacing(6, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, .85)'
  },
  logo: {
    maxWidth: 180,
    maxHeight: 120
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <img className={classes.logo} src={DEFAULT_COMPANY_CONFIG['logo']} title={DEFAULT_COMPANY_CONFIG['name']} alt={DEFAULT_COMPANY_CONFIG['name']} />
        <LoginForm />
        <Copyright mt={4} />
      </Paper>
    </Container>
  );
}