import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";

export const ListingPageHeader = (props) => {
  const { setCreating, handleFilterChange, onExport, clearNotification } =
    props;

  return (
    <PageHeader hideNotification>
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增傷口
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<OpenInBrowserOutlinedIcon />}
              onClick={onExport}
            >
              匯出
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
