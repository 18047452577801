import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useMediaQuery } from "@material-ui/core";
import { TopBar } from './components/TopBar';
import { MenuDrawer } from './components/MenuDrawer';
import { MainContainer } from './components/MainContainer';
import { history } from '../../history';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
}));

export function PrivateLayout(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
    const [open, setOpen] = useState(!isMdDown);
    const [currentLocation, setCurrentLocation] = useState(history.location.pathname);

    const handleDrawerToggle = () => {
        setOpen(!open);

    };

    useEffect(() => {
        history.listen((location, action) => {
            if (location.pathname && currentLocation !== location.pathname && window.innerWidth < 1280) {
                setOpen(false);
                setCurrentLocation(location.pathname);
            }
        });

    }, []);



    return (
        <div className={classes.root}>
            <CssBaseline />
            <TopBar open={open} handleDrawerToggle={handleDrawerToggle} />
            <MenuDrawer open={open} />
            <MainContainer>
                {props.children}
            </MainContainer>
        </div>
    );
}