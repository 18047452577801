import * as Yup from "yup";
import moment from "moment";
import "moment-lunar";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  DISCHARGE_REASON_OPTIONS,
  DEATH_REASON_OPTIONS,
} from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikSwitchField from "../../../components/inputs/FormikSwitchField";

export const initialValues = {
  submitDeathRecordForm: false,
  resident: "",
  dischargeDate: moment(new Date()).format("YYYY-MM-DDT00:00:00+08:00"),
  reason: "",
  status: "",
  hospitalLocation: "",
  hospitalDate: "",
  deathLocation: "",
  deathDate: "",
  deathReason: "",
  source: "",
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  reason: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // status: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // dischargeDate: Yup.date().when(["reason", "submitDeathRecordForm"], (reasonValue, submitDeathRecordFormValue, schema) => {
  //   if (reasonValue == "DEATH" && submitDeathRecordFormValue) {
  //     return schema.required(<FormattedMessage id="validation.message.required" />)
  //       .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
  //       .when("reason", {
  //         is: "DEATH",
  //         then: Yup.date().max(new Date(), "身故的退院日期需為今日或以前"),
  //       })
  //       .nullable();
  //   }
  //   return schema.notRequired().nullable();
  // }),
  dischargeDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .when("reason", {
      is: "DEATH",
      then: Yup.date().max(new Date(), "身故的退院日期需為今日或以前"),
    })
    .nullable(),
  // hospitalLocation: Yup.string().when(["reason", "submitDeathRecordForm"], (reasonValue, submitDeathRecordFormValue, schema) => {
  //   if (reasonValue == "DEATH" && submitDeathRecordFormValue) {
  //     return schema.required(
  //       <FormattedMessage id="validation.message.required" />
  //     );
  //   }
  //   return schema.notRequired().nullable();
  // }),
  // hospitalLocation: Yup.string().when("reason", {
  //   is: "DEATH",
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
  // hospitalDate: Yup.string().when(["reason", "submitDeathRecordForm"], (reasonValue, submitDeathRecordFormValue, schema) => {
  //   if (reasonValue == "DEATH" && submitDeathRecordFormValue) {
  //     return schema.required(
  //       <FormattedMessage id="validation.message.required" />
  //     );
  //   }
  //   return schema.notRequired().nullable();
  // }),
  // hospitalDate: Yup.string().when("reason", {
  //   is: "DEATH",
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
  // deathLocation: Yup.string().when(["reason", "submitDeathRecordForm"], (reasonValue, submitDeathRecordFormValue, schema) => {
  //   if (reasonValue == "DEATH" && submitDeathRecordFormValue) {
  //     return schema.required(
  //       <FormattedMessage id="validation.message.required" />
  //     );
  //   }
  //   return schema.notRequired().nullable();
  // }),
  // deathLocation: Yup.string().when("reason", {
  //   is: "DEATH",
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
  // deathDate: Yup.string().when(["reason", "submitDeathRecordForm"], (reasonValue, submitDeathRecordFormValue, schema) => {
  //   if (reasonValue == "DEATH" && submitDeathRecordFormValue) {
  //     return schema.required(
  //       <FormattedMessage id="validation.message.required" />
  //     );
  //   }
  //   return schema.notRequired().nullable();
  // }),
  // deathDate: Yup.string().when("reason", {
  //   is: "DEATH",
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
  // deathReason: Yup.string().when(["reason", "submitDeathRecordForm"], (reasonValue, submitDeathRecordFormValue, schema) => {
  //   if (reasonValue == "DEATH" && submitDeathRecordFormValue) {
  //     return schema.required(
  //       <FormattedMessage id="validation.message.required" />
  //     );
  //   }
  //   return schema.notRequired().nullable();
  // }),
  // deathReason: Yup.string().when("reason", {
  //   is: "DEATH",
  //   then: Yup.string().required(
  //     <FormattedMessage id="validation.message.required" />
  //   ),
  // }),
});

export function CreateDialog(props) {
  const { open, onClose, onSubmit } = props;
  const [entityValues, setEntityValues] = useState();

  return (
    <FormikDialog
      initialValues={initialValues}
      validationSchema={validationSchema}
      open={open}
      dialogTitle={"新增退院"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="md"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();
  // const [submitDeathRecordFormChecked, setSubmitDeathRecordFormChecked] = useState(false);

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {}, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                退院資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option?.lastnameTc + option?.firstnameTc
                    }
                    getOptionValue={(option) => option?.id}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateField
                    required
                    label="退院日期 (身故的退院日期需為今日或以前)"
                    name="dischargeDate"
                    startTime
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="退院原因"
                    name="reason"
                    options={DISCHARGE_REASON_OPTIONS}
                  />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                                    <FormikSelectField
                                        required
                                        label="狀態"
                                        name="status"
                                        options={DISCHARGE_STATUS_OPTIONS}
                                    />
                                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* {values.reason == "DEATH" && (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <FormikSwitchField
                  name="submitFollowupForm"
                  label="填寫死亡記錄"
                  checked={submitDeathRecordFormChecked}
                  setChecked={() => {
                    values.submitDeathRecordForm = !values.submitDeathRecordForm;
                    setSubmitDeathRecordFormChecked(!submitDeathRecordFormChecked);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )} */}
        {values.reason == "DEATH" &&
        // submitDeathRecordFormChecked &&
        (
          <Grid item sm={12} xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  死亡記錄
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      // required
                      label="留醫地點"
                      name="hospitalLocation"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      // required
                      label="留醫日期"
                      name="hospitalDate"
                      startTime
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField
                      // required
                      label="死亡地點"
                      name="deathLocation"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikDateField
                      // required
                      label="死亡日期"
                      name="deathDate"
                      startTime
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikSelectField
                      // required
                      label="死亡原因"
                      name="deathReason"
                      options={DEATH_REASON_OPTIONS}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormikTextField label="資料來源" name="source" />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <FormikTextField label="備註" name="remarks" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
