import { useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { useFormikContext } from "formik";
import _ from "lodash";

export default function FormikSwitchField(props) {
  const {
    checked,
    setChecked,
    name,
    label,
    center,
  } = props;

  const {
    values,
    resetForm
  } = useFormikContext();

  const [isOn, setIsOn] = useState(_.get(values, name) || checked || false);

  const defaultHandleChange = () => {
    _.set(values, name, !isOn);
    resetForm({ values: values });
    setIsOn(!isOn);
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            size="small"
            checked={_.get(values, name) || checked}
            onChange={() => {
              setChecked ? setChecked(!checked) : defaultHandleChange();
            }}
            name={name}
            label="Small"
            color="primary"
            value={_.get(values, name) || false}
          />
        }
        label={label}
        style={center ? {justifyContent: "center"} : {}}
      />
    </FormGroup>
  )
}
