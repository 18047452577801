import * as Yup from "yup";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikCheckboxField from "../../../components/inputs/FormikCheckboxField";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { NotificationActions } from "../../../actions";
import { useDispatch } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const initialValues = {
  contactList: [],
  contactName: "",
  relationship: "",
  contactNo: "",
  contactNo2: "",
  address: "",
  sequence: "",
  guarantor: false,
  emergencyContact: false,
  recipient: false,
};

const validationSchema = Yup.object().shape({
  contactList: Yup.array().of(
    Yup.object().shape({
      contactName: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      relationship: Yup.string()
        // .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      contactNo: Yup.string()
        .min(8, "電話長度必須為8個位")
        .max(8, "電話長度必須為8個位")
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      contactNo2: Yup.string()
        .min(8, "電話長度必須為8個位")
        .max(8, "電話長度必須為8個位")
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .nullable(),
      address: Yup.string()
        //.required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
      sequence: Yup.string()
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .required(<FormattedMessage id="validation.message.required" />)
        .nullable(),
    })
  ),
});

export const ContactFormContent = (props) => {
  const { entity, ...rest } = props;
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
    initialValues,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: { ...initialValues, ...entity } });
  }, [entity]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <fieldset style={{ marginBottom: 15 }}>
            <FormLabel component="legend">親友資料</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FieldArray
                  name="contactList"
                  render={(arrayHelpers) => (
                    <div /*style={{ overflowX: 'scroll' }}*/>
                      <TableContainer
                        style={{ maxHeight: 400, minWidth: 1000 }}
                      >
                        <Table
                          stickyHeader
                          padding="none"
                          style={{ marginBottom: 15 }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 120 }}
                              >
                                親友姓名
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 110 }}
                              >
                                關係
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 100 }}
                              >
                                聯絡電話(1)
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 100 }}
                              >
                                聯絡電話(2)
                              </TableCell>
                              <TableCell component="th" style={{ padding: 5 }}>
                                地址
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 80 }}
                              >
                                次序
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 60 }}
                              >
                                保證人
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 80 }}
                              >
                                緊急聯絡人
                              </TableCell>
                              <TableCell
                                component="th"
                                style={{ padding: 5, width: 60 }}
                              >
                                收件人
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ width: 50 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.contactList &&
                              values.contactList.map((friend, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikTextField
                                      required
                                      label="親友姓名"
                                      name={`contactList.${index}.contactName`}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikTextField
                                      label="關係"
                                      name={`contactList.${index}.relationship`}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikTextField
                                      required
                                      label="聯絡電話(1)"
                                      name={`contactList.${index}.contactNo`}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikTextField
                                      label="聯絡電話(2)"
                                      name={`contactList.${index}.contactNo2`}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikTextField
                                      //required
                                      label="地址"
                                      name={`contactList.${index}.address`}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{ paddingRight: 1, paddingTop: 3 }}
                                  >
                                    <FormikTextField
                                      required
                                      label="次序"
                                      name={`contactList.${index}.sequence`}
                                    />
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <FormikCheckboxField
                                      name={`contactList.${index}.guarantor`}
                                    />
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <FormikCheckboxField
                                      name={`contactList.${index}.emergencyContact`}
                                    />
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <FormikCheckboxField
                                      name={`contactList.${index}.recipient`}
                                    />
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        color="default"
                        variant="contained"
                        type="button"
                        size="small"
                        onClick={() =>
                          arrayHelpers.push({
                            contactName: null,
                            relationship: null,
                            contactNo: null,
                            contactNo2: null,
                            address: null,
                            sequence: null,
                            guarantor: false,
                            emergencyContact: false,
                            recipient: false,
                          })
                        }
                      >
                        新增
                      </Button>
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
      <Button
        style={{ marginRight: 15 }}
        color="primary"
        variant="contained"
        type="submit"
        disabled={isSubmitting}
      >
        儲存
      </Button>
      <Button
        color="default"
        variant="contained"
        type="reset"
        onClick={resetForm}
      >
        重設
      </Button>
    </form>
  );
};

export const ContactForm = (props) => {
  const { ...rest } = props;
  let { id } = useParams();
  const [entity, setEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const createParams = (values) => {
    let params = {};
    ["contactList"].map((key) => {
      params[key] = values[key];
    });

    /*if (params.exemptionPaperExpiryDate) {
            if (typeof params.exemptionPaperExpiryDate === "string") {
                params.exemptionPaperExpiryDate = params.exemptionPaperExpiryDate.substring(0, 10);
            } else {
                params.exemptionPaperExpiryDate = format(params.exemptionPaperExpiryDate, "yyyy-MM-dd");
            }
        }*/

    return params;
  };

  const handleSubmit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT + "/" + id + "/contact", createParams(values))
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    axios
      .get(API_URL.RESIDENT + "/" + id)
      .then((response) => {
        setEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
  }, [id]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => loaded && <ContactFormContent entity={entity} />}
    </Formik>
  );
};
