import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Notification } from './Notification';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(6),
    },
}));

export function PageHeader(props) {
    const { title, variant, component, hideNotification, ...rest } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant={variant || "h2"} component={component || "h1"} gutterBottom>
                {title}
            </Typography>
            {!hideNotification && (
            <Notification />)}
            {props.children}
        </Box>
    );
}