export const ROUTES = {
  // 其他
  DASHBOARD: "/",
  LOGIN: "/login",
  PRIVACY_ZH_PATH: "/privacyZh",
  TERMS_AND_CONDITIONS_ZH_PATH: "/termsAndConditionsZh",

  // 院舍管理
  CALENDAR: "/home/calendar",
  HOME_OVERVIEW: "/home/overview",
  HOME_MANAGEMENT: "/home/listing",
  BED_MANAGEMENT: "/home/beds",
  ROLE_MANAGEMENT: "/roles",
  USER_MANAGEMENT: "/home/users",
  HOME_INFO: "/home/info",

  // 院友管理
  RESIDENT_MANAGEMENT: "/residents/listing",
  RESERVATION_MANAGEMENT: "/reservations/listing",
  DOWN_PAYMENT_RECORD: "/downPaymentRecord/listing",
  DISCHARGE_MANAGEMENT: "/discharge/listing",
  DEATH_RECORD: "/deathRecord/listing",
  LEAVE_RECORD: "/leaveRecord/listing",
  RESIDENT_STATISTICS: "/residents/statistics",
  RESIDENT_SETTING: "/residents/setting",
  RESIDENT: "/residents/:id",

  // 會計管理
  DEFAULT_EXPENSE: "/defaultExpense/listing",
  RESIDENT_INVOICE: "/residentInvoice/listing",
  HOME_EXPENSE: "/homeExpense/listing",
  HOME_EXPENSE_RECORD: "/homeExpenseRecord/listing",
  HOME_EXPENSE_PETTY_CASH: "/homeExpensePettyCash/listing",
  HOME_EXPENSE_PETTY_CASH_EXPENSE: "/homeExpensePettyCashExpense/listing",
  RESIDENT_DEPOSIT: "/residentDeposit/listing",
  STAFF_SALARY: "/staffSalary/listing",
  ACCOUNTING_SETTING: "/accounting/setting",

  // 藥物管理
  MEDICINE_MANAGEMENT: "/medicine/listing",
  MEDICINE_REPORT: "/medicine/report",
  MEDICINE_SETTING: "/medicine/setting",

  // 醫療記錄
  MEDICAL_RECORD_MANAGEMENT: "/medicalRecord/listing",
  RESIDENT_FOLLOW_UP_MANAGEMENT: "/residentFollowUp/listing",
  MEDICAL_ITEM: "/medicalItems/listing",
  RESIDENT_EMERGENCY_RECORD_MANAGEMENT: "/residentEmergencyRecord/listing",
  MEDICAL_SETTING: "/medical/setting",

  // 護理記錄
  RESIDENT_VITAL_SIGN: "/residentVitalSigns/listing",
  WOUND_MANAGEMENT: "/wound/listing",
  RESIDENT_RESTRAINT_ITEM: "/residentRestraintItems/listing",
  ICP: "/icp/listing",
  NURSING_SETTING: "/nursing/setting",

  // 員工管理
  STAFF_USERS: "/staffUsers/listing",
  ROSTER_RECURRING: "/staffRosterRecurring",
  ROSTER_MANAGEMENT: "/rosterManagement/listing",
  ROSTER_DETAILS: "/rosterManagement/:id",

  // 手帶管理
  WRISTBAND_MANAGEMENT: "/wristband/listing",
  WRISTBAND_SETTING: "/wristband/setting",

  // 智能手帶管理
  IBEACON_MANAGEMENT: "/ibeacon/listing",
  IBEACON_SETTING: "/ibeacon/setting",

  // 文件管理
  DOCUMENT_LISTING: "/document/listing",
  DOCUMENT_MANAGEMENT: "/document/setting",
};
