import { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const { setCreating, setBatchCreate, handleFilterChange, clearNotification } =
    props;

  return (
    <PageHeader title="手帶設定">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增手帶
            </Button>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<LibraryAddIcon />}
              onClick={() => {
                clearNotification();
                setBatchCreate(true);
              }}
            >
              批量新增手帶
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
