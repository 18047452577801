import { NOTIFICATION_TYPE } from '../constants';

export function notification(state = {}, action) {
    switch (action.type) {
        case NOTIFICATION_TYPE.SUCCESS:
            return {
                type: 'success',
                title: action.title,
                message: action.message
            };
        case NOTIFICATION_TYPE.INFO:
            return {
                type: 'info',
                title: action.title,
                message: action.message
            };
        case NOTIFICATION_TYPE.WARNING:
            return {
                type: 'warning',
                title: action.title,
                message: action.message
            };
        case NOTIFICATION_TYPE.ERROR:
            return {
                type: 'error',
                title: action.title,
                message: action.message
            };
        case NOTIFICATION_TYPE.CLEAR:
            return {};
        default:
            return state;
    }
}