
export const homeActions = {
    set,
    clear
};

function set(home) {
    sessionStorage.setItem('home', JSON.stringify(home));

    return { type: 'set', home };
}

function clear() {
    sessionStorage.removeItem('home');

    return { type: 'clear' };
}