import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import {
  API_URL,
  TAKING_STATUS_OPTIONS,
  TAKING_INSTRUCTION_OPTIONS,
  PRESCRIPTION_TYPE_OPTIONS,
  TAKING_PERIOD_OPTIONS,
  UNIT_OPTIONS,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import {
  PrintForm,
  mergePrintInitialValues,
  mergePrintValidationSchema,
} from "./components/PrintForm";
import { ListingPageHeader } from "./components/ListingPageHeader";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import clsx from "clsx";
import { PrintEmptyDialog } from "./components/PrintEmptyForm";
import { apiService } from "../../services";
import fileDownload from "js-file-download";

const useStyles = makeStyles({
  isPrn: {
    color: "red",
  },
});

export default function MedicineManagementPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [printOpen, setPrintOpen] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    if (values.endDate) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }
    params.resident = params.resident.id || params.resident;
    params.institution = params.institution.id || params.institution;
    params.specialist = params.specialist.id || params.specialist;
    params.medicineNamePackageDose = params.medicineNamePackageDose;
    params.medicine = values.medicineCode.id || values.medicineCode;
    params.medicineCode = params.medicineCode.code;
    params.medicationSchedule =
      params.medicationSchedule.id || params.medicationSchedule;
    params.residentMedicationScheduleSession = (
      params.residentMedicationScheduleSession || []
    ).map((i) => {
      return { time: moment(i.time).format("HH:mm") };
    });

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.homeId = home?.home?.id;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;
    values.medicationSchedule = values.medicationSchedule.id;
    values.medicineCode = values.medicine.id;
    values.residentMedicationScheduleSession.map((item) => {
      item.time = moment().format("YYYY-MM-DD") + "T" + item.time + "+08:00";
    });
    values.institution = values.institution.id;
    values.specialist = values.specialist.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const onPrintEmpty = (values, actions) => {
    let dayofmonth = moment(values.month).daysInMonth();
    apiService
      .get(apiUrl.emptyPrint + values.resident, {
        params: {
          startDate: moment(values.month).format("YYYY-MM-01"),
          endDate: moment(values.month).format("YYYY-MM-") + dayofmonth,
          type: values.type,
        },
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        actions.setSubmitting(false);
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_空白藥紙_print.docx"
        );
        setPrintOpen(false);
      })
      .catch(function (response) {
        actions.setSubmitting(false);
      });
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <IconButton color="default" size="small" disabled={loading}>
                                <InsertLinkOutlinedIcon />
                            </IconButton>
                            <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip>*/}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友名稱",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row["resident"].lastnameTc +
                params.row["resident"].firstnameTc}
            </div>
          );
        },
      },
      {
        field: "medicineNamePackageDose",
        headerName: "藥物英文名稱 - 包裝 - 劑量",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]}
            </div>
          );
        },
      },
      {
        field: "medicineCount",
        headerName: "用藥數量",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field] +
                " " +
                UNIT_OPTIONS.find(
                  (elem) => elem.value == params.row["medicineUnit"]
                )?.label}
            </div>
          );
        },
      },
      {
        field: "medicationSchedule",
        headerName: "服用次數",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field].code +
                " - " +
                params.row[params.field].nameTc}
            </div>
          );
        },
      },
      {
        field: "status",
        headerName: "用藥狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {
                TAKING_STATUS_OPTIONS.find(
                  (elem) => elem.value == params.row[params.field]
                )?.label
              }
            </div>
          );
        },
      },
      {
        field: "takingPeriod",
        headerName: "服用週期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          if (params.row[params.field] == "EVERY_N_DAY") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"每" + params.row["nValue"] + "日"}
              </div>
            );
          } else if (params.row[params.field] == "EVERY_N_WEEK") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"每" + params.row["nValue"] + "星期"}
              </div>
            );
          } else if (params.row[params.field] == "EVERY_N_DAY_OF_WEEK") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"逢星期" + params.row["nValue"]}
              </div>
            );
          } else if (params.row[params.field] == "EVERY_N_MONTH") {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {"每" + params.row["nValue"] + "個月"}
              </div>
            );
          } else {
            return (
              <div
                className={clsx({
                  [classes.isPrn]: params.row["isPrn"],
                })}
              >
                {
                  TAKING_PERIOD_OPTIONS.find(
                    (elem) => elem.value == params.row[params.field]
                  )?.label
                }
              </div>
            );
          }
        },
      },
      {
        field: "takingMethod",
        headerName: "服用方法",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]
                ? TAKING_INSTRUCTION_OPTIONS.find(
                    (elem) => elem.value == params.row[params.field]
                  )?.label
                : "--"}
            </div>
          );
        },
      },
      {
        field: "prescriptionType",
        headerName: "藥紙類別",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {
                PRESCRIPTION_TYPE_OPTIONS.find(
                  (elem) => elem.value == params.row[params.field]
                )?.label
              }
            </div>
          );
        },
      },
      // hide && ({
      //     field: 'prescriptionBoard', headerName: '藥板', width: 100, sortable: false, filterable: true, renderCell: function (params) {
      //         return params.row[params.field] ? params.row[params.field] : "--";
      //     }
      // }),
      {
        field: "startDate",
        headerName: "開始使用藥物日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]}
            </div>
          );
        },
      },
      {
        field: "endDate",
        headerName: "結束日期",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field] ? params.row[params.field] : "--"}
            </div>
          );
        },
      },
      {
        field: "institution",
        headerName: "來源",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]
                ? params.row[params.field].nameTc
                : "--"}
            </div>
          );
        },
      },
      {
        field: "specialist",
        headerName: "專科",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field]
                ? params.row[params.field].nameTc
                : "--"}
            </div>
          );
        },
      },
      {
        field: "isPrn",
        headerName: "需要時PRN",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div
              className={clsx({
                [classes.isPrn]: params.row["isPrn"],
              })}
            >
              {params.row[params.field] ? "是" : "否"}
            </div>
          );
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_DRUG_MEDICATION + "/searchResidentMedication",
      getOne: API_URL.RESIDENT_DRUG_MEDICATION,
      create: API_URL.RESIDENT_DRUG_MEDICATION,
      edit: API_URL.RESIDENT_DRUG_MEDICATION,
      delete: API_URL.RESIDENT_DRUG_MEDICATION,
      medicinePrint: "/api/residents/doc/personalPrescription/print",
      emptyPrint: "/api/residents/doc/personalPrescription/print/empty/",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="院友藥物"
      entityPrintTitle="列印藥紙"
      editableFields={[
        "resident",
        "medicineCode",
        "medicineNamePackageDose",
        "takingMethod",
        "takingPeriod",
        "nValue",
        "medicationSchedule",
        "medicineCount",
        "medicineUnit",
        "isPrn",
        "isGp",
        "institution",
        "specialist",
        "prescriptionType",
        "prescriptionBoard",
        "startDate",
        "endDate",
        "residentDrugMedicationRemarks",
        "remarkName",
        "remarks",
        "status",
        "medicine",
        "residentMedicationScheduleSession",
        // "injectionPosition",
      ]}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setPrintOpen: setPrintOpen,
      }}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      mergePrintInitialValues={mergePrintInitialValues}
      mergePrintValidationSchema={mergePrintValidationSchema}
      mergePrintForm={PrintForm}
      maxWidth="lg"
      getColumnSettings={getColumnSettings}
      printButton={"列印"}
    >
      <PrintEmptyDialog
        open={printOpen}
        onClose={() => {
          setPrintOpen(false);
        }}
        onSubmit={onPrintEmpty}
      />
    </EntityManagement>
  );
}
