import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../components/PageHeader';
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import Button from '@material-ui/core/Button';
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);

    const {
        setCreating,
        handleFilterChange,
        onExport
    } = props;

    return (
        <PageHeader title="病歷管理">
            <Formik
                initialValues={initialFilteringValues}
                validationSchema={filteringValidationSchema}
            >
                {() => (
                    <Fragment>
                        <FilteringForm
                            handleFilterChange={handleFilterChange}
                        />
                        {/* <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutlinedIcon />}
                            onClick={() => { setCreating(true) }}
                        >
                            新增訂位
                        </Button> */}
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<OpenInBrowserOutlinedIcon />}
                            onClick={onExport}
                        >
                            匯出
                        </Button>
                    </Fragment>
                )}
            </Formik>
        </PageHeader >
    );
}