import { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _uniqueId from "lodash/uniqueId";
import { DataGrid } from "@material-ui/data-grid";
//import { TextField, IconButton, Paper, Table, TableBody, TableFooter, TableCell, TableContainer, TableHead, TableRow, TablePagination } from "@material-ui/core";
//import axios from 'axios';
import { apiService } from "../services";
import {
  FormikCreateDialog,
  FormikMergeCreateDialog,
  FormikEditDialog,
  FormikDeleteDialog,
  FormikCopyDialog,
  FormikMergePrintDialog,
} from "../components/dialogs";
import { useDispatch } from "react-redux";
import { NotificationActions } from "../actions";
import fileDownload from "js-file-download";
import moment from "moment";
import { Typography, withStyles } from "@material-ui/core";
import { getEntities } from "./GetEntities";
import { getDocumentAndFolderEntities } from "./GetDocumentAndFolderEntities";
import { ERROR_STATUS, BLOOD_SUGAR_SAMPLE_TIMES as SAMPLE_TIMES } from "../constants";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);

const CreateDialog = (props) => {
  const {
    open,
    onClose,
    onSave,
    entityModel,
    apiUrl,
    entity,
    initialValues,
    validationSchema,
    maxWidth,
    dataInputForm: DataInputForm,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;
  const Form = DataInputForm || "div";

  return (
    <FormikCreateDialog
      open={open}
      entityModel={entityModel}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth={maxWidth}
      onSubmit={onSave}
      onClose={onClose}
    >
      <Form entity={entity} apiUrl={apiUrl} />
    </FormikCreateDialog>
  );
};

const MergeCreateDialog = (props) => {
  const {
    open,
    onClose,
    onSave,
    entityModel,
    apiUrl,
    entity,
    initialValues,
    validationSchema,
    maxWidth,
    dataInputForm: DataInputForm,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;
  const Form = DataInputForm || "div";
  return (
    <FormikMergeCreateDialog
      open={open}
      entityModel={entityModel}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth={maxWidth}
      onSubmit={onSave}
      onClose={onClose}
    >
      <Form entity={entity} apiUrl={apiUrl} />
    </FormikMergeCreateDialog>
  );
};

const EditDialog = (props) => {
  const {
    open,
    onClose,
    onSave,
    entityModel,
    apiUrl,
    entity,
    normalizeReceivedValues,
    initialValues,
    validationSchema,
    maxWidth,
    dataInputForm: DataInputForm,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      apiService
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    loaded && (
      <FormikEditDialog
        open={open}
        entityModel={entityModel}
        initialValues={entityValues}
        validationSchema={validationSchema}
        maxWidth={maxWidth}
        onSubmit={onSave}
        onClose={onClose}
      >
        <DataInputForm entity={entityValues} apiUrl={apiUrl} />
      </FormikEditDialog>
    )
  );
};

const DeleteDialog = (props) => {
  const {
    onClose,
    onConfirm,
    entityModel,
    entity,
    initialValues = {},
    open,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;

  const [entityName, setEntityName] = useState("");

  useEffect(() => {
    if (entity) {
      setEntityName(
        entity?.expenseId ||
          entity?.invoiceId ||
          entity?.nameTc ||
          entity?.name ||
          entity?.type ||
          entity?.resident?.lastnameTc + entity?.resident?.firstnameTc ||
          entity?.refNo ||
          entity?.day ||
          entity?.item ||
          entity?.code ||
          entity?.id
      );
    }
  }, [entity]);

  return (
    <FormikDeleteDialog
      open={open}
      entityModel={entityModel}
      entity={entity}
      initialValues={initialValues}
      maxWidth="sm"
      onSubmit={onConfirm}
      onClose={onClose}
    >
      注意！<strong>{entityName}</strong>及其相關資料將會被刪除
    </FormikDeleteDialog>
  );
};

const CopyDialog = (props) => {
  const {
    open,
    onClose,
    onSave,
    entityModel,
    apiUrl,
    entity,
    normalizeReceivedValues,
    normalizeCopyValues,
    initialValues,
    validationSchema,
    maxWidth,
    dataInputForm: DataInputForm,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      apiService
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          normalizeCopyValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    loaded && (
      <FormikCopyDialog
        open={open}
        entityModel={entityModel}
        initialValues={entityValues}
        validationSchema={validationSchema}
        maxWidth={maxWidth}
        onSubmit={onSave}
        onClose={onClose}
      >
        <DataInputForm entity={entityValues} apiUrl={apiUrl} />
      </FormikCopyDialog>
    )
  );
};

const MergePrintDialog = (props) => {
  const {
    open,
    onClose,
    onSave,
    entityModel,
    apiUrl,
    button,
    entity,
    initialValues,
    validationSchema,
    maxWidth,
    dataInputForm: DataInputForm,
    dialogId = _uniqueId("dialog"),
    ...rest
  } = props;

  const Form = DataInputForm || "div";

  return (
    <FormikMergePrintDialog
      open={open}
      entityModel={entityModel}
      initialValues={initialValues}
      validationSchema={validationSchema}
      maxWidth={maxWidth}
      onSubmit={onSave}
      onClose={onClose}
      button={button}
    >
      <Form entity={entity} apiUrl={apiUrl} />
    </FormikMergePrintDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export function EntityManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    pageHeader: PageHeader,
    pageHeaderProps,
    title,
    editableFields,
    entityModel,
    entityPrintTitle,
    filteringParams,
    createParams,
    createFilteringParams,
    normalizeReceivedValues,
    normalizeCopyValues,
    initialValues,
    validationSchema,
    mergePrintInitialValues,
    mergePrintValidationSchema,
    maxWidth,
    dataInputForm,
    mergeInputForm,
    mergePrintForm,
    filterModel,
    apiUrl,
    columns,
    disableCreate,
    disableEdit,
    disableDelete,
    getColumnSettings,
    defaultPageSize,
    defaultSorting,
    rowHeight,
    type,
    checkboxSelection,
    onRowSelected,
    hide,
    homeStat,
    sortModel,
    printButton,
    setSelectionsList,
    reloadPageHeader,
    setReloadPageHeader,
    listDetailsVariation,
  } = props;
  const [creating, setCreating] = useState(false);
  const [mergeCreating, setMergeCreating] = useState(false);
  const [mergePrinting, setMergePrinting] = useState(false);
  const [mergePrintingEntity, setMergePrintingEntity] = useState(false);
  const [createEntity, setCreateEntity] = useState(null);
  const [mergeCreateEntity, setMergeCreateEntity] = useState(null);
  const [editingEntity, setEditingEntity] = useState(null);
  const [deletingEntity, setDeletingEntity] = useState(null);
  const [copyEntity, setCopyEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize || 20);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [documentRows, setDocumentRows] = useState([]);
  const [folderRows, setFolderRows] = useState([]);
  const [sort, setSort] = useState(defaultSorting || "createdAt,desc");
  const [listEmpty, setListEmpty] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const getAppropriateEntities = () => {
    if (listDetailsVariation == "document-folder") {
      getDocumentAndFolderEntities(
        setLoading,
        createFilteringParams,
        coreCreateFilteringParams,
        apiUrl,
        setDocumentRows,
        setFolderRows,
        filteringParams,
        setListEmpty,
      );
    } else {
      getEntities(
        setLoading,
        createFilteringParams,
        coreCreateFilteringParams,
        apiUrl,
        setTotalRows,
        setRows,
        filteringParams,
        setListEmpty
      );
    }
  }

  const coreCreateParams = (values) => {
    let params = {};

    (editableFields || []).map((field) => {
      params[field] = values[field];
    });

    return params;
  };

  const coreCreateFilteringParams = (values) => {
    let params = {};

    for (var propName in values) {
      if (
        values[propName] !== undefined &&
        values[propName] !== null &&
        values[propName] !== ""
      ) {
        params[propName] = values[propName];
      }
    }

    params.page = page;
    params.size = pageSize;
    params.sort = sort;

    return params;
  };

  const coreNormalizeReceivedValues = (values) => {
    if (normalizeReceivedValues) {
      normalizeReceivedValues(values);
    }
  };

  const coreNormalizeCopyValues = (values) => {
    if (normalizeCopyValues) {
      normalizeCopyValues(values);
    }
  };

  const onDelete = (values, formikActions) => {
    console.debug("setReloadPageHeader is:", setReloadPageHeader);
    console.debug("reloadPageHeader is:", reloadPageHeader);
    apiService
      .delete(
        (apiUrl.delete ? apiUrl.delete : apiUrl) + "/" + deletingEntity.id
      )
      .then(function (response) {
        dispatch(NotificationActions.success(null, "已刪除"));
        setLoading(false);
        if (setReloadPageHeader) setReloadPageHeader(!reloadPageHeader);
        setDeletingEntity(null);
        formikActions.setSubmitting(false);
        // getEntities(
        //   setLoading,
        //   createFilteringParams,
        //   coreCreateFilteringParams,
        //   apiUrl,
        //   setTotalRows,
        //   setRows,
        //   filteringParams
        // );
        getAppropriateEntities();
      })
      .catch(function (error) {
        //         console.log(error.response);
        // //					"Cannot delete, wistband user exist"

        //         if (error?.response?.data?.errors){
        //         let res = error?.response?.data?.errors[0];
        //         let message = "";

        //         if(res.includes("staffUsers exist")){
        //           message = "此用戶群組有用戶正在使用，不能刪除";
        //         }

        //         if(res.includes("Cannot delete, wistband user exist")){
        //           message = "此手帶有用戶正在使用，不能刪除";
        //         }

        //         message==""?message="hihi":message;

        //           dispatch(NotificationActions.error(null,message
        if (error.response.data.errors) {
          let normalError = true;
          ERROR_STATUS.map((err) => {
            if (error.response.data.errors[0] == err.errorResponse) {
              dispatch(NotificationActions.error(null, err.message));
              normalError = false;
            }
          });
          if (normalError == true) {
            dispatch(NotificationActions.error(null, "不能刪除"));
          }
        } else {
          dispatch(NotificationActions.error(null, "不能刪除"));
        }
        formikActions.setSubmitting(false);
        setLoading(false);
      });
  };

  const onCreate = (values, formikActions) => {
    // queue list of different params set to be post
    const paramsPostQueue = [];

    const params = createParams(values, coreCreateParams);

    if (params.bloodSugarRecords) {
      // if there is blood sugar records field (from BloodSugarForm)
      params.bloodSugarRecords.forEach(record => {
        const thisParams = {...params};
        // only the sampling time at the record time of blood sugar level is set to true, others sampling time field set to false
        SAMPLE_TIMES.forEach(sampleTime => thisParams[sampleTime] = (sampleTime === record.time));

        thisParams.bloodSugar = record.bloodSugar;

        paramsPostQueue.push(thisParams)
      });
    } else {
      paramsPostQueue.push(params);
    }

    // call http post for each params set in paramsPostQueue
    paramsPostQueue.forEach(params => {
      apiService
      .post(
        apiUrl.create ? apiUrl.create : apiUrl,
        params
      )
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setCreating(false);
        if (setReloadPageHeader) setReloadPageHeader(!reloadPageHeader);
        formikActions.setSubmitting(false);
        // getEntities(
        //   setLoading,
        //   createFilteringParams,
        //   coreCreateFilteringParams,
        //   apiUrl,
        //   setTotalRows,
        //   setRows,
        //   filteringParams,
        //   setListEmpty
        // );
        getAppropriateEntities();
      })
      .catch((error) => {
        if (
          error.response?.data?.errors &&
          error.response?.data?.errors[0].includes("Username exists")
        ) {
          dispatch(NotificationActions.error(null, "用戶名稱已被使用"));
        } else if (
          error.response?.data?.errors &&
          error.response?.data?.errors[0].includes("hkid invalid")
        ) {
          dispatch(NotificationActions.error(null, "身份證號碼不正確"));
        } else if (
          (error.response?.data?.errors &&
            error.response?.data?.errors[0] === "code: is existed") ||
          error.response?.data?.errors[0] === "namePackageDose: is existed"
        ) {
          dispatch(
            NotificationActions.error(
              null,
              "不能使用重覆的 藥物編號 或 藥物英文名稱 - 包裝 - 劑量"
            )
          );
        } else {
          dispatch(NotificationActions.error(null, "錯誤"));
        }
        formikActions.setSubmitting(false);
      });
    });
  };

  const onMergeCreate = (values, formikActions) => {
    // queue list of different params set to be post
    const paramsPostQueue = [];

    const params = createParams(values, coreCreateParams);

    console.debug("on merge create params:", params);

    if (params.bloodSugarRecords) {
      // if there is blood sugar records field (from BloodSugarForm)
      params.bloodSugarRecords.forEach(record => {
        const thisParams = {};
        // only the sampling time at the record time of blood sugar level is set to true, others sampling time field set to false
        SAMPLE_TIMES.forEach(sampleTime => thisParams[sampleTime] = (sampleTime === record.time));

        thisParams.bloodSugar = record.bloodSugar;

        // copy other blood sugar form related fields
        [
          'recordDate',
          'remarks',
          'resident',
        ].forEach(fieldName => thisParams[fieldName] = params[fieldName]);
        // end of copy other blood sugar form related fields

        paramsPostQueue.push(thisParams)
      });

      // queueing fields in forms other than blood sugar form fields
      const paramsWithoutBloodSugarFormFields = {...params};
      [
        ...SAMPLE_TIMES,
        ...SAMPLE_TIMES.map(time => `${time}BloodSugar`),
        'bloodSugar',
        'bloodSugarRecords',
      ].forEach(fieldName => {
        delete paramsWithoutBloodSugarFormFields[fieldName];
      });
      paramsPostQueue.push(paramsWithoutBloodSugarFormFields);
      // end of queueing fields in forms other than blood sugar form fields
    } else {
      paramsPostQueue.push(params);
    }

    paramsPostQueue.forEach(params => {
      apiService
      .post(
        apiUrl.create ? apiUrl.create : apiUrl,
        params
      )
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setMergeCreating(false);
        formikActions.setSubmitting(false);
        // getEntities(
        //   setLoading,
        //   createFilteringParams,
        //   coreCreateFilteringParams,
        //   apiUrl,
        //   setTotalRows,
        //   setRows,
        //   filteringParams
        // );
        getAppropriateEntities();
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    });
  };

  const onEdit = (values, formikActions) => {
    apiService
      .put(
        (apiUrl.edit ? apiUrl.edit : apiUrl) + "/" + values.id,
        createParams(values, coreCreateParams)
      )
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditingEntity(null);
        formikActions.setSubmitting(false);
        // getEntities(
        //   setLoading,
        //   createFilteringParams,
        //   coreCreateFilteringParams,
        //   apiUrl,
        //   setTotalRows,
        //   setRows,
        //   filteringParams
        // );
        getAppropriateEntities();
      })
      .catch((error) => {
        if (
          error.response.data?.errors &&
          error.response.data?.errors[0].includes("Username exists")
        ) {
          dispatch(NotificationActions.error(null, "用戶名稱已被使用"));
        } else if (
          error.response.data?.errors &&
          error.response.data?.errors[0].includes("hkid invalid")
        ) {
          dispatch(NotificationActions.error(null, "身份證號碼不正確"));
        } else {
          dispatch(NotificationActions.error(null, "錯誤"));
        }
        formikActions.setSubmitting(false);
      });
  };

  const onCopy = (values, formikActions) => {
    apiService
      .post(
        apiUrl.copy ? apiUrl.copy : apiUrl,
        createParams(values, coreCreateParams)
      )
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setCopyEntity(null);
        formikActions.setSubmitting(false);
        // getEntities(
        //   setLoading,
        //   createFilteringParams,
        //   coreCreateFilteringParams,
        //   apiUrl,
        //   setTotalRows,
        //   setRows,
        //   filteringParams
        // );
        getAppropriateEntities();
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  const onExport = (values, formikActions) => {
    getExport();
  };

  const onExportWeight = (values, formikActions) => {
    getExportWeight();
  };

  const onPrint = (values, formikActions) => {
    apiService
      .get(apiUrl.print ? apiUrl.print + "/" + values.id : apiUrl, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        setLoading(false);
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") +
            "_" +
            entityModel +
            "_print.docx"
        );
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  const onPrintChecked = (values, formikActions) => {
    selectionModel &&
      selectionModel.map((item) => {
        apiService
          .get(apiUrl.print ? apiUrl.print + "/" + item : apiUrl, {
            responseType: "blob",
          })
          .then(function (response) {
            let data = response.data;
            setLoading(false);
            fileDownload(
              data,
              moment().format("YYYY-MM-DD_HH_mm_SS") +
                "_" +
                entityModel +
                "_print.docx"
            );
          })
          .catch(function (response) {
            setLoading(false);
          });
      });
  };

  const onMergePrint = (values, formikActions) => {
    if (apiUrl.mergePrint) {
      apiService
        .get(
          apiUrl.mergePrint
            ? apiUrl.mergePrint + "/" + values.resident
            : apiUrl,
          {
            params: {
              startDate: moment(values.startDate).format("YYYY-MM-DD"),
              endDate: moment(values.endDate).format("YYYY-MM-DD"),
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          let data = response.data;
          dispatch(NotificationActions.success(null, "成功"));
          setMergePrinting(false);
          formikActions.setSubmitting(false);
          fileDownload(
            data,
            moment().format("YYYY-MM-DD_HH_mm_SS") +
              "_" +
              entityModel +
              "_print.docx"
          );
        })
        .catch((error) => {
          dispatch(NotificationActions.error(null, "錯誤"));
          formikActions.setSubmitting(false);
        });
    }
    if (apiUrl.medicinePrint) {
      let dayofmonth = moment(values.month).daysInMonth();
      apiService
        .get(
          apiUrl.medicinePrint
            ? apiUrl.medicinePrint + "/" + values.resident
            : apiUrl,
          {
            params: {
              // startDate: moment(values.month).add(-1, "months").format("YYYY-MM-21"),
              startDate: moment(values.month).format("YYYY-MM-01"),
              // endDate: moment(values.month).format("YYYY-MM-20"),
              endDate: moment(values.month).format("YYYY-MM-") + dayofmonth,
              type: values.type,
              status: values.status,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          let data = response.data;
          dispatch(NotificationActions.success(null, "成功"));
          setMergePrinting(false);
          formikActions.setSubmitting(false);
          fileDownload(
            data,
            moment().format("YYYY-MM-DD_HH_mm_SS") +
              "_" +
              entityModel +
              "_print.docx"
          );
        })
        .catch((error) => {
          dispatch(NotificationActions.error(null, "錯誤"));
          formikActions.setSubmitting(false);
        });
    }
  };

  /*const getColumnSettings = (columns, disableEdit, disableDelete) => {
      let newColumns = columns.slice(0);

      if (!disableEdit || !disableDelete) {
          newColumns.unshift({
              field: 'action', headerName: '動作', width: 100, sortable: false, filterable: false, renderCell: (params) => {
                  return <div>
                      {!disableEdit &&
                          <IconButton color="default" size="small" onClick={() => { dispatch(NotificationActions.clear()); setEditingEntity(params.row); }} disabled={loading}>
                              <EditOutlinedIcon />
                          </IconButton>}
                      {!disableDelete &&
                          <IconButton color="default" size="small" onClick={() => { dispatch(NotificationActions.clear()); setDeletingEntity(params.row); }} disabled={loading}>
                              <DeleteOutlinedIcon />
                          </IconButton>}
                  </div>;
              }
          });

          return newColumns;
      }

      return newColumns;
  }*/

  const clearNotification = () => {
    dispatch(NotificationActions.clear());
  };

  const handlePageChange = (params) => {
    setPage(params.page || params);
  };

  const handlePageSizeChange = (params) => {
    setPage(0);
    setPageSize(params.pageSize || params);
  };

  const handleSortChange = (params) => {
    if (params.sortModel.length === 1) {
      setSort(params.sortModel[0].field + "," + params.sortModel[0].sort);
    } else {
      setSort(defaultSorting);
    }
  };

  // const getEntities = () => {
  //     setLoading(true);

  //     const getFilteringParams = createFilteringParams ? createFilteringParams : coreCreateFilteringParams;

  //     let params = getFilteringParams(filteringParams, coreCreateFilteringParams);
  //     delete params.timestamp; // remove timestamp when getting records

  //     apiUrl.getAllDetail ?
  //         apiService.get((apiUrl.getAllDetail ? apiUrl.getAllDetail : apiUrl), { params: params }).then(function (response) {
  //             let data = response.data;
  //             setTotalRows(data?.rosterSessions?.length);
  //             setRows(data?.rosterSessions || []);
  //             setLoading(false);
  //         }).catch(function (response) {
  //             setLoading(false);
  //         })
  //         :
  //         apiService.get((apiUrl.getAll ? apiUrl.getAll : apiUrl), { params: params }).then(function (response) {
  //             let data = response.data;
  //             setTotalRows(data.totalElements);
  //             setRows(data?.content || []);
  //             setLoading(false);
  //         }).catch(function (response) {
  //             setLoading(false);
  //         });
  // }

  const getExport = () => {
    setLoading(true);

    const getFilteringParams = createFilteringParams
      ? createFilteringParams
      : coreCreateFilteringParams;

    let params = getFilteringParams(filteringParams, coreCreateFilteringParams);
    delete params.timestamp; // remove timestamp when getting records

    apiService
      .get(apiUrl.export ? apiUrl.export : apiUrl, {
        params: params,
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        //setTotalRows(data.totalElements);
        //setRows(data?.content || []);
        setLoading(false);
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") +
            "_" +
            entityModel +
            "_export.xlsx"
        );
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  const getExportWeight = () => {
    setLoading(true);
    const getFilteringParams = createFilteringParams
      ? createFilteringParams
      : coreCreateFilteringParams;

    let params = getFilteringParams(filteringParams, coreCreateFilteringParams);
    delete params.timestamp; // remove timestamp when getting records

    apiService
      .get(apiUrl.exportWeight ? apiUrl.exportWeight : apiUrl, {
        params: params,
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        //setTotalRows(data.totalElements);
        //setRows(data?.content || []);
        setLoading(false);
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_體重報告_export.xlsx"
        );
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (listDetailsVariation == "document-folder") {
      getDocumentAndFolderEntities(
        setLoading,
        createFilteringParams,
        coreCreateFilteringParams,
        apiUrl,
        setDocumentRows,
        setFolderRows,
        filteringParams,
        setListEmpty,
        props.currentFolderId,
      );
    } else {
      getEntities(
        setLoading,
        createFilteringParams,
        coreCreateFilteringParams,
        apiUrl,
        setTotalRows,
        setRows,
        filteringParams,
        setListEmpty
      );
    }
  }, [apiUrl, filteringParams, page, pageSize, sort]);

  return (
    <Fragment>
      <PageHeader
        setCreating={setCreating}
        setMergeCreating={setMergeCreating}
        setMergePrinting={setMergePrinting}
        onExport={onExport}
        onExportWeight={onExportWeight}
        onPrint={onPrint}
        onPrintChecked={onPrintChecked}
        clearNotification={clearNotification}
        listEmpty={listEmpty}
        {...pageHeaderProps}
      />
      {/*<PageHeader title={title}>
              {(!creating && !editingEntity && !deletingEntity) && <Notification />}
              {!disableCreate && (
                  <Button
                      variant="contained"
                      color="primary"
                      className={classes.toolbarButton}
                      startIcon={<AddCircleOutlinedIcon />}
                      onClick={() => { setCreating(true) }}
                  >
                      <FormattedMessage id="entity.create" values={{ entityModel: entityModel }} />
                  </Button>
              )}

          </PageHeader>*/}

      {/* <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="custom pagination table">
                  <TableHead>
                      {abc.map(item => (
                      <TableCell component="th" scope="row">
                          {item}
                      </TableCell>
                      ))}
                  </TableHead>
                  <TableBody>
                  {fff &&
                                  fff.length > 0 && (
                      <TableRow>
                      {fff.map(item => (
                          <TableCell component="th" scope="row">
                              {item.name}
                          </TableCell>
                          ))}
                      </TableRow>
                                  )}
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                          <TablePagination
                              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                              colSpan={3}
                              count={rows.length}
                              rowsPerPage={[5, 10, 20, 50]}
                              page={page}
                              SelectProps={{
                                  inputProps: { 'aria-label': 'rows per page' },
                                  native: true,
                              }}
                              onPageChange={handlePageChange}
                          //onRowsPerPageChange={handleChangeRowsfsPerPage}
                          //ActionsComponent={TablePaginationActions}
                          />
                      </TableRow>
                  </TableFooter>
              </Table>
          </TableContainer> */}
      {!listDetailsVariation &&
        <DataGrid
          rows={rows}
          autoHeight={true}
          density="compact"
          disableSelectionOnClick={true}
          columns={getColumnSettings({
            setEditingEntity,
            setDeletingEntity,
            setCopyEntity,
            onPrint,
            onPrintChecked,
            onMergePrint,
            clearNotification,
            loading,
            homeStat,
          })}
          page={page}
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          rowsPerPageOptions={[5, 10, 20, 50]}
          sortingMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSortModelChange={handleSortChange}
          onSelectionModelChange={(item) => {
            setSelectionModel(item.selectionModel);
            setSelectionsList(item);
          }}
          selectionModel={selectionModel}
          disableColumnMenu
          checkboxSelection={checkboxSelection}
          loading={loading}
          scrollbarSize={50}
          filterModel={filterModel}
          rowHeight={rowHeight}
          type={type}
          onRowSelected={onRowSelected}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "每頁行數",
              labelDisplayedRows: ({ from, to, count }) =>
                `正在顯示 ${from} - ${to} 個項目, 共 ${count} 個`,
            },
          }}
          localeText={{
            noRowsLabel: "沒有任何資料",
            footerRowSelected: (count) =>
              `已選擇 ${count.toLocaleString()} 個項目`,
          }}
        />
      }
      {listDetailsVariation == "document-folder" &&
        <>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ marginTop: "30px", marginBottom: "10px" }}
          >
            文件夾
          </Typography>
          <DataGrid
            rows={folderRows}
            autoHeight={true}
            density="compact"
            disableSelectionOnClick={true}
            columns={getColumnSettings({
              setEditingEntity,
              setDeletingEntity,
              setCopyEntity,
              onPrint,
              onPrintChecked,
              onMergePrint,
              clearNotification,
              loading,
              homeStat,
            })}
            // page={page}
            // pageSize={pageSize}
            // rowCount={folderRows.length}
            // paginationMode="server"
            // rowsPerPageOptions={[5, 10, 20, 50]}
            // sortingMode="server"
            // onPageChange={handlePageChange}
            // onPageSizeChange={handlePageSizeChange}
            // onSortModelChange={handleSortChange}
            onSelectionModelChange={(item) => {
              setSelectionModel(item.selectionModel);
              setSelectionsList(item);
            }}
            selectionModel={selectionModel}
            disableColumnMenu
            checkboxSelection={checkboxSelection}
            loading={loading}
            scrollbarSize={50}
            filterModel={filterModel}
            rowHeight={rowHeight}
            type={type}
            onRowSelected={onRowSelected}
            // componentsProps={{
            //   pagination: {
            //     labelRowsPerPage: "每頁行數",
            //     labelDisplayedRows: ({ from, to, count }) =>
            //       `正在顯示 ${from} - ${to} 個項目, 共 ${count} 個`,
            //   },
            // }}
            // localeText={{
            //   noRowsLabel: "沒有任何資料",
            //   footerRowSelected: (count) =>
            //     `已選擇 ${count.toLocaleString()} 個項目`,
            // }}
          />
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ marginTop: "30px", marginBottom: "10px" }}
          >
            文件
          </Typography>
          <DataGrid
            rows={documentRows}
            autoHeight={true}
            density="compact"
            disableSelectionOnClick={true}
            columns={getColumnSettings({
              setEditingEntity,
              setDeletingEntity,
              setCopyEntity,
              onPrint,
              onPrintChecked,
              onMergePrint,
              clearNotification,
              loading,
              homeStat,
            })}
            // page={page}
            // pageSize={pageSize}
            // rowCount={documentRows.length}
            // paginationMode="server"
            // rowsPerPageOptions={[5, 10, 20, 50]}
            // sortingMode="server"
            // onPageChange={handlePageChange}
            // onPageSizeChange={handlePageSizeChange}
            // onSortModelChange={handleSortChange}
            onSelectionModelChange={(item) => {
              setSelectionModel(item.selectionModel);
              setSelectionsList(item);
            }}
            selectionModel={selectionModel}
            disableColumnMenu
            checkboxSelection={checkboxSelection}
            loading={loading}
            scrollbarSize={50}
            filterModel={filterModel}
            rowHeight={rowHeight}
            type={type}
            onRowSelected={onRowSelected}
            // componentsProps={{
            //   pagination: {
            //     labelRowsPerPage: "每頁行數",
            //     labelDisplayedRows: ({ from, to, count }) =>
            //       `正在顯示 ${from} - ${to} 個項目, 共 ${count} 個`,
            //   },
            // }}
            // localeText={{
            //   noRowsLabel: "沒有任何資料",
            //   footerRowSelected: (count) =>
            //     `已選擇 ${count.toLocaleString()} 個項目`,
            // }}
          />
        </>
      }
      {props.children}
      <CreateDialog
        open={creating}
        entityModel={entityModel}
        entity={createEntity}
        apiUrl={apiUrl}
        initialValues={initialValues}
        validationSchema={validationSchema}
        dataInputForm={dataInputForm}
        maxWidth={maxWidth}
        onClose={() => {
          setCreateEntity(null);
          setCreating(false);
        }}
        onSave={onCreate}
      />
      <MergeCreateDialog
        open={mergeCreating}
        entityModel={entityModel}
        entity={mergeCreateEntity}
        apiUrl={apiUrl}
        initialValues={initialValues}
        validationSchema={validationSchema}
        dataInputForm={mergeInputForm}
        maxWidth="lg"
        onClose={() => {
          setMergeCreateEntity(null);
          setMergeCreating(false);
        }}
        onSave={onMergeCreate}
      />
      <EditDialog
        open={editingEntity !== null}
        entityModel={entityModel}
        apiUrl={apiUrl}
        entity={editingEntity}
        normalizeReceivedValues={coreNormalizeReceivedValues}
        initialValues={initialValues}
        validationSchema={validationSchema}
        dataInputForm={dataInputForm}
        maxWidth={maxWidth}
        onClose={() => {
          setEditingEntity(null);
          setLoading(false);
        }}
        onSave={onEdit}
      />
      <DeleteDialog
        open={deletingEntity !== null}
        entityModel={entityModel}
        apiUrl={apiUrl}
        entity={deletingEntity}
        onClose={() => {
          setDeletingEntity(null);
          setLoading(false);
        }}
        onConfirm={onDelete}
      />
      <CopyDialog
        open={copyEntity !== null}
        entityModel={entityModel}
        apiUrl={apiUrl}
        entity={copyEntity}
        normalizeReceivedValues={coreNormalizeReceivedValues}
        normalizeCopyValues={coreNormalizeCopyValues}
        initialValues={initialValues}
        validationSchema={validationSchema}
        dataInputForm={dataInputForm}
        maxWidth={maxWidth}
        onClose={() => {
          setCopyEntity(null);
          setLoading(false);
        }}
        onSave={onCopy}
      />
      <MergePrintDialog
        open={mergePrinting}
        entityModel={entityPrintTitle}
        entity={mergePrintingEntity}
        apiUrl={apiUrl}
        initialValues={mergePrintInitialValues}
        validationSchema={mergePrintValidationSchema}
        dataInputForm={mergePrintForm}
        maxWidth="xs"
        onClose={() => {
          setMergePrinting(false);
          setMergePrintingEntity(null);
          setLoading(false);
        }}
        onSave={onMergePrint}
        button={printButton}
      />
    </Fragment>
  );
}
