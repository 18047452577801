import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { API_URL, ROUTES, FOLLOW_UP_STATUS_OPTION } from "../../../constants";
import { useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { FormikDialog } from "../../../components/dialogs";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import axios from "axios";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

export const initialValues = {
  resident: "",
  date: moment(new Date()).toISOString(),
  institution: "",
  specialist: "",
  medicalFollowUpItems: "",
  transport: "",
  escorterName: "",
  remarks: "",
  status: "",
  finishDate: "",
  nextDate: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  date: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  institution: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  specialist: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function EditDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"修改覆診"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="md"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                院友資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    disabled
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="覆診狀態"
                    name="status"
                    options={FOLLOW_UP_STATUS_OPTION}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginBottom: 15 }}
              >
                覆診資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikDateTimeField
                    required
                    label="覆診日期及時間"
                    name="date"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="醫院 / 診所"
                    name="institution"
                    apiUrl={"/api/institutions"}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) =>
                      option.code
                        ? option.code + " - " + option.nameTc
                        : option.nameTc
                    }
                    getOptionValue={(option) => option?.id}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="專科"
                    name="specialist"
                    apiUrl={"/api/specialists"}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) =>
                      option.code + " - " + option.nameTc
                    }
                    getOptionValue={(option) => option?.id}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <fieldset>
                    <FormLabel component="legend">覆診事項</FormLabel>
                    <FieldArray
                      name="medicalFollowUpItems"
                      render={(arrayHelpers) => (
                        <div>
                          <TableContainer style={{ maxHeight: 400 }}>
                            <Table
                              stickyHeader
                              padding="none"
                              style={{ marginBottom: 15 }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    omponent="th"
                                    style={{ padding: 10 }}
                                  >
                                    項目
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: 50 }}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.medicalFollowUpItems &&
                                  values.medicalFollowUpItems.map(
                                    (item, index) => (
                                      <TableRow key={index} disablegutters>
                                        <TableCell style={{ paddingRight: 1 }}>
                                          <FormikReferenceField
                                            edit={entity ? true : false}
                                            label="項目"
                                            apiUrl={
                                              API_URL.MEDICAL_FOLLOW_UP_ITEM
                                            }
                                            getOptionLabel={(option) =>
                                              option?.nameTc
                                            }
                                            getOptionValue={(option) =>
                                              option?.id
                                            }
                                            apiParams={{
                                              unpaged: true,
                                            }}
                                            name={`medicalFollowUpItems.${index}.id`}
                                          />
                                        </TableCell>
                                        <TableCell align="right">
                                          <IconButton
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <DeleteOutlinedIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Button
                            color="default"
                            variant="contained"
                            type="button"
                            size="small"
                            onClick={() => arrayHelpers.push("")}
                          >
                            新增
                          </Button>
                        </div>
                      )}
                    />
                  </fieldset>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    label="交通工具"
                    name="transport"
                    apiUrl={"/api/transport"}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) => option.nameTc}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikTextField label="陪診員" name="escorterName" />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    label="關係"
                    name="escorterRelationship"
                    apiUrl={"/api/escorterRelationship"}
                    apiParams={{
                      unpaged: true,
                    }}
                    getOptionLabel={(option) => option.nameTc}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikTextField label="備註" name="remarks" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {values.status == "COMPLETED" && (
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  下次覆診資料
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormikDateTimeField
                      label="下次覆診日期及時間"
                      name="nextDate"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
