export default {
    root: {
        padding: '0px 15px 15px'
    },
    spacing: {
        '& > :not(:first-child)': {
            marginLeft: '15px'
        }
    }
};
