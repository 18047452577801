import * as Yup from "yup";
import moment from "moment";
import "moment-lunar";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import HotelIcon from "@material-ui/icons/Hotel";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikHKIDField from "../../../components/inputs/FormikHKIDField";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import {
  API_URL,
  EDUCATIONAL_BACKGROUND_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  GENDER_OPTIONS,
  CHECK_IN_STATUS_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";
import { NotificationActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import { ChangeBedDialog } from "./personalData/ChangeBedDialog";
import { ChangeHomeDialog } from "./personalData/ChangeHomeDialog";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FormikUploadField from "../../../components/inputs/FormikUploadField"
import Typography from "@material-ui/core/Typography";

const initialValues = {
  lastnameTc: "",
  firstname: "",
  lastname: "",
  firstnameTc: "",
  dateOfBirth: "",
  gender: "",
  hkid: "",
  generatedId: "",
  nationality: "",
  maritalStatus: "",
  educationalBackground: "",
  ldsNo: "",
  evaluationResult: "",
  evaluationDate: "",
  checkInStatus: "",
  bedId: "",
  residentBedType: "",
  checkInDate: "",
  vaccinationUrl: "",
};

const validationSchema = Yup.object().shape({
  lastnameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  firstname: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  lastname: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  firstnameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dateOfBirth: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  gender: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // hkid: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  //generatedId: Yup.string()
  //    .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // nationality: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // maritalStatus: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // educationalBackground: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  //status: Yup.string()
  //    .required(<FormattedMessage id="validation.message.required" />).nullable(),
  //checkInDate: Yup.date()
  //    .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable(),
  //bedId: Yup.string()
  //    .required(<FormattedMessage id="validation.message.required" />).nullable(),
  residentBedType: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // ldsNo: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationResult: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationDate: Yup.date()
  //     .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable()
});

export const PersonalDataFormContent = (props) => {
  const { entity, getEntity, ...rest } = props;
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
    initialValues,
  } = useFormikContext();
  const [changeBedOpen, setChangeBedOpen] = useState(false);
  const [changeHomeOpen, setChangeHomeOpen] = useState(false);
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);

  const setLunarDateOfBirthANdAge = (value) => {
    setFieldValue(
      "lunarDateOfBirth",
      value ? moment(value).lunar().format("YYYY-MM-DD") : ""
    );
    setFieldValue("age", "" + moment().diff(value, "years", false));
  };

  const handleDateOfBirthChange = (value) => {
    setFieldValue("dateOfBirth", value);
    setLunarDateOfBirthANdAge(value);
  };

  const onChangeHome = (values, actions) => {
    axios
      .post(API_URL.RESIDENT + "/residentChangeHome", {
        resident: entity.id,
        newBed: values.newBed,
        home: values.home,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已轉換"));
        getEntity();
        actions.setSubmitting(false);
        setChangeHomeOpen(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onChangeBed = (values, actions) => {
    axios
      .post(API_URL.RESIDENT + "/residentChangeBed", {
        resident: entity.id,
        newBed: values.newBed,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已轉換"));
        getEntity();
        actions.setSubmitting(false);
        setChangeBedOpen(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    resetForm({ values: { ...initialValues, ...entity } });
    setLunarDateOfBirthANdAge(entity.dateOfBirth);
  }, [entity]);

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Button
              style={{ marginBottom: 5 }}
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              startIcon={<HomeWorkIcon />}
              onClick={() => {
                setChangeHomeOpen(true);
              }}
            >
              轉院
            </Button>
          </Grid>
          <Grid item md={8} xs={12}>
            <fieldset>
              <FormLabel component="legend">個人資料</FormLabel>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    label="中文姓氏"
                    name="lastnameTc"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    label="中文名字"
                    name="firstnameTc"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField required label="英文姓氏" name="lastname" />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField required label="英文名字" name="firstname" />
                </Grid>
                <Grid item sm={5} xs={12}>
                  <FormikDateField
                    required
                    label="出生日期"
                    name="dateOfBirth"
                    onChange={handleDateOfBirthChange}
                  />
                </Grid>
                <Grid item sm={5} xs={12}>
                  <FormikDateField
                    disabled
                    label="農曆出生日期"
                    name="lunarDateOfBirth"
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <FormikTextField disabled label="年齡" name="age" />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikSelectField
                    required
                    label="性別"
                    name="gender"
                    options={GENDER_OPTIONS}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikHKIDField label="身份證號碼" name="hkid" />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikTextField
                    label="其他證明文件號碼"
                    name="homeReturnPermitId"
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <FormikTextField
                    required
                    disabled
                    label="院友編號"
                    name="generatedId"
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikTextField label="國籍" name="nationality" />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikSelectField
                    label="婚姻狀況"
                    name="maritalStatus"
                    options={MARITAL_STATUS_OPTIONS}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FormikSelectField
                    label="文化程度"
                    name="educationalBackground"
                    options={EDUCATIONAL_BACKGROUND_OPTIONS}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Typography style={{fontSize: 12, color: "rgba(0, 0, 0, 0.54)"}}>
                  針卡圖片
                </Typography>
                <FormikUploadField
                  name="vaccinationUrl"
                  policy="VACCINATION_PHOTO"
                />
              </Grid>
            </fieldset>
          </Grid>
          <Grid item md={4} xs={12}>
            <fieldset style={{ marginBottom: 15 }}>
              <FormLabel component="legend">床位資料</FormLabel>
              <Grid container spacing={2} style={{ marginBottom: 15 }}>
                <Grid item sm={12} xs={12}>
                  <FormikSelectField
                    required
                    disabled
                    label="入住狀態"
                    name="checkInStatus"
                    options={CHECK_IN_STATUS_OPTIONS}
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <FormikTextField
                    required
                    disabled
                    label="床位號碼"
                    name="bedId"
                    value={values?.bed?.bedNo}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={6}
                  style={{ alignSelf: "center", marginTop: 4 }}
                  align="right"
                >
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={<HotelIcon />}
                    onClick={() => {
                      setChangeBedOpen(true);
                    }}
                  >
                    轉換床位
                  </Button>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="床位類別"
                    name="residentBedType"
                    apiUrl={"/api/residentBedTypes"}
                    getOptionLabel={(option) => option.type}
                    apiParams={{
                      unpaged: true,
                    }}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikDateField
                    required
                    label="入住日期"
                    name="checkInDate"
                  />
                </Grid>
              </Grid>
            </fieldset>
            <fieldset>
              <FormLabel component="legend">評核資料</FormLabel>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikTextField label="LDS編號" name="ldsNo" />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikSelectField
                    label="評核結果"
                    name="evaluationResult"
                    options={EVALUATION_RESULT_OPTION}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikDateField label="評核日期" name="evaluationDate" />
                </Grid>
              </Grid>
            </fieldset>
          </Grid>
        </Grid>
        <Button
          style={{ marginRight: 15 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          儲存
        </Button>
        <Button
          color="default"
          variant="contained"
          type="reset"
          onClick={resetForm}
        >
          重設
        </Button>
      </form>
      <ChangeBedDialog
        open={changeBedOpen}
        onClose={() => {
          setChangeBedOpen(false);
        }}
        onSubmit={onChangeBed}
        resident={entity.lastnameTc + entity.firstnameTc}
      />
      <ChangeHomeDialog
        open={changeHomeOpen}
        onClose={() => {
          setChangeHomeOpen(false);
        }}
        onSubmit={onChangeHome}
        resident={entity.lastnameTc + entity.firstnameTc}
      />
    </div>
  );
};

export const PersonalDataForm = (props) => {
  const { ...rest } = props;
  let { id } = useParams();
  const [entity, setEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const normalizeReceivedValues = (values) => {
    values.residentBedType = values.residentBedType.id;

    return values;
  };

  const createParams = (values) => {
    let params = {};
    if (values.hkid === "_______(_)") {
      values.hkid = null;
    }

    [
      "lastnameTc",
      "lastname",
      "firstnameTc",
      "firstname",
      "dateOfBirth",
      "gender",
      "hkid",
      "homeReturnPermitId",
      "nationality",
      "maritalStatus",
      "educationalBackground",
      "residentBedType",
      "ldsNo",
      "evaluationResult",
      "evaluationDate",
      "checkInDate",
      "vaccinationUrl",
    ].map((key) => {
      params[key] = values[key];
    });

    params.checkInDate = moment(values.checkInDate).format("YYYY-MM-DD");
    params.dateOfBirth = moment(values.dateOfBirth).format("YYYY-MM-DD");
    if (values.evaluationDate) {
      params.evaluationDate = moment(values.evaluationDate).format(
        "YYYY-MM-DD"
      );
    }

    console.debug("onCreate params:", params);
    return params;
  };

  const handleSubmit = (values, actions) => {
    console.debug("handleSubmit values:", values);

    axios
      .put(API_URL.RESIDENT + "/" + id + "/personalData", createParams(values))
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
      })
      .catch((error) => {
        if (
          error.response.data?.errors &&
          error.response.data?.errors[0].includes("hkid invalid")
        ) {
          dispatch(NotificationActions.error(null, "身份證號碼不正確"));
        } else {
          dispatch(NotificationActions.error(null, "錯誤"));
        }
        actions.setSubmitting(false);
      });
  };

  const getEntity = () => {
    axios
      .get(API_URL.RESIDENT + "/" + id)
      .then((response) => {
        normalizeReceivedValues(response.data);
        setEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEntity();
  }, [id]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) =>
        loaded && (
          <PersonalDataFormContent entity={entity} getEntity={getEntity} />
        )
      }
    </Formik>
  );
};
