import { API_URL, ROUTES } from "../../../constants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import axios from "axios";
import { NotificationActions } from "../../../actions";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "../components/AntiWanderingManagementFilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PersonAddDisabledOutlinedIcon from "@material-ui/icons/PersonAddDisabledOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import {
  AssignDialog,
  initialValues,
  validationSchema,
} from "../components/AssignDialog";
import { AssignInRowDialog } from "../components/AssignInRowDialog";
import { RevokeDialog } from "../components/RevokeDialog";
import { Formik } from "formik";
import { Fragment } from "react";

const AntiWanderingManagementPageHeader = (props) => {
  const {
    clearNotification,
    setAssign,
    handleFilterChange,
  } = props;

  // const [filteringParams, setFilteringParams] = useState(
  //   initialFilteringValues
  // );
  // const handleFilterChange = (values) => {
  //   setFilteringParams(values);
  // };

  return (
    <PageHeader>
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm
              handleFilterChange={handleFilterChange}
            />
          </Fragment>
        )}
      </Formik>
      <Button
        style={{ marginRight: 15, marginBottom: 15 }}
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={() => {
          clearNotification();
          setAssign(true);
        }}
      >
        分配院友智能手帶
      </Button>
    </PageHeader>
  );
};

export default function AntiWanderingManagement(props) {
  const { handleFilterChange, filteringParams, createFilteringParams } = props;
  const [assign, setAssign] = useState(false);

  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  // const [filteringParams, setFilteringParams] = useState(
  //   initialFilteringValues
  // );
  const [assignInRow, setAssignInRow] = useState(null);
  const [revoke, setRevoke] = useState(null);
  const [assignInRowOpen, setAssignInRowOpen] = useState(false);
  const [revokeOpen, setRevokeOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.code = values.code;
    params.resident = params.resident.id || params.resident;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    return values;
  };

  const onAssign = (values, actions) => {
    axios
      .put(API_URL.IBEACON_RESIDENT_MATCH + values.bandCode, {
        home: home.home.id,
        bandCode: values.bandCode,
        resident: values.resident,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已分配"));
        actions.setSubmitting(false);
        setAssign(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onAssignInRow = (values, actions) => {
    axios
      .put(API_URL.IBEACON + "/addResident/" + values.id, {
        home: home.home.id,
        bandCode: values.bandCode,
        resident: values.resident.id || values.resident,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已分配"));
        actions.setSubmitting(false);
        setAssignInRowOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onRevoke = (values, actions) => {
    axios
      .put(API_URL.IBEACON + "/addResident/" + values.id, {
        home: home.home.id,
        bandCode: values.bandCode,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已收回"));
        actions.setSubmitting(false);
        setRevokeOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const getTabColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;


    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="分配智能手帶">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setAssignInRow(params.row);
                    setAssignInRowOpen(true);
                  }}
                  disabled={params.row["resident"] || loading}
                >
                  <PersonAddOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="收回智能手帶">
                <span>
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      setRevoke(params.row);
                      setRevokeOpen(true);
                    }}
                    disabled={!params.row["resident"] || loading}
                  >
                    <PersonAddDisabledOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "bandCode",
        headerName: "智能手帶編號",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]
            ? params.row["resident"].lastnameTc +
                params.row["resident"].firstnameTc
            : "--";
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 400,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"] &&
            params.row["resident"].lastname &&
            params.row["resident"].firstname
            ? params.row["resident"].lastname +
                " " +
                params.row["resident"].firstname
            : "--";
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.IBEACON,
      getOne: API_URL.IBEACON,
      edit: API_URL.IBEACON_RESIDENT_MATCH,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="防遊走管理"
      editableFields={["resident", "bandCode"]}
      getColumnSettings={getTabColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      initialValues={initialValues}
      validationSchema={validationSchema}
      apiUrl={apiUrl}
      pageHeader={AntiWanderingManagementPageHeader}
      pageHeaderProps={{
        setAssign: setAssign,
        handleFilterChange: handleFilterChange,
      }}
      maxWidth="sm"
    >
      <AssignDialog
        open={assign}
        onClose={() => {
          setAssign(false);
        }}
        onSubmit={onAssign}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <AssignInRowDialog
        open={assignInRowOpen}
        entity={assignInRow}
        onClose={() => {
          setAssignInRow(null);
          setAssignInRowOpen(false);
        }}
        onSubmit={onAssignInRow}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <RevokeDialog
        open={revokeOpen}
        entity={revoke}
        onClose={() => {
          setRevoke(null);
          setRevokeOpen(false);
        }}
        onSubmit={onRevoke}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
