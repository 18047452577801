import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../components/PageHeader';
import { Formik } from "formik";
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import Button from '@material-ui/core/Button';
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { API_URL } from '../../../constants';

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);
    const {
        setCreating,
        handleFilterChange,
        clearNotification,
        setExportOpen,
    } = props;

    return (
        <PageHeader title="員工薪金">
            <Formik
                initialValues={initialFilteringValues}
                validationSchema={filteringValidationSchema}
            >
                {() => (
                    <Fragment>
                        <FilteringForm
                            handleFilterChange={handleFilterChange}
                        />
                        {/* <Button
                          style={{ marginRight: 15 }}
                          variant="contained"
                          color="primary"
                          startIcon={<AddCircleOutlinedIcon />}
                          onClick={() => {
                            clearNotification();
                            setCreating(true);
                          }}
                        >
                          新增員工薪金
                        </Button> */}
                        <Button
                          style={{ marginRight: 15, marginBottom: 15 }}
                          variant="contained"
                          color="primary"
                          startIcon={<OpenInBrowserOutlinedIcon />}
                          onClick={() => setExportOpen(true)}
                        >
                          匯出
                        </Button>
                    </Fragment>
                )}
            </Formik>
        </PageHeader >
    );
}
