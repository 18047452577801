import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { FilteringForm, initialFilteringValues, filteringValidationSchema } from './FilteringForm';
import { PageHeader } from '../../../components/PageHeader';
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
    const home = useSelector(state => state.home);

    const {
        setCreating,
        handleFilterChange
    } = props;

    return (
        <PageHeader title="更表管理">
            <Formik
                initialValues={initialFilteringValues}
                validationSchema={filteringValidationSchema}
            >
                {() => (
                    <Fragment>
                        <FilteringForm
                            handleFilterChange={handleFilterChange}
                        />
                    </Fragment>
                )}
            </Formik>
        </PageHeader >
    );
}