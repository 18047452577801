import * as Yup from "yup";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import {
  API_URL,
  UNIT_OPTIONS,
  TAKING_INSTRUCTION_OPTIONS,
  PRESCRIPTION_TYPE_OPTIONS,
} from "../../../../constants";
import FormikCheckboxField from "../../../../components/inputs/FormikCheckboxField";

export const initialValues = {
  code: "",
  namePackageDose: "",
  name: "",
  nameTc: "",
  productName: "",
  unit: "",
  takingInstruction: "",
  prescriptionType: "",
  takingRemarks: "",
  effect: "",
  sideEffect: "",
  ingredients: "",
  remarks: "",
  isArMedicine: false,
  isEffective: false,
  //injectionPosition: "",
};

export const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  namePackageDose: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // name: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // nameTc: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // productName: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // unit: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // takingInstruction: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // prescriptionType: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // takingRemarks: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // effect: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // sideEffect: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // ingredients: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // remarks: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const MedicineForm = (props) => {
  const { entity, apiUrl = "", ...rest } = props;
  //const [beds, setBeds] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  const handlePrescriptionTypeChange = (event) => {
    setFieldValue("prescriptionType", event.target.value);
    if (event.target.value !== "INJECTION") {
      setFieldValue("injectionPosition", "");
    }
  };

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              藥物資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="藥物編號" name="code" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField
                  required
                  label="藥物名稱 - 包裝 - 劑量 (英文)"
                  name="namePackageDose"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="藥物名稱 (英文)" name="name" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="藥物名稱 (中文)" name="nameTc" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="商品名稱" name="productName" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikSelectField
                  label="藥物單位"
                  name="unit"
                  options={UNIT_OPTIONS}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikSelectField
                  label="服用方法"
                  name="takingInstruction"
                  options={TAKING_INSTRUCTION_OPTIONS}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikSelectField
                  label="藥紙類別"
                  name="prescriptionType"
                  options={PRESCRIPTION_TYPE_OPTIONS}
                  onChange={handlePrescriptionTypeChange}
                />
              </Grid>
              {/* {values.prescriptionType === "INJECTION" && (
                <Grid item sm={12} xs={12}>
                  <FormikTextField label="注射位置" name="injectionPosition" />
                </Grid>
              )} */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              其他藥物資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="服用須知" name="takingRemarks" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="藥物功效" name="effect" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="副作用" name="sideEffect" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="成份" name="ingredients" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikCheckboxField label="AR藥物" name="isArMedicine" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikCheckboxField label="有效藥物" name="isEffective" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
