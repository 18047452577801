import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ROUTES, API_URL } from "../../../constants";
import { Link } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import axios from "axios";
import { BrExpiryDialog } from "./dialogs/BrExpiryDialog";
import { CalendarDialog } from "./dialogs/CalendarDialog";
import { NotificationActions } from "../../../actions";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  table: {
    minWidth: 300,
    marginBottom: 15,
  },
  itemExpired: {
    backgroundColor: "#efbad6",
  },
  itemToday: {
    backgroundColor: "#b1d3c5",
  },
});

export default function Calendar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [brExpiry, setBrExpiry] = useState([]);
  const [brExpiryEntity, setBrExpiryEntity] = useState(null);
  const [brExpiryOpen, setBrExpiryOpen] = useState(false);
  const [calendarValues, setCalendarValues] = useState([]);
  const [calendarEntity, setCalendarEntity] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const home = useSelector((state) => state.home);

  const getEntities = () => {
    axios
      .get("/api/homes/" + home?.home?.id + "/checkBrExpiry")
      .then((response) => {
        let data = response?.data;
        setBrExpiry(data);
      });
    axios
      .get("/api/calendar", {
        params: {
          startDate: moment(new Date()).subtract(7, "d").format("yyyy-MM-DD"),
          endDate: moment(new Date()).add(1, "months").format("yyyy-MM-DD"),
          home: home?.home?.id,
        },
      })
      .then((response) => {
        let data = response?.data?.content;
        setCalendarValues(data);
      });
  };

  const calendarNormalizeReceivedValues = (values) => {
    // values.resident = values.resident.id;
    // return values;
  };

  const onBrExpiry = (values, formikActions) => {
    axios
      .put("/api/homes/" + home?.home?.id + "/checkBrExpiry", {
        license: values.license,
        licenseExpiryDate: values.licenseExpiryDate,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setBrExpiryEntity(null);
        formikActions.setSubmitting(false);
        setBrExpiryOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  const onCalendar = (values, formikActions) => {
    axios
      .put(API_URL.CALENDAR + "/" + values.id, {
        home: home.home.id,
        item: values.item,
        dateTime: values.dateTime,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setCalendarEntity(null);
        formikActions.setSubmitting(false);
        setCalendarOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  useEffect(() => {
    getEntities();
  }, [home, reload]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        {brExpiry && (
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" gutterBottom>
              更新牌照提醒
            </Typography>
            <TableContainer>
              <Table
                className={classes.table}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: 70 }}>
                      動作
                    </TableCell>
                    <TableCell align="left">安老院牌照號碼</TableCell>
                    <TableCell align="left">牌照有效期至</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={brExpiry.id}
                    className={clsx({
                      [classes.itemExpired]: moment(new Date()).isAfter(
                        brExpiry.licenseExpiryDate
                      ),
                      [classes.itemToday]:
                        moment(new Date()).format("YYYY-MM-DD") ===
                        moment(brExpiry.licenseExpiryDate).format("YYYY-MM-DD"),
                    })}
                  >
                    <TableCell component="th" scope="row">
                      <Tooltip title="修改">
                        <IconButton
                          color="default"
                          size="small"
                          onClick={() => {
                            dispatch(NotificationActions.clear());
                            setBrExpiryEntity(brExpiry);
                            setBrExpiryOpen(true);
                          }}
                          disabled={loading}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      {brExpiry.license}
                    </TableCell>
                    <TableCell align="left">
                      {brExpiry.licenseExpiryDate}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            <Link to={ROUTES.CALENDAR}>行事曆</Link>
          </Typography>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ minWidth: 70 }}>
                    動作
                  </TableCell>
                  <TableCell align="left">日期</TableCell>
                  <TableCell align="left">事項</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calendarValues && calendarValues.length !== 0 ? (
                  calendarValues.map((row) => (
                    <TableRow
                      key={row.id}
                      className={clsx({
                        [classes.itemExpired]: moment(new Date()).isAfter(
                          row.dateTime
                        ),
                        [classes.itemToday]:
                          moment(new Date()).format("YYYY-MM-DD") ===
                          moment(row.dateTime).format("YYYY-MM-DD"),
                      })}
                    >
                      <TableCell component="th" scope="row">
                        <Tooltip title="修改">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              dispatch(NotificationActions.clear());
                              setCalendarEntity(row);
                              setCalendarOpen(true);
                            }}
                            disabled={loading}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.dateTime?.substring(0, 10)}
                      </TableCell>
                      <TableCell align="left">{row.item}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      未有任何 7日前 或 未來1個月 的行事曆記錄
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <BrExpiryDialog
        open={brExpiryOpen}
        entity={brExpiryEntity}
        onClose={() => {
          setBrExpiryEntity(null);
          setLoading(false);
          setBrExpiryOpen(false);
        }}
        onSubmit={onBrExpiry}
        //normalizeReceivedValues={brExpiryNormalizeReceivedValues}
      />
      <CalendarDialog
        open={calendarOpen}
        entity={calendarEntity}
        onClose={() => {
          setCalendarEntity(null);
          setLoading(false);
          setCalendarOpen(false);
        }}
        onSubmit={onCalendar}
        normalizeReceivedValues={calendarNormalizeReceivedValues}
      />
    </Fragment>
  );
}
