import { Fragment } from "react";
import * as Yup from "yup";
import { API_URL, ROUTES, CHECK_IN_STATUS_OPTIONS } from "../../../constants";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../components/inputs/FormikDateField";
import { FormLabel } from "@material-ui/core";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import Button from "@material-ui/core/Button";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";

export const initialFilteringValues = {
  status: "CHECKED_IN",
  startDate: "",
  endDate: "",
  resident: "",
};

export const filteringValidationSchema = Yup.object().shape({});

const useStyles = makeStyles((theme) => ({
  containerFields: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginRight: theme.spacing(3),
  },
}));

export const FilteringForm = (props) => {
  const home = useSelector((state) => state.home);
  const { handleFilterChange } = props;
  const { values, isSubmitting, submitForm, resetForm } = useFormikContext();
  const classes = useStyles();

  const handleSubmit = (event) => {
    handleFilterChange({
      ...values,
      timestamp: new Date(), //Force refresh when click submit without changing values
    });
  };

  const handleReset = (event) => {
    resetForm();
    handleFilterChange(initialFilteringValues);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item className={classes.containerFields} xs={12} md={12} lg={8}>
          <fieldset>
            <FormLabel component="legend">搜尋</FormLabel>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormikReferenceField
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home?.home?.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                  getOptionValue={(option) => option?.id}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikDateField label="搜尋入住日期由" name="startDate" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikDateField label="搜尋入住日期至" name="endDate" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormikSelectField
                  label="院友狀態"
                  options={CHECK_IN_STATUS_OPTIONS}
                  name="status"
                />
              </Grid>
              <Grid item xs={12} md={4} style={{ alignSelf: "center" }}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  startIcon={<SearchOutlinedIcon />}
                >
                  篩選
                </Button>
                <Button
                  className={classes.button}
                  color="default"
                  variant="outlined"
                  onClick={handleReset}
                  disabled={isSubmitting}
                  startIcon={<RotateLeftOutlinedIcon />}
                >
                  重設
                </Button>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </Fragment>
  );
};
