export const ERROR_STATUS = [
  {
    errorResponse: "status: Cannot delete, wristband user exist",
    message: "此手帶已分配至院友，不能刪除",
  },
  {
    errorResponse: "status: Cannot delete, staffUsers exist",
    message: "此用戶群組已有用戶正在使用，不能刪除",
  },
  {
    errorResponse:
      "status: The institution has Resident Drug Medication or Resident Emergency or Resident Follow Up record.",
    message: "此機構已記錄在院友藥物/院友急症/院友覆診，不能刪除",
  },
  {
    errorResponse:
      "status: The specialist has Resident Drug Medication or Resident Follow Up record.",
    message: "此專科已記錄在院友藥物/院友覆診，不能刪除",
  },
  {
    errorResponse: "status: The item was in use by resident record.",
    message: "此項目已有院友正在使用，不能刪除",
  },
  {
    errorResponse: "status: The expense item has expense record.",
    message: "此支出項目已記錄在支出記錄，不能刪除",
  },
  {
    errorResponse: "status: The supplier has expense record.",
    message: "此供應商已記錄在支出記錄，不能刪除",
  },
  {
    errorResponse: "status: The home has related record.",
    message: "此院舍已有其相關記錄，不能刪除",
  },
  {
    errorResponse: "status: The invoice item has invoice record.",
    message: "此收費項目已記錄在院友發票，不能刪除",
  },
  {
    errorResponse: "status: The memo has resident record.",
    message: "此備忘項目已記錄在院友資料，不能刪除",
  },
  {
    errorResponse:
      "status: The item was in use by resident / resevation record.",
    message: "此項目有記錄已用過，不能刪除",
  },
];
