import messages_en from '../languages/en.message.json';
import messages_zh_HK from '../languages/zh_HK.message.json';

export const MESSAGES = {
  'en': messages_en,
  'en_US': messages_en,
  'en-US': messages_en,
  'zh_HK': messages_zh_HK,
  'zh-HK': messages_zh_HK
};

export const DEFAULT_LOCALE = 'zh-HK';