let defaultHome = JSON.parse(sessionStorage.getItem('home'));
const initialState = defaultHome ? { type: 'set', home: defaultHome } : {};

export function home(state = initialState, action) {
    switch (action.type) {
        case 'set':
            return {
                type: 'set',
                home: action.home
            };
        case 'clear':
            return {};
        default:
            return state;
    }
}