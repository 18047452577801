import * as Yup from "yup";
import moment from "moment";
import "moment-lunar";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  API_URL,
  ROUTES,
  RESIDENT_DEPOSIT_STATUS_OPTION,
} from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import axios from "axios";

export const initialValues = {
  invoiceId: "",
  resident: "",
  status: "",
};

export const validationSchema = Yup.object().shape({
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function EditDialog(props) {
  const { entity, open, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      entity={entity}
      dialogTitle={"修改付款狀態 (" + entityValues?.invoiceId + ")"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {}, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    disabled
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" +
                      home?.home?.id +
                      "/reservationResidents/references"
                    }
                    getOptionLabel={(option) =>
                      option.reservations &&
                      option?.reservations[0].lastnameTc +
                        option?.reservations[0].firstnameTc
                    }
                    getOptionValue={(option) => option?.id}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikSelectField
                    required
                    label="付款狀態"
                    name="status"
                    options={RESIDENT_DEPOSIT_STATUS_OPTION}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
