import { Fragment } from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import { FormLabel } from "@material-ui/core";
import FormikTextField from "../../../components/inputs/FormikTextField";
import Button from "@material-ui/core/Button";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import moment from "moment";

export const initialFilteringValues = {
  resident: "",
  bandCode: "",
  // passStartDateFrom: "",
  // passStartDateTo: "",
  passEndDateFrom: "",
  passEndDateTo: "",
};

export const filteringValidationSchema = Yup.object().shape({});

const useStyles = makeStyles((theme) => ({
  containerFields: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginRight: theme.spacing(3),
  },
}));

export const FilteringForm = (props) => {
  const home = useSelector((state) => state.home);
  const { handleFilterChange } = props;
  const { values, isSubmitting, submitForm, resetForm } = useFormikContext();
  const classes = useStyles();

  const handleSubmit = (event) => {
    handleFilterChange({
      resident: values.resident,
      bandCode: values.bandCode,
      // passStartDateFrom: moment(values.passStartDateFrom).format("YYYY-MM-DD"),
      // passStartDateTo: moment(values.passStartDateTo).format("YYYY-MM-DD"),
      passEndDateFrom: values.passEndDateFrom ? moment(values.passEndDateFrom).format("YYYY-MM-DD") : values.passEndDateFrom,
      passEndDateTo: values.passEndDateTo ? moment(values.passEndDateTo).format("YYYY-MM-DD") : values.passEndDateTo,
      timestamp: new Date(), //Force refresh when click submit without changing values
    });
  };

  const handleReset = (event) => {
    resetForm();
    handleFilterChange(initialFilteringValues);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item className={classes.containerFields} xs={12} md={12} lg={8}>
          <fieldset>
            <FormLabel component="legend">搜尋</FormLabel>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormikReferenceField
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home?.home?.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                  getOptionValue={(option) => option?.id}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormikTextField label="智能手帶編號" name="bandCode" />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <FormikDateField
                  label="遊走日期開始由"
                  name="passStartDateFrom"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormikDateField
                  label="遊走日期開始至"
                  name="passStartDateTo"
                />
              </Grid> */}
              <Grid item xs={12} md={3}>
                <FormikDateField
                  label="遊走結束日期由"
                  name="passEndDateFrom"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormikDateField
                  label="遊走結束日期至"
                  name="passEndDateTo"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} style={{ alignSelf: "center" }}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  startIcon={<SearchOutlinedIcon />}
                >
                  篩選
                </Button>
                <Button
                  className={classes.button}
                  color="default"
                  variant="outlined"
                  onClick={handleReset}
                  disabled={isSubmitting}
                  startIcon={<RotateLeftOutlinedIcon />}
                >
                  重設
                </Button>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </Fragment>
  );
};
