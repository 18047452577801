import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, RESIDENT_DEPOSIT_STATUS_OPTION } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { PaymentDialog } from "./components/PaymentDialog";
import { DetailsDialog } from "./components/DetailsDialog";
import { PrintDialog } from "./components/PrintDialog";
import { ReturnDepositDialog } from "./components/ReturnDepositDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";

export default function ResidentDepositPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [paymentEntity, setPaymentEntity] = useState(null);
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [printEntity, setPrintEntity] = useState(null);
  const [returnEntity, setReturnEntity] = useState(null);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editOpen, setEditOpen] = useState(false);
  const [payOpen, setPayOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};

    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.resident = params.resident.id || params.resident;
    params.status = "NOT_YET_PAID";
    params.residentInvoiceItems = [
      {
        invoiceItem: values.depositId,
        description: values.description,
        price: values.price,
        qty: values.qty,
      },
    ];

    //params.contact = [{ contactName: params.contactName, contactNo: params.contactTel, relationship: params.contactRelationship }];

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改按金狀態">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <IconButton color="default" size="small" onClick={() => { clearNotification(); setDeletingEntity(params.row); }} disabled={params.row['status'] != 'PAID' || loading}>
                                <DeleteOutlinedIcon />
                            </IconButton> */}
              <Tooltip title="按金付款">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setPaymentEntity(params);
                    setPayOpen(true);
                  }}
                  disabled={loading}
                >
                  <LocalAtmOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="退還按金">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setReturnEntity(params);
                    setReturnOpen(true);
                  }}
                  disabled={loading}
                >
                  <ReplayOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="列印">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setPrintEntity(params.row);
                    setPrintOpen(true);
                  }}
                  disabled={loading}
                >
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "issueDateTime",
        headerName: "按金日期及時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.substring(0, 10) +
            " / " +
            params.row[params.field]?.substring(11, 16)
          );
        },
      },
      {
        field: "invoiceId",
        headerName: "按金編號",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row["resident"].lastnameTc +
            params.row["resident"].firstnameTc
          );
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].lastname &&
            params.row["resident"].firstname
            ? params.row["resident"].lastname +
                " " +
                params.row["resident"].firstname
            : "--";
        },
      },
      {
        field: "total",
        headerName: "按金金額",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return "HK$ " + params.row[params.field].toLocaleString();
        },
      },
      {
        field: "status",
        headerName: "付款狀態",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_DEPOSIT_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT_INVOICE + "/" + values.id, {
        status: values.status,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onPay = (values, actions) => {
    const paymentList = [
      {
        paymentMethod: values.paymentMethod,
        paymentDate: values.paymentDate,
        remarks: values.remarks,
        amount: values.amount,
      },
    ];
    axios
      .post(API_URL.RESIDENT_INVOICE + "/" + values.id + "/pay", {
        paymentList,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已付款"));
        actions.setSubmitting(false);
        setPayOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onReturn = (values, actions) => {
    axios
      .put("/api/residentInvoice/" + values.id + "/returnDeposit", {
        status: "RETURNED_DEPOSIT",
        amount: values.amount,
        description: values.description,
        method: values.method,
        issueDateTime: values.issueDateTime,
        remarks: values.remarks,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已退款"));
        actions.setSubmitting(false);
        setReturnOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_INVOICE + "?type=DEPOSIT",
      getOne: API_URL.RESIDENT_INVOICE,
      create: API_URL.RESIDENT_INVOICE,
      edit: API_URL.RESIDENT_INVOICE,
      delete: API_URL.RESIDENT_INVOICE,
      print: API_URL.RESIDENT_INVOICE,
      export: API_URL.RESIDENT_INVOICE + "/export?type=DEPOSIT",
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="按金"
      editableFields={[
        "resident",
        "residentInvoiceItems",
        "issueDateTime",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      maxWidth="sm"
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <PaymentDialog
        open={payOpen}
        entity={paymentEntity}
        onClose={() => {
          setPaymentEntity(null);
          setPayOpen(false);
        }}
        onSubmit={onPay}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <PrintDialog
        open={printOpen}
        entity={printEntity}
        onClose={() => {
          setPrintEntity(null);
          setPrintOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <ReturnDepositDialog
        open={returnOpen}
        entity={returnEntity}
        onClose={() => {
          setReturnEntity(null);
          setReturnOpen(false);
        }}
        onSubmit={onReturn}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
