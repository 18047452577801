import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  ROUTES,
  RESIDENT_INVOICE_STATUS_OPTION,
  CHECK_IN_STATUS_OPTIONS,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import { initialFilteringValues } from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { PaymentDialog } from "./components/PaymentDialog";
import { DetailsDialog } from "./components/DetailsDialog";
//import { PrintDialog } from './components/PrintDialog';
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import fileDownload from "js-file-download";
import { setSeconds } from "date-fns";

export default function ResidentInvoicePage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [paymentEntity, setPaymentEntity] = useState(null);
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [payOpen, setPayOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [reload, setReload] = useState(false);
  // list of id of the selected invoices
  const [selectionsList, setSelectionsList] = useState([]);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.resident = params.resident.id || params.resident;
    params.status = "NOT_YET_PAID";

    params.residentInvoiceItems?.forEach(item => {
      item.invoiceItem = item.invoiceItem?.id || item.invoiceItem;
    });

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.residentInvoiceItemList.map((item) => {
      item.invoiceItem = item.invoiceItem.id;
    });
    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改發票狀態">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="發票付款">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setPaymentEntity(params);
                    setPayOpen(true);
                  }}
                  disabled={loading}
                >
                  <LocalAtmOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="列印發票">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    onPrintInvoice(params.row.id);
                  }}
                  disabled={loading}
                >
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "issueDateTime",
        headerName: "發票日期",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "invoiceId",
        headerName: "發票編號",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row["resident"].lastnameTc +
            params.row["resident"].firstnameTc
          );
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].lastname &&
            params.row["resident"].firstname
            ? params.row["resident"].lastname +
                " " +
                params.row["resident"].firstname
            : "--";
        },
      },
      {
        field: "total",
        headerName: "發票金額",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return "HK$ " + params.row[params.field].toLocaleString();
        },
      },
      {
        field: "status",
        headerName: "付款狀態",
        width: 130,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_INVOICE_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "checkInStatus",
        headerName: "入住狀態",
        width: 170,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return CHECK_IN_STATUS_OPTIONS.find(
            (elem) => elem.value == params.row["resident"]?.checkInStatus
          )?.label;
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT_INVOICE + "/" + values.id, {
        home: values.home.id,
        issueDateTime: values.issueDateTime,
        resident: values.resident.id || values.resident,
        status: values.status,
        residentInvoiceItems: values.residentInvoiceItemList.map((item) => ({
          description: item.description,
          invoiceItem: item.invoiceItem,
          price: item.price,
          qty: item.qty,
        })),
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onPay = (values, actions) => {
    // TODO: Uncomment newer version
    const paymentList = values.residentInvoiceItemList.reduce((list, item) => {
      if (!item.price || !item.qty) return list;

      return [...list, {
        paymentMethod: values.paymentMethod,
        paymentDate: values.paymentDate,
        remarks: values.remarks,
        amount: item.price,
        qty: item.qty,
        residentInvoiceItem: item.id,
      }];
    }, []);
    if (paymentList.length === 0) {
      dispatch(NotificationActions.error(null, "沒有任何付款項目"));
      return actions.setSubmitting(false);
    };

    // TODO: Comment older version
    // const paymentList = [
    //   {
    //     paymentMethod: values.paymentMethod,
    //     paymentDate: values.paymentDate,
    //     remarks: values.remarks,
    //     amount: values.amount,
    //   },
    // ];
    axios
      .post(API_URL.RESIDENT_INVOICE + "/" + values.id + "/pay", {
        paymentList,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已付款"));
        actions.setSubmitting(false);
        setPayOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });

    // if (!values.status) return;
    // // update invoice status
    // axios.get(API_URL.RESIDENT_INVOICE + "/" + values.id).then((response) => {
    //   const invoiceData = response?.data;
    //   axios
    //     .put(API_URL.RESIDENT_INVOICE + "/" + values.id, {
    //       home: invoiceData.home.id,
    //       status: values.status,
    //     })
    //     .then((response) => {
    //       // dispatch(NotificationActions.success(null, "已儲存"));
    //       // actions.setSubmitting(false);
    //       // setEditOpen(false);
    //       // setReload(!reload);
    //     })
    //     .catch((error) => {
    //       dispatch(NotificationActions.error(null, "付款狀態錯誤"));
    //       actions.setSubmitting(false);
    //     });
    // }).catch((error) => {
    // }).finally(() => {
    // });
    // end of update invoice status
  };

  const onPrintInvoice = (values, formikActions) => {
    axios
      .get(apiUrl.print ? apiUrl.print + "/print/" + values : apiUrl, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_院友發票_print.docx"
        );
      })
      .catch((error) => {});
  };

  const onMergePrintInvoice = () => {
    selectionsList.map(invoiceId => {
      axios
        .get(`${apiUrl.print}/print/${invoiceId}`, {
          responseType: "blob",
        })
        .then((response) => {
          let data = response.data;
          fileDownload(
            data,
            moment().format("YYYY-MM-DD_HH_mm_SS") + "_院友發票_print.docx"
          );
        })
        .catch((error) => {});
    });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_INVOICE + "?type=INVOICE",
      getOne: API_URL.RESIDENT_INVOICE,
      create: API_URL.RESIDENT_INVOICE,
      edit: API_URL.RESIDENT_INVOICE,
      delete: API_URL.RESIDENT_INVOICE,
      print: API_URL.RESIDENT_INVOICE,
      getDefaultExpenseItems: API_URL.DEFAULT_EXPENSE_ITEMS,
    });
  }, [home, reload]);

  // useEffect(() => {
  //   console.debug(selectionsList)
  // }, [selectionsList])

  return (
    <EntityManagement
      entityModel="發票"
      editableFields={[
        "resident",
        "residentInvoiceItems",
        "issueDateTime",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        onMergePrintInvoice: onMergePrintInvoice,
      }}
      checkboxSelection={true}
      setSelectionsList={setSelectionsList}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <PaymentDialog
        open={payOpen}
        entity={paymentEntity}
        onClose={() => {
          setPaymentEntity(null);
          setPayOpen(false);
        }}
        onSubmit={onPay}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
