import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { PageHeader } from "../../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const home = useSelector((state) => state.home);

  const { setCreating, onExport, listEmpty } = props;

  useEffect(() => {}, [listEmpty]);

  return (
    <PageHeader hideNotification>
      <Formik>
        {() => (
          <Fragment>
            {listEmpty && (
              <Button
                style={{ marginRight: 15 }}
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlinedIcon />}
                onClick={() => {
                  setCreating(true);
                }}
              >
                新增病歷
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              startIcon={<OpenInBrowserOutlinedIcon />}
              onClick={onExport}
            >
              匯出
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
