import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikDialog } from './FormikDialog';

export const FormikMergePrintDialog = props => {
    const { dialogTitle, entityModel, ...rest } = props;

    return (
        <FormikDialog
            dialogTitle={entityModel || "合拼列印"}
            {...rest}
        >
            {props.children}
        </FormikDialog>
    );
}