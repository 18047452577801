import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const { setCreating, onMergePrintInvoice, onPrintChecked, handleFilterChange, clearNotification } =
    props;

  return (
    <PageHeader title="院友發票">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增發票
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                clearNotification();
                // onPrintChecked(true);
                onMergePrintInvoice();
              }}
            >
              合拼列印
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
