import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";
import { FormikFileField, FormikReferenceField } from "../../../components/inputs";
import { useEffect, useLayoutEffect } from "react";
import { useFormikContext } from "formik";
import { Typography } from "@material-ui/core";
import { API_URL } from "../../../constants";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

export const initialValues = {
  folder: "",
  files: [],
};

export const validationSchema = Yup.object().shape({
  folder: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // files: Yup.array()
  //   // .of(
  //   //   Yup.object()
  //   //   // .shape({
  //   //   //   // define validation rules for each object in the array
  //   //   //   // example rules for the object properties
  //   //   //   name: Yup.string().required('Name is required'),
  //   //   //   size: Yup.number().positive().required('Size is required'),
  //   //   // })
  //   // )
  //   .min(1, '請上傳檔案')
  //   .max(1, '不能多過一個檔案, 請重新上傳檔案'),
});

export function UploadDialog(props) {
  const { open, onClose, onSubmit, injectErrorMsg, setInjectErrorMsg } = props;

  return (
    <FormikDialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      maxWidth="sm"
      dialogTitle={"上傳文件"}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <UploadForm
        injectErrorMsg={injectErrorMsg}
        setInjectErrorMsg={setInjectErrorMsg}
      />
    </FormikDialog>
  );
}

const UploadForm = ({ injectErrorMsg, setInjectErrorMsg }) => {
  const { initialValues, resetForm, values } = useFormikContext();
  useLayoutEffect(() => {
    setInjectErrorMsg("");
    resetForm({ values: initialValues });
  }, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikFileField
                    required
                    label="文件"
                    document
                    injectErrorMsg={injectErrorMsg}
                    setInjectErrorMsg={setInjectErrorMsg}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography Typography variant="subtitle2" color="textSecondary">
                    儲存至:
                  </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    required
                    label="文件夾位置"
                    name="folder"
                    apiUrl={API_URL.DOCUMENT_FOLDER}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    variation={"documentFolder"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
