import React, { Fragment } from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { API_URL, MEDICAL_ITEM_STATUS_OPTION } from "../../../constants";
import clsx from "clsx";
import { MedicalTreatmentItemDialog } from "./dialogs/MedicalTreatmentItemDialog";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { NotificationActions } from "../../../actions";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  table: {
    minWidth: 300,
    marginBottom: 15,
  },
  itemExpired: {
    backgroundColor: "#efbad6",
  },
  itemToday: {
    backgroundColor: "#b1d3c5",
  },
});

export default function MedicalTreatmentItemsReminder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [medicalTreatmentItem, setMedicalTreatmentItem] = useState([]);
  const [residentMedicalTreatmentItem, setResidentMedicalTreatmentItem] = useState([]);
  const [medicalTreatmentItemEntity, setMedicalTreatmentItemEntity] = useState(null);
  const [medicalTreatmentItemOpen, setMedicalTreatmentItemOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getEntities = () => {
    axios.get(API_URL.MEDICAL_TREATMENT_ITEM)
      .then((response) => {
        let data = response?.data?.content;
        setMedicalTreatmentItem(data);
      })
      .catch((error) => console.error(error));

    axios.get(
        API_URL.RESIDENT_TREATMENT_ITEM +
          "/searchUpcomingTreatment/" +
          home?.home?.id
      )
      .then((response) => {
        let data = response?.data;
        setResidentMedicalTreatmentItem(data);
      })
      .catch((error) => console.error(error));
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;
    values.treatmentItem = values.treatmentItem.id;
    return values;
  };

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_TREATMENT_ITEM + "/" + values.id, {
        resident: values.resident,
        remarks: values.remarks,
        treatmentItem: values.treatmentItem,
        status: values.status,
        dateTime: values.dateTime,
        finishedDateTime: values.finishedDateTime,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setMedicalTreatmentItemEntity(null);
        formikActions.setSubmitting(false);
        setMedicalTreatmentItemOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    if (values.status == "COMPLETED" && values.nextDate !== "") {
      axios.post(API_URL.RESIDENT_TREATMENT_ITEM, {
        resident: values.resident,
        remarks: values.remarks,
        treatmentItem: values.treatmentItem,
        status: "APPOINTMENT",
        dateTime: values.nextDate,
        finishedDateTime: "",
      });
    }
  };

  useEffect(() => {
    getEntities();
  }, [home, reload]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        {medicalTreatmentItem ? (
          medicalTreatmentItem.map((item) => (
            <Grid key={item.id} item xs={12} md={12} lg={12}>
              <Typography variant="h6" gutterBottom>
                {item.nameTc}
              </Typography>
              <TableContainer>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead style={{ backgroundColor: item.calendarColor }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ color: item.calendarFontColor }}
                      >
                        動作
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: item.calendarFontColor }}
                      >
                        院友姓名
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: item.calendarFontColor }}
                      >
                        日期
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: item.calendarFontColor }}
                      >
                        時間
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: item.calendarFontColor }}
                      >
                        狀態
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {residentMedicalTreatmentItem &&
                    residentMedicalTreatmentItem.filter(
                      (resident) => resident.treatmentItem.nameTc == item.nameTc
                    ) != "" ? (
                      residentMedicalTreatmentItem
                        .filter(
                          (resident) =>
                            resident.treatmentItem.nameTc == item.nameTc
                        )
                        .map((row) => (
                          <TableRow
                            key={row.id}
                            className={clsx({
                              [classes.itemExpired]: moment(new Date()).isAfter(
                                row.dateTime
                              ),
                              [classes.itemToday]:
                                moment(new Date()).format("YYYY-MM-DD") ===
                                moment(row.dateTime).format("YYYY-MM-DD"),
                            })}
                          >
                            <TableCell component="th" scope="row">
                              <Tooltip title="修改">
                                <IconButton
                                  color="default"
                                  size="small"
                                  onClick={() => {
                                    dispatch(NotificationActions.clear());
                                    setMedicalTreatmentItemEntity(row);
                                    setMedicalTreatmentItemOpen(true);
                                  }}
                                  disabled={loading}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            <TableCell component="td" scope="row">
                              {row.resident.lastnameTc +
                                row.resident.firstnameTc}
                            </TableCell>
                            <TableCell align="left">
                              {row.dateTime?.substring(0, 10)}
                            </TableCell>
                            <TableCell align="left">
                              {row.dateTime?.substring(11, 16)}
                            </TableCell>
                            <TableCell align="left">
                              {
                                MEDICAL_ITEM_STATUS_OPTION.find(
                                  (elem) => elem.value == row.status
                                )?.label
                              }
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell component="th" colSpan={5} align="center">
                          未有任何 已過期 或 未來1個月 的 {item.nameTc} 記錄
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" gutterBottom>
              未有新增任何醫療項目
            </Typography>
          </Grid>
        )}
      </Grid>
      <MedicalTreatmentItemDialog
        open={medicalTreatmentItemOpen}
        entity={medicalTreatmentItemEntity}
        onClose={() => {
          setMedicalTreatmentItemEntity(null);
          setLoading(false);
          setMedicalTreatmentItemOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
      />
    </Fragment>
  );
}
