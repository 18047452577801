import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, HOME_EXPENSE_STATUS_OPTION } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { PaymentDialog } from "./components/PaymentDialog";
import { MergePaymentDialog } from "./components/MergePaymentDialog";
import { DetailsDialog } from "./components/DetailsDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import DailyReportDialog from "./components/DailyReportDialog";

export default function HomeExpensePage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [paymentEntity, setPaymentEntity] = useState(null);
  const [mergePaymentEntity, setMergePaymentEntity] = useState(false);
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [payOpen, setPayOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [dailyReportOpen, setDailyReportOpen] = useState(false);
  const [dailyReportEntity, setDailyReportEntity] = useState(null);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [selectedRow, setSelectedRow] = useState([]);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const createPaymentParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const handleRowSelected = (event) => {
    if (event.isSelected) {
      if (selectedRow.includes(event.data.id)) {
        return selectedRow;
      } else {
        setSelectedRow([...selectedRow, event.data.id]);
        return [...selectedRow, event.data.id];
      }
    } else {
      const newValues = selectedRow.filter((p) => p !== event.data.id);
      setSelectedRow(newValues);
      return newValues;
    }
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改支出狀態">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="支出付款">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setPaymentEntity(params);
                    setPayOpen(true);
                  }}
                  disabled={loading}
                >
                  <LocalAtmOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印發票">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "issueDateTime",
        headerName: "日期及時間",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.substring(0, 10) +
            " / " +
            params.row[params.field]?.substring(11, 16)
          );
        },
      },
      {
        field: "expenseId",
        headerName: "支出編號",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "supplier",
        headerName: "供應商",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.nameTc
            : "--";
        },
      },
      {
        field: "expenseItem",
        headerName: "支出項目",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.nameTc;
        },
      },
      {
        field: "description",
        headerName: "描述",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "amount",
        headerName: "金額",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return "HK$ " + params.row[params.field].toLocaleString();
        },
      },
      {
        field: "status",
        headerName: "付款狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return HOME_EXPENSE_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.EXPENSE + "/" + values.id, {
        status: values.status,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onPay = (values, actions) => {
    axios
      .post("/api/expenseTransaction", {
        method: values.method,
        issueDateTime: values.paymentDate,
        expenseList: [
          {
            expense: values.id,
            amount: values.payAmount,
            remarks: values.remarks,
          },
        ],
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已付款"));
        actions.setSubmitting(false);
        setPayOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onMergePay = (values, actions) => {
    axios
      .post("/api/expenseTransaction", {
        method: values.method,
        issueDateTime: values.issueDateTime,
        expenseList: (values.expenseList || []).map((i) => {
          return { expense: i.expense, amount: i.amount, remarks: i.remarks };
        }),
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已付款"));
        actions.setSubmitting(false);
        setMergePaymentEntity(null);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.EXPENSE + "?type=EXPENSE",
      getOne: API_URL.EXPENSE,
      create: API_URL.EXPENSE,
      edit: API_URL.EXPENSE,
      delete: API_URL.EXPENSE,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="支出"
      editableFields={[
        "expenseItem",
        "supplier",
        "issueDateTime",
        "amount",
        "status",
        "description",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createPaymentParams={createPaymentParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setMergePaymentEntity: setMergePaymentEntity,
        setDailyReportEntity: setDailyReportEntity,
        setDailyReportOpen: setDailyReportOpen,
      }}
      checkboxSelection={false}
      maxWidth="sm"
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <PaymentDialog
        open={payOpen}
        entity={paymentEntity}
        onClose={() => {
          setPaymentEntity(null);
          setPayOpen(false);
        }}
        onSubmit={onPay}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <MergePaymentDialog
        open={mergePaymentEntity}
        onClose={() => {
          setMergePaymentEntity(null);
        }}
        onSubmit={onMergePay}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DailyReportDialog
        open={dailyReportOpen}
        setOpen={setDailyReportOpen}
        entity={dailyReportEntity}
        onClose={() => {
          setDailyReportEntity(null);
          setDailyReportOpen(false);
        }}
        home={home}
      />
    </EntityManagement>
  );
}
