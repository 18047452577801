import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL } from "../../constants";
import { apiService } from "../../services";
import IconButton from "@material-ui/core/IconButton";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";

export default function DocumentListingPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [loading, setLoading] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [currentFolderId, setCurrentFolderId] = useState("");
  const [folderNav, setFolderNav] = useState([]);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    return params;
  };

  const normalizeReceivedValues = (values) => {};

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const onDownload = (values) => {
    apiService
      .get(values.url, { responseType: "blob" })
      .then(function (response) {
        let data = response.data;
        setLoading(false);
        fileDownload(data, values.name);
      })
      .catch(function (response) {
        setLoading(false);
      });
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          if (params.row['url']) {
            return (
              <div>
                <Tooltip title="下載">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      onDownload(params.row);
                    }}
                    disabled={loading}
                  >
                    <GetAppOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div>
                <Tooltip title="查看">
                  <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                      clearNotification();
                      setCurrentFolderId(params.row['id']);
                      setFolderNav([...folderNav, {
                        id: params.row['id'],
                        name: params.row['name']
                      }])
                    }}
                    disabled={loading}
                  >
                    <FolderOpenOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            );
          }
        },
      },
      {
        field: "name",
        headerName: "文件名稱",
        width: 1000,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      // getAll: API_URL.DOCUMENT,
      getAll: (currentFolderId) ? `${API_URL.DOCUMENT_FOLDER}/${currentFolderId}` : API_URL.DOCUMENT_FOLDER,
      getOne: API_URL.DOCUMENT,
    });
  }, [home, currentFolderId]);

  return (
    <EntityManagement
      entityModel="文件總覽"
      editableFields={["name", "url"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setCurrentFolderId: setCurrentFolderId,
        folderNav: folderNav,
        setFolderNav: setFolderNav,
      }}
      apiUrl={apiUrl}
      pageHeader={ListingPageHeader}
      maxWidth="sm"
      listDetailsVariation={"document-folder"}
      currentFolderId={currentFolderId}
    />
  );
}
