import * as Yup from "yup";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikHKIDField from "../../../components/inputs/FormikHKIDField";
import {
  BED_TYPE_OPTIONS,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";

export const initialValues = {
  name: "",
  nameTc: "",
  address: "",
  addressTc: "",
  tel: "",
  fax: "",
  website: "",
  email: "",
  license: "",
  licenseExpiryDate: "",
  brNo: "",
  typeOfService: "",
  servicePurposeAndGoals: "",
};

export const validationSchema = Yup.object().shape({
  lastnameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  lastname: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  firstnameTc: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  firstname: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  gender: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dateOfBirth: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  hkid: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  contactName: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  contactTel: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // contactRelationship: Yup.string()
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  roomRemarks: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  estimatedCheckInDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  roomRemarks: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  bedNo: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  bedType: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  // ldsNo: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationResult: Yup.string()
  //     .required(<FormattedMessage id="validation.message.required" />).nullable(),
  // evaluationDate: Yup.date()
  //     .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable(),
  /*
    name: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    nameTc: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    address: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    addressTc: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    tel: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    fax: Yup.string()
        .nullable(),
    website: Yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "網址格式錯誤"
        ).nullable(),
    email: Yup.string()
        .email(<FormattedMessage id="validation.message.invalidEmailFormat" />).nullable(),
    license: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    licenseExpiryDate: Yup.date()
        .required(<FormattedMessage id="validation.message.required" />).typeError(<FormattedMessage id="validation.message.invalidDateFormat" />).nullable(),
    brNo: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable()*/
});

export const DataInputForm = (props) => {
  const { entity, ...rest } = props;
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, resetForm } = useFormikContext();

  // useEffect(() => {
  //     resetForm({ values: initialValues });
  //     if (entity) {
  //         resetForm({ values: { ...initialValues, ...entity } });
  //     } else {
  //         resetForm({values: initialValues});
  //     }
  // }, [entity]);
  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              個人資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="中文姓氏" name="lastnameTc" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="中文名字" name="firstnameTc" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="英文姓氏" name="lastname" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="英文名字" name="firstname" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="性別"
                  name="gender"
                  options={GENDER_OPTIONS}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField required label="出生日期" name="dateOfBirth" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikHKIDField required label="身份證號碼" name="hkid" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              聯絡人資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="聯絡人" name="contactName" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label="聯絡人電話"
                  name="contactTel"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="關係" name="contactRelationship" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              備註
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="房間備註" name="roomRemarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={8} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              床位資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="床號" name="bedNo" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikSelectField
                  required
                  label="床位類別"
                  name="bedType"
                  options={BED_TYPE_OPTIONS}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikDateField
                  required
                  label="預計入住日期"
                  name="estimatedCheckInDate"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              評核資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="LSD編號" name="ldsNo" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikSelectField
                  label="評核結果"
                  name="evaluationResult"
                  options={EVALUATION_RESULT_OPTION}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikDateField label="評核日期" name="evaluationDate" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
