import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import { FormikDialog } from "../../../../components/dialogs";
import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import FormikDateTimeField from "../../../../components/inputs/FormikDateTimeField";
import { API_URL, FOLLOW_UP_STATUS_OPTION, MEDICAL_ITEM_STATUS_OPTION } from "../../../../constants";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import FormikSwitchField from "../../../../components/inputs/FormikSwitchField";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FormLabel from "@material-ui/core/FormLabel";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

export const initialValues = {
  // treatment form fields
  submitFollowupForm: false,
  treatmentItem: "",
  treatmentStatus: "",
  treatmentRemarks: "",
  dateTime: moment(new Date()).toISOString(),
  finishedDateTime: "",
  // followup form fields
  date: "",
  followupStatus: "",
  followupRemarks: "",
  institution: "",
  specialist: "",
  medicalFollowUpItems: [],
};

export const validationSchema = Yup.object().shape({
  // treatment form fields
  treatmentItem: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  treatmentStatus: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  // end of treatment form fields
  // followup form fields
  date: Yup.date().when(["submitFollowupForm", "dateTime"], (submitFollowupFormValue, dateTimeValue, schema) => {
    // if submitFollowupForm is not true, meaning the followup form is hidden
    if (!submitFollowupFormValue) {
      return schema.notRequired().nullable();
    }
    if (dateTimeValue) {
      return schema
        .required(<FormattedMessage id="validation.message.required" />)
        .min(dateTimeValue, '此日期及時間必須在"醫療項目日期及時間"之後')
        .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
        .nullable();
    }
    return schema
      .required(<FormattedMessage id="validation.message.required" />)
      .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
      .nullable();
  }),
  followupStatus: Yup.string().when("submitFollowupForm", (value, schema) => {
    if (!value) {
      return schema.notRequired().nullable();
    }
    return schema
      .required(<FormattedMessage id="validation.message.required" />)
      .nullable();
  }),
  institution: Yup.string().when("submitFollowupForm", (value, schema) => {
    if (!value) {
      return schema.notRequired().nullable();
    }
    return schema
      .required(<FormattedMessage id="validation.message.required" />)
      .nullable();
  }),
  specialist: Yup.string().when("submitFollowupForm", (value, schema) => {
    if (!value) {
      return schema.notRequired().nullable();
    }
    return schema
      .required(<FormattedMessage id="validation.message.required" />)
      .nullable();
  }),
  // end of followup form fields
});

export function CreateDialog(props) {
  const { open, onClose, onSubmit } = props;
  const [entityValues, setEntityValues] = useState();

  return (
    <FormikDialog
      initialValues={initialValues}
      validationSchema={validationSchema}
      open={open}
      dialogTitle={"院友資料"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="md"
    >
      <Form entity={entityValues} />
    </FormikDialog>
  );
}

const Form = (props) => {
  const home = useSelector(state => state.home);
  const { entity } = props;
  const { initialValues, values, resetForm } = useFormikContext();
  const [submitFollowupFormSwitchChecked, setSubmitFollowupFormSwitchChecked] = useState(false);

  return (
    <form>
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              院友資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="醫療項目狀態"
                  name="treatmentStatus"
                  options={MEDICAL_ITEM_STATUS_OPTION}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              醫療項目
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="醫療項目"
                  name="treatmentItem"
                  apiUrl={API_URL.MEDICAL_TREATMENT_ITEM}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) => option.nameTc}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateTimeField
                  required
                  label="醫療項目日期及時間"
                  name="dateTime"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateTimeField
                  label="完成醫療項目日期及時間"
                  name="finishedDateTime"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="treatmentRemarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <FormikSwitchField
              name="submitFollowupForm"
              label="填寫覆診資料"
              checked={submitFollowupFormSwitchChecked}
              setChecked={() => {
                values.submitFollowupForm = !values.submitFollowupForm;
                setSubmitFollowupFormSwitchChecked(!submitFollowupFormSwitchChecked);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {submitFollowupFormSwitchChecked && (
        <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
              覆診資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikDateTimeField
                  required
                  label="覆診日期及時間"
                  name="date"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="覆診狀態"
                  name="followupStatus"
                  options={FOLLOW_UP_STATUS_OPTION}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="醫院 / 診所"
                  name="institution"
                  apiUrl={"/api/institutions"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) =>
                    option.code
                      ? option.code + " - " + option.nameTc
                      : option.nameTc
                  }
                  getOptionValue={(option) => option.id}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="專科"
                  name="specialist"
                  apiUrl={"/api/specialists"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.nameTc
                  }
                  getOptionValue={(option) => option.id}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <fieldset>
                  <FormLabel component="legend">覆診事項</FormLabel>
                  <FieldArray
                    name="medicalFollowUpItems"
                    render={(arrayHelpers) => (
                      <div>
                        <TableContainer style={{ maxHeight: 400 }}>
                          <Table
                            stickyHeader
                            padding="none"
                            style={{ marginBottom: 15 }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  omponent="th"
                                  style={{ padding: 10 }}
                                >
                                  項目
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ width: 50 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values.medicalFollowUpItems &&
                                values.medicalFollowUpItems.map(
                                  (item, index) => (
                                    <TableRow key={index} disablegutters>
                                      <TableCell style={{ paddingRight: 1 }}>
                                        <FormikReferenceField
                                          edit={entity ? true : false}
                                          label="項目"
                                          apiUrl={
                                            API_URL.MEDICAL_FOLLOW_UP_ITEM
                                          }
                                          getOptionLabel={(option) =>
                                            option?.nameTc
                                          }
                                          getOptionValue={(option) =>
                                            option?.id
                                          }
                                          apiParams={{
                                            unpaged: true,
                                          }}
                                          name={`medicalFollowUpItems.${index}.id`}
                                        />
                                      </TableCell>
                                      <TableCell align="right">
                                        <IconButton
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <DeleteOutlinedIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Button
                          color="default"
                          variant="contained"
                          type="button"
                          size="small"
                          onClick={() => arrayHelpers.push("")}
                        >
                          新增
                        </Button>
                      </div>
                    )}
                  />
                </fieldset>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  label="交通工具"
                  name="transport"
                  apiUrl={"/api/transport"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) => option.nameTc}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormikTextField label="陪診員" name="escorterName" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  label="關係"
                  name="escorterRelationship"
                  apiUrl={"/api/escorterRelationship"}
                  apiParams={{
                    unpaged: true,
                  }}
                  getOptionLabel={(option) => option.nameTc}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="備註" name="followupRemarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        </Grid>
      )}

    </Grid>
    </form>
  )
}
