import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  ROUTES,
  STAFF_ROSTER_OPTION,
  STAFF_POSITION_OPTION,
} from "../../constants";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import TocOutlinedIcon from "@material-ui/icons/TocOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { DetailsDialog } from "./components/DetailsDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function RosterManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editOpen, setEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    let dayofmonth = moment(values.startDate).daysInMonth();
    params.startDate = moment(values.startDate).format("yyyy-MM-01");
    params.endDate = moment(values.startDate).format("yyyy-MM-") + dayofmonth;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改整個記錄的標籤">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除整個記錄">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteSweepOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="查看">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Link to={"/rosterManagement/" + params.id}>
                <Tooltip title="詳細資料">
                  <IconButton color="default" size="small" disabled={loading}>
                    <TocOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            </div>
          );
        },
      },
      {
        field: "staff",
        headerName: "員工姓名",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["staffUserRoster"].staffUser.nameTc;
        },
      },
      {
        field: "roster",
        headerName: "相應更期",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STAFF_ROSTER_OPTION.find(
            (elem) => elem.value == params.row["staffUserRoster"].roster
          )?.label;
        },
      },
      {
        field: "position",
        headerName: "相應職級",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return STAFF_POSITION_OPTION.find(
            (elem) => elem.value == params.row["staffUserRoster"].position
          )?.label;
        },
      },
      {
        field: "workingTime",
        headerName: "上班時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row["startTime"].substring(0, 5) +
            " - " +
            params.row["endTime"].substring(0, 5)
          );
        },
      },
      {
        field: "workingDays",
        headerName: "上班日數",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["rosterSessions"].length;
        },
      },
      {
        field: "tag",
        headerName: "更表標籤",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.STAFF_ROSTER_RECURRING + "/" + values.id, {
        tag: values.tag,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.STAFF_ROSTER_RECURRING,
      getOne: API_URL.STAFF_ROSTER_RECURRING,
      create: API_URL.STAFF_ROSTER_RECURRING,
      edit: API_URL.STAFF_ROSTER_RECURRING,
      delete: API_URL.STAFF_ROSTER_RECURRING,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="更表"
      editableFields={[
        "resident",
        "residentInvoiceItems",
        "issueDateTime",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
