import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  ROUTES,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import { initialFilteringValues } from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { DetailsDialog } from "./components/DetailsDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";

export default function DefaultExpensePage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.resident = params.resident.id || params.resident;

    params.startDate = moment(values.startDate).startOf("month").format("YYYY-MM-DD");
    params.endDate = moment(values.endDate).endOf("month").format("YYYY-MM-DD");

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const normalizeReceivedValues = (values) => {
    // values.defaultExpenseItemEntityList.map((item) => {
    //   item.invoiceItem = item.invoiceItem.id;
    // });
    values.resident = values.resident.id || values.resident;
    values.defaultExpenseItemViewList.map((item) => {
      item.invoiceItem = item.invoiceItem.id;
    });
    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改費用">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row["resident"].lastnameTc +
            params.row["resident"].firstnameTc
          );
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].lastname &&
            params.row["resident"].firstname
            ? params.row["resident"].lastname +
                " " +
                params.row["resident"].firstname
            : "--";
        },
      },
      {
        field: "expense",
        headerName: "金額",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return "HK$ " + params.row["defaultExpenseItemViewList"]?.reduce((acc, item) => {
              return acc + item['qty'] * item['price'];
          }, 0).toLocaleString();
          // return params.row["defaultExpenseItemViewList"][0]['qty'];
        },
      },
    ];
  };

  const onEdit = (values, actions) => {
    axios
      .put(API_URL.DEFAULT_EXPENSE + "/" + values.id, {
        home: values.home.id,
        resident: values.resident.id || values.resident,
        defaultExpenseItemEntityList: values.defaultExpenseItemViewList.map((item) => ({
          description: item.description,
          invoiceItem: item.invoiceItem,
          price: item.price,
          qty: item.qty,
          airConditioningFee: item.airConditioningFee,
        })),
        startDate: moment(values.startDate).startOf("month").format("YYYY-MM-DD"),
        endDate: moment(values.endDate).endOf("month").format("YYYY-MM-DD"),
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.DEFAULT_EXPENSE,
      getOne: API_URL.DEFAULT_EXPENSE,
      create: API_URL.DEFAULT_EXPENSE,
      edit: API_URL.DEFAULT_EXPENSE,
      delete: API_URL.DEFAULT_EXPENSE,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="預設費用"
      editableFields={[
        "resident",
        "defaultExpenseItemEntityList",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      checkboxSelection={true}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
