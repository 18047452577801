import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export default {
  root: {
    [createMuiTheme().breakpoints.up('sm')]: {
      minWidth: '100px',
    },
    padding: '5px 15px'
  },
  scrollable: {
    minWidth: '300px'
  }
};
