import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, ROUTES, RESERVATION_STATUS_OPTIONS } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import InputIcon from "@material-ui/icons/Input";
import { CheckInDialog } from "./components/CheckInDialog";
import { CheckInAgainDialog } from "./components/CheckInAgainDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import { history } from "../../history";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import fileDownload from "js-file-download";
import "moment-timezone";

export default function ReservationManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [checkin, setCheckIn] = useState(false);
  const [checkinEntity, setCheckInEntity] = useState(null);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editEntity, setEditEntity] = useState(null);
  const [reload, setReload] = useState(false);
  const [createCheckInAgain, setCreateCheckInAgain] = useState(false);
  const [checkInAgainEntity, setCheckInAgainEntity] = useState(null);

  const createParams = (values, coreCreateParams) => {
    let params;
    if (coreCreateParams) {
      params = coreCreateParams(values);
    } else {
      params = {...values};
    }

    params.homeId = home?.home?.id;

    params.estimatedCheckInDate = moment(values.estimatedCheckInDate).format(
      "YYYY-MM-DD"
    );

    if (params.checkInDate) {
      params.checkInDate = moment(params.checkInDate).format(
        "YYYY-MM-DD"
      );
    }

    if (values.hkid === "_______(_)") {
      params.hkid = null;
    }

    if (values.downPrice == "") {
      params.downPrice = null;
    }
    if (values.downIssueTime == "") {
      params.downIssueTime = null;
    }
    if (values.downPaymentMethod == "") {
      params.downPaymentMethod = null;
    }

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.homeId = home?.home?.id;

    if (params.dateFrom != null) {
      params.dateFrom = moment(values.dateFrom).format("YYYY-MM-DD");
    }

    if (params.dateTo != null) {
      params.dateTo = moment(values.dateTo).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.residentBedType = values.residentBedType.id;
    values.bed = values.bed.id;
    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={params.row["status"] != "RESERVING" || loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="列印訂位確認書">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    onPrint(params.row.id);
                  }}
                  disabled={loading}
                >
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="入住登記">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCheckInEntity(params);
                    setCheckIn(true);
                  }}
                  disabled={params.row["status"] != "RESERVING" || loading}
                >
                  <InputIcon />
                </IconButton>
              </Tooltip>
              <IconButton
                color="default"
                size="small"
                disabled={loading}
                style={{ display: "none" }}
              >
                <LocalAtmOutlinedIcon />
              </IconButton>
            </div>
          );
        },
      },
      {
        field: "reservationDate",
        headerName: "預約日期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "refNo",
        headerName: "訂位編號",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "院友姓名 (中文)",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["lastnameTc"] && params.row["firstnameTc"]
            ? params.row["lastnameTc"] + params.row["firstnameTc"]
            : "--";
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["lastname"] && params.row["firstname"]
            ? params.row["lastname"] + params.row["firstname"]
            : "--";
        },
      },
      {
        field: "hkid",
        headerName: "身份證號碼",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "bed",
        headerName: "預定床位",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["bed"] && params.row["bed"]["bedNo"];
        },
      },
      {
        field: "estimatedCheckInDate",
        headerName: "預定入住日期",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "status",
        headerName: "訂位狀態",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESERVATION_STATUS_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "deposit",
        headerName: "訂金",
        width: 80,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "residentId",
        headerName: "院友編號",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.generatedId
            ? params.row["resident"]?.generatedId
            : "--";
        },
      },
      {
        field: "checkInDate",
        headerName: "入住日期",
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.checkInDate
            ? params.row["resident"]?.checkInDate?.substring(0, 10)
            : "--";
        },
      },
      {
        field: "currentBed",
        headerName: "現在床位",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.bed
            ? params.row["resident"]?.bed?.bedNo
            : "--";
        },
      },
    ];
  };

  const onCheckIn = (values, actions) => {
    axios
      .put(API_URL.RESERVATION + "/" + values.id + "/checkIn", {
        bed: values?.bed,
        checkInDate: moment(values?.checkInDate).format("YYYY-MM-DD"),
      })
      .then((response) => {
        history.push(ROUTES.RESIDENT.replace(":id", response.data.resident.id));
        dispatch(NotificationActions.success(null, "已入住"));
        actions.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onCheckInAgain = (values, actions) => {
    axios
      .put(API_URL.RESIDENT_DISCHARGED_TO_CHECKIN + "/" + values.resident)
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setCreateCheckInAgain(false);
        setCheckInAgainEntity(null);
        setReload(setReload(!reload));
        actions.setSubmitting(false);

        // axios.get(API_URL.RESERVATION, {
        //   status: "RESERVING",
        //   unpaged: true,
        // })
        //   .then((response) => {
        //     const reservations = response.data?.content || response.data || [];
        //     const reservationOfResident = reservations.find(r => r.resident?.id === values.resident);
        //     if (!reservationOfResident) return;

        //     const params = createParams(values);
        //     params.id = reservationOfResident.id;
        //     console.log("reservationOfResident's id:", reservationOfResident.id);
        //     onEdit(params, actions);
        //   })
        //   .catch((error) => dispatch(NotificationActions.error(null, "錯誤")));
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  const onEdit = (values, actions) => {
    if (values.hkid === "_______(_)") {
      values.hkid = null;
    }
    axios
      .put(API_URL.RESERVATION + "/" + values.id, {
        lastnameTc: values.lastnameTc,
        lastname: values.lastname,
        firstnameTc: values.firstnameTc,
        firstname: values.firstname,
        gender: values.gender,
        hkid: values.hkid,
        homeReturnPermitId: values.homeReturnPermitId,
        dateOfBirth: values.dateOfBirth,
        bed: values.bed,
        residentBedType: values.residentBedType,
        estimatedCheckInDate: values.estimatedCheckInDate
          ? moment(values.estimatedCheckInDate).format("yyyy-MM-DD")
          : values.estimatedCheckInDate,
        contactName: values.contactName,
        contactNo: values.contactNo,
        contactRelationship: values.contactRelationship,
        roomRemarks: values.roomRemarks,
        ldsNo: values.ldsNo,
        evaluationResult: values.evaluationResult,
        evaluationDate: values.evaluationDate
          ? moment(values.evaluationDate).format("yyyy-MM-DDTHH:mm:ss")
          : values.evaluationDate,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        actions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        if (
          error.response.data?.errors &&
          error.response.data?.errors[0].includes("hkid invalid")
        ) {
          dispatch(NotificationActions.error(null, "身份證號碼不正確"));
        } else {
          dispatch(NotificationActions.error(null, "錯誤"));
        }
        actions.setSubmitting(false);
      });
  };

  const onPrint = (values, formikActions) => {
    axios
      .get(apiUrl.print ? apiUrl.print + "/print/" + values : apiUrl, {
        responseType: "blob",
      })
      .then(function (response) {
        let data = response.data;
        fileDownload(
          data,
          moment().format("YYYY-MM-DD_HH_mm_SS") + "_訂位確認書_print.docx"
        );
      });
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESERVATION,
      getOne: API_URL.RESERVATION,
      create: API_URL.RESERVATION,
      edit: API_URL.RESERVATION,
      delete: API_URL.RESERVATION,
      print: API_URL.RESERVATION,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="訂位"
      editableFields={[
        "lastnameTc",
        "lastname",
        "firstnameTc",
        "firstname",
        "gender",
        "hkid",
        "homeReturnPermitId",
        "dateOfBirth",
        "bed",
        "residentBedType",
        "estimatedCheckInDate",
        "contactName",
        "contactNo",
        "contactRelationship",
        "roomRemarks",
        "ldsNo",
        "evaluationResult",
        "evaluationDate",
        "downPrice",
        "downIssueTime",
        "downPaymentMethod",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setCreateCheckInAgain: setCreateCheckInAgain,
      }}
    >
      <CheckInDialog
        open={checkin}
        entity={checkinEntity}
        onClose={() => {
          setCheckInEntity(null);
          setCheckIn(false);
        }}
        onSubmit={onCheckIn}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <CheckInAgainDialog
        open={createCheckInAgain}
        entity={checkInAgainEntity}
        onClose={() => {
          setCheckInAgainEntity(null);
          setCreateCheckInAgain(false);
        }}
        onSubmit={onCheckInAgain}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
