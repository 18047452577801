import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikDialog } from './FormikDialog';

export const FormikMergeCreateDialog = props => {
    const { dialogTitle, entityModel, ...rest } = props;
    const [title, setTitle] = useState(dialogTitle);

    useEffect(() => {
        if (!title && entityModel) {
            setTitle(<FormattedMessage id="entity.create" values={{ entityModel: "生命表徵" }} />);
        }
    }, []);

    return (
        <FormikDialog
            dialogTitle={title}
            {...rest}
        >
            {props.children}
        </FormikDialog>
    );
}