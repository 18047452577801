import { useDropzone } from "react-dropzone";
import React from "react";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useFormikContext } from "formik";
import { Button, makeStyles } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import RefreshOutlinedIcon from "@material-ui/icons/RefreshOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";
import Image from "../Image";
import documentImg from "../document-img.png";

const useStyles = makeStyles((theme) => ({
  zone: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 89,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    borderColor: "rgba(0,0,0,0.23)",
    color: theme.palette.grey["500"],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
  },
  drop: {
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  preview: {
    justifyContent: "start",
  },
  previewItem: {
    padding: theme.spacing(1),
  },
  previewImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 50,
    minHeight: 50,
  },
  previewImage: {
    maxWidth: 50,
    maxHeight: 50,
  },
  previewDrop: {
    flexGrow: 1,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const FormikUploadDropzone = (props) => {
  const { name, policy, previewImageStyle, onChange, document } = props;

  const classes = useStyles();

  const {
    values = {},
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting,
  } = useFormikContext();

  const { getRootProps, getInputProps } = useDropzone({
    accept: document ? null : "image/jpg, image/png, image/jpeg",

    onDrop: (acceptedFiles) => {
      if (!acceptedFiles || acceptedFiles.length === 0) {
        return;
      }

      (values.files || []).forEach((file) => URL.revokeObjectURL(file.preview));

      setFieldValue(
        "files",
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropRejected: () => {
      setFieldTouched(name, true, false);
      setFieldError(name, "上傳錯誤，請上傳單個檔案！");
    },
    multiple: false,
    disabled: isSubmitting,
    onDragEnter: () => {},
    onDragOver: () => {},
    onDragLeave: () => {},
  });

  // Status of files can be "uploading", "failed", "successful"

  React.useEffect(() => {
    // Upload files

    if ((values.files || []).length === 1) {
      const uploadFile = values.files[0];

      if (!uploadFile.status) {
        setFieldValue(
          "files",
          (values.files || []).map((file) => {
            if (file.path === uploadFile.path) {
              return Object.assign(file, {
                status: "uploading",
              });
            } else {
              return Object.assign(file);
            }
          })
        );

        const formData = new FormData();

        formData.append("file", uploadFile);
        formData.append("policy", policy);

        axios
          .post("/api/upload", formData, {
            onUploadProgress: (progressEvent) => {
              if (uploadFile.status === "failed") {
                return;
              }

              setFieldValue(
                "files",
                (values.files || []).map((file) => {
                  if (file.path === uploadFile.path) {
                    return Object.assign(file, {
                      progress:
                        (progressEvent.loaded / progressEvent.total) * 100,
                    });
                  } else {
                    return Object.assign(file);
                  }
                })
              );
            },
          })
          .then((response) => {
            if (uploadFile.status === "failed") {
              return;
            }

            setFieldValue(
              "files",
              (values.files || []).map((file) => {
                if (file.path === uploadFile.path) {
                  return Object.assign(file, {
                    status: "successful",
                  });
                } else {
                  return Object.assign(file);
                }
              })
            );

            setFieldValue(
              name,
              response.data.location.replace(/\\/g, "/"),
              true
            );

            onChange && onChange();
          })
          .catch(() => {
            setFieldValue(
              "files",
              (values.files || []).map((file) => {
                if (file.path === uploadFile.path) {
                  return Object.assign(file, {
                    status: "failed",
                    progress: undefined,
                  });
                } else {
                  return Object.assign(file);
                }
              })
            );

            setFieldTouched(name, true, false);
            setFieldError(name, "上傳失敗");
          });
      }
    }

    if ((values.files || []).length > 1) {
      setFieldTouched(name, true, false);
      setFieldError(name, "每次只能上傳一個文件");
    }
  }, [values, setFieldTouched, setFieldValue, setFieldError, name]);

  const handleStopFile = (targetFile) => {
    setFieldValue(
      "files",
      (values.files || []).map((file) => {
        if (file.path === targetFile.path) {
          return Object.assign(file, {
            status: "failed",
            progress: undefined,
          });
        } else {
          return Object.assign(file);
        }
      })
    );
  };

  const handleRefreshFile = (targetFile) => {
    setFieldValue(
      "files",
      (values.files || []).map((file) => {
        if (file.path === targetFile.path) {
          return Object.assign(file, {
            status: undefined,
            progress: undefined,
          });
        } else {
          return Object.assign(file);
        }
      })
    );
  };

  const handleDeleteFile = (targetFile) => {
    setFieldValue(name, null, true);

    setFieldValue(
      "files",
      (values.files || []).filter((file) => file.path !== targetFile.path)
    );

    URL.revokeObjectURL(targetFile.preview);

    onChange && onChange();
  };

  const dropzone = (
    <Paper
      elevation={0}
      className={clsx(classes.zone, classes.drop)}
      {...getRootProps()}
    >
      <Typography variant="subtitle2" color="inherit">
        {document
          ? "將文件拖放到此處或單擊以上傳"
          : "將圖片拖放到此處或單擊以上傳"}
      </Typography>
      <CloudUploadOutlinedIcon color="inherit" fontSize="large" />
      <input {...getInputProps()} />
    </Paper>
  );

  const dropzoneWithPreview = (
    <Paper elevation={0} className={clsx(classes.zone, classes.preview)}>
      {values &&
        (values.files || []).map((file) => (
          <React.Fragment key={file.name}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.previewItem}
            >
              <Grid item className={classes.previewImageContainer} xs={12}>
                {document ? (
                  <div>
                    <img
                      src={documentImg}
                      style={{ maxWidth: "150px", maxHeight: "200px" }}
                    />
                    {file.name}
                  </div>
                ) : (
                  <Image
                    className={classes.previewImage}
                    url={file.preview}
                    alt={file.name}
                    style={{ maxWidth: "150px", maxHeight: "200px" }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  // justify="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={8}>
                    {file.status !== "successful" && (
                      <LinearProgress
                        variant="determinate"
                        color="secondary"
                        value={file.progress || 0}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {file.status === "uploading" ? (
                      <IconButton
                        color="secondary"
                        onClick={() => handleStopFile(file)}
                        size="small"
                      >
                        <HighlightOffOutlinedIcon />
                      </IconButton>
                    ) : file.status === "failed" ? (
                      <IconButton
                        color="secondary"
                        onClick={() => handleRefreshFile(file)}
                        size="small"
                      >
                        <RefreshOutlinedIcon />
                      </IconButton>
                    ) : document === true ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        endIcon={<RefreshOutlinedIcon />}
                        onClick={() => handleDeleteFile(file)}
                      >
                        繼續上傳
                      </Button>
                    ) : (
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteFile(file)}
                        size="small"
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      <Grid
        className={classes.previewDrop}
        container
        justify="center"
        alignItems="center"
        {...getRootProps()}
      >
        <Grid item>{!document && <EditOutlinedIcon color="inherit" />}</Grid>
        <Grid item>
          <Typography variant="subtitle2" color="inherit">
            {document ? "" : "將圖片拖放到此處或單擊以更改"}
          </Typography>
        </Grid>
        <input {...getInputProps()} />
      </Grid>
    </Paper>
  );

  const dropzoneWithExistingPreview = (
    <Paper elevation={0} className={clsx(classes.zone, classes.preview)}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.previewItem}
      >
        <Grid item className={classes.previewImageContainer}>
          {document ? (
            <img
              src={documentImg}
              style={{ maxWidth: "150px", maxHeight: "200px" }}
            />
          ) : (
            <Image
              className={classes.previewImage}
              url={values[name]}
              style={{ maxWidth: "150px", maxHeight: "200px" }}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end" alignItems="center" spacing={1}>
            <Grid item xs={8} />
            <Grid item>
              <IconButton
                color="secondary"
                onClick={() => {
                  setFieldValue(name, null, true) && onChange && onChange();
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid
        className={classes.previewDrop}
        container
        justify="center"
        alignItems="center"
        {...getRootProps()}
      >
        <Grid item>
          <EditOutlinedIcon color="inherit" />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" color="inherit">
            {document ? "" : "將圖片拖放到此處或單擊以更改"}
          </Typography>
        </Grid>
        <input {...getInputProps()} />
      </Grid>
    </Paper>
  );

  const errorMessage = touched[name] && errors[name];

  return (
    <React.Fragment>
      {(values.files || []).length > 0
        ? dropzoneWithPreview
        : values[name]
        ? dropzoneWithExistingPreview
        : dropzone}
      <Typography
        component="div"
        variant="caption"
        color="textSecondary"
        gutterBottom
      >
        {document ? "" : " 僅支持JPG, PNG類型"}
      </Typography>
      <Typography component="div" variant="caption" color="textSecondary">
        檔案大小上限為10MB
      </Typography>
      {errorMessage && (
        <FormHelperText error={Boolean(errorMessage)}>
          {errorMessage}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

export default FormikUploadDropzone;
