import { useDispatch, useSelector } from "react-redux";
import { NotificationActions } from "../actions";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

export function Notification(props) {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  const useStyles = makeStyles((theme) => ({
    root: {
      marginBottom: theme.spacing(3),
      whiteSpace: "pre-line",
    },
  }));

  const classes = useStyles();

  return notification.title || notification.message ? (
    <Alert
      className={classes.root}
      severity={notification.type}
      onClose={() => {
        dispatch(NotificationActions.clear());
      }}
    >
      {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
      {notification.message}
    </Alert>
  ) : null;
}
