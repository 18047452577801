import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import InputAdornment from "@material-ui/core/InputAdornment";

export const initialValues = {
  expenseItem: "",
  supplier: "",
  issueDateTime: moment(new Date()).toISOString(),
  status: "NOT_YET_PAID",
  amount: "",
  amountConfirmation: "",
};

export const validationSchema = Yup.object().shape({
  expenseItem: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  supplier: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  issueDateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  amount: Yup.string()
    .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  amountConfirmation: Yup.string().when("amount", (value, schema) => {
    if (!value) {
      return schema.notRequired();
    }
    return schema
      .oneOf([Yup.ref("amount")], "確認金額與金額不符")
      .required(<FormattedMessage id="validation.message.required" />);
  }),
});

export const DataInputForm = (props) => {
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="支出項目"
                  name="expenseItem"
                  apiUrl={"/api/expenseItems?type=EXPENSE"}
                  getOptionLabel={(option) => option.nameTc}
                  apiParams={{
                    unpaged: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  label="供應商"
                  name="supplier"
                  apiUrl={"/api/expenseSuppliers"}
                  getOptionLabel={(option) => option.nameTc}
                  apiParams={{
                    unpaged: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateTimeField
                  required
                  label="發票日期及時間"
                  name="issueDateTime"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="描述" name="description" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  type="number"
                  label="金額"
                  name="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  type="number"
                  label="確認金額"
                  name="amountConfirmation"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
