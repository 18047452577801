import * as Yup from 'yup';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../components/inputs/FormikTextField";

export const initialValues = {
    bandCode: '',
    resident: '',
}

export const validationSchema = Yup.object().shape({
    bandCode: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const DataInputForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const { initialValues, values, setValues, touched, errors, setFieldValue, resetForm } = useFormikContext();

    useEffect(() => {
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({ values: initialValues });
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField
                                    required
                                    label="智能手帶編號"
                                    name="bandCode"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
