export default {
  fontFamily: [
    'Roboto',
    '"Open Sans"',
    '"Microsoft JhengHei"',
    '"微軟正黑體"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(","),
  h1: {
    fontWeight: 700,
    fontSize: "35px",
    letterSpacing: "-0.24px",
    lineHeight: "40px"
  },
  h2: {
    fontWeight: 700,
    fontSize: "29px",
    letterSpacing: "-0.24px",
    lineHeight: "32px"
  },
  h3: {
    fontWeight: 700,
    fontSize: "24px",
    letterSpacing: "-0.06px",
    lineHeight: "28px"
  },
  h4: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "-0.06px",
    lineHeight: "24px"
  },
  h5: {
    fontWeight: 700,
    fontSize: "18px",
    letterSpacing: "-0.05px",
    lineHeight: "22px"
  },
  h6: {
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "20px"
  },
  subtitle1: {
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "25px"
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px"
  },
  body1: {
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px"
  },
  body2: {
    fontSize: "12px",
    letterSpacing: "-0.04px",
    lineHeight: "18px"
  },
  button: {
    fontSize: "14px"
  },
  caption: {
    fontSize: "11px",
    letterSpacing: "0.33px",
    lineHeight: "13px"
  },
  overline: {
    fontSize: "11px",
    fontWeight: 700,
    letterSpacing: "0.33px",
    lineHeight: "13px",
    textTransform: "uppercase"
  }
};
