import * as Yup from "yup";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikDateField from "../../../components/inputs/FormikDateField";

export const initialValues = {
  item: "",
  dateTime: "",
};

export const validationSchema = Yup.object().shape({
  item: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  dateTime: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField required label="提醒事項名稱" name="item" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikDateField
                  required
                  label="到期日子"
                  name="dateTime"
                  startTime
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
