import { Fragment } from 'react';
import * as Yup from 'yup';
import { API_URL, ROUTES, FOLLOW_UP_STATUS_OPTION } from '../../../../constants';
import { useFormikContext } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../../components/inputs/FormikDateField";
import { FormLabel } from '@material-ui/core';
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import Button from "@material-ui/core/Button";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';

export const initialFilteringValues = {
    status: '',
    dateFrom: '',
    dateTo: '',
    nameTc: '',
    name: '',
}

export const filteringValidationSchema = Yup.object().shape({
});

const useStyles = makeStyles((theme) => ({
    containerFields: {
        marginBottom: theme.spacing(6),
    },
    button: {
        marginRight: theme.spacing(3),
    },
}));

export const FilteringForm = props => {
    const { handleFilterChange } = props;
    const { values, isSubmitting, submitForm, resetForm } = useFormikContext();
    const classes = useStyles();

    const handleSubmit = (event) => {
        handleFilterChange({
            ...values,
            timestamp: new Date() //Force refresh when click submit without changing values
        });
    }

    const handleReset = (event) => {
        resetForm();
        handleFilterChange(initialFilteringValues);
    }

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item className={classes.containerFields} xs={12} md={12}>
                    <fieldset>
                        <FormLabel component="legend">搜尋</FormLabel>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormikSelectField
                                    label="項目狀態"
                                    options={FOLLOW_UP_STATUS_OPTION}
                                    name="status"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormikDateField
                                    label="搜尋日期由"
                                    name="dateFrom"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <FormikDateField
                                    label="搜尋日期至"
                                    name="dateTo"
                                />
                            </Grid>
                            <Grid item item xs={12} md={6} lg={3} style={{ alignSelf: 'center' }}>
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                    startIcon={<SearchOutlinedIcon />}
                                >
                                    篩選
                                </Button>
                                <Button
                                    className={classes.button}
                                    color="default"
                                    variant="outlined"
                                    onClick={handleReset}
                                    disabled={isSubmitting}
                                    startIcon={<RotateLeftOutlinedIcon />}
                                >
                                    重設
                                </Button>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
            </Grid>
        </Fragment>
    );
}