import { apiService } from "../services";
import { ACCESS_TOKEN_KEY, USER_KEY } from "../constants/auth.constant";

export function getEntities(
  setLoading,
  createFilteringParams,
  coreCreateFilteringParams,
  apiUrl,
  setTotalRows,
  setRows,
  filteringParams,
  setListEmpty
) {
  setLoading(true);

  const getFilteringParams = createFilteringParams
    ? createFilteringParams
    : coreCreateFilteringParams;

  let params = getFilteringParams(filteringParams, coreCreateFilteringParams);

  delete params.timestamp; // remove timestamp when getting records

  apiUrl.getAllDetail
    ? apiService
        .get(apiUrl.getAllDetail ? apiUrl.getAllDetail : apiUrl, {
          params: params,
        })
        .then(function (response) {
          let data = response.data;
          setTotalRows(data?.rosterSessions?.length);
          setRows(data?.rosterSessions || []);
          setLoading(false);
        })
        .catch(function (response) {
          setLoading(false);
        })
    : apiService
        .get(apiUrl.getAll ? apiUrl.getAll : apiUrl, {
          params: params,
        })
        .then(function (response) {
          let data = response.data;
          setTotalRows(data.totalElements);
          setRows(data?.content || []);
          setLoading(false);
          setListEmpty(response.data.empty);
        })
        .catch(function (response) {
          setLoading(false);
        });
}
