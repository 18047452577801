import axios from "axios";
import { AuthService } from "../services";
//import apiService from "../services/api.service";

class axiosConfig {
  setInterceptors = () => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        return new Promise(() => {
          let response = error.response;

          if (AuthService.isErrorResponse(response)) {
            // Check if the error is due to OAuth 2.0 protection
            AuthService.handleErrorResponse(response);
          }/* else if (apiService.isErrorResponse(response)) {
            // Check if the error is due to api processing
            apiService.handleErrorResponse(response);
          }*/

          throw error;
        });
      }
    );
  };

  setAuthorizationHeader = accessToken => {
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  removeAuthorizationHeader = () => {
    this.setAuthorizationHeader(null);
  };
}

const instance = new axiosConfig();

export default instance;
