import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, ROUTES, RESIDENT_ICP_STATUS_OPTIONS } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import axios from "axios";
import moment from "moment";
import { NotificationActions } from "../../actions";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";

export default function IcpPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [editEntity, setEditEntity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    if (values.submitDate) {
      params.submitDate = moment(values.submitDate).format("YYYY-MM-DD");
    }
    params.resident = params.resident.id || params.resident;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      onPrint,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <IconButton color="default" size="small" onClick={() => { clearNotification(); setCheckInEntity(params); }} disabled={params.row['status'] != 'RESERVING' || loading}>
                                <InputIcon />
                            </IconButton>
                            <IconButton color="default" size="small" disabled={loading} style={{display: 'none'}}>
                                <LocalAtmOutlinedIcon />
                            </IconButton> */}
              <Tooltip title="列印">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    onPrint(params.row);
                  }}
                  disabled={loading}
                >
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名 (中文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "name",
        headerName: "院友姓名 (英文)",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].lastname &&
            params.row["resident"].firstname
            ? params.row["resident"].lastname +
                " " +
                params.row["resident"].firstname
            : "--";
        },
      },
      {
        field: "status",
        headerName: "ICP 狀態",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_ICP_STATUS_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "submitDate",
        headerName: "遞交 ICP 日期",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field].substring(0, 10);
        },
      },
      {
        field: "bmi",
        headerName: "BMI",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? Number(params.row[params.field]).toFixed(2)
            : "--";
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 300,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_ICP + "/" + values.id, {
        resident: values.resident,
        remarks: values.remarks,
        status: values.status,
        submitDate: moment(values.submitDate).format("YYYY-MM-DD"),
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    if (values.status == "SUBMITTED" && values.nextDate !== "") {
      axios.post(API_URL.RESIDENT_ICP, {
        resident: values.resident,
        remarks: values.remarks,
        status: "IN_DRAFT",
        submitDate: moment(values.nextDate).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_ICP,
      getOne: API_URL.RESIDENT_ICP,
      create: API_URL.RESIDENT_ICP,
      edit: API_URL.RESIDENT_ICP,
      delete: API_URL.RESIDENT_ICP,
      print: API_URL.RESIDENT_ICP + "/print",
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="ICP"
      editableFields={[
        "resident",
        "status",
        "remarks",
        "submitDate",
        "nextSubmitDate",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
