import { NOTIFICATION_TYPE } from '../constants';

export const NotificationActions = {
    success,
    error,
    info,
    warning,
    clear
};

function success(title, message) {
    return { type: NOTIFICATION_TYPE.SUCCESS, title, message };
}

function info(title, message) {
    return { type: NOTIFICATION_TYPE.INFO, title, message };
}

function warning(title, message) {
    return { type: NOTIFICATION_TYPE.WARNING, title, message };
}

function error(title, message) {
    return { type: NOTIFICATION_TYPE.ERROR, title, message };
}

function clear() {
    return { type: NOTIFICATION_TYPE.CLEAR };
}