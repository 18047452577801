import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PageHeader } from '../../components/PageHeader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Calendar from './components/calendar';
import Reminder from './components/reminder';
import MedicalTreatmentItemsReminder from './components/medicalTreatmentItemsReminder';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: 15 }}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        護老院
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  calendar: {
    padding: 15,
    height: 270,
    overflow: 'auto',
    minWidth: '100%',
    marginBottom: 10,
  },
  reminder: {
    padding: 15,
    height: 391,
    overflow: 'auto',
    minWidth: '100%'
  },
  medicalTreatmentItemsReminder: {
    padding: 15,
    height: 670,
    overflow: 'auto',
    minWidth: '100%'

  },
  paper: {
    minWidth: 'calc(100vw - 50px)',
  },
  paper2: {
    padding: 15,
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <PageHeader title="院舍總覽" />
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={3} className={classes.calendar}>
                <Calendar />
              </Paper>
              <Paper elevation={3} className={classes.reminder}>
                <Reminder />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Paper elevation={3} className={classes.medicalTreatmentItemsReminder}>
            <MedicalTreatmentItemsReminder />
          </Paper>
        </Grid>
      </Grid>
      <Copyright />
    </div>
  );
}