import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../constants";
// import {
//   FilteringForm,
//   initialFilteringValues,
//   filteringValidationSchema,
// } from "./components/FilteringForm";

import { ListingPageHeader } from "./components/ListingPageHeader";


export default function AntiWanderingOverallPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState({});

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.code = values.code;
    params.resident = params.resident.id || params.resident;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.IBEACON,
      getOne: API_URL.IBEACON,
    })
  }, [home]);

  return (
    <ListingPageHeader
        handleFilterChange={handleFilterChange}
        filteringParams={filteringParams}
        createFilteringParams={createFilteringParams}
    />
  );
}
