import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { useFormikContext } from "formik";
import _ from "lodash";
import { IMaskInput } from "react-imask";
import FormControl from "@material-ui/core/FormControl";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="#[#]000000(L)"
      definitions={{
        "#": /[A-Z]/,
        L: /[A0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      lazy={false}
    />
  );
});

const FormikHKIDField = (props) => {
  const { name, component: Component, ...rest } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    inputProps,
  } = useFormikContext();

  const Field = Component || TextField;

  return (
    <FormControl fullWidth>
      <Field
        variant={rest.variant || "outlined"}
        id={rest.id || name}
        margin={rest.margin || "dense"}
        fullWidth
        name={name}
        value={_.get(values, name) || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={rest.disabled || isSubmitting}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
        helperText={_.get(touched, name) && _.get(errors, name)}
        InputProps={{
          inputComponent: TextMaskCustom,
          startAdornment: <InputAdornment></InputAdornment>,
        }}
        {...rest}
      />
    </FormControl>
  );
};

export default FormikHKIDField;
