import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  API_URL,
  ROUTES,
  RESIDENT_RESTRAINT_STATUS_OPTION,
  RESIDENT_RESTRAINT_ITEM_OPTION,
  RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION,
} from "../../../../constants";
import { Formik, useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../../components/dialogs";
import FormikDateField from "../../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import axios from "axios";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';

export const initialValues = {
  resident: "",
  status: "",
  startDate: moment(new Date()).toISOString(),
  endDate: "",
  residentRestraintItemList: [],
};

export const validationSchema = Yup.object().shape({
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function RestraintItemViewDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get(API_URL.RESIDENT_RESTRAINT_ITEM + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          // normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);


  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"約束物品詳細資料"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <div>基本資料</div>
              <Card variant="outlined" style={{ marginBottom: 15 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      { `院友姓名: ${entityValues?.resident.lastnameTc}${entityValues?.resident.firstnameTc}` }
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      { `狀態: ${RESIDENT_RESTRAINT_STATUS_OPTION.find(o => o.value === entityValues?.status )?.label}` }
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      { `開始日期: ${entityValues?.startDate}` }
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      { `結束日期: ${entityValues?.endDate}` }
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
              <div>項目資料</div>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>項目</TableCell>
                    <TableCell>備註</TableCell>
                    <TableCell>使用情況</TableCell>
                    <TableCell>全日</TableCell>
                    <TableCell>日間</TableCell>
                    <TableCell>晚間</TableCell>
                    <TableCell>PRN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entityValues?.residentRestraintItemList &&
                    entityValues?.residentRestraintItemList.map((restraintItem, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          {RESIDENT_RESTRAINT_ITEM_OPTION.find(o => o.value === restraintItem.item)?.label}
                        </TableCell>
                        <TableCell>
                          {restraintItem.remarks}
                        </TableCell>
                        <TableCell>
                          <Grid container direction="column">
                          {restraintItem.residentRestraintItemRemarks.map((remark, index) => (
                            <Grid item>
                              {RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION.find(o => o.value === remark.remarks)?.label }
                            </Grid>
                          ))}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          {restraintItem.fullDay ? '是' : '否'}
                        </TableCell>
                        <TableCell>
                          {(restraintItem.dayTime) ? `${restraintItem.dayTimeStartTime} - ${restraintItem.dayTimeEndTime}` : '不適用'}
                        </TableCell>
                        <TableCell>
                          {(restraintItem.nightTime) ? `${restraintItem.nightTimeStartTime} - ${restraintItem.nightTimeEndTime}` : '不適用'}
                        </TableCell>
                        <TableCell>
                          {restraintItem.timePrn ? '是' : '否'}
                        </TableCell>
                        {/* <TableCell>
                          <FieldArray
                            name={`residentRestraintItemList.${index}.residentRestraintItemRemarks`}
                            render={(arrayHelpers) => (
                              <div>
                                <Table
                                  stickyHeader
                                  padding="none"
                                  style={{ marginBottom: 15 }}
                                >
                                  <TableBody>
                                    {entityValues.residentRestraintItemList[index]
                                      .residentRestraintItemRemarks &&
                                      entityValues.residentRestraintItemList[
                                        index
                                      ].residentRestraintItemRemarks.map(
                                        (remarks, remarkIndex) => (
                                          <TableRow
                                            key={remarkIndex}
                                            disablegutters
                                          >
                                            <TableCell
                                              style={{ paddingRight: 1 }}
                                            >
                                              <FormikSelectField
                                                label="種類"
                                                name={`residentRestraintItemList.${index}.residentRestraintItemRemarks.${remarkIndex}.remarks`}
                                                options={
                                                  RESIDENT_RESTRAINT_ITEM_REMARKS_OPTION
                                                }
                                              />
                                            </TableCell>
                                            <TableCell align="right">
                                              <IconButton
                                                onClick={() =>
                                                  arrayHelpers.remove(
                                                    remarkIndex
                                                  )
                                                }
                                              >
                                                <DeleteOutlinedIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                                <Button
                                  color="default"
                                  variant="contained"
                                  type="button"
                                  size="small"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  新增
                                </Button>
                              </div>
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <FormikCheckboxField
                            name={`residentRestraintItemList.${index}.fullDay`}
                          />
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          <FormikCheckboxField
                            name={`residentRestraintItemList.${index}.dayTime`}
                            onChange={(event, value) =>
                              handleDayTimeChange(event, value, index)
                            }
                          />
                          <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <FormikTextField
                                disabled={
                                  entityValues.residentRestraintItemList[index]
                                    .dayTime
                                    ? false
                                    : dayTimeFieldDisabled
                                }
                                label="開始時間"
                                name={`residentRestraintItemList.${index}.dayTimeStartTime`}
                                type="number"
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FormikTextField
                                disabled={
                                  entityValues.residentRestraintItemList[index]
                                    .dayTime
                                    ? false
                                    : dayTimeFieldDisabled
                                }
                                label="結束時間"
                                name={`residentRestraintItemList.${index}.dayTimeEndTime`}
                                type="number"
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell style={{ minWidth: 200 }}>
                          <FormikCheckboxField
                            name={`residentRestraintItemList.${index}.nightTime`}
                            onChange={(event, value) =>
                              handleNightTimeChange(event, value, index)
                            }
                          />
                          <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <FormikTextField
                                disabled={
                                  entityValues.residentRestraintItemList[index]
                                    .nightTime
                                    ? false
                                    : nightTimeFieldDisabled
                                }
                                label="開始時間"
                                name={`residentRestraintItemList.${index}.nightTimeStartTime`}
                                type="number"
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <FormikTextField
                                disabled={
                                  entityValues.residentRestraintItemList[index]
                                    .nightTime
                                    ? false
                                    : nightTimeFieldDisabled
                                }
                                label="結束時間"
                                name={`residentRestraintItemList.${index}.nightTimeEndTime`}
                                type="number"
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <FormikCheckboxField
                            name={`residentRestraintItemList.${index}.timePrn`}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose} >
              關閉
          </Button>
      </DialogActions>
    </Dialog>
  );

  // return (
  //   <FormikDialog
  //     open={open}
  //     dialogTitle={"修改 約束物品"}
  //     validationSchema={validationSchema}
  //     onSubmit={onSubmit}
  //     onClose={onClose}
  //     maxWidth="md"
  //   >
  //     <EditForm entity={entityValues} />
  //   </FormikDialog>
  // );
}

// const EditForm = (props) => {
//   const home = useSelector((state) => state.home);
//   const { entity, apiUrl = "" } = props;
//   const { initialValues, values, touched, errors, setFieldValue, resetForm } =
//     useFormikContext();

//   useLayoutEffect(() => {
//     resetForm({ values: initialValues });
//     if (entity) {
//       resetForm({ values: { ...initialValues, ...entity } });
//     } else {
//       resetForm({ values: initialValues });
//     }
//   }, [entity]);

//   return (
//     <form>
//       <Grid container spacing={2}>
//         <Grid item sm={12} xs={12}>
//           <Card variant="outlined" style={{ marginBottom: 15 }}>
//             <CardContent>
//               <Grid container spacing={2}>
//                 <Grid item sm={6} xs={12}>
//                   <FormikReferenceField
//                     edit={entity ? true : false}
//                     disabled={entity ? true : false}
//                     required
//                     label="院友姓名"
//                     name="resident"
//                     apiUrl={
//                       "/api/homes/" + home.home.id + "/residents/references"
//                     }
//                     getOptionLabel={(option) =>
//                       option.lastnameTc + option.firstnameTc
//                     }
//                   />
//                 </Grid>
//                 <Grid item sm={6} xs={12}>
//                   <FormikSelectField
//                     required
//                     label="約束物品狀態"
//                     name="status"
//                     options={RESIDENT_RESTRAINT_STATUS_OPTION}
//                   />
//                 </Grid>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </form>
//   );
// };
