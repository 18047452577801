import { Button, Card, Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";
import { FormikReferenceField, FormikTextField } from "../../../components/inputs";
import { useEffect, useLayoutEffect } from "react";
import { useFormikContext } from "formik";
import { API_URL } from "../../../constants";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

export const initialValues = {
  name: "",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function CreateFolderDialog(props) {
  const { open, onClose, onSubmit } = props;

  return (
    <FormikDialog
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      maxWidth="sm"
      dialogTitle={"新增文件夾"}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <CreateFolderForm />
    </FormikDialog>
  );
}

const CreateFolderForm = (props) => {
  // const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  // useLayoutEffect(() => {
  //   resetForm({ values: initialValues });
  // }, []);

  useEffect(() => {
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikTextField
                    required
                    label="文件夾名稱"
                    name="name"
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    label="文件夾位置"
                    name="folder"
                    apiUrl={API_URL.DOCUMENT_FOLDER}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    variation={"documentFolder"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
