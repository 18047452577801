import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import { API_URL, ROUTES, LEAVE_STATUS_OPTIONS } from "../../../constants";

export const initialValues = {
  resident: "",
  bed: "",
  status: "",
  reason: "",
  remarks: "",
  leaveDate: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
  estimateReturnDate: "",
  actualReturnDate: "",
  returnRemarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  bed: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  reason: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  leaveDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, ...rest } = props;
  const { initialValues, values, handleChange, setFieldValue, resetForm } =
    useFormikContext();

  const handleResidentChange = (event, value) => {
    setFieldValue("bed", value?.bed?.bedNo);
  };

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              個人資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  disabled={entity}
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home.home.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                  onChange={handleResidentChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField disabled label="床位" name="bed" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="現在狀態"
                  name="status"
                  options={LEAVE_STATUS_OPTIONS}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              外宿資料
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikTextField required label="外宿原因" name="reason" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="外宿備註" name="remarks" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  required
                  label="外宿日期"
                  name="leaveDate"
                  startTime
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  label="預計回院日期"
                  name="estimateReturnDate"
                  startTime
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  label="實際回院日期"
                  name="actualReturnDate"
                  startTime
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="回院備註" name="returnRemarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
