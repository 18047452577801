import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { API_URL, RESIDENT_ICP_STATUS_OPTIONS } from "../../../constants";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";

export const initialValues = {
  resident: "",
  status: "",
  submitDate: moment(new Date()).toISOString(),
  nextSubmitDate: moment(new Date()).add(6, "months").toISOString(),
  remarks: "",
};

export const validationSchema = Yup.object().shape({
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  status: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  submitDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
});

export const DataInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikReferenceField
                  edit={entity ? true : false}
                  disabled={entity ? true : false}
                  required
                  label="院友姓名"
                  name="resident"
                  apiUrl={
                    "/api/homes/" + home.home.id + "/residents/references"
                  }
                  getOptionLabel={(option) =>
                    option.lastnameTc + option.firstnameTc
                  }
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikSelectField
                  required
                  label="ICP狀態"
                  name="status"
                  options={RESIDENT_ICP_STATUS_OPTIONS}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  required
                  label="遞交ICP日期"
                  name="submitDate"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
