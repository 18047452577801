import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import { API_URL } from "../../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikReferenceField from "../../../../components/inputs/FormikReferenceField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Draggable from "react-draggable";
import Typography from "@material-ui/core/Typography";
import { Notification } from "../../../../components/Notification";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const initialValues = {
  home: "",
  newBed: "",
};

export const validationSchema = Yup.object().shape({
  home: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  newBed: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

function PaperComponent(props) {
  const { dialogId, ...rest } = props;

  return (
    <Draggable
      bounds="parent"
      handle={"#" + dialogId}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...rest} />
    </Draggable>
  );
}

export function ChangeHomeDialog(props) {
  const { open, onClose, onSubmit, resident, dialogId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Dialog
          fullWidth={true}
          maxWidth={"xs"}
          scroll="body"
          open={open}
          PaperComponent={PaperComponent}
          PaperProps={{ dialogId: dialogId }}
          aria-labelledby={dialogId}
        >
          <DialogTitle
            style={{ cursor: "move" }}
            id={dialogId}
            disableTypography
          >
            <Typography variant="h4" component="h2">
              轉院
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="div">
              <Notification />
              <ChangeHomeForm />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={onClose} disabled={isSubmitting}>
              取消
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => {
                setConfirmOpen(true);
              }}
            >
              儲存
            </Button>
          </DialogActions>
          <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            scroll="body"
            open={confirmOpen}
            PaperComponent={PaperComponent}
            PaperProps={{ dialogId: dialogId }}
            aria-labelledby={dialogId}
          >
            <form onSubmit={handleSubmit} noValidate>
              <DialogTitle
                style={{ cursor: "move" }}
                id={dialogId}
                disableTypography
              >
                <Typography variant="h4" component="h2">
                  轉院
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText component="div">
                  <Notification />
                  確定要將 <strong>{resident}</strong> 轉院 ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="default"
                  onClick={() => setConfirmOpen(false)}
                  disabled={isSubmitting}
                >
                  取消
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => setConfirmOpen(false)}
                >
                  確定
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Dialog>
      )}
    </Formik>
  );
}

const ChangeHomeForm = (props) => {
  const { values } = useFormikContext();
  const [homeId, setHomeId] = useState("");

  const handleHomeChange = (event, value) => {
    setHomeId(value?.id);
  };

  const BedGrid = () => {
    if (homeId) {
      return (
        <Grid item sm={12} xs={12}>
          <FormikReferenceField
            disabled={values.home == null}
            required
            label="床位號碼"
            name="newBed"
            apiUrl={API_URL.BED + "/references"}
            apiParams={{
              homeId: homeId,
              status: "AVAILABLE",
            }}
            getOptionLabel={(option) => option?.bedNo}
          />
        </Grid>
      );
    }
    return <Grid item sm={12} xs={12}></Grid>;
  };

  useEffect(() => {}, [homeId]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    required
                    label="轉至院舍"
                    name="home"
                    apiUrl={"/api/homes/references"}
                    getOptionLabel={(option) => option.nameTc}
                    onChange={handleHomeChange}
                  />
                </Grid>
                <BedGrid />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
