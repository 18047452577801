import * as Yup from "yup";
import moment from "moment";
import "moment-lunar";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  API_URL,
  ROUTES,
  RESIDENT_DEPOSIT_STATUS_OPTION,
  EXPENSE_TRANSACTION_METHOD_OPTION,
  RETURN_TRANSACTION_METHOD_OPTION,
} from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import axios from "axios";

export const initialValues = {};

export function DetailsDialog(props) {
  const { entity, open, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <Dialog
      open={open}
      entity={entity}
      keepMounted
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"訂單詳細 (" + entityValues?.invoiceId + ")"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: 200 }}>院友姓名</TableCell>
                <TableCell style={{ width: 500 }}>
                  {entityValues?.resident?.lastnameTc +
                    entityValues?.resident?.firstnameTc}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>訂金編號</TableCell>
                <TableCell>{entityValues?.invoiceId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>訂金日期及時間</TableCell>
                <TableCell>
                  {entityValues?.issueDateTime?.substring(0, 10) +
                    " / " +
                    entityValues?.issueDateTime?.substring(11, 16)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>訂金狀態</TableCell>
                <TableCell>
                  {
                    RESIDENT_DEPOSIT_STATUS_OPTION.find(
                      (elem) => elem.value == entityValues?.status
                    )?.label
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>訂金金額</TableCell>
                <TableCell>$ {entityValues?.total}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>收費項目</TableCell>
                <TableCell>
                  {entityValues &&
                    entityValues.residentInvoiceItemList &&
                    entityValues.residentInvoiceItemList.length > 0 && (
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>收費項目名稱</TableCell>
                            <TableCell>描述</TableCell>
                            <TableCell>單價</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {entityValues.residentInvoiceItemList.map((item) => (
                            <TableRow hover key={item.id}>
                              <TableCell>{item.invoiceItem.nameTc}</TableCell>
                              <TableCell>
                                {item.description ? item.description : "--"}
                              </TableCell>
                              <TableCell>$ {item.price} </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>付款記錄</TableCell>
                <TableCell>
                  {entityValues && entityValues.residentInvoicePaymentList
                    ? entityValues.residentInvoicePaymentList.length > 0 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>付款方式</TableCell>
                              <TableCell>付款日期</TableCell>
                              <TableCell>總數</TableCell>
                              <TableCell>備註</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {entityValues.residentInvoicePaymentList.map(
                              (item) => (
                                <TableRow hover key={item.id}>
                                  <TableCell>
                                    {
                                      EXPENSE_TRANSACTION_METHOD_OPTION.find(
                                        (elem) =>
                                          elem.value == item.paymentMethod
                                      )?.label
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {item.paymentDate?.substring(0, 10) +
                                      " / " +
                                      item.paymentDate?.substring(11, 16)}
                                  </TableCell>
                                  <TableCell>$ {item.amount} </TableCell>
                                  <TableCell>
                                    {item.remarks ? item.remarks : "--"}{" "}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      )
                    : "未有任何付款記錄"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>退還訂金記錄</TableCell>
                <TableCell>
                  {entityValues && entityValues.expenses
                    ? entityValues.expenses.length > 0 && (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>退還方式</TableCell>
                              <TableCell>退還日期</TableCell>
                              <TableCell>總數</TableCell>
                              <TableCell>備註</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {entityValues.expenses.map((item) => (
                              <TableRow hover key={item.id}>
                                <TableCell>
                                  {item.expenseExpenseTransaction?.map(
                                    (r) =>
                                      RETURN_TRANSACTION_METHOD_OPTION.find(
                                        (elem) =>
                                          elem.value ==
                                          r.expenseTransaction?.method
                                      )?.label
                                  )}
                                </TableCell>
                                <TableCell>
                                  {item.issueDateTime?.substring(0, 10) +
                                    " / " +
                                    item.issueDateTime?.substring(11, 16)}
                                </TableCell>
                                <TableCell>$ {item.amount} </TableCell>
                                <TableCell>
                                  {item.expenseExpenseTransaction?.map((r) =>
                                    r.remarks != "" ? r.remarks : "--"
                                  )}{" "}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )
                    : "未有任何退還記錄"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  );
}
