import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikSelectField from "../../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../../components/inputs/FormikTextField";
import { API_URL, RESIDENT_WOUND_DRESSING_OPTION } from '../../../../constants';

export const initialValues = {
    name: '',
    type: '',
}

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required(<FormattedMessage id="validation.message.required" />).nullable(),
    type: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />).nullable(),
});

export const WoundDressingsForm = props => {
    const home = useSelector(state => state.home);
    const { entity, apiUrl = "", ...rest } = props;
    const { initialValues, values, touched, errors, setFieldValue, resetForm } = useFormikContext();

    useEffect(() => {
        resetForm({ values: initialValues });
        if (entity) {
            resetForm({ values: entity });
        } else {
            resetForm({values: initialValues});
        }
    }, [entity]);

    return (
        <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12} xs={12}>
                                <FormikTextField required label="傷口敷料名稱" name="name" />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <FormikSelectField required label="傷口敷料類別" name="type" options={RESIDENT_WOUND_DRESSING_OPTION}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}