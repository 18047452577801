import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import PublishIcon from "@material-ui/icons/Publish";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { Grid, Typography, Link } from "@material-ui/core";
//import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const { setUploadOpen, setCreateFolderOpen, clearNotification, handleFilterChange, setCurrentFolderId, folderNav, setFolderNav } = props;

  return (
    <PageHeader title="文件管理">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Grid container>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PublishIcon />}
                  onClick={() => {
                    clearNotification();
                    setUploadOpen(true);
                  }}
                >
                  上傳文件
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{marginLeft: "15px"}}
                  variant="contained"
                  color="primary"
                  startIcon={<CreateNewFolderIcon />}
                  onClick={() => {
                    clearNotification();
                    setCreateFolderOpen(true);
                  }}
                >
                  新增文件夾
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "45px" }}>
              <Link
                onClick={() => {
                  setCurrentFolderId("")
                  setFolderNav([]);
                }}
                style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 700 }}
              >
                主目錄
              </Link>
              {folderNav.map((folder, index) => (
                <>
                  <Typography
                    key={index}
                    style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 700 }}
                  >
                    &nbsp;/&nbsp;
                  </Typography>
                  <Link
                    key={index}
                    onClick={() => {
                      setCurrentFolderId(folder.id)
                      setFolderNav([...folderNav].splice(0, index + 1));
                    }}
                    style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 700 }}
                  >
                    {folder.name}
                  </Link>
                </>
              ))}
            </Grid>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
