import { grey } from "@material-ui/core/colors";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    main: '#014598',
  },
  /*secondary: {
    main: '#00753c',
  },*/
  contrastThreshold: 3,
  tonalOffset: 0.2,
};
