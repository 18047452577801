import { useState } from 'react';
import { FormikDeleteDialog } from '../../../components/dialogs';

export function DeleteDialog(props) {
    const { entity, onClose, onSubmit, currentBed, open } = props;

    return (
        <FormikDeleteDialog
            open={open}
            entity={entity}
            maxWidth="sm"
            onSubmit={onSubmit}
            onClose={onClose}
        >
            注意！<strong> 床位 ({currentBed}) </strong>及其相關資料將會被刪除
        </FormikDeleteDialog>
    );
}