import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { ROUTES } from "../../../constants";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { API_URL, RESIDENT_RESTRAINT_STATUS_OPTION, RESIDENT_EMERGENCY_REASON_OPTION } from "../../../constants";
import clsx from "clsx";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { NotificationActions } from "../../../actions";
import { FollowUpDialog } from "./dialogs/FollowUpDialog";
import { EmergencyDialog } from "./dialogs/EmergencyDialog";
import { IcpDialog } from "./dialogs/IcpDialog";
import { LeaveDialog } from "./dialogs/LeaveDialog";
import { RestraintItemEditDialog } from "./dialogs/RestraintItemEditDialog";
import { RestraintItemViewDialog } from "./dialogs/RestraintItemViewDialog";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  table: {
    minWidth: 300,
    marginBottom: 15,
  },
  followUp: {
    overflowX: "auto",
  },
  itemExpired: {
    backgroundColor: "#efbad6",
  },
  itemToday: {
    backgroundColor: "#b1d3c5",
  },
});

export default function Reminder() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [followUpValues, setFollowUpValues] = useState([]);
  const [followUpEntity, setFollowUpEntity] = useState(null);
  const [followUpOpen, setFollowUpOpen] = useState(false);
  const [emergencyValues, setEmergencyValues] = useState([]);
  const [emergencyEntity, setEmergencyEntity] = useState(null);
  const [emergencyOpen, setEmergencyOpen] = useState(false);
  const [leaveValues, setLeaveValues] = useState([]);
  const [leaveEntity, setLeaveEntity] = useState(null);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const [icpValues, setIcpValues] = useState([]);
  const [icpEntity, setIcpEntity] = useState(null);
  const [icpOpen, setIcpOpen] = useState(false);
  const [restraintItems, setRestraintItems] = useState([]);
  const [restraintItemsEntity, setRestraintItemsEntity] = useState(null);
  const [restraintItemsOpenEdit, setRestraintItemsOpenEdit] = useState(false);
  const [restraintItemsOpenView, setRestraintItemsOpenView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const home = useSelector((state) => state.home);

  const getEntities = () => {
    // 覆診
    axios
      .get(
        API_URL.RESIDENT_FOLLOW_UP + "/searchUpcomingFollowUp/" + home?.home?.id
      )
      .then((response) => {
        let data = response?.data;
        setFollowUpValues(data);
      })
      .catch((error) => console.error(error));

    // 急症
    axios
      .get(
        API_URL.RESIDENT_EMERGENCY + "/searchInEmergencyList/" + home?.home?.id
      )
      .then((response) => {
        let data = response?.data;
        setEmergencyValues(data);
      })
      .catch((error) => console.error(error));

    // 外宿
    axios
      .get(API_URL.LEAVE + "/searchInLeaveList/" + home?.home?.id)
      .then((response) => {
        let data = response?.data;
        setLeaveValues(data);
        setLoading(false);
      })
      .catch((error) => console.error(error));

    // ICP
    axios
      .get(API_URL.RESIDENT_ICP + "/searchUpcomingIcpByDate/" + home?.home?.id)
      // .get(API_URL.RESIDENT_ICP + "/searchUpcomingIcp/" + home?.home?.id)
      .then((response) => {
        let data = response?.data;
        setIcpValues(data);
        setLoading(false);
      })
      .catch((error) => console.error(error));

    axios
      .get(API_URL.RESIDENT_RESTRAINT_ITEM +
        "/searchResidentRestraintList/" +
        home?.home?.id
      )
      .then((response) => {
        let data = response?.data;
        console.debug("restraint items:", data);
        setRestraintItems(data);
      })
      .catch((error) => console.error(error));
  };

  const normalizeReceivedValues = (values) => {
    if (values.institution) {
      values.institution = values.institution.id;
    }
    if (values.specialist) {
      values.specialist = values.specialist.id;
    }
    values.resident = values.resident.id;
    return values;
  };

  const onFollowUp = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_FOLLOW_UP + "/" + values.id, {
        date: values.date,
        escorterName: values.escorterName,
        escorterRelationship:
          values?.escorterRelationship?.id || values?.escorterRelationship,
        institution: values.institution,
        medicalFollowUpItems: (values.medicalFollowUpItems || []).map((i) => {
          return { id: i.id };
        }),
        remarks: values.remarks,
        resident: [values.resident],
        specialist: values.specialist,
        status: values.status,
        transport: values?.transport?.id || values?.transport,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setFollowUpEntity(null);
        formikActions.setSubmitting(false);
        setFollowUpOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    if (values.status == "COMPLETED" && values.nextDate !== "") {
      axios.post(API_URL.RESIDENT_FOLLOW_UP, {
        date: values.nextDate,
        escorterName: values.escorterName,
        escorterRelationship:
          values?.escorterRelationship?.id || values?.escorterRelationship,
        institution: values.institution,
        medicalFollowUpItems: (values.medicalFollowUpItems || []).map((i) => {
          return { id: i.id };
        }),
        remarks: values.remarks,
        resident: [values.resident],
        specialist: values.specialist,
        status: "APPOINTMENT",
        transport: values?.transport?.id || values?.transport,
      });
    }
  };

  const onEmergency = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_EMERGENCY + "/" + values.id, {
        resident: values.resident,
        status: values.status,
        reason: values.reason,
        issueDateTime: values.issueDateTime,
        institution: values.institution,
        wardBedNo: values.wardBedNo,
        remarks: values.remarks,
        dischargedDateTime: values.dischargedDateTime,
        dischargedReason: values.dischargedReason,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEmergencyEntity(null);
        formikActions.setSubmitting(false);
        setEmergencyOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  const onLeave = (values, formikActions) => {
    axios
      .put(API_URL.LEAVE + "/" + values.id, {
        resident: values.resident,
        status: values.status,
        reason: values.reason,
        remarks: values.remarks,
        leaveDate: values.leaveDate,
        estimateReturnDate: values.estimateReturnDate,
        actualReturnDate: values.actualReturnDate
          ? moment(values.actualReturnDate).format("YYYY-MM-DDT00:00:00")
          : "",
        returnRemarks: values.returnRemarks,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setLeaveEntity(null);
        formikActions.setSubmitting(false);
        setLeaveOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  const onIcp = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_ICP + "/" + values.id, {
        resident: values.resident,
        remarks: values.remarks,
        status: values.status,
        submitDate: values.submitDate,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setIcpEntity(null);
        formikActions.setSubmitting(false);
        setIcpOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    if (values.status == "SUBMITTED" && values.nextDate !== "") {
      axios.post(API_URL.RESIDENT_ICP, {
        resident: values.resident,
        remarks: values.remarks,
        status: "IN_DRAFT",
        submitDate: values.nextDate,
      });
    }
  };

  const onRestraintItemEdit = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_RESTRAINT_ITEM + "/" + values.id, {
        resident: values.resident,
        status: values.status,
        startDate: values.startDate,
        endDate: values.endDate,
        residentRestraintItemList: values.residentRestraintItemList,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setRestraintItemsEntity(null);
        formikActions.setSubmitting(false);
        setRestraintItemsOpenEdit(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getEntities();
  }, [home, reload]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            <Link to={ROUTES.RESIDENT_FOLLOW_UP_MANAGEMENT}>覆診</Link>
          </Typography>
          <Paper elevation={0}>
            <TableContainer className={classes.followUp}>
              <Table
                className={classes.followUpTable}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: 70 }}>
                      動作
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      院友姓名
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      日期
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      時間
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      醫院 / 診所
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 110 }}>
                      交通工具
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 150 }}>
                      陪診員
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {followUpValues && followUpValues.length !== 0 ? (
                    followUpValues.map((row) => (
                      <TableRow
                        key={row.id}
                        className={clsx({
                          [classes.itemExpired]: moment(new Date()).isAfter(
                            row.date
                          ),
                          [classes.itemToday]:
                            moment(new Date()).format("YYYY-MM-DD") ===
                            moment(row.date).format("YYYY-MM-DD"),
                        })}
                      >
                        <TableCell component="th" scope="row">
                          <Tooltip title="修改">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => {
                                dispatch(NotificationActions.clear());
                                setFollowUpEntity(row);
                                setFollowUpOpen(true);
                              }}
                              disabled={loading}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.resident?.lastnameTc + row.resident?.firstnameTc}
                        </TableCell>
                        <TableCell align="left">
                          {row.date?.substring(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          {row.date?.substring(11, 16)}
                        </TableCell>
                        <TableCell align="left">
                          {row.institution?.nameTc}
                        </TableCell>
                        <TableCell align="left">
                          {row.transport ? row.transport?.nameTc : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.escorterRelationship && row.escorterName
                            ? row.escorterRelationship?.nameTc +
                              " - " +
                              row?.escorterName
                            : "--"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        未有任何 已過期 或 未來1個月 的覆診記錄
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            <Link to={ROUTES.RESIDENT_EMERGENCY_RECORD_MANAGEMENT}>急症</Link>
          </Typography>
          <Paper elevation={0}>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: 70 }}>
                      動作
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      院友姓名
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      急症日期
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      急症時間
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 200 }}>
                      入住醫院 / 診所
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      樓層 / 病房
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 150 }}>
                      入住原因
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emergencyValues && emergencyValues.length !== 0 ? (
                    emergencyValues.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <Tooltip title="修改">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => {
                                dispatch(NotificationActions.clear());
                                setEmergencyEntity(row);
                                setEmergencyOpen(true);
                              }}
                              disabled={loading}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.resident?.lastnameTc + row.resident?.firstnameTc}
                        </TableCell>
                        <TableCell align="left">
                          {row.issueDateTime?.substring(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          {row.issueDateTime?.substring(11, 16)}
                        </TableCell>
                        <TableCell align="left">
                          {row.institution?.nameTc}
                        </TableCell>
                        <TableCell align="left">{row.wardBedNo}</TableCell>
                        <TableCell align="left">
                          {
                            RESIDENT_EMERGENCY_REASON_OPTION.find(
                              (elem) => elem.value == row.reason
                            )?.label
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        未有任何急症住院記錄
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            <Link to={ROUTES.LEAVE_RECORD}>外宿</Link>
          </Typography>
          <Paper elevation={0}>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ minWidth: 70 }}>
                      動作
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      院友姓名
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 120 }}>
                      外宿日期
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 130 }}>
                      預計回院日期
                    </TableCell>
                    <TableCell align="left">外宿原因</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveValues && leaveValues.length !== 0 ? (
                    leaveValues.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <Tooltip title="修改">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => {
                                dispatch(NotificationActions.clear());
                                setLeaveEntity(row);
                                setLeaveOpen(true);
                              }}
                              disabled={loading}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.resident?.lastnameTc + row.resident?.firstnameTc}
                        </TableCell>
                        <TableCell align="left">
                          {row.leaveDate?.substring(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          {row.estimateReturnDate
                            ? row.estimateReturnDate.substring(0, 10)
                            : "--"}
                        </TableCell>
                        <TableCell align="left">{row.reason}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        未有任何外宿記錄
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            <Link to={ROUTES.ICP}>安老院舍住客個人照顧計劃 ICP</Link>
          </Typography>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: "10%" }}>
                    動作
                  </TableCell>
                  <TableCell align="left" style={{ width: "45%" }}>
                    院友姓名
                  </TableCell>
                  <TableCell align="left" style={{ width: "45%" }}>
                    遞交日期
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {icpValues && icpValues.length !== 0 ? (
                  icpValues.map((row) => (
                    <TableRow
                      key={row.id}
                      className={clsx({
                        [classes.itemExpired]: moment(new Date()).isAfter(
                          row.submitDate
                        ),
                        [classes.itemToday]:
                          moment(new Date()).format("YYYY-MM-DD") ===
                          moment(row.submitDate).format("YYYY-MM-DD"),
                      })}
                    >
                      <TableCell component="th" scope="row">
                        <Tooltip title="修改">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              dispatch(NotificationActions.clear());
                              setIcpEntity(row);
                              setIcpOpen(true);
                            }}
                            disabled={loading}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.resident?.lastnameTc + row.resident?.firstnameTc}
                      </TableCell>
                      <TableCell align="left">{row.submitDate}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      未有任何已過期的 ICP 記錄
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            <Link to={ROUTES.RESIDENT_RESTRAINT_ITEM}>約束物品</Link>
          </Typography>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: "20%" }}>
                    動作
                  </TableCell>
                  <TableCell align="left" style={{ width: "20%" }}>
                    院友姓名
                  </TableCell>
                  <TableCell align="left" style={{ width: "20%" }}>
                    開始日期
                  </TableCell>
                  <TableCell align="left" style={{ width: "20%" }}>
                    結束日期
                  </TableCell>
                  <TableCell align="left" style={{ width: "20%" }}>
                    狀態
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {restraintItems && restraintItems.length !== 0 ? (
                  restraintItems.map((row) => (
                    <TableRow
                      key={row.id}
                      // className={clsx({
                      //   [classes.itemExpired]: moment(new Date()).isAfter(
                      //     row.submitDate
                      //   ),
                      //   [classes.itemToday]:
                      //     moment(new Date()).format("YYYY-MM-DD") ===
                      //     moment(row.submitDate).format("YYYY-MM-DD"),
                      // })}
                    >
                      <TableCell component="th" scope="row">
                        <Tooltip title="修改">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              dispatch(NotificationActions.clear());
                              setRestraintItemsEntity(row);
                              setRestraintItemsOpenEdit(true);
                            }}
                            disabled={loading}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="查看">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              dispatch(NotificationActions.clear());
                              setRestraintItemsEntity(row);
                              setRestraintItemsOpenView(true);
                            }}
                            disabled={loading}
                          >
                            <RemoveRedEyeOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.resident?.lastnameTc + row.resident?.firstnameTc}
                      </TableCell>
                      <TableCell align="left">{row.startDate}</TableCell>
                      <TableCell align="left">{row.endDate}</TableCell>
                      <TableCell align="left">
                        {RESIDENT_RESTRAINT_STATUS_OPTION?.filter(
                          option => option.value === row.status
                          )[0]?.label}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      未有任何 已過期 或 未來5個月 的約束物品記錄
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <FollowUpDialog
        open={followUpOpen}
        entity={followUpEntity}
        onClose={() => {
          setFollowUpEntity(null);
          setLoading(false);
          setFollowUpOpen(false);
        }}
        onSubmit={onFollowUp}
        normalizeReceivedValues={normalizeReceivedValues}
      />
      <EmergencyDialog
        open={emergencyOpen}
        entity={emergencyEntity}
        onClose={() => {
          setEmergencyEntity(null);
          setLoading(false);
          setEmergencyOpen(false);
        }}
        onSubmit={onEmergency}
        normalizeReceivedValues={normalizeReceivedValues}
      />
      <LeaveDialog
        open={leaveOpen}
        entity={leaveEntity}
        onClose={() => {
          setLeaveEntity(null);
          setLoading(false);
          setLeaveOpen(false);
        }}
        onSubmit={onLeave}
        normalizeReceivedValues={normalizeReceivedValues}
      />
      <IcpDialog
        open={icpOpen}
        entity={icpEntity}
        onClose={() => {
          setIcpEntity(null);
          setLoading(false);
          setIcpOpen(false);
        }}
        onSubmit={onIcp}
        normalizeReceivedValues={normalizeReceivedValues}
      />
      <RestraintItemEditDialog
        open={restraintItemsOpenEdit}
        entity={restraintItemsEntity}
        onClose={() => {
          setRestraintItemsEntity(null);
          setLoading(false);
          setRestraintItemsOpenEdit(false);
        }}
        onSubmit={onRestraintItemEdit}
        normalizeReceivedValues={normalizeReceivedValues}
      />
      <RestraintItemViewDialog
        open={restraintItemsOpenView}
        entity={restraintItemsEntity}
        onClose={() => {
          setRestraintItemsEntity(null);
          setLoading(false);
          setRestraintItemsOpenView(false);
        }}
        // normalizeReceivedValues={normalizeReceivedValues}
      />
    </Fragment>
  );
}
