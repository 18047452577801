import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import {
  API_URL,
  ROUTES,
  RESIDENT_WOUND_STATUS_OPTION,
  RESIDENT_WOUND_POSITION_OPTION,
} from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

export default function WoundManagementPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);
    params.home = home?.home?.id;
    params.resident = params.resident.id || params.resident;

    params.residentWoundDressings = (params.residentWoundDressings || []).map(
      (i) => {
        return { woundDressing: i.woundDressingId, remarks: i.remarks };
      }
    );

    // params.woundPosition = params.woundPosition && params.woundPosition.forEach((value, index) => {
    //     return `woundPosition[${index}]`
    // })

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.resident = values.resident.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "bed",
        headerName: "床位",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"].bed?.bedNo;
        },
      },
      {
        field: "woundStatus",
        headerName: "傷口狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_WOUND_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "woundPosition",
        headerName: "傷口位置",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return RESIDENT_WOUND_POSITION_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "suggestWashingFrequency",
        headerName: "清洗頻率",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["suggestWashingFrequencyDay"] &&
            params.row["suggestWashingFrequencyTimes"]
            ? params.row["suggestWashingFrequencyDay"] +
                "日" +
                params.row["suggestWashingFrequencyTimes"] +
                "次"
            : "--";
        },
      },
      {
        field: "startDate",
        headerName: "開始日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.substring(0, 10);
        },
      },
      {
        field: "recoveredDate",
        headerName: "結束日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10)
            : "--";
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_WOUND,
      getOne: API_URL.RESIDENT_WOUND,
      create: API_URL.RESIDENT_WOUND,
      edit: API_URL.RESIDENT_WOUND,
      delete: API_URL.RESIDENT_WOUND,
      export: API_URL.RESIDENT_WOUND + "/export",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="傷口管理"
      editableFields={[
        "resident",
        "woundStatus",
        "woundSource",
        "suggestWashingFrequencyDay",
        "suggestWashingFrequencyTimes",
        "startDate",
        "recoveredDate",
        "woundPosition",
        "woundLength",
        "woundWidth",
        "woundDepth",
        "woundLevel",
        "woundColour",
        "woundExudateType",
        "woundSmell",
        "woundExudateAmount",
        "residentWoundDressings",
        "imageUrl",
        "remarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      maxWidth="lg"
    />
  );
}
