import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";

export const initialValues = {
  startDate: null,
  endDate: null,
};

export const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  endDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
});

export function ExportDialog(props) {
  const { open, entity, onClose, onSubmit } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (entity) {
  //     axios
  //       .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
  //       .then((response) => {
  //         let data = response?.data;
  //         let entityValues = { ...initialValues, ...data };
  //         normalizeReceivedValues(entityValues);
  //         setEntityValues(entityValues);
  //       })
  //       .catch((error) => {})
  //       .finally(() => {
  //         setLoaded(true);
  //       });
  //   } else {
  //     setEntityValues(initialValues);
  //   }
  // }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"匯出報表"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      <ExportForm entity={entityValues} />
    </FormikDialog>
  );
}

export const ExportForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikDateField
                    required
                    label="日期由"
                    name="startDate"
                    startTime
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikDateField
                    required
                    label="日期至"
                    name="endDate"
                    endTime
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
