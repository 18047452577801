import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import PrintIcon from "@material-ui/icons/Print";

export const ListingPageHeader = (props) => {
  const {
    setCreating,
    setMergePrinting,
    handleFilterChange,
    handleHideChange,
    setPrintOpen,
    clearNotification,
  } = props;

  return (
    <PageHeader title="藥物管理">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm
              handleFilterChange={handleFilterChange}
              handleHideChange={handleHideChange}
            />
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增院友藥物
            </Button>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                clearNotification();
                setMergePrinting(true);
              }}
            >
              列印藥紙
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => {
                clearNotification();
                setPrintOpen(true);
              }}
            >
              列印空白藥紙
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
