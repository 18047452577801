import { Fragment } from "react";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./FilteringForm";
import { PageHeader } from "../../../components/PageHeader";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import OpenInBrowserOutlinedIcon from '@material-ui/icons/OpenInBrowserOutlined';
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

export const ListingPageHeader = (props) => {
  const {
    setCreating,
    setMergePaymentEntity,
    setDailyReportEntity,
    setDailyReportOpen,
    handleFilterChange,
    clearNotification,
    onExport,
  } = props;

  return (
    <PageHeader title="院舍支出">
      <Formik
        initialValues={initialFilteringValues}
        validationSchema={filteringValidationSchema}
      >
        {() => (
          <Fragment>
            <FilteringForm handleFilterChange={handleFilterChange} />
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlinedIcon />}
              onClick={() => {
                clearNotification();
                setCreating(true);
              }}
            >
              新增支出
            </Button>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<LibraryAddIcon />}
              onClick={() => {
                clearNotification();
                setMergePaymentEntity(true);
              }}
            >
              合拼付款
            </Button>
            <Button
              style={{ marginRight: 15 }}
              variant="contained"
              color="primary"
              startIcon={<OpenInBrowserOutlinedIcon />}
              onClick={() => {
                clearNotification();
                // setDailyReportEntity(true);
                setDailyReportOpen(true);
              }}
            >
              匯出報表
            </Button>
          </Fragment>
        )}
      </Formik>
    </PageHeader>
  );
};
