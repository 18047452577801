import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormikTextField from "../../../../../components/inputs/FormikTextField";
import FormikDateField from "../../../../../components/inputs/FormikDateField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormikCheckboxField from "../../../../../components/inputs/FormikCheckboxField";
import { FormLabel } from "@material-ui/core";

export const initialValues = {
  resident: "",
  recordDate: moment(new Date()).format("yyyy-MM-DDT00:00:00+08:00"),
  systolicBloodPressure: "",
  diastolicBloodPressure: "",
  pluse: "",
  bloodOxygen: "",
  bloodSugar: "",
  breathingRate: "",
  temperature: "",
  height: "",
  weight: "",
  remarks: "",
  mealBefore: false,
  mealBeforeBloodSugar: "",
  mealAfter: false,
  mealAfterBloodSugar: "",
  fasting: false,
  fastingBloodSugar: "",
  morning: false,
  morningBloodSugar: "",
  noon: false,
  noonBloodSugar: "",
  night: false,
  nightBloodSugar: "",
};

export const validationSchema = Yup.object().shape({
  recordDate: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  systolicBloodPressure: Yup.number()
    .min(10, "上壓最少為2位數")
    .max(999, "上壓最多為3位數")
    .nullable(),
  diastolicBloodPressure: Yup.number()
    .min(10, "下壓最少為2位數")
    .max(999, "下壓最多為3位數")
    .nullable(),
  pluse: Yup.number()
    .min(10, "脈搏最少為2位數")
    .max(999, "脈搏最多為3位數")
    .nullable(),
  bloodOxygen: Yup.number()
    .min(10, "脈搏最少為2位數")
    .max(999, "脈搏最多為3位數")
    .nullable(),
  mealBeforeBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  mealAfterBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  fastingBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  morningBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  noonBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  nightBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  breathingRate: Yup.number()
    .min(1, "呼吸速率最少為1位數")
    .max(999, "呼吸速率最多為3位數")
    .nullable(),
  temperature: Yup.number()
    .min(10, "體溫最少為2位數")
    .max(99, "體溫最多為2位數")
    .nullable(),
  height: Yup.number()
    .min(10, "高度最少為2位數")
    .max(999, "高度最多為3位數")
    .nullable(),
  weight: Yup.number()
    .min(10, "體重最少為2位數")
    .max(999, "體重最多為3位數")
    .nullable(),
  mealBefore: Yup.boolean(),
  mealAfter: Yup.boolean(),
  fasting: Yup.boolean(),
  morning: Yup.boolean(),
  noon: Yup.boolean(),
  night: Yup.boolean(),
});

export const BloodSugarForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const {
    initialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  useEffect(() => {
    // there should be existing blood glucose record that contains an id if it is an edit form
    if (entity?.id) setIsEditForm(true);

    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  // clear previous blood sugar data input
  useEffect(() => {
    if (!values) return;
    if (!values.mealBefore) values.mealBeforeBloodSugar = "";
    if (!values.mealAfter) values.mealAfterBloodSugar = "";
    if (!values.morning) values.morningBloodSugar = "";
    if (!values.noon) values.noonBloodSugar = "";
    if (!values.night) values.nightBloodSugar = "";
  }, [values]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormikDateField
                  required
                  label="記錄日期"
                  name="recordDate"
                  startTime
                />
              </Grid>
              {isEditForm && <Grid item sm={6} xs={12}>
                <FormikTextField
                  required
                  label={(() => {
                    let time;
                    if (values.mealBefore) {
                      time = " - 餐前";
                    } else if (values.mealAfter) {
                      time = " - 餐後";
                    } else if (values.fasting) {
                      time = " - 空腹"
                    } else if (values.morning) {
                      time = " - 早上";
                    } else if (values.noon) {
                      time = " - 中午";
                    } else if (values.night) {
                      time = " - 夜晚";
                    }
                    return "血糖" + time;
                  })()}
                  name="bloodSugar"
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mmol/l</InputAdornment>
                    ),
                  }}
                />
              </Grid>}
              <Grid item sm={isEditForm ? 12 : 6} xs={12}>
                <FormikTextField label="備註" name="remarks" />
              </Grid>
              {!isEditForm &&
              <Grid item xs={12} md={12}>
                <fieldset>
                  <FormLabel component="legend">取樣時間/血糖</FormLabel>
                  <Grid container>
                    <Grid item xl={values.mealBefore ? 3 : 12} xs={12}>
                        <FormikCheckboxField label="餐前" name="mealBefore" />
                    </Grid>
                    {values.mealBefore &&

                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        required
                        label="血糖"
                        name="mealBeforeBloodSugar"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmol/l</InputAdornment>
                          ),
                        }}
                        disabled={!values.mealBefore}
                      />
                    </Grid>}
                    <Grid item md={values.mealAfter ? 3 : 12} xs={12}>
                        <FormikCheckboxField label="餐後" name="mealAfter" />
                    </Grid>
                    {values.mealAfter &&
                    <Grid item xs={12}>
                      <FormikTextField
                        required
                        label="血糖"
                        name="mealAfterBloodSugar"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmol/l</InputAdornment>
                          ),
                        }}
                        disabled={!values.mealAfter}
                      />
                    </Grid>}
                    <Grid item md={values.fasting ? 3 : 12} xs={12}>
                        <FormikCheckboxField label="空腹" name="fasting" />
                    </Grid>
                    {values.fasting &&
                    <Grid item xs={12}>
                      <FormikTextField
                        required
                        label="血糖"
                        name="fastingBloodSugar"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmol/l</InputAdornment>
                          ),
                        }}
                        disabled={!values.fasting}
                      />
                    </Grid>}
                    <Grid item md={values.morning ? 3 : 12} xs={12}>
                        <FormikCheckboxField label="早上" name="morning" />
                    </Grid>
                    {values.morning &&
                    <Grid item xs={12}>
                      <FormikTextField
                        required
                        label="血糖"
                        name="morningBloodSugar"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmol/l</InputAdornment>
                          ),
                        }}
                        disabled={!values.morning}
                      />
                    </Grid>}
                    <Grid item md={values.noon ? 3 : 12} xs={12}>
                        <FormikCheckboxField label="中午" name="noon" />
                    </Grid>
                    {values.noon &&
                    <Grid item xs={12}>
                      <FormikTextField
                        required
                        label="血糖"
                        name="noonBloodSugar"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmol/l</InputAdornment>
                          ),
                        }}
                        disabled={!values.noon}
                      />
                    </Grid>}
                    <Grid item md={values.night ? 3 : 12} xs={12}>
                        <FormikCheckboxField label="夜晚" name="night" />
                    </Grid>
                    {values.night &&
                    <Grid item xs={12}>
                      <FormikTextField
                        required
                        label="血糖"
                        name="nightBloodSugar"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmol/l</InputAdornment>
                          ),
                        }}
                        disabled={!values.night}
                      />
                    </Grid>}
                  </Grid>
                </fieldset>
              </Grid>}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
