import React, { useState } from "react";
import { matchPath, withRouter } from "react-router";
import _uniqueId from "lodash/uniqueId";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { navigation } from "../../../config";
import { DEFAULT_COMPANY_CONFIG } from "../../../config/app.config";
import { ListItemLink } from "../../../components";
import { ROUTES } from "../../../constants";
import { history } from "../../../history";
import { Button } from "@material-ui/core";
import { AuthService } from "../../../services";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
    },
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 2,
    whiteSpace: "nowrap",
  },
  drawerContent: {},
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  logoContainer: {
    width: "100%",
    padding: 0,
  },
  logo: {
    maxWidth: 180,
    maxHeight: 120,
    margin: "15px auto",
    display: "block",
  },
  menu: {
    padding: 0,
  },
  nestedMenu: {
    paddingLeft: theme.spacing(6),
  },
  menu0: {
    backgroundColor: "#efbad6",
  },
  menu1: {
    backgroundColor: "#dadafc",
  },
  menu2: {
    backgroundColor: "#ffd6aa",
    "&>li:first-child": {
      backgroundColor: "#f5bb90"
    },
  },
  menu3: {
    backgroundColor: "#fef5d4",
  },
  menu4: {
    backgroundColor: "#b1d1e4",
  },
  menu5: {
    backgroundColor: "#b1d3c5",
  },
  menu6: {
    backgroundColor: "#6eceda",
  },
  menu7: {
    backgroundColor: "#e2b3a3",
  },
  menu8: {
    backgroundColor: "#efefef",
  },
  nestedMenu0: {
    backgroundColor: "pink",
  },
}));

const MenuRenderer = (props) => {
  const { node, depth, index } = props;
  const classes = useStyles();
  const Tag = node.href ? ListItemLink : ListItem;

  const visible =
    !node.children ||
    AuthService.hasAnyPermission(node.permissions) ||
    node?.children?.reduce(
      (visible, subitem) =>
        visible || AuthService.hasAnyPermission(subitem?.permissions),
      false
    );

  return visible ? (
    <li>
      <Tag
        component="div"
        key={node}
        className={clsx(classes["menu" + index])}
        selected={node.selected}
        href={node.href}
        onClick={() => {
          !node.children && history.push(node.href);
        }}
      >
        {node.icon && <ListItemIcon component="div">{node.icon}</ListItemIcon>}
        <ListItemText primary={node.title} />
        <ListItemSecondaryAction
          component="div"
          button="true"
          onClick={() => {
            node.children && history.push(node.href);
            node.opened = !node.opened;
          }}
        >
          <IconButton edge="end" aria-label="comments">
            {node.children && (node.opened ? <ExpandLess /> : <ExpandMore />)}
          </IconButton>
        </ListItemSecondaryAction>
      </Tag>
      {node.children && (
        <Collapse in={node.opened} timeout="auto" unmountOnExit>
          <List
            component="ul"
            disablePadding
            className={clsx(classes["menu" + index])}
          >
            {node.children.map((subitem, index) => {
              return (
                <MenuRenderer key={index} node={subitem} depth={depth + 1} />
              );
            })}
          </List>
        </Collapse>
      )}
    </li>
  ) : null;
};

export function MenuDrawer(props) {
  const classes = useStyles();
  const { open } = props;
  const [opened, setopened] = useState(false);

  const handleopened = (node) => (event) => {
    if (node === opened && node.children) {
      setopened(false);
    } else {
      setopened(node);
    }
  };

  navigation.forEach((section) => {
    processNavigation(section);
  });

  function processNavigation(node) {
    node.id = node.id || _uniqueId("menu");

    node.selected =
      matchPath(window.location.pathname, {
        path: node.href,
        exact: false,
      }) !== null;

    if (node.selected && typeof node.opened == "undefined") {
      node.opened = true;
    }

    if (node.children) {
      node.children.forEach((child) => processNavigation(child));

      if (
        node.children.reduce(
          (selected, child) => selected || child.selected,
          false
        )
      ) {
        node.selected = true;
      }
    }

    if (node.selected && typeof node.opened == "undefined") {
      node.opened = true;
    }
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Button
          className={classes.logoContainer}
          onClick={() => history.push(ROUTES.DASHBOARD)}
        >
          <img
            className={classes.logo}
            src={DEFAULT_COMPANY_CONFIG["logo"]}
            title={DEFAULT_COMPANY_CONFIG["name"]}
            alt={DEFAULT_COMPANY_CONFIG["name"]}
          />
        </Button>
      </div>
      <Divider />
      <List component="nav" className={classes.menu}>
        {navigation.map((item, index) => {
          return (
            <MenuRenderer key={index} node={item} depth={0} index={index} />
          );
        })}
      </List>
    </Drawer>
  );
}
