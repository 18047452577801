export const PERMISSIONS = {
  HOME_DASHBOARD: "HOME_DASHBOARD",
  CALENDAR: "CALENDAR",
  HOME_MANAGEMENT: "HOME_MANAGEMENT",
  BED_MANAGEMENT: "BED_MANAGEMENT",
  ROLE_MANAGEMENT: "ROLE_MANAGEMENT",
  HOME_INFO: "HOME_INFO",

  RESIDENT_MANAGEMENT: "RESIDENT_MANAGEMENT",
  RESERVATION_MANAGEMENT: "RESERVATION_MANAGEMENT",
  DISCHARGE_MANAGEMENT: "DISCHARGE_MANAGEMENT",
  DEATH_RECORD: "DEATH_RECORD",
  LEAVE_RECORD: "LEAVE_RECORD",
  RESIDENT_STATISTICS: "RESIDENT_STATISTICS",

  RESIDENT_SETTING: "RESIDENT_SETTING",

  RESIDENT_INVOICE: "RESIDENT_INVOICE",
  HOME_EXPENSE: "HOME_EXPENSE",
  HOME_EXPENSE_RECORD: "HOME_EXPENSE_RECORD",
  RESIDENT_DEPOSIT: "RESIDENT_DEPOSIT",
  PETTYCASH_MANAGEMENT: "PETTYCASH_MANAGEMENT",
  PETTYCASH_EXPENSE: "PETTYCASH_EXPENSE",
  STAFF_SALARY: "STAFF_SALARY",
  ACCOUNTING_SETTING: "ACCOUNTING_SETTING",

  MEDICINE_MANAGEMENT: "MEDICINE_MANAGEMENT",
  MEDICINE_REPORT: "MEDICINE_REPORT",
  MEDICINE_SETTING: "MEDICINE_SETTING",

  MEDICAL_RECORD_MANAGEMENT: "MEDICAL_RECORD_MANAGEMENT",
  RESIDENT_FOLLOW_UP_MANAGEMENT: "RESIDENT_FOLLOW_UP_MANAGEMENT",
  MEDICAL_ITEM: "MEDICAL_ITEM",
  RESIDENT_EMERGENCY_RECORD_MANAGEMENT: "RESIDENT_EMERGENCY_RECORD_MANAGEMENT",

  MEDICAL_SETTING: "MEDICAL_SETTING",

  RESIDENT_VITAL_SIGN: "RESIDENT_VITAL_SIGN",
  WOUND_MANAGEMENT: "WOUND_MANAGEMENT",
  RESIDENT_RESTRAINT_ITEM: "RESIDENT_RESTRAINT_ITEM",
  ICP: "ICP",
  NURSING_SETTING: "NURSING_SETTING",

  STAFF_USERS: "STAFF_USERS",
  ROSTER_RECURRING: "ROSTER_RECURRING",
  ROSTER_MANAGEMENT: "ROSTER_MANAGEMENT",
  ROSTER_DETAILS: "ROSTER_DETAILS",

  WRISTBAND_MANAGEMENT: "WRISTBAND_MANAGEMENT",
  WRISTBAND_SETTING: "WRISTBAND_SETTING",

  IBEACON_MANAGEMENT: "IBEACON_MANAGEMENT",
  IBEACON_SETTING: "IBEACON_SETTING",

  DOCUMENT_LISTING: "DOCUMENT_LISTING",
  DOCUMENT_MANAGEMENT: "DOCUMENT_MANAGEMENT",
};
