import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, PAYMENT_RECORD_OPTION } from "../../constants";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { DetailsDialog } from "./components/DetailsDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";

export default function HomeExpensePaymentRecordPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [detailsEntity, setDetailsEntity] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="查看資料">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDetailsEntity(params);
                    setDetailsOpen(true);
                  }}
                  disabled={loading}
                >
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "issueDateTime",
        headerName: "付款日期及時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field]?.substring(0, 10) +
            " / " +
            params.row[params.field]?.substring(11, 16)
          );
        },
      },
      {
        field: "method",
        headerName: "付款方式",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return PAYMENT_RECORD_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      {
        field: "expenseExpenseTransaction",
        headerName: "付款金額",
        width: 1000,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            .map((item) => {
              return "HK$ " + item.amount.toLocaleString();
            })
            .join(" | ");
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.EXPENSE_TRANSACTION,
      getOne: API_URL.EXPENSE_TRANSACTION,
      export: API_URL.EXPENSE_TRANSACTION + "/export",
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="付款記錄"
      editableFields={[
        "resident",
        "residentInvoiceItems",
        "issueDateTime",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      normalizeReceivedValues={normalizeReceivedValues}
      createFilteringParams={createFilteringParams}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
    >
      <DetailsDialog
        open={detailsOpen}
        entity={detailsEntity}
        onClose={() => {
          setDetailsEntity(null);
          setDetailsOpen(false);
        }}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
