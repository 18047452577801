import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {
  API_URL,
  GENDER_OPTIONS,
  EVALUATION_RESULT_OPTION,
} from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikHKIDField from "../../../components/inputs/FormikHKIDField";
import axios from "axios";

export const initialValues = {
  lastnameTc: "",
  lastname: "",
  firstnameTc: "",
  firstname: "",
  gender: null,
  dateOfBirth: "",
  hkid: "",
  contactName: "",
  contactNo: "",
  contactRelationship: "",
  roomRemarks: "",
  estimatedCheckInDate: moment(new Date()).toISOString(),
  bed: "",
  residentBedType: "",
  ldsNo: "",
  evaluationResult: "NOT_EVALUATED",
  evaluationDate: "",
  downPrice: "",
  downIssueTime: "",
  downPaymentMethod: "",
};

export function EditDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"修改訂位"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="md"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const {
    initialValues,
    values,
    setValues,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = useFormikContext();

  const setLunarDateOfBirthAndAge = (value) => {
    setFieldValue(
      "lunarDateOfBirth",
      value ? moment(value).lunar().format("YYYY-MM-DD") : ""
    );
    setFieldValue("age", moment().diff(value, "years", false) || "0");
  };

  const handleDateOfBirthChange = (value) => {
    setFieldValue("dateOfBirth", moment(value).format("YYYY-MM-DD"));
    setLunarDateOfBirthAndAge(value);
  };

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
      setLunarDateOfBirthAndAge(entity.dateOfBirth);
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {}, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    個人資料
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        required
                        label="中文姓氏"
                        name="lastnameTc"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        required
                        label="中文名字"
                        name="firstnameTc"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        //required
                        label="英文姓氏"
                        name="lastname"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        //required
                        label="英文名字"
                        name="firstname"
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormikSelectField
                        //required
                        label="性別"
                        name="gender"
                        options={GENDER_OPTIONS}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormikHKIDField label="身份證號碼" name="hkid" />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormikTextField
                        label="其他證明文件號碼"
                        name="homeReturnPermitId"
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormikDateField
                        //required
                        label="出生日期"
                        name="dateOfBirth"
                        onChange={handleDateOfBirthChange}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormikDateField
                        //required
                        disabled
                        label="農曆"
                        name="lunarDateOfBirth"
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <FormikTextField
                        //required
                        disabled
                        label="年齡"
                        name="age"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    床位資料
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikReferenceField
                        edit={entity ? true : false}
                        required
                        label="床號"
                        name="bed"
                        apiUrl={API_URL.BED + "/references"}
                        apiParams={{
                          homeId: home?.home.id,
                          status: "AVAILABLE",
                        }}
                        getOptionLabel={(option) => option?.bedNo}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikReferenceField
                        edit={entity ? true : false}
                        required
                        label="床位類別"
                        name="residentBedType"
                        apiUrl={"/api/residentBedTypes"}
                        getOptionLabel={(option) => option.type}
                        apiParams={{
                          unpaged: true,
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikDateField
                        startTime
                        required
                        label="預計入住日期"
                        name="estimatedCheckInDate"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    聯絡人資料
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        required
                        label="聯絡人"
                        name="contactName"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        required
                        label="聯絡人電話"
                        name="contactNo"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        label="關係"
                        name="contactRelationship"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    備註
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField label="房間備註" name="roomRemarks" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    評核資料
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField label="LDS編號" name="ldsNo" />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikSelectField
                        label="評核結果"
                        name="evaluationResult"
                        options={EVALUATION_RESULT_OPTION}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikDateField label="評核日期" name="evaluationDate" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
