import React, { useState } from "react";
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../constants';
import { PrivateLayout } from '../layouts/PrivateLayout/index';
import { apiService } from "../services";
import AuthService from '../services/auth.service';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Route {...rest} render={props => {
      if (!AuthService.isloggedIn()) {
        return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />
      } else {

        apiService.get('/api/profile').then((response) => {
          AuthService.storeUser(response.data);
          setLoaded(true);
        }).catch((error) => {
        });

        return <PrivateLayout>{loaded ? <Component {...props} /> : null}</PrivateLayout>
      }
    }} />
  );
}