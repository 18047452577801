import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import axios from "axios";
import { STAFF_TAG_OPTION } from "../../../constants";

export const initialValues = {
  day: "",
  tag: "",
};

export function EditDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"修改更表標籤"}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
    >
      <EditForm entity={entityValues} />
    </FormikDialog>
  );
}

const EditForm = (props) => {
  const { entity = "" } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {}, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikTextField disabled required label="日期" name="day" />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikSelectField
                    required
                    label="更表標籤"
                    name="tag"
                    options={STAFF_TAG_OPTION}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
