import { Fragment } from "react";
import * as Yup from "yup";
import { API_URL, RESIDENT_INVOICE_STATUS_OPTION } from "../../../constants";
import { useFormikContext } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import { FormLabel } from "@material-ui/core";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import Button from "@material-ui/core/Button";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";

export const initialFilteringValues = {
  status: "",
  startDay: "",
  endDay: "",
  expenseItem: "",
  supplier: "",
};

export const filteringValidationSchema = Yup.object().shape({});

const useStyles = makeStyles((theme) => ({
  containerFields: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginRight: theme.spacing(3),
  },
}));

export const FilteringForm = (props) => {
  const home = useSelector((state) => state.home);
  const { handleFilterChange } = props;
  const { values, isSubmitting, submitForm, resetForm } = useFormikContext();
  const classes = useStyles();

  const handleSubmit = (event) => {
    handleFilterChange({
      ...values,
      timestamp: new Date(), //Force refresh when click submit without changing values
    });
  };

  const handleReset = (event) => {
    resetForm();
    handleFilterChange(initialFilteringValues);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item className={classes.containerFields} xs={12} md={12} lg={8}>
          <fieldset>
            <FormLabel component="legend">搜尋</FormLabel>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormikSelectField
                  label="付款狀態"
                  options={RESIDENT_INVOICE_STATUS_OPTION}
                  name="status"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikDateField label="搜尋日期由" name="startDate" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikDateField label="搜尋日期至" name="endDate" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormikReferenceField
                  label="支出項目"
                  name="expenseItem"
                  apiUrl={"/api/expenseItems"}
                  getOptionLabel={(option) => option.nameTc}
                  getOptionValue={(option) => option?.id}
                  apiParams={{
                    unpaged: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikReferenceField
                  label="供應商"
                  name="supplier"
                  apiUrl={"/api/expenseSuppliers"}
                  getOptionLabel={(option) => option.nameTc}
                  getOptionValue={(option) => option?.id}
                  apiParams={{
                    unpaged: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4} style={{ alignSelf: "center" }}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  startIcon={<SearchOutlinedIcon />}
                >
                  篩選
                </Button>
                <Button
                  className={classes.button}
                  color="default"
                  variant="outlined"
                  onClick={handleReset}
                  disabled={isSubmitting}
                  startIcon={<RotateLeftOutlinedIcon />}
                >
                  重設
                </Button>
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
    </Fragment>
  );
};
