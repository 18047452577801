import { AUTHENTICATION_STATUS } from '../constants';
import { NotificationActions } from '../actions';
import AuthService from '../services/auth.service';
import { FormattedMessage } from 'react-intl';
import { history } from '../history';

export const authActions = {
    login,
    logout
};

function login(username, password, from) {
    return dispatch => {
        dispatch(request({ username }));

        AuthService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(NotificationActions.success(null, <FormattedMessage id={"api.message.LOGIN_SUCCESS"} />));
                    history.push(from);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(NotificationActions.error(null, <FormattedMessage id={"api.message." + error.response.data.messageCode} />));
                }
            );
    };

    function request(user) {
        return { type: AUTHENTICATION_STATUS.LOGIN_REQUEST, user }
    }

    function success(user) {
        return { type: AUTHENTICATION_STATUS.LOGIN_SUCCESS, user }
    }

    function failure(error) {
        return { type: AUTHENTICATION_STATUS.LOGIN_FAIL, error }
    }
}

function logout() {
    AuthService.logout();
    return { type: AUTHENTICATION_STATUS.LOGOUT };
}