import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, PAYMENT_RECORD_OPTION } from "../../constants";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./components/EditDialog";
import { ExportDialog } from "./components/ExportDialog";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import { DataInputForm, initialValues, validationSchema } from "./components/DataInputForm";
import _ from "lodash";
import axios from "axios";
import { NotificationActions } from "../../actions";

export default function HomeExpensePaymentRecordPage() {
  const home = useSelector((state) => state.home);
  const dispatch = useDispatch();
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [exportEntity, setExportEntity] = useState(null);
  const [exportOpen, setExportOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [reload, setReload] = useState(false);
  const [reloadPageHeader, setReloadPageHeader] = useState(false);

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.startDate != null) {
      params.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }

    if (params.endDate != null) {
      params.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    //values.bed && (values.bed = values.bed?.id);
    //return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const createParams = (values, coreCreateParams) => {
    let params = {};

    params = coreCreateParams(values);

    params.home = home?.home?.id;
    params.issueDateTime = values.issueDateTime;
    params.amount = values.amount;

    return params;
  }

  const onEdit = (values, formikActions) => {
    console.debug("onEdit values:", values);
    const params = _.pick(values, ['issueDateTime', 'amount']);
    params.home = home?.home?.id;

    axios
      .put(apiUrl.edit + "/" + values.id, params)
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        formikActions.setSubmitting(false);
        setEditOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  }

  const onExport = (values, formikActions) => {
    console.log("onExport");
    const params = {...values};
    params.home = home?.home?.id;

    // TODO: finish the codes when backend api finish
    dispatch(NotificationActions.error(null, "Error"));
    formikActions.setSubmitting(false);
    setExportOpen(false);
    setReload(!reload);
    // axios
    //   .get(apiUrl.edit + "/" + values.id, params)
    //   .then((response) => {
    //     dispatch(NotificationActions.success(null, "已匯出"));
    //     formikActions.setSubmitting(false);
    //     setEditOpen(false);
    //     setReload(!reload);
    //   })
    //   .catch((error) => {
    //     dispatch(NotificationActions.error(null, "錯誤"));
    //     formikActions.setSubmitting(false);
    //   });
  }

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    // setEditingEntity(params.row);
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "issueDateTime",
        headerName: "付款日期及時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          const date = params.row[params.field]?.substring(0, 10);
          const time = params.row[params.field]?.substring(11, 16);
          return (date && time) ? `${date} / ${time}` : "沒有記錄";
        },
      },
      {
        field: "amount",
        headerName: "金額",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return `$ ${params.row[params.field]?.toLocaleString() || "0"}`;
        },
      },
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.PETTY_CASH,
      getOne: API_URL.PETTY_CASH,
      create: API_URL.PETTY_CASH,
      edit: API_URL.PETTY_CASH,
      delete: API_URL.PETTY_CASH,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="付款記錄"
      editableFields={[
        "resident",
        "residentInvoiceItems",
        "issueDateTime",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      maxWidth="xs"
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        reload: reload,
        reloadPageHeader: reloadPageHeader,
        setExportOpen: setExportOpen,
      }}
      reloadPageHeader={reloadPageHeader}
      setReloadPageHeader={setReloadPageHeader}
    >
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
      <ExportDialog
        open={exportOpen}
        entity={exportEntity}
        onClose={() => {
          setExportEntity(null);
          setExportOpen(false);
        }}
        onSubmit={onExport}
      />
    </EntityManagement>
  );
}
