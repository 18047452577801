import React from "react";
import { useFormikContext } from "formik";
import _ from "lodash";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import zhLocale from "date-fns/locale/zh-TW";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      paddingRight: 0
    }
  }
}));

const FormikDateField = (props) => {
  const { name, format = "yyyy-MM-dd HH:mm", component: Component, ...rest } = props;
  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting
  } = useFormikContext();

  const Field = Component || KeyboardDateTimePicker;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhLocale} className={classes.root}>
      <Field
        okLabel="確定"
        clearLabel="重設"
        cancelLabel="取消"
        todayLabel="今日"
        invalidDateMessage="日期時間格式錯誤"
        id={rest.id || name}
        margin={rest.margin || "dense"}
        format={format}
        fullWidth
        value={_.get(values, name) || null}
        inputVariant={rest.inputVariant || "outlined"}
        onChange={date => {
          setFieldValue(name, date ? date : null);
        }}
        onClose={() => {
          setFieldTouched(name, true, false);
        }}
        onBlur={handleBlur}
        showTodayButton
        clearable={true}
        disabled={isSubmitting}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
        helperText={_.get(touched, name) && _.get(errors, name)}
        /*onError={error => {
          if (error && _.get(errors, name) !== error) {
            setFieldError(name, error);
          }
        }}*/
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

export default FormikDateField;
