import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormikTextField from "../../../../../components/inputs/FormikTextField";
import FormikCheckboxField from "../../../../../components/inputs/FormikCheckboxField";
import axios from "axios";
import { API_URL } from "../../../../../constants";
import FormikDateField from "../../../../../components/inputs/FormikDateField";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useParams } from "react-router-dom";

export const initialValues = {
  recordDate: moment(new Date()).format("yyyy-MM-DDT00:00:00+08:00"),
  systolicBloodPressure: "",
  diastolicBloodPressure: "",
  pluse: "",
  bloodOxygen: "",
  bloodSugar: "",
  breathingRate: "",
  temperature: "",
  height: "",
  weight: "",
  remarks: "",
  mealBefore: false,
  mealBeforeBloodSugar: "",
  mealAfter: false,
  mealAfterBloodSugar: "",
  fasting: false,
  fastingBloodSugar: "",
  morning: false,
  morningBloodSugar: "",
  noon: false,
  noonBloodSugar: "",
  night: false,
  nightBloodSugar: "",
};

export const validationSchema = Yup.object().shape({
  recordDate: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  systolicBloodPressure: Yup.number()
    .min(10, "上壓最少為2位數")
    .max(999, "上壓最多為3位數")
    .nullable(),
  diastolicBloodPressure: Yup.number()
    .min(10, "下壓最少為2位數")
    .max(999, "下壓最多為3位數")
    .nullable(),
  pluse: Yup.number()
    .min(10, "脈搏最少為2位數")
    .max(999, "脈搏最多為3位數")
    .nullable(),
  bloodOxygen: Yup.number()
    .min(10, "脈搏最少為2位數")
    .max(999, "脈搏最多為3位數")
    .nullable(),
  mealBeforeBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  mealAfterBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  fastingBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  morningBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  noonBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  nightBloodSugar: Yup.number()
    .min(1, "血糖最少為1位數")
    .max(99, "血糖最多為2位數")
    .nullable(),
  breathingRate: Yup.number()
    .min(1, "呼吸速率最少為1位數")
    .max(999, "呼吸速率最多為3位數")
    .nullable(),
  temperature: Yup.number()
    .min(10, "體溫最少為2位數")
    .max(99, "體溫最多為2位數")
    .nullable(),
  height: Yup.number()
    .min(10, "高度最少為2位數")
    .max(999, "高度最多為3位數")
    .nullable(),
  weight: Yup.number()
    .min(10, "體重最少為2位數")
    .max(999, "體重最多為3位數")
    .nullable(),
  mealBefore: Yup.boolean(),
  mealAfter: Yup.boolean(),
  fasting: Yup.boolean(),
  morning: Yup.boolean(),
  noon: Yup.boolean(),
  night: Yup.boolean(),
});

export const MergeInputForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "", ...rest } = props;
  const [vitalSignsEntity, setVitalSignsEntity] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const {
    mergeInitialValues,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormikContext();
  let { id } = useParams();
  values.resident = id;

  const handleResidentChange = (event, value) => {
    let residentId = value?.id || "";
    if (residentId) {
      axios
        .get(API_URL.RESIDENT_VITAL_SIGN, {
          params: {
            resident: residentId,
            page: 0,
            size: 10,
          },
        })
        .then((response) => {
          let data = response.data;
          setVitalSignsEntity(response.data.content);
          setPageSize(data);
          setLoaded(true);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    resetForm({ values: mergeInitialValues });
    if (entity) {
      resetForm({ values: entity });
    } else {
      resetForm({ values: mergeInitialValues });
    }
  }, [entity]);

  useEffect(() => {
    if (!values) return;
    if (!values.mealBefore) values.mealBeforeBloodSugar = "";
    if (!values.mealAfter) values.mealAfterBloodSugar = "";
    if (!values.morning) values.morningBloodSugar = "";
    if (!values.noon) values.noonBloodSugar = "";
    if (!values.night) values.nightBloodSugar = "";
  }, [values]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Card variant="outlined" style={{ marginBottom: 15 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              合拼輸入
            </Typography>
            <Grid container spacing={2} style={{ marginBottom: 15 }}>
              <Grid item sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item sm={4} xs={12}>
                    <FormikDateField
                      required
                      label="記錄日期及時間"
                      name="recordDate"
                      startTime
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FormikTextField
                      label="備註"
                      name="remarks"
                      helpertext="*如輸入表徵多於一項則會一併備註"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
                <fieldset style={{ marginBottom: 15 }}>
                  <FormLabel component="legend">血壓 / 脈搏</FormLabel>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        label="上壓"
                        name="systolicBloodPressure"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmHg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        label="下壓"
                        name="diastolicBloodPressure"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">mmHg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        label="脈搏"
                        name="pluse"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              每分鐘
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </fieldset>
                <fieldset style={{ marginBottom: 15 }}>
                  <FormLabel component="legend">血氧</FormLabel>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        label="血氧"
                        name="bloodOxygen"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              SpO2%
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item sm={4} xs={12}>
                <fieldset style={{ marginBottom: 15 }}>
                  <FormLabel component="legend">呼吸速率</FormLabel>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        label="呼吸速率"
                        name="breathingRate"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </fieldset>
                <fieldset>
                  <FormLabel component="legend">體溫</FormLabel>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                      <FormikTextField
                        label="體溫"
                        name="temperature"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">°C</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
              <Grid item sm={4} xs={12}>
                <fieldset>
                  <FormLabel component="legend">身高體重指數</FormLabel>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        label="身高"
                        name="height"
                        type="number"
                        value={values.height}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">cm</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormikTextField
                        label="體重"
                        name="weight"
                        type="number"
                        value={values.weight}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Card variant="outlined" style={{ padding: 15 }}>
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="h6" component="h2">
                              BMI:
                              {entity?.weight && entity?.height
                                ? Number(
                                    entity.weight /
                                      ((entity.height / 100) *
                                        (entity.height / 100))
                                  ).toFixed(1) || ""
                                : values.weight && values.height
                                ? Number(
                                    values.weight /
                                      ((values.height / 100) *
                                        (values.height / 100))
                                  ).toFixed(1)
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Typography variant="body1" component="h2">
                              <u>
                                <b>{"BMI 指數參考"}</b>
                              </u>
                              <br></br>
                              {"體重過輕: < 18.5"}
                              <br></br>
                              {"體重正常: 18.5 ~ 24"}
                              <br></br>
                              {"體重過重: 24 ~ 27"}
                              <br></br>
                              {"輕度肥胖: 27 ~ 30"}
                              <br></br>
                              {"中度肥胖: 30 ~ 35"}
                              <br></br>
                              {"重度肥胖: ≥ 35"}
                              <br></br>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
              <Grid sm={12} xs={12}>
                    <fieldset>
                      <FormLabel component="legend">血糖</FormLabel>
                      <Grid item sm={6} xs={12}>
                          <FormikCheckboxField label="餐前" name="mealBefore" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          required
                          label="血糖"
                          name="mealBeforeBloodSugar"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mmol/l</InputAdornment>
                            ),
                          }}
                          disabled={!values.mealBefore}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                          <FormikCheckboxField label="餐後" name="mealAfter" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          required
                          label="血糖"
                          name="mealAfterBloodSugar"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mmol/l</InputAdornment>
                            ),
                          }}
                          disabled={!values.mealAfter}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                          <FormikCheckboxField label="空腹" name="fasting" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          required
                          label="血糖"
                          name="fastingBloodSugar"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mmol/l</InputAdornment>
                            ),
                          }}
                          disabled={!values.fasting}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                          <FormikCheckboxField label="早上" name="morning" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          required
                          label="血糖"
                          name="morningBloodSugar"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mmol/l</InputAdornment>
                            ),
                          }}
                          disabled={!values.morning}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                          <FormikCheckboxField label="中午" name="noon" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          required
                          label="血糖"
                          name="noonBloodSugar"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mmol/l</InputAdornment>
                            ),
                          }}
                          disabled={!values.noon}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                          <FormikCheckboxField label="夜晚" name="night" />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <FormikTextField
                          required
                          label="血糖"
                          name="nightBloodSugar"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">mmol/l</InputAdornment>
                            ),
                          }}
                          disabled={!values.night}
                        />
                      </Grid>
                    </fieldset>
                  </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
              歷史記錄
            </Typography>
            <TableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">院友姓名</TableCell>
                    <TableCell align="left">記錄日期</TableCell>
                    <TableCell align="left">記錄時間</TableCell>
                    <TableCell align="left">上壓</TableCell>
                    <TableCell align="left">下壓</TableCell>
                    <TableCell align="left">脈搏</TableCell>
                    <TableCell align="left">血氧</TableCell>
                    <TableCell align="left">血糖</TableCell>
                    <TableCell align="left">呼吸速率</TableCell>
                    <TableCell align="left">體溫</TableCell>
                    <TableCell align="left">身高</TableCell>
                    <TableCell align="left">體重</TableCell>
                    <TableCell align="left">BMI</TableCell>
                    <TableCell align="left">備註</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vitalSignsEntity ? (
                    vitalSignsEntity.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="td" scope="row">
                          {row.resident.lastnameTc + row.resident.firstnameTc}
                        </TableCell>
                        <TableCell align="left">
                          {row.recordDate?.substring(0, 10)}
                        </TableCell>
                        <TableCell align="left">
                          {row.recordDate?.substring(11, 16)}
                        </TableCell>
                        <TableCell align="left">
                          {row.systolicBloodPressure
                            ? row.systolicBloodPressure
                            : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.diastolicBloodPressure
                            ? row.diastolicBloodPressure
                            : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.pluse ? row.pluse : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.bloodOxygen ? row.bloodOxygen : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.bloodSugar ? row.bloodSugar : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.breathingRate ? row.breathingRate : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.temperature ? row.temperature : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.height ? row.height : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.weight ? row.weight : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.height && row.weight
                            ? Number(
                                (
                                  row.weight /
                                  ((row.height / 100) * (row.height / 100))
                                ).toFixed(1)
                              ) || ""
                            : "--"}
                        </TableCell>
                        <TableCell align="left">
                          {row.remarks ? row.remarks : "--"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell component="th" colSpan={20} align="center">
                        未有任何記錄
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
