import * as Yup from "yup";
import moment from "moment";
import { useState, useEffect, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  API_URL,
  ROUTES,
  RESIDENT_INVOICE_PAYMENT_METHOD_OPTION,
} from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikDateTimeField from "../../../components/inputs/FormikDateTimeField";
import FormikSelectField from "../../../components/inputs/FormikSelectField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import axios from "axios";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Table, TableCell, TableBody, TableHead, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core";
import { RESIDENT_PAYMENT_STATUS_OPTION } from "../../../constants";

export const initialValues = {
  // amount: "",
  // amountConfirmation: "",
  paymentMethod: "",
  paymentDate: moment(new Date()).toISOString(),
  remarks: "",
  residentInvoiceItemList: [],
};

export const validationSchema = Yup.object().shape({
  // amount: Yup.string()
  //   // .matches(/^[0-9]+(\.[0-9]{1})?$/, "輸入必須為數字+最多1個小數位")
  //   .required(<FormattedMessage id="validation.message.required" />)
  //   .nullable(),
  paymentMethod: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  paymentDate: Yup.date()
    .required(<FormattedMessage id="validation.message.required" />)
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  residentInvoiceItemList: Yup.array().of(
    Yup.object().shape({
      price: Yup.string()
        .matches(/^(\-|\+)?\d+(\.\d+)?$/, "輸入必須為數字")
        .nullable(),
      qty: Yup.string()
        .matches(/^[0-9]*$/, "輸入必須為正整數")
        .nullable(),
    })
  ),

  // amountConfirmation: Yup.string().when("amount", (value, schema) => {
  //   if (!value) {
  //     return schema.notRequired();
  //   }
  //   return schema
  //     .oneOf([Yup.ref("amount")], "確認付款金額與付款金額不符")
  //     .required(<FormattedMessage id="validation.message.required" />);
  // }),
});

export function PaymentDialog(props) {
  const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } =
    props;
  const [entityValues, setEntityValues] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (entity) {
      axios
        .get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + "/" + entity.id)
        .then((response) => {
          let data = response?.data;
          data.residentInvoiceItemList?.forEach((item) => {
            item.price = 0;
            item.qty = 0;
          });

          let entityValues = { ...initialValues, ...data };
          normalizeReceivedValues(entityValues);
          setEntityValues(entityValues);
        })
        .catch((error) => {})
        .finally(() => {
          setLoaded(true);
        });
    } else {
      setEntityValues(initialValues);
    }
  }, [entity]);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"發票付款 (" + entityValues?.invoiceId + ")"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <PaymentForm entity={entityValues} />
    </FormikDialog>
  );
}

const PaymentForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, apiUrl = "" } = props;
  const [beds, setBeds] = useState([]);
  const [detailEntity, setDetailEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
    if (entity) {
      resetForm({ values: { ...initialValues, ...entity } });
    } else {
      resetForm({ values: initialValues });
    }
  }, [entity]);

  useEffect(() => {}, []);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                發票資料
              </Typography>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    disabled
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    required
                    label="發票編號"
                    name="invoiceId"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateTimeField
                    disabled
                    required
                    label="發票日期及時間"
                    name="issueDateTime"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    disabled
                    required
                    label="發票金額"
                    name="total"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                付款資料
              </Typography>
              <Grid container spacing={2}>
              {/* TODO: comment */}

                {/* <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    type="number"
                    label="付款金額"
                    name="amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikTextField
                    required
                    type="number"
                    label="確認付款金額"
                    name="amountConfirmation"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid> */}

                {/* TODO: activate */}
                  <Grid item sm={12} xs={12}>
                    <Table
                      stickyHeader
                      padding="none"
                      style={{ marginBottom: 15 }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            omponent="th"
                            style={{ padding: 10, minWidth: 250 }}
                          >
                            收費項目
                          </TableCell>
                          {/* <TableCell
                            omponent="th"
                            style={{ padding: 10, minWidth: 250 }}
                          >
                            描述
                          </TableCell> */}
                          <TableCell
                            omponent="th"
                            style={{ padding: 10, minWidth: 100 }}
                          >
                            數量
                          </TableCell>
                          <TableCell
                            omponent="th"
                            style={{ padding: 10, minWidth: 100 }}
                          >
                            單價
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            omponent="th"
                            style={{ padding: 10, minWidth: 100 }}
                          >
                            總數
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ width: 50, minWidth: 50 }}
                          ></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.residentInvoiceItemList &&
                          values.residentInvoiceItemList.map((item, index) => (
                            <TableRow key={index} disablegutters>
                              <TableCell style={{ paddingRight: 1 }}>
                                <FormikReferenceField
                                  edit={entity ? true : false}
                                  disabled
                                  required
                                  styles={{ marginTop: "-4px" }}
                                  label="收費項目"
                                  apiUrl={"/api/invoiceItems?type=INVOICE"}
                                  getOptionLabel={(option) => option?.nameTc}
                                  getOptionValue={(option) => option?.id}
                                  name={`residentInvoiceItemList.${index}.invoiceItem`}
                                  triggerUpdateOnChangeOf={item.invoiceItem}
                                  apiParams={{
                                    unpaged: true,
                                  }}
                                />
                              </TableCell>
                              {/* <TableCell style={{ paddingRight: 1 }}>
                                <FormikTextField
                                  disabled={
                                    entity.residentInvoicePaymentList
                                      ? true
                                      : false
                                  }
                                  label="描述"
                                  name={`residentInvoiceItemList.${index}.description`}
                                />
                              </TableCell> */}
                              <TableCell style={{ paddingRight: 1 }}>
                                <FormikTextField
                                  type="number"
                                  label="數量"
                                  name={`residentInvoiceItemList.${index}.qty`}
                                  defaultValue="0"
                                />
                              </TableCell>
                              <TableCell style={{ paddingRight: 10 }}>
                                <FormikTextField
                                  type="number"
                                  label="單價"
                                  name={`residentInvoiceItemList.${index}.price`}
                                  defaultValue="0"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              {/* <TableCell align="center">
                                {values.residentInvoiceItemList[index].qty &&
                                values.residentInvoiceItemList[index].price
                                  ? "$ " +
                                    Number(
                                      (
                                        values.residentInvoiceItemList[index]
                                          .qty *
                                        values.residentInvoiceItemList[index]
                                          .price
                                      ).toFixed(2)
                                    )
                                  : "$ " + 0}
                              </TableCell> */}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>

                <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    required
                    label="付款方法"
                    name="paymentMethod"
                    options={RESIDENT_INVOICE_PAYMENT_METHOD_OPTION}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormikDateTimeField
                    required
                    label="入帳日期及時間"
                    name="paymentDate"
                  />
                </Grid>
                {/* <Grid item sm={6} xs={12}>
                  <FormikSelectField
                    label="付款狀態"
                    name="status"
                    options={RESIDENT_PAYMENT_STATUS_OPTION}
                  />
                </Grid> */}
                {/* <FormikReferenceField
                  edit={entity ? true : false}
                  required
                  styles={{
                    marginTop: "-4px",
                  }}
                  label="付款項目"
                  apiUrl={"/api/invoiceItems?type=INVOICE"}
                  getOptionLabel={(option) => option?.nameTc}
                  getOptionValue={(option) => option?.id}
                  name={"paymentItem"}
                  apiParams={{
                    unpaged: true,
                  }}
                /> */}
                <Grid item sm={12} xs={12}>
                  <FormikTextField label="付款備註" name="remarks" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
