import * as Yup from "yup";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Formik, useFormikContext, FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikDateField from "../../../components/inputs/FormikDateField";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikTextAreaField from "../../../components/inputs/FormikTextAreaField";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { NotificationActions } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import moment from "moment";

const initialValues = {
  wsdNo: "",
  exemptionPaperExpiryDate: "",
  accessibleCardNo: "",
  welfareRemarks: "",
  allowanceList: [],
};

const validationSchema = Yup.object().shape({
  wsdNo: Yup.string().nullable(),
  exemptionPaperExpiryDate: Yup.date()
    .typeError(<FormattedMessage id="validation.message.invalidDateFormat" />)
    .nullable(),
  accessibleCardNo: Yup.string().nullable(),
  welfareRemarks: Yup.string().nullable(),
});

export const WelfareFormContent = (props) => {
  const { entity, ...rest } = props;
  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
    initialValues,
  } = useFormikContext();

  useEffect(() => {
    resetForm({ values: { ...initialValues, ...entity } });
  }, [entity]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} style={{ marginBottom: 15 }}>
        <Grid item md={6} xs={12}>
          <fieldset>
            <FormLabel component="legend">社會福利署資料</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="社會福利署檔案編號" name="wsdNo" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikDateField
                  label="豁免紙到期日"
                  name="exemptionPaperExpiryDate"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextField label="易達巴證號碼" name="accessibleCardNo" />
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormikTextAreaField label="福利備註" name="welfareRemarks" />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
        <Grid item md={6} xs={12}>
          <fieldset>
            <FormLabel component="legend">津貼種類</FormLabel>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <FieldArray
                  name="allowanceList"
                  render={(arrayHelpers) => (
                    <div>
                      <TableContainer style={{ maxHeight: 400 }}>
                        <Table
                          stickyHeader
                          padding="none"
                          style={{ marginBottom: 15 }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" style={{ padding: 5 }}>
                                項目
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ width: 50 }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.allowanceList &&
                              values.allowanceList.map((friend, index) => (
                                <TableRow key={index} disablegutters>
                                  <TableCell style={{ paddingTop: 3 }}>
                                    <FormikReferenceField
                                      edit={entity ? true : false}
                                      label="項目"
                                      name={`allowanceList.${index}.allowanceId`}
                                      apiUrl={API_URL.ALLOWANCE + "/references"}
                                      apiParams={{
                                        unpaged: true,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <DeleteOutlinedIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        color="default"
                        variant="contained"
                        type="button"
                        size="small"
                        onClick={() => arrayHelpers.push({ allowanceId: null })}
                      >
                        新增
                      </Button>
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      </Grid>
      <Button
        style={{ marginRight: 15 }}
        color="primary"
        variant="contained"
        type="submit"
        disabled={isSubmitting}
      >
        儲存
      </Button>
      <Button
        color="default"
        variant="contained"
        type="reset"
        onClick={resetForm}
      >
        重設
      </Button>
    </form>
  );
};

export const WelfareForm = (props) => {
  const { ...rest } = props;
  let { id } = useParams();
  const [entity, setEntity] = useState({});
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const createParams = (values) => {
    let params = {};

    [
      "wsdNo",
      "exemptionPaperExpiryDate",
      "accessibleCardNo",
      "wsdNo",
      "welfareRemarks",
      "allowanceList",
    ].map((key) => {
      params[key] = values[key];
    });
    if (values.exemptionPaperExpiryDate) {
      params.exemptionPaperExpiryDate = moment(
        values.exemptionPaperExpiryDate
      ).format("YYYY-MM-DD");
    }

    /*if (params.exemptionPaperExpiryDate) {
            if (typeof params.exemptionPaperExpiryDate === "string") {
                params.exemptionPaperExpiryDate = params.exemptionPaperExpiryDate.substring(0, 10);
            } else {
                params.exemptionPaperExpiryDate = format(params.exemptionPaperExpiryDate, "yyyy-MM-dd");
            }
        }*/

    return params;
  };

  const handleSubmit = (values, actions) => {
    axios
      .put(API_URL.RESIDENT + "/" + id + "/welfare", createParams(values))
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    axios
      .get(API_URL.RESIDENT + "/" + id)
      .then((response) => {
        setEntity(response.data);
        setLoaded(true);
      })
      .catch((error) => {});
  }, [id]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => loaded && <WelfareFormContent entity={entity} />}
    </Formik>
  );
};
