import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, DISCHARGE_REASON_OPTIONS } from "../../constants";
//import { DataInputForm, initialValues, validationSchema } from './components/DataInputForm';
import {
  CreateDialog,
  initialValues,
  validationSchema,
} from "./components/CreateDialog";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { NotificationActions } from "../../actions";

export default function DischargeManagementPage() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [createOpen, setCreateOpen] = useState(false);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.home = home?.home?.id;

    params.dischargeDate = moment(values.dischargeDate).format("YYYY-MM-DD");

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    if (params.dateFrom != null) {
      params.dateFrom = moment(values.dateFrom).format("YYYY-MM-DD");
    }

    if (params.dateTo != null) {
      params.dateTo = moment(values.dateTo).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {};

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      onPrint,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              {/* <Tooltip title="修改">
                                <IconButton color="default" size="small" onClick={() => { clearNotification(); setEditingEntity(params.row); }} disabled={loading}>
                                    <EditOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="">
                                <IconButton color="default" size="small" disabled={loading || true}>
                                    <ReceiptOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
              <Tooltip title="列印">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    onPrint(params.row);
                  }}
                  disabled={loading}
                >
                  <PrintOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "dischargeDate",
        headerName: "退院日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "reason",
        headerName: "退院原因",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return DISCHARGE_REASON_OPTIONS.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
      // {
      //     field: 'status', headerName: '狀態', width: 150, sortable: false, filterable: false, renderCell: function (params) {
      //         return DISCHARGE_STATUS_OPTIONS.find(elem => elem.value == params.row[params.field])?.label;
      //     }
      // },
    ];
  };

  const onCreate = (values, actions) => {
    axios
      .post(API_URL.DISCHARGE, {
        resident: values.resident,
        dischargeDate: moment(values.dischargeDate).format("YYYY-MM-DD"),
        reason: values.reason,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        actions.setSubmitting(false);
        setCreateOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        actions.setSubmitting(false);
      });
    if (values.reason == "DEATH"
      // && values.submitDeathRecordForm
    ) {
      const params = {
        resident: values.resident,
        hospitalLocation: values.hospitalLocation,
        dischargeDate: moment(values.dischargeDate).format("YYYY-MM-DD"),
        deathLocation: values.deathLocation,
        source: values.source,
        remarks: values.remarks,
      };
      if (values.deathDate) {
        params.deathDate = moment(values.deathDate).format("YYYY-MM-DD");
      }
      if (values.hospitalDate) {
        params.hospitalDate = moment(values.hospitalDate).format("YYYY-MM-DD");
      }
      if (values.deathReason) {
        params.reason = values.deathReason;
      }
      axios
        .post(API_URL.DEATH, params)
        .then((response) => {
          actions.setSubmitting(false);
        })
        .catch((error) => {
          actions.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.DISCHARGE,
      getOne: API_URL.DISCHARGE,
      create: API_URL.DISCHARGE,
      edit: API_URL.DISCHARGE,
      delete: API_URL.DISCHARGE,
      print: API_URL.DISCHARGE + "/print",
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="退院"
      editableFields={[
        "resident",
        "dischargeDate",
        "reason",
        "hospitalLocation",
        "hospitalDate",
        "deathLocation",
        "deathDate",
        "deathReason",
        "source",
        "remarks",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      //dataInputForm={DataInputForm}
      maxWidth="sm"
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setCreateOpen: setCreateOpen,
      }}
    >
      <CreateDialog
        open={createOpen}
        onClose={() => {
          setCreateOpen(null);
        }}
        onSubmit={onCreate}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
