import * as Yup from "yup";
import { useState, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { API_URL } from "../../../constants";
import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { FormikDialog } from "../../../components/dialogs";
import FormikReferenceField from "../../../components/inputs/FormikReferenceField";

export const initialValues = {
  bandCode: "",
  resident: "",
};

export const validationSchema = Yup.object().shape({
  bandCode: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
  resident: Yup.string()
    .required(<FormattedMessage id="validation.message.required" />)
    .nullable(),
});

export function AssignDialog(props) {
  const { entity, onClose, onSubmit, open, apiUrl, normalizeReceivedValues } =
    props;
  const [loaded, setLoaded] = useState(false);

  return (
    <FormikDialog
      open={open}
      dialogTitle={"分配智能手帶"}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth="xs"
    >
      <AssignIForm open={open} />
    </FormikDialog>
  );
}

const AssignIForm = (props) => {
  const home = useSelector((state) => state.home);
  const { entity, open, apiUrl = "" } = props;
  const { initialValues, values, touched, errors, setFieldValue, resetForm } =
    useFormikContext();

  useLayoutEffect(() => {
    resetForm({ values: initialValues });
  }, [open]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Card variant="outlined" style={{ marginBottom: 15 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="智能手帶編號"
                    name="bandCode"
                    apiUrl={
                      API_URL.IBEACON + "/?home=" + home.home.id
                    }
                    getOptionLabel={(option) => option.bandCode}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <FormikReferenceField
                    edit={entity ? true : false}
                    required
                    label="院友姓名"
                    name="resident"
                    apiUrl={
                      "/api/homes/" + home.home.id + "/residents/references"
                    }
                    apiParams={{
                      type: "WRIST",
                    }}
                    getOptionLabel={(option) =>
                      option.lastnameTc + option.firstnameTc
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
