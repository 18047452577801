import typography from "../typography";

export default {
  marginDense: {
    ...typography.body1
  },
  multiline: {
    ...typography.body1
  }
};
