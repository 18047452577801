import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../constants';
import AuthService from '../services/auth.service';

export const PublicRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      if (AuthService.isloggedIn()) {
        return <Redirect to={{ pathname: ROUTES.DASHBOARD, state: { from: props.location } }} />
      } else {
        return <Component {...props} />
      }
    }} />
  );
}