import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../../components";
import { API_URL, MEDICAL_ITEM_STATUS_OPTION } from "../../../constants";
// import {
//   DataInputForm,
//   initialValues,
//   validationSchema,
// } from "./treatmentItems/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./treatmentItems/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { ListingPageHeader } from "./treatmentItems/ListingPageHeader";
import { useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { EditDialog } from "./treatmentItems/EditDialog";
import { CreateDialog } from "./treatmentItems/CreateDialog";
import axios from "axios";
import { NotificationActions } from "../../../actions";
import _ from "lodash";

export default function TreatmentItems() {
  const dispatch = useDispatch();
  const home = useSelector((state) => state.home);
  let { id } = useParams();
  const [apiUrl, setApiUrl] = useState({});
  const [editEntity, setEditEntity] = useState(null);
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );
  const [loading, setLoading] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.homeId = home?.home?.id;

    //params.contact = [{ contactName: params.contactName, contactNo: params.contactTel, relationship: params.contactRelationship }];
    params.resident = id;
    params.treatmentItem = params?.treatmentItem?.id || params?.treatmentItem;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.homeId = home?.home?.id;

    if (params.dateFrom != null) {
      params.dateFrom = moment(values.dateFrom).format("YYYY-MM-DD");
    }

    if (params.dateTo != null) {
      params.dateTo = moment(values.dateTo).format("YYYY-MM-DD");
    }

    return params;
  };

  const normalizeReceivedValues = (values) => {
    values.treatmentItem = values.treatmentItem.id;

    return values;
  };

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const {
      setEditingEntity,
      setDeletingEntity,
      setCopyEntity,
      clearNotification,
      loading,
    } = props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditEntity(params.row);
                    setEditOpen(true);
                  }}
                  disabled={loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="另存">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setCopyEntity(params.row);
                  }}
                  disabled={loading}
                >
                  <FileCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="列印">
                                <IconButton color="default" size="small" disabled={loading}>
                                    <PrintOutlinedIcon />
                                </IconButton>
                            </Tooltip> */}
            </div>
          );
        },
      },
      {
        field: "resident",
        headerName: "院友姓名",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            params.row[params.field].lastnameTc +
            params.row[params.field].firstnameTc
          );
        },
      },
      {
        field: "bed",
        headerName: "床位",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["resident"]?.bed?.bedNo;
        },
      },
      {
        field: "date",
        headerName: "醫療項目日期",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["dateTime"]?.substring(0, 10);
        },
      },
      {
        field: "time",
        headerName: "醫療項目時間",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row["dateTime"]?.substring(11, 16);
        },
      },
      {
        field: "treatmentItem",
        headerName: "醫療項目",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]?.nameTc;
        },
      },
      {
        field: "finishedDateTime",
        headerName: "完成醫療項目日期及時間",
        width: 200,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field]
            ? params.row[params.field]?.substring(0, 10) +
                " " +
                params.row[params.field]?.substring(11, 16)
            : "--";
        },
      },
      {
        field: "remarks",
        headerName: "備註",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field] ? params.row[params.field] : "--";
        },
      },
      {
        field: "status",
        headerName: "醫療項目狀態",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return MEDICAL_ITEM_STATUS_OPTION.find(
            (elem) => elem.value == params.row[params.field]
          )?.label;
        },
      },
    ];
  };

  const onCreate = (values, formikActions) => {
    const submitFollowupForm = values.submitFollowupForm;
    const params = values;

    const treatmentParams = _.pick(params, [
      "dateTime",
      "treatmentItem",
      "finishedDateTime",
    ]);
    treatmentParams.resident = id;
    treatmentParams.remarks = params.treatmentRemarks;
    treatmentParams.status = params.treatmentStatus;

    // post treatment
    axios
      .post(API_URL.RESIDENT_TREATMENT_ITEM + "/", treatmentParams)
      .then((response) => {
        // if not submit followup form, then dispatch, close, and return
        if (!submitFollowupForm) {
          dispatch(NotificationActions.success(null, "已儲存"));
          formikActions.setSubmitting(false);
          setCreateOpen(false);
          setReload(!reload);
          return;
        }

        // if submit followup form, then posting followup
        const followupParams = _.pick(params, [
          "date",
          "specialist",
          "institution",
          "medicalFollowUpItems",
          "transport",
          "escorterName",
          "escorterRelationship",
        ]);
        followupParams.resident = [id];
        followupParams.remarks = params.followupRemarks;
        followupParams.status = params.followupStatus;
        axios
          .post(API_URL.RESIDENT_FOLLOW_UP + "/", followupParams)
          .then((response) => {
            dispatch(NotificationActions.success(null, "已儲存"));
            formikActions.setSubmitting(false);
            setCreateOpen(false);
            setReload(!reload);
          })
          .catch((error) => {
            dispatch(NotificationActions.error(null, "錯誤"));
            formikActions.setSubmitting(false);
          });
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
  }

  const onEdit = (values, formikActions) => {
    axios
      .put(API_URL.RESIDENT_TREATMENT_ITEM + "/" + values.id, {
        resident: id,
        remarks: values.remarks,
        treatmentItem: values.treatmentItem,
        status: values.status,
        dateTime: values.dateTime,
        finishedDateTime: values.finishedDateTime,
      })
      .then((response) => {
        dispatch(NotificationActions.success(null, "已儲存"));
        setEditEntity(null);
        formikActions.setSubmitting(false);
        setReload(!reload);
        setEditOpen(false);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(null, "錯誤"));
        formikActions.setSubmitting(false);
      });
    if (values.status == "COMPLETED" && values.nextDate !== "") {
      axios.post(API_URL.RESIDENT_TREATMENT_ITEM, {
        resident: id,
        remarks: values.remarks,
        treatmentItem: values.treatmentItem,
        status: "APPOINTMENT",
        dateTime: values.nextDate,
        finishedDateTime: "",
      });
    }
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.RESIDENT_TREATMENT_ITEM + "?residentId=" + id,
      getOne: API_URL.RESIDENT_TREATMENT_ITEM,
      create: API_URL.RESIDENT_TREATMENT_ITEM,
      edit: API_URL.RESIDENT_TREATMENT_ITEM,
      delete: API_URL.RESIDENT_TREATMENT_ITEM,
      copy: API_URL.RESIDENT_TREATMENT_ITEM,
      export: API_URL.RESIDENT_TREATMENT_ITEM + "/export?residentId=" + id,
    });
  }, [home, reload]);

  return (
    <EntityManagement
      entityModel="醫療項目"
      editableFields={[
        "resident",
        "dateTime",
        "treatmentItem",
        "finishedDateTime",
        "remarks",
        "status",
      ]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      // dataInputForm={DataInputForm}
      // initialValues={initialValues}
      // validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
        setCreateOpen: setCreateOpen,
      }}
    >
      <CreateDialog
        open={createOpen}
        onClose={() => {
          setCreateOpen(null);
        }}
        onSubmit={onCreate}
      />
      <EditDialog
        open={editOpen}
        entity={editEntity}
        onClose={() => {
          setEditEntity(null);
          setLoading(false);
          setEditOpen(false);
        }}
        onSubmit={onEdit}
        normalizeReceivedValues={normalizeReceivedValues}
        apiUrl={apiUrl}
      />
    </EntityManagement>
  );
}
