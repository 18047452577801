import moment from 'moment';
import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import axios from 'axios';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import fileDownload from "js-file-download";

export const initialValues = {

}

export function PrintDialog(props) {
    const { open, entity, onClose, onSubmit, apiUrl, normalizeReceivedValues } = props;
    const [entityValues, setEntityValues] = useState();
    const [loaded, setLoaded] = useState(false);

    const onPrint = (values, formikActions) => {
        axios.get((apiUrl.print ? (apiUrl.print + "Payments/printDeposit/" + values) : apiUrl), { responseType: 'blob' }).then(function (response) {
            let data = response.data;
            fileDownload(data, moment().format('YYYY-MM-DD_HH_mm_SS') + "_按金收據_print.docx");
        })
    }

    const onPrintReturn = (values, formikActions) => {
        axios.get((apiUrl.print ? (apiUrl.print + "/print/returnDeposit/" + values) : apiUrl), { responseType: 'blob' }).then(function (response) {
            let data = response.data;
            fileDownload(data, moment().format('YYYY-MM-DD_HH_mm_SS') + "_退還按金_print.docx");
        })
    }


    useEffect(() => {
        if (entity) {
            axios.get((apiUrl.getOne ? apiUrl.getOne : apiUrl) + '/' + entity.id).then((response) => {
                let data = response?.data;
                let entityValues = { ...initialValues, ...data };
                normalizeReceivedValues(entityValues);
                setEntityValues(entityValues);
            }).catch((error) => {
            }).finally(() => {
                setLoaded(true);
            });
        } else {
            setEntityValues(initialValues);
        }
    }, [entity]);

    return (

        <Dialog
            open={open}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"列印 (" + entityValues?.invoiceId + ")"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Card variant="outlined" style={{ marginBottom: 15 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12}>
                                    <ButtonGroup variant="outlined" orientation="vertical" >
                                        <Button size="large" variant="outlined" onClick={() => onPrint(entityValues?.id)} fullWidth startIcon={<ConfirmationNumberOutlinedIcon />}>
                                            按金收據
                                        </Button>
                                        <Button size="large" variant="outlined" onClick={() => onPrintReturn(entityValues?.id)} fullWidth startIcon={<ReceiptOutlinedIcon />}>
                                            按金退還確認書
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={onClose}>
                    關閉
                </Button>
            </DialogActions>
        </Dialog>
    );
}

