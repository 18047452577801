import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityManagement } from "../../components";
import { API_URL, STAFF_ROLE_SECTION_OPTIONS } from "../../constants";
import {
  DataInputForm,
  initialValues,
  validationSchema,
} from "./components/DataInputForm";
import {
  FilteringForm,
  initialFilteringValues,
  filteringValidationSchema,
} from "./components/FilteringForm";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { ListingPageHeader } from "./components/ListingPageHeader";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";

export default function RoleManagementPage() {
  const home = useSelector((state) => state.home);
  const [apiUrl, setApiUrl] = useState({});
  const [filteringParams, setFilteringParams] = useState(
    initialFilteringValues
  );

  const createParams = (values, coreCreateParams) => {
    let params = {};
    params = coreCreateParams(values);

    params.homeId = home?.home?.id;

    params.permissions = values.permissions;

    return params;
  };

  const createFilteringParams = (values, coreCreateFilteringParams) => {
    let params = {};
    params = coreCreateFilteringParams(values);

    params.home = home?.home?.id;

    return params;
  };

  const normalizeReceivedValues = (values) => {};

  const handleFilterChange = (values) => {
    setFilteringParams(values);
  };

  const getColumnSettings = (props) => {
    const { setEditingEntity, setDeletingEntity, clearNotification, loading } =
      props;

    return [
      {
        field: "action",
        headerName: "動作",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <div>
              <Tooltip title="修改">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setEditingEntity(params.row);
                  }}
                  disabled={params.row["editable"] == false || loading}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="刪除">
                <IconButton
                  color="default"
                  size="small"
                  onClick={() => {
                    clearNotification();
                    setDeletingEntity(params.row);
                  }}
                  disabled={params.row["deletable"] == false || loading}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
              {/* <IconButton color="default" size="small" disabled={loading} style={{ display: 'none' }}>
                                <LocalAtmOutlinedIcon />
                            </IconButton>
                            <IconButton color="default" size="small" disabled={loading} style={{ display: 'none' }}>
                                <PrintOutlinedIcon />
                            </IconButton> */}
            </div>
          );
        },
      },
      {
        field: "name",
        headerName: "群組名稱(英文)",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      {
        field: "nameTc",
        headerName: "群組名稱(中文)",
        width: 150,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return params.row[params.field];
        },
      },
      ...STAFF_ROLE_SECTION_OPTIONS.map((item) => ({
        field: item.value,
        headerName: item.label,
        width: 135,
        sortable: false,
        filterable: false,
        renderCell: function (params) {
          return (
            <div>
              <Table size="small">
                <TableBody>
                  {item.children.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell width="100" style={{ padding: 1 }}>
                          {row.label}
                        </TableCell>
                        <TableCell style={{ padding: 1 }}>
                          {params.row["permissions"] &&
                          params.row["permissions"].includes(row.value) ? (
                            <DoneIcon
                              fontSize="small"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <CloseIcon
                              fontSize="small"
                              style={{ color: "red" }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          );
        },
      })),
    ];
  };

  useEffect(() => {
    setApiUrl({
      getAll: API_URL.ROLE_MANAGEMENT,
      getOne: API_URL.ROLE_MANAGEMENT,
      create: API_URL.ROLE_MANAGEMENT,
      edit: API_URL.ROLE_MANAGEMENT,
      delete: API_URL.ROLE_MANAGEMENT,
    });
  }, [home]);

  return (
    <EntityManagement
      entityModel="用戶群組"
      editableFields={["code", "name", "nameTc"]}
      getColumnSettings={getColumnSettings}
      createParams={createParams}
      createFilteringParams={createFilteringParams}
      normalizeReceivedValues={normalizeReceivedValues}
      filteringParams={filteringParams}
      apiUrl={apiUrl}
      dataInputForm={DataInputForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      pageHeader={ListingPageHeader}
      pageHeaderProps={{
        handleFilterChange: handleFilterChange,
      }}
      maxWidth="sm"
      rowHeight={300}
    />
  );
}
